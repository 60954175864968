import React from "react";
import { DashboardLayout } from "@/components/layouts";
import { useAdvertisementStyles, useMasterListStyles } from "@/static/stylesheets/screens";
import { SectionHeader } from "@/components/molecules/Bazaars";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { FeaturesTab } from "@/components/organisms/FeaturesTab";
import { MasterListGrid } from "@/components/molecules/Admin/MasterListGrid";
import AdvertisementListGrid from "@/components/molecules/AdvertisementListGrid/AdvertisementListGrid";

const Advertisement = () => {
  const classes = useMasterListStyles();

  return (
    <>
       <DashboardLayout>
        <div className={classes.root}>
          <div className={classes.pageTitle}>Ads</div>

          <FeaturesTab
            items={[
              {
                label: "Advertisement Clients",
                content: <AdvertisementListGrid type={"advertisementClient"} />,
              },
              {
                label: "Ads",
                content: <AdvertisementListGrid type={"ads"} />,
              },
              {
                label: "Plans",
                content: <AdvertisementListGrid type={"plan"} />,
              },
              {
                label: "Invoices",
                content: <AdvertisementListGrid type={"invoice"} />,
              },
            ]}
          />
        </div>
      </DashboardLayout>
    </>
  );
};

export default Advertisement;
