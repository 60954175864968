import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { getUserId, getWholeSellerId } from "@/helper"

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

//----------------- Get Top products -----------------//
export const TopProductsList: any = createAsyncThunk('TopProductsList', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${getUserId()}/dashboard/top-products/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const TopProductsListdata = createSlice({
  name: 'TopProductsList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      TopProductsList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      TopProductsList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      TopProductsList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export const DashboardReport: any = createAsyncThunk('DashboardReport', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`/api/wholeseller/data/${getUserId()}/report/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const DashboardReportSlice = createSlice({
  name: 'DashboardReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DashboardReport.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      DashboardReport.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DashboardReport.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

//----------------- Get categoriesList -----------------//
export const categoriesList: any = createAsyncThunk('categoriesList', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${getUserId()}/dashboard/categories/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const categoriesListdata = createSlice({
  name: 'categoriesList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      categoriesList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      categoriesList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      categoriesList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

//----------------- Get subCategoriesList -----------------//
export const subCategoriesList: any = createAsyncThunk('subCategoriesList', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${getUserId()}/dashboard/sub-categories/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const subCategoriesListdata = createSlice({
  name: 'subCategoriesList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      subCategoriesList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      subCategoriesList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      subCategoriesList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//----------------- Get adsPerformance -----------------//
export const adsPerformance: any = createAsyncThunk('adsPerformance', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${id}/dashboard/ads-performance/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const adsPerformancedata = createSlice({
  name: 'adsPerformance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      adsPerformance.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      adsPerformance.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      adsPerformance.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//----------------- Get top-branches -----------------//
export const topBranches: any = createAsyncThunk('topBranches', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${getUserId()}/dashboard/top-branches/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const topBranchesdata = createSlice({
  name: 'topBranches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      topBranches.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      topBranches.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      topBranches.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

//----------------- Get total-product -----------------//
export const totalProduct: any = createAsyncThunk('totalProduct', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${id}/dashboard/total-product/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const totalProductdata = createSlice({
  name: 'totalProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      totalProduct.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      totalProduct.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      totalProduct.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//----------------- Get total-order -----------------//
export const totalOrder: any = createAsyncThunk('totalOrder', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${id}/dashboard/total-order/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const totalOrderdata = createSlice({
  name: 'totalOrder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      totalOrder.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      totalOrder.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      totalOrder.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//----------------- Get total-income / total revenue -----------------//
export const totalIncome: any = createAsyncThunk('totalIncome', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${id}/dashboard/total-income/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const totalIncomedata = createSlice({
  name: 'totalIncome',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      totalIncome.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      totalIncome.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      totalIncome.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

//----------------- Get total-income / total revenue -----------------//
export const newRetailers: any = createAsyncThunk('newRetailers', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/data/${id}/dashboard/new-retailers/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const newRetailersdata = createSlice({
  name: 'newRetailers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      newRetailers.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      newRetailers.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      newRetailers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})