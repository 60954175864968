import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Radio } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Iprops {
  name?: string
  colorcode?: string
  loopData?: any
  checkbox?: boolean
}


export default function TwMultiSelect(props: Iprops) {
  const [rediochangevalue,setRedioChangevalue] = useState<any>([])
  
  const hendelrediochange = (value:any) =>{
      rediochangevalue.push(value)
      setRedioChangevalue([...rediochangevalue])
  }
  return (
    <Menu as="div" className="relative inline-block text-left select-box">
      <div>
        <Menu.Button className={`inline-flex h-[36px] justify-center rounded-[4px] border bg-[${props?.colorcode ? props?.colorcode : 'white'}] font-[600] px-4 py-2 text-sm text-['14px'] text-[${props?.colorcode ? '#FBFAFC' : '#84818A'}] shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}>
          {props.name || "product"}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {
              props?.loopData && props?.loopData?.map((item: any) => (
                <Menu.Item>
                  {({ active }) => (
                    <menu
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      { props.checkbox ? <CheckBox/> : <Radio id="radio" name={item?.category_name || item?.subcategory_name || item} checked={rediochangevalue.includes(item?.category_name || item?.subcategory_name || item?.label || String(item))} onChange={(e:any)=>hendelrediochange(e.target.name)}/> }
                      {item?.category_name || item?.subcategory_name || item?.label || item}
                    </menu>
                  )}
                </Menu.Item>
              ))
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}