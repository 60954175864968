import React, { useEffect, useState } from "react";
import { useDispatch, AppDispatch } from "@/Store";
import { CreateCategoryPlan, EditCategoryPlan, EditSubCategoryPlan, createSubCategoryPlan } from "@/Store/Sclicese/PlanSlice";

type props = {
  title: string,
  updated_date: any,
  cash_discount_type?: string,
  cash_value?: number,
  platinum_discount_type?: string,
  platinum_value?: number,
  diamond_discount_type?: string,
  diamond_value?: number,
  gold_discount_type?: string,
  gold_value?: number,
  bronze_discount_type?: string,
  bronze_value?: number,
  retailer_type?: number[],
  branch?: number,
  category?: number,
  sub_category?: number,
  id: any,
  handleOpen: any
}
const ItemRow = ({ title, updated_date, cash_discount_type,
  cash_value,
  platinum_discount_type,
  platinum_value,
  diamond_discount_type,
  diamond_value,
  gold_discount_type,
  gold_value,
  bronze_discount_type,
  bronze_value,
  retailer_type,
  branch,
  category,
  sub_category,
  id,
  handleOpen
}: props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({
    cash_discount_type,
    cash_value,
    platinum_discount_type,
    platinum_value,
    diamond_discount_type,
    diamond_value,
    gold_discount_type,
    gold_value,
    bronze_discount_type,
    bronze_value
  })

  useEffect(() => {
    setData({
      ...data,
      cash_discount_type,
      cash_value,
      platinum_discount_type,
      platinum_value,
      diamond_discount_type,
      diamond_value,
      gold_discount_type,
      gold_value,
      bronze_discount_type,
      bronze_value
    })
  }, [retailer_type])

  const handleChange = (plan: any, value: any, type: string) => {
    setData({
      ...data,
      [plan.value]: value,
      [plan.type]: type
    })
  }

  const handleSubmit = () => {
    const postData = {
      ...data,
      last_update_date: new Date().toJSON(),
      retailer_type,
      branch,
    }
    if (category) {
      postData.category = category
      submitCategoryPlan(postData)
    }
    if (sub_category) {
      postData.sub_category = sub_category
      submitSubCategoryPlan(postData)
    }
  }

  const submitCategoryPlan = (postData: any) => {
    if (id && id > 0) {
      postData.id = id
      dispatch(EditCategoryPlan(postData));
    } else {
      dispatch(CreateCategoryPlan(postData));
    }
  }

  const submitSubCategoryPlan = (postData: any) => {
    if (id && id > 0) {
      postData.id = id
      dispatch(EditSubCategoryPlan(postData));
    } else {
      dispatch(createSubCategoryPlan(postData));
    }
  }


  const planTypes = [
    {
      type: 'cash_discount_type',
      value: 'cash_value'
    },
    {
      type: 'platinum_discount_type',
      value: 'platinum_value'
    },
    {
      type: 'diamond_discount_type',
      value: 'diamond_value'
    },
    {
      type: 'gold_discount_type',
      value: 'gold_value'
    },
    {
      type: 'bronze_discount_type',
      value: 'bronze_value'
    }
  ]

  return (<tr className="border-b align-baseline">
    <th
      scope="row"
      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
    >
      <div className="flex gap-[20px] items-center ">
        <p className="mb-3 text-[14px] font-[600] text-black font-Manrope cursor-pointer">
          {title}
        </p>
      </div>
    </th>
    {
      planTypes.map((plan) => {
        return <td className="py-4 px-3  color-[#2E2C34;]">
          <div className="planCard">
            <div className="flex gap-2 justify-center items-center">
              <div className="planCardChild flex">
                <input style={{ width: '30px' }} value={data[plan.type] === 'PERCENTAGE' ? data[plan?.value] : ''} onChange={(e) => handleChange(plan, e.target.value, 'PERCENTAGE')} />%
              </div>
              <p>or</p>
              <div className="planCardChild flex">
                <input style={{ width: '40px' }} value={data[plan.type] === 'RUPEE' ? data[plan?.value] : ''} onChange={(e) => handleChange(plan, e.target.value, 'RUPEE')} />
              </div>
            </div>
          </div>
        </td>
      })
    }
    <td className="py-4 px-6 color-[#2E2C34;]">
      <p className="text-[14px] font-medium font-[600] text-black">24 Jun,2021 10:30pm</p>
    </td>
    <td className="py-4 px-6 color-[#2E2C34;]">
      <div className="text-center">
        <button onClick={() => handleSubmit()} className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent rounded">
          APPLY
        </button>
      </div>
    </td>
    <td className="py-4 px-3 color-[#2E2C34;]" onClick={handleOpen}>
      <p className="text-[14px] font-medium font-[600] text-black">Log</p>
    </td>
  </tr>

  );
};

export default ItemRow;
