import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

const PlanSearch: any = createAsyncThunk('PlanSearch', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/plans/data/?search=${data}`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}



export const PlanSearchdata = createSlice({
  name: 'PlanSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      PlanSearch.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      PlanSearch.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      PlanSearch.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default PlanSearch