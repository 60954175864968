import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import { Box, Checkbox, Dialog, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Pagination, Radio, Select, SelectChangeEvent } from "@mui/material";
import { GetCategoryListPlan, GetCategoryPlan, SubCategoryListPlan, SubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
import ItemRow from "./ItemRow";
import { SectionHeader } from "@/components/molecules/Bazaars";
import UserProduts from "@/Store/Sclicese/WholesellerProducts";
import { GetProductPricing } from "@/Store/Sclicese/ProductDetail";
import { useProductsStyles } from "@/static/stylesheets/screens/productsStyles";
import { AppService } from "@/service/AllApiData.service";
import moment from "moment";
import { log } from "console";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const ProductStock = () => {
  const classes = useBranchPlanStyles();
  const classes1 = useProductsStyles();
  const wholesellerproductdata = useSelector(state => state.Wholesellerproduct.data)
  const GetCategoryPlanListData: any = useSelector<any>(state => state?.GetCategoryListPlanData?.data)
  const GetProductPricingData: any = useSelector<any>(state => state?.GetGetProductPricingData?.data?.results)
  const SubCategoryPlanListData: any = useSelector<any>(state => state?.SubCategoryListPlanData?.data)
  const GetCategoryPlanData: any = useSelector<any>(state => state?.GetCategoryPlanData?.data?.results)
  const SubCategoryPlanData: any = useSelector<any>(state => state?.SubCategoryPlanData?.data?.results)
  const ItemWisePlanData: any = useSelector<any>(state => state?.ItemWisePlanData?.data?.results)
  const GetBranch: any = useSelector<any>(state => state?.GetBranch?.data?.results)
  const [CategoryValue, setCategoryValue] = React.useState<string[]>(["All_Category"]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch()
  const localStoragedata = JSON.parse(localStorage.getItem("Wholeseller_id") || "")
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)

  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedType, setselectedType] = useState<any>({});
  const ProductBazaar = useSelector(state => state?.ProductBazaarList?.data?.results)
  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const [groupCategories, setGroupCategories] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [subCategories, setSubCategories] = useState<any>([]);
  const [selectedGroupCategories, setSelectedGroupCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelecetedSubCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<any>([]);
  const [totalCount, SetTotalCount] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [showLog, setShowLog] = useState<any>(false);
  const [logs, setLogs] = useState<any>([]);

  // useEffect(()=>{
  //   if(Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0){
  //     handleGroupClick(CategoryGroupData[0])
  //   }
  // },[CategoryGroupData])

  // useEffect(()=>{
  //   if(Array.isArray(CategoryData) && CategoryData.length > 0){
  //     handleCategoryClick(CategoryData[0])
  //   }
  // },[CategoryData])

  // useEffect(()=>{
  //   if(Array.isArray(SubCategoryData) && SubCategoryData.length > 0){
  //     handleSubCategoryClick(SubCategoryData[0])
  //   }
  // },[SubCategoryData])


  useEffect(() => {
    console.log("logger123...", wholesellerproductdata)
    let groupCategories: { label: any; value: any; }[] = [];
    let categories: { label: any; value: any; }[] = [];
    let subCategories: { label: any; value: any; }[] = [];

    setProducts(wholesellerproductdata);
    SetTotalCount(wholesellerproductdata?.length);
    wholesellerproductdata?.map((item: any) => {
      groupCategories.push({
        label: item?.category_group?.parent_category_name,
        value: item?.category_group?.id
      })
      categories.push({
        label: item?.category?.category_name,
        value: item?.category?.id
      })
      subCategories.push({
        label: item?.subcategory?.subcategory_name,
        value: item?.subcategory?.id
      })
    })

    groupCategories = removeDuplicates(groupCategories);
    categories = removeDuplicates(categories);
    subCategories = removeDuplicates(subCategories);

    setGroupCategories(groupCategories);
    setCategories(categories);
    setSubCategories(subCategories);

    console.log("logger123...", groupCategories, categories, subCategories)

  }, [wholesellerproductdata])

  const removeDuplicates = (array: any) => {

    let uniqueArray = Array.from(new Set(array.map((item: any) => item.value)))
      .map(value => {
        return array.find((item: any) => item.value === value);
      });

    return uniqueArray;
  }

  useEffect(() => {
    dispatch(UserProduts())
    dispatch(CategoryGroup(bazaarId))
  }, [])

  useEffect(() => {
    dispatch(GetBranchList(localStoragedata)).then((e: any) => {
      console.log('localStoragedata', e)
    })
    dispatch(MasterRetailerType())
  }, [])



  const showLogs = async (productId:any) => {
    try{
      // alert(productId)
      const response = await AppService.GetProductStockDetails({product: productId});
      setLogs(response.data.results || []);
      setShowLog(true);
    } catch (error:any) {
      console.log("ERROR ====> ",error)
    }
  }

  // useEffect(() => {
  //   if (GetCategoryPlanListData?.length && GetCategoryPlanData) {
  //     let array: any = []
  //     GetCategoryPlanListData[0]?.map((x: any) => {
  //       GetCategoryPlanData?.map((z: any) => {
  //         if (x.id == z.category) {
  //           array.push(z)
  //         }
  //       })
  //     })
  //     setMapData(array)
  //   }

  // }, [GetCategoryPlanListData?.length, GetCategoryPlanData])

  useEffect(() => {
    if (CategoryValue.includes('All_Category')) {
      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item])
      })
    }
  }, [CategoryValue, SubCategoryValue, ItemWiseValue, GetCategoryPlanListData, GetCategoryPlanData])



  const handleChange = async (event: SelectChangeEvent<typeof CategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    await setCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );
    if (value == "All_Category") {
      setCategoryValue(["All_Category"])

      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      GetCategoryPlanData?.map((item: any) => {
        if (value == item?.category_name[0]) {
          setSubCategoryValue(["All_Sub-Categories"])
        }
      })
    }
  };

  const subhandleChange = (event: SelectChangeEvent<typeof SubCategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setSubCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_Sub-Categories") {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      SubCategoryPlanListData[0]?.map((item: any) => {
        if (value == item.subcategory_name) {
          SubCategoryPlanData.map((x: any) => {
            if (item.id == x.sub_category) {
              setItemWiseValue(["All_items"])
            }
          })
        }
      })
    }
  };


  const itemhandleChange = (event: SelectChangeEvent<typeof ItemWiseValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setItemWiseValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_items") {
      setMapData(ItemWisePlanData)
    }
    else {
      ItemWisePlanData?.map((item: any) => {
        if (value == item.product_details[0].product_name) {
          setMapData([item])
        }
      })
    }
  };


  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))

  const branchFilterOptions = GetBranch?.map((item: any) => ({
    label: item?.branch_name,
    value: item?.id
  }))


  const [filterData, setFilterData] = useState({
    group_category: null,
    category: null,
    sub_category: null,
    retailer: null,
    branch: 0,
    mode: 'Category'
  })


  useEffect(() => {
    if (filterData?.branch > 0) {
      dispatch(GetProductPricing(filterData?.branch))
    }
  }, [filterData?.branch])

  useEffect(() => {
    if (Array.isArray(GetBranch) && GetBranch.length > 0) {
      setFilterData({
        ...filterData,
        branch: GetBranch.filter((brn: any) => brn.main_branch)[0]?.id
      })
    }
  }, [GetBranch])

  useEffect(() => {
    if (Array.isArray(MasterRetailerTypeData) && MasterRetailerTypeData.length > 0) {
      setFilterData({
        ...filterData,
        retailer: MasterRetailerTypeData[0]?.id
      })
    }
  }, [MasterRetailerTypeData])

  const handleChangeMaster = (selectboxName: string, id: any) => {

    if (selectboxName === 'Group Category') {

      dispatch(clearCategoryState())
      dispatch(clearSubCategoryState())
      dispatch(CategorySlice({ bazaarId: bazaarId, id }))
      setFilterData({
        ...filterData,
        group_category: id,
        category: null,
        mode: 'Category'
      })

      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === id))
    }
    if (selectboxName === 'Category') {
      dispatch(clearSubCategoryState())
      dispatch(SubCategorySlice({ bazaarId: bazaarId, category: id, id: filterData.group_category }))
      setFilterData({
        ...filterData,
        category: id,
        mode: 'Sub Category'
      })

      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === filterData.group_category && item?.category?.id === id))

    }
    if (selectboxName === 'Sub Category') {
      setFilterData({
        ...filterData,
        sub_category: id
      })

      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === filterData.group_category && item?.category?.id === filterData.category && item?.subcategory?.id === id))

    }

    if (selectboxName === 'Branch') {
      setFilterData({
        ...filterData,
        branch: id
      })
    }

  }


  const handlePageChange = (event: any, value: any) => {

    setCurrentPage(value);
  };


  console.log(GetProductPricingData, "===product pr", wholesellerproductdata)
  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <SectionHeader />
          <div>

            <div className="flex justify-between items-center mb-5">
              <p className="py-[10px] font-semibold text-2xl" >Product Stock</p>
              <SearchField icontype={false} inputdata={setSearch} className="ml-[40px]" />
            </div>

            <div className="flex gap-5 mb-[50px]">
              {/* <TwMultiSelect name="Select City" /> */}
              {/* <TwMultiSelect name="Select Branch" loopData={}/> */}
              {branchFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Branch"}
                hint={"Select Branch"}
                options={branchFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={branchFilterOptions?.filter((item: any) => filterData.branch == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              />}

              {groupCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Group Category"}
                hint={"Select Group Category"}
                options={groupCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {categoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Category"}
                hint={"Select Category"}
                options={categoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {subCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Sub Category"}
                hint={"Select Sub Category"}
                options={subCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
              />}

              {/* <CommonSelectElectronicBazaar
                label={"Group Category"}
                hint={"Select Group Categories"}
                options={groupCategories}
                handleSelect={handleChangeMaster}
                selectedIds={groupCategories?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              />

              <CommonSelectElectronicBazaar
                label={"Category"}
                hint={"Select Category"}
                options={categories}
                handleSelect={handleChangeMaster}
                selectedIds={categories?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              />

              <CommonSelectElectronicBazaar
                label={"Sub Category"}
                hint={"Select Sub Category"}
                options={subCategories}
                handleSelect={handleChangeMaster}
                selectedIds={subCategories?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              /> */}



            </div>

            {/* <div className="flex gap-5 py-[20px]">
            {
              MasterRetailerTypeData.map((rtl: any) => {
                return <ActionButton
                  title={rtl.retailer_type_name}
                  size="large"
                  onClick={()=>  setFilterData({
                    ...filterData,
                    retailer: rtl.id
                  })}
                  variant={rtl.id === filterData.retailer ? "primary" : 'default'}
                />
              })
            }
            </div> */}

            <div className="flex justify-between">
              <div className="flex gap-5">

              </div>
              {/* <div>
                <SearchField icontype/>
              </div> */}
            </div>

            {/* { Array.isArray(wholesellerproductdata) && GetProductPricingData ? <div> */}
            <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] ">
                  <tr className="tableTitle color-[#2E2C34;]">
                    <th scope="col" className="py-3 px-3">
                      Item Name
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Stock
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Update Operation
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Update Stock
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Min Quantity
                    </th><th scope="col" className="py-3 px-6">
                      Max Quantity
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Apply
                    </th>
                    <th scope="col" className="py-3 px-6">
                      LAST UPDATED
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Logs
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {GetProductPricingData && products?.slice(10 * (currentPage - 1), 10 * currentPage)?.filter((item: any) => item?.product_name?.toLowerCase()?.includes(search))?.map((item: any) => {
                    const isExist = GetProductPricingData?.filter((itm: any) => itm?.product === item.id)[0] || {}
                    return (
                      <ItemRow title={item.product_name} stock={item.product_stocks} updated_date={item.updated_date}
                        category={item.id} product={item.id} id={item?.id} new_stock={""} key={item.id} branch={filterData.branch} setShowLog={showLogs} item={item} reload={() => dispatch(UserProduts())} />
                    )
                  })}
                </tbody>
              </table>
            </div>

            <Pagination
              count={Math.ceil(totalCount / 10)}
              page={currentPage}
              onChange={handlePageChange}
            />

            <div>
              <Dialog
                open={showLog}
                maxWidth={"xl"}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "6px",
                  },
                }}
                onClose={() => setShowLog(false)}
              >
                <div className={`${classes1.addDialog} w-full !p-0 !px-[56px]`}>
                  <div className="flex justify-between modalHead items-center">
                    <p className="reportTitle tracking-[1px] py-[35px]">Logs</p>
                    <div onClick={() => setShowLog(false)}>
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                        <line
                          x1="24.7305"
                          y1="12.4228"
                          x2="12.4268"
                          y2="24.7265"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                        <line
                          x1="24.3104"
                          y1="24.7266"
                          x2="12.0068"
                          y2="12.4229"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="flex gap-10 pt-[20px] py-[100px] items-center">

                    <div className="w-full  overflow-x-auto relative pt-[40px] pb-[40px]">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] ">
                          <tr className="tableTitle color-[#2E2C34;]">
                            <th scope="col" className="py-3 px-3">
                              Particulars
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Opening Stock
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Closing Stock
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Reason
                            </th>
                            <th scope="col" className="py-3 px-6">
                              LAST UPDATED
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {logs && logs?.map((item: any) => {
                            // const isExist = GetProductPricingData?.filter((itm: any) => itm?.product === item.id)[0] || {}
                            return (
                              <>
                                <tr className="border-b align-baseline">
                                  <th
                                    scope="row"
                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                  >
                                    <div className="flex gap-[20px] items-center ">
                                      <p className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope cursor-pointer">
                                        {item.trigger === 1 ? "Increased By" : "Decreased By"} {item?.qty}
                                      </p>
                                    </div>
                                  </th>
                                  <td className="py-4 px-6  color-[#2E2C34;]">
                                    <div className="priceTitle"> {item.opening_qty}</div>
                                  </td>
                                  <td className="py-4 px-6  color-[#2E2C34;]">
                                    {/* <div className="bg-[#FFF0EE] p-[10px] flex justify-center rounded-md w-[160px]"> */}
                                    {/* <p className="updatePrice"></p> */}
                                    <div className="priceTitle">{item?.closing_qty}</div>

                                    {/* </div> */}
                                  </td>
                                  <td className="py-4 px-6  color-[#2E2C34;]">
                                    <div className="priceTitle"> {item.reason}</div>
                                  </td>
                                  <td className="py-4 px-6 color-[#2E2C34;]">
                                    <p className="text-[14px] font-medium font-[600] text-black">{moment(item?.change_date).format("DD-MM-YYYY, HH:MM:SS A")}</p>
                                  </td>

                                </tr>

                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                  </div>



                </div>
              </Dialog>
            </div>

            {/* </div> 
            : <div className="text-center p-3">
              <div>
              Select branch
              </div>
            </div>
            } */}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default ProductStock;
