import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { usAddRetailerStyles } from "@/static/stylesheets/screens/addretailerStyle";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useNavigate } from "react-router-dom";
import { EditRetaier, PostRetailer, getIdRetaier, retailerNumber, getretailerNumber } from "@/Store/Sclicese/Retailerssclices";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import { useParams } from "react-router-dom";
import { GetCityList, GetCityListFromDistrict } from "@/Store/Sclicese/CityListSlice";
import { GetdistrictList, GetdistrictListFromState } from "@/Store/Sclicese/DistrictListSlice";
import PhoneInput from "react-phone-input-2";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
import { GetstateList } from "@/Store/Sclicese/StateListSlice";
import { getWholeSellerId, planTypesData } from "@/helper";
import { GetBranchList } from "@/Store/Sclicese/BranchSlice";


const AddRetailers = () => {
  const classes = usAddRetailerStyles();
  const dispatch = useDispatch()
  const CityListData: any = useSelector(state => state?.CityListData?.data)
  const retailerNumberdata: any = useSelector(state => state?.getretailerNumberdata?.data?.results)
  const DistrictListData: any = useSelector(state => state?.DistrictListdata?.data)
  const navigate = useNavigate()
  const { editId } = useParams()
  const [masterType, setMasterType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const getIdRetaierdata: any = useSelector(state => state?.getIdRetaierdata.data)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const StateListData = useSelector(state => state?.StateListData.data)
  const BranchList: any = useSelector<any>(state => state?.GetBranch?.data);


  console.log(StateListData)

  const [inputdata, setInputdata] = useState<any>({
    retailer_type: "",
    retailer_business_status: "",
    retailer_name: "",
    retailer_contact_per: "",
    retailer_number: "",
    retailer_branch: "",
    retailer_state: "",
    retailer_district: "",
    retailer_city: "",
    retailer_plan: "",
    retailer_opening_balance: null,
    "retailer_credit_limit": null,
    "retailer_credit_days": null,
    "retailer_credit_amount": null,
    "retailer_no_of_bills_allowed": null,
  });

  useEffect(() => {
    if (editId) {
      dispatch(getIdRetaier(editId))
    }
  }, [editId])

  useEffect(() => {
    if (editId && !inputdata.id) {
      console.log("logger123......", getIdRetaierdata)
      setInputdata({
        ...getIdRetaierdata,
        retailer_number: getIdRetaierdata?.retailer_number_and_details?.[0]?.retailer_number
      })
      if (getIdRetaierdata.retailer_state) {
        dispatch(GetdistrictListFromState(getIdRetaierdata.retailer_state))
      }
      if (getIdRetaierdata.retailer_district) {
        dispatch(GetCityListFromDistrict(getIdRetaierdata.retailer_district))
      }
    }
  }, [getIdRetaierdata])

  useEffect(() => {
    dispatch(GetBranchList());
    dispatch(MasterRetailerType())
    dispatch(GetstateList())
  }, [])

  useEffect(() => {
    if (phoneNumber?.length == 13) {
      dispatch(retailerNumber({ retailer_number: phoneNumber }))
    }
  }, [phoneNumber])

  useEffect(() => {
    if (retailerNumberdata?.length) {
      retailerNumberdata?.map((x: any) => {
        if (x.retailer_number == inputdata?.retailer_number) {
          console.log("first1", x.id)
          inputdata.retailer_number = [x.id]
        }
      })
    }
  }, [retailerNumberdata])

  const inputvalue = (e: any) => {
    const { name, value } = e.target
    if (name == 'retailer_state') {
      dispatch(GetdistrictListFromState(value))
    }
    if (name == 'retailer_district') {
      dispatch(GetCityListFromDistrict(value))
    }
    if (name == "retailer_plan") {
      setMasterType(value as string)
    }

    if (Number(value)) {
      setInputdata((prev: any) => ({ ...prev, [name]: Number(value) }))
    }
    else {
      setInputdata((prev: any) => ({ ...prev, [inputdata.retailer_created_at]: new Date().toJSON().slice(0, 19), [name]: value }))
    }
  };

  const hendalClickData = async () => {
    await dispatch(getretailerNumber())
    const data = { data: inputdata, navigate: navigate }
    console.log(data);
    // return;
    if (editId) {
      data.data.retailer_image = undefined
      data.data.retailer_adhar_front_image = undefined
      data.data.retailer_adhar_back_image = undefined
      data.data.retailer_pancard_image = undefined
      data.data.retailer_gst_image = undefined
      dispatch(EditRetaier(data))
    }
    else {
      data.data.retailer_wholeseller = [getWholeSellerId()]
      dispatch(PostRetailer(data))
    }
  }

  const setBranchPhonefun = (e: any) => {
    setInputdata((prev: any) => ({ ...prev, "retailer_number": `+${e}` }))
    setPhoneNumber(`+${e}`)
  }




  return (
    <>
      <DashboardLayout>
        <div className={`${classes.root} !w-[50%]`}>
          <div>
            <div className="flex items- mt-5">
              <div onClick={() => navigate('/retailers')}>
                <span className="pe-[20px] text-[#84818A] cursor-pointer"><KeyboardArrowLeftIcon /></span>
              </div>
              <p className="font-Manrope not-italic font-[600] text-[24px] leading-[20px] text-[#2E2C34] tracking-[1px]">{!editId ? "Add New" : "Edit"} Retailer</p>
            </div>
          </div>

          <div>
            <div className="formContainer mt-[21px]">
              <div className="w-2/4">
                <label className="text-[12px] text-[#84818A] leading-[18px]">Retailer Type</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  name="retailer_type"
                  value={inputdata?.retailer_type}
                  onChange={inputvalue}
                >
                  {
                    MasterRetailerTypeData.map((rtl: any) => {
                      return <MenuItem value={rtl.id}>{rtl.retailer_type_name}</MenuItem>
                    })
                  }
                </Select>
              </div>
              <div className="w-2/4">
                <label className="text-[12px] text-[#84818A] leading-[18px]">Business Status</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  value={inputdata.retailer_business_status}
                  name="retailer_business_status"
                  onChange={inputvalue}
                >
                  <MenuItem value={"REGISTERED"}>REGISTERED</MenuItem>
                  <MenuItem value={"NOTREGISTERED"}>
                    NOTREGISTERED
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className="formContainer">
              <div className="w-2/4">
                <label className="text-[12px] text-[#84818A] leading-[18px]">Retailer Firm Name</label>
                <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata?.retailer_name} name="retailer_name" onChange={inputvalue} />
              </div>

              <div className="w-2/4">
                <label className="text-[12px] text-[#84818A] leading-[18px]">Branch</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  value={inputdata.retailer_branch}
                  name="retailer_branch"
                  onChange={inputvalue}
                >
                  {
                    BranchList?.results && BranchList?.results.map((item: any, index: number) => (
                      <MenuItem key={index} value={item?.id}>{item?.branch_name}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="formContainer items-end">
              <div className="w-2/4">
                <label className="text-[12px] text-[#84818A] leading-[18px]">Contact Person</label>
                <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_contact_per} name="retailer_contact_per" onChange={inputvalue} />
              </div>
              <div className="w-2/4">
                <PhoneInput
                  country={"in"}
                  placeholder="Enter Phone Number"
                  value={inputdata.retailer_number}
                  onChange={(e: any) => setBranchPhonefun(e)}
                  containerStyle={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <div className="formContainer">
              <div className="w-2/4">
                <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">State</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  value={inputdata.retailer_state}
                  name="retailer_state"
                  onChange={inputvalue}
                >
                  {
                    StateListData?.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>{item.state}</MenuItem>
                    ))
                  }
                </Select>
              </div>
              <div className="w-2/4">
                <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">District</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  value={inputdata.retailer_district}
                  name="retailer_district"
                  onChange={inputvalue}
                >
                  {
                    Array.isArray(DistrictListData) && DistrictListData?.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>{item.district}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>
            <div className="formContainer">
              <div className="w-2/4">
                <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">City</label>
                <Select
                  sx={{
                    border: 0,
                    boxShadow: "none",
                  }}
                  className={'!text-[14px]'}
                  label="offer item"
                  variant={"standard"}
                  fullWidth={true}
                  value={inputdata.retailer_city}
                  name="retailer_city"
                  onChange={inputvalue}
                >
                  {
                    Array.isArray(CityListData) && CityListData?.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>{item.city}</MenuItem>
                    ))
                  }
                </Select>
              </div>
            </div>

            <div>
              <p className="font-Manrope not-italic font-[600] text-[16px] leading-[20px] text-[#2E2C34] tracking-[1px]">Plan</p>
              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Select Plan</label>
                  <Select
                    sx={{
                      border: 0,
                      boxShadow: "none",
                    }}
                    className={'!text-[14px]'}
                    label="offer item"
                    variant={"standard"}
                    fullWidth={true}
                    value={inputdata.retailer_plan}
                    name="retailer_plan"
                    onChange={inputvalue}
                  >
                    {
                      planTypesData?.map((item) => (
                        <MenuItem value={item.label.toLocaleUpperCase()} key={item.label}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>
              {masterType == '2' && <> <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Limit</label>
                  <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_limit} name="retailer_credit_limit" onChange={inputvalue} />
                </div>
                <div className="w-2/4">
                  <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Days</label>
                  <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_days} name="retailer_credit_days" onChange={inputvalue} />
                </div>
              </div>
                <div className="formContainer">
                  {/* <div className="w-2/4">
                    <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Amount</label>
                    <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_amount} name="retailer_credit_amount" onChange={inputvalue} />
                  </div> */}
                  <div className="w-2/4">
                    <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">No.of Bills Allowed</label>
                    <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_no_of_bills_allowed} name="retailer_no_of_bills_allowed" onChange={inputvalue} />
                  </div>
                </div></>}
              <div className="formContainer">
                {
                  inputdata.retailer_plan && inputdata.retailer_plan !== 'CASH' ?
                    <>
                      <div className="w-2/4">
                        <label className=" whitespace-nowrap text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Limit</label>
                        <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_limit} name="retailer_credit_limit" onChange={inputvalue} />
                      </div>
                      <div className="w-2/4">
                        <label className=" whitespace-nowrap text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Days</label>
                        <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_days} name="retailer_credit_days" onChange={inputvalue} />
                      </div>
                      {/* <div className="w-2/4">
                    <label className="text-[14px] text-[#84818A] leading-[20px] font-[500]">Credit Amount</label>
                    <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_credit_amount} name="retailer_credit_amount" onChange={inputvalue} />
                  </div> */}
                      <div className="w-2/4">
                        <label className=" whitespace-nowrap text-[14px] text-[#84818A] leading-[20px] font-[500]">No. of Bills Allowed</label>
                        <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_no_of_bills_allowed} name="retailer_no_of_bills_allowed" onChange={inputvalue} />
                      </div>
                    </> : null
                }
                <div className="w-2/4">
                  <label className=" whitespace-nowrap text-[14px] text-[#84818A] leading-[20px] font-[500]">Opening Balance</label>
                  <input type="number" className="outline-none border-b-[1px] border-[#84818A] w-full" defaultValue={inputdata.retailer_opening_balance} name="retailer_opening_balance" onChange={inputvalue} />
                </div>

              </div>
            </div>

            <div className="flex gap-5 py-5">
              <ActionButton title="Cancel" variant="default" onClick={() => {
                setInputdata({
                  retailer_type: "",
                  retailer_business_status: "",
                  retailer_name: "",
                  retailer_contact_per: "",
                  retailer_number: "",
                  retailer_branch: "",
                  retailer_state: "",
                  retailer_district: "",
                  retailer_city: "",
                  retailer_plan: "",
                  retailer_opening_balance: null,
                  "retailer_credit_limit": null,
                  "retailer_credit_days": null,
                  "retailer_credit_amount": null,
                  "retailer_no_of_bills_allowed": null,
                })
              }} />
              <ActionButton title="Save" variant="primary" onClick={hendalClickData} />
            </div>

          </div>
          {/* <div>
            <div>
              <p className="commonTitle">Add Retailer</p>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="singleForm">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="singleForm">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="formContainer">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>

              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="singleForm">
              <Select
                label="Age"
                variant={"standard"}
                fullWidth={true}
                value={masterType}
                onChange={handleChangeMasterType}
              >
                <MenuItem value={"Regional Wholeseller"}>Grocery</MenuItem>
                <MenuItem value={"Regional Wholeseller 1"}>Grocery 1</MenuItem>
              </Select>
            </div>

            <div className="flex gap-5 py-5">
              <ActionButton title="Cancel" variant="primary" />
              <ActionButton title="Cancel" variant="default" />
            </div>
          </div> */}
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddRetailers;