import React, { useEffect } from "react";
import { Routes } from "@/routes";
import { GlobalStyles } from "@/static/stylesheets";
import { ToastContainer } from "react-toastify";
import './global.css'
import "react-phone-input-2/lib/style.css";
import { AppService } from "./service/AllApiData.service";
import { useDispatch } from "./Store";
import { GetWholeseller } from "./Store/Sclicese/WholesellerSlice";
import { getWholeSellerId } from "./helper";

function App() {
  const dispatch = useDispatch()

  const [isAnyModalOpen, setisAnyModalOpen] = React.useState(false);

  const handleModalBackdrop = (value: any) => {
    setisAnyModalOpen(value);
  };


  useEffect(() => {
    if(getWholeSellerId()){
      dispatch(GetWholeseller())
    }
  }, []);

  return (
    <>
      {/* <GlobalStyles /> */}
      <div className={isAnyModalOpen ? "app-active" : "app"}>
      <GlobalStyles />
      <Routes handleModalBackdrop={handleModalBackdrop} />
    </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
}

export default App;
