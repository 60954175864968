import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import { Box, Checkbox, Dialog, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent } from "@mui/material";
import { GetCategoryListPlan, GetCategoryPlan, SubCategoryListPlan, SubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
import ItemRow from "./ItemRow";
import BranchPlanLog from "./BranchPlanLog";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const BranchPlan = () => {
  const classes = useBranchPlanStyles();
  const GetCategoryPlanListData: any = useSelector<any>(state => state?.GetCategoryListPlanData?.data)
  const SubCategoryPlanListData: any = useSelector<any>(state => state?.SubCategoryListPlanData?.data)
  const GetCategoryPlanData: any = useSelector<any>(state => state?.GetCategoryPlanData?.data?.results)
  const SubCategoryPlanData: any = useSelector<any>(state => state?.SubCategoryPlanData?.data?.results)
  const ItemWisePlanData: any = useSelector<any>(state => state?.ItemWisePlanData?.data?.results)
  const GetBranch: any = useSelector<any>(state => state?.GetBranch?.data?.results)
  const [CategoryValue, setCategoryValue] = React.useState<string[]>(["All_Category"]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch()
  const localStoragedata = JSON.parse(localStorage.getItem("Wholeseller_id") || "")
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)

  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedType, setselectedType] = useState<any>({});
  const ProductBazaar = useSelector(state => state?.ProductBazaarList?.data?.results)
  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const [open, handleOpen] = React.useState(false);

  // useEffect(()=>{
  //   if(Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0){
  //     handleGroupClick(CategoryGroupData[0])
  //   }
  // },[CategoryGroupData])

  // useEffect(()=>{
  //   if(Array.isArray(CategoryData) && CategoryData.length > 0){
  //     handleCategoryClick(CategoryData[0])
  //   }
  // },[CategoryData])

  // useEffect(()=>{
  //   if(Array.isArray(SubCategoryData) && SubCategoryData.length > 0){
  //     handleSubCategoryClick(SubCategoryData[0])
  //   }
  // },[SubCategoryData])




  useEffect(() => {
    dispatch(GetBranchList())
    dispatch(MasterRetailerType())
  }, [])

  useEffect(() => {
    dispatch(CategoryGroup(bazaarId))
  }, [])

  useEffect(() => {
    if (bazaarId) {
      // dispatch(GetCategoryListPlan(bazaarId))
      // dispatch(SubCategoryListPlan(bazaarId))
    }
    dispatch(GetCategoryPlan())
    dispatch(SubCategoryPlan())
    // dispatch(ItemWisePlan())
  }, [bazaarId])

  // useEffect(() => {
  //   if (GetCategoryPlanListData?.length && GetCategoryPlanData) {
  //     let array: any = []
  //     GetCategoryPlanListData[0]?.map((x: any) => {
  //       GetCategoryPlanData?.map((z: any) => {
  //         if (x.id == z.category) {
  //           array.push(z)
  //         }
  //       })
  //     })
  //     setMapData(array)
  //   }

  // }, [GetCategoryPlanListData?.length, GetCategoryPlanData])

  useEffect(() => {
    if (CategoryValue.includes('All_Category')) {
      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item])
      })
    }
  }, [CategoryValue, SubCategoryValue, ItemWiseValue, GetCategoryPlanListData, GetCategoryPlanData])



  const handleChange = async (event: SelectChangeEvent<typeof CategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    await setCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );
    if (value == "All_Category") {
      setCategoryValue(["All_Category"])

      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      GetCategoryPlanData?.map((item: any) => {
        if (value == item?.category_name[0]) {
          setSubCategoryValue(["All_Sub-Categories"])
        }
      })
    }
  };

  const subhandleChange = (event: SelectChangeEvent<typeof SubCategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setSubCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_Sub-Categories") {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      SubCategoryPlanListData[0]?.map((item: any) => {
        if (value == item.subcategory_name) {
          SubCategoryPlanData.map((x: any) => {
            if (item.id == x.sub_category) {
              setItemWiseValue(["All_items"])
            }
          })
        }
      })
    }
  };


  const itemhandleChange = (event: SelectChangeEvent<typeof ItemWiseValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setItemWiseValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_items") {
      setMapData(ItemWisePlanData)
    }
    else {
      ItemWisePlanData?.map((item: any) => {
        if (value == item.product_details[0].product_name) {
          setMapData([item])
        }
      })
    }
  };


  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))

  const branchFilterOptions = GetBranch?.map((item: any) => ({
    label: item?.branch_name,
    value: item?.id
  }))


  const data = [1, 2, 3];

  const [filterData, setFilterData] = useState({
    group_category: null,
    category: null,
    sub_category: null,
    retailer: null,
    branch: null,
    mode:'Category'
  })

  useEffect(()=>{
    if(Array.isArray(GetBranch) && GetBranch.length > 0){
      setFilterData({
        ...filterData,
        branch: GetBranch.filter((brn:any) => brn.main_branch)[0]?.id
      })
    }
  },[GetBranch])

  useEffect(()=>{
    if(Array.isArray(MasterRetailerTypeData) && MasterRetailerTypeData.length > 0){
      setFilterData({
        ...filterData,
        retailer: MasterRetailerTypeData[0]?.id
      })
    }
  },[MasterRetailerTypeData])

  const handleChangeMaster = (selectboxName: string, id: any) => {

    if (selectboxName === 'Group Category') {

      dispatch(clearCategoryState())
      dispatch(clearSubCategoryState())
      dispatch(CategorySlice({ bazaarId: bazaarId, id }))
      setFilterData({
        ...filterData,
        group_category: id,
        category: null,
        mode:'Category'
      })
    }
    if (selectboxName === 'Category') {
      dispatch(clearSubCategoryState())
      dispatch(SubCategorySlice({ bazaarId: bazaarId, category: id, id: filterData.group_category }))
      setFilterData({
        ...filterData,
        category: id,
        mode:'Sub Category'
      })
    }
    if (selectboxName === 'Sub Category') {
      setFilterData({
        ...filterData,
        sub_category: id
      })
    }

    if (selectboxName === 'Branch') {
      setFilterData({
        // ...filterData,
        mode:filterData.mode,
        group_category: filterData.group_category,
        category: filterData.category,
        sub_category: filterData.sub_category,
        retailer: filterData.retailer,
        branch: id
      })

    }

  }
  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div className="flex mb-[32px] items-center justify-start">
            <p className="pageTitle">Category Wise Plans</p>
            <div className="flex gap-5 ml-[40px]">
              {/* <TwMultiSelect name="Select City" /> */}
              {/* <TwMultiSelect name="Select Branch" loopData={}/> */}
              {branchFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Branch"}
                hint={"Select Branch"}
                options={branchFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={branchFilterOptions?.filter((item: any) => filterData.branch == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              />}
            </div>
            <div></div>
          </div>
          {/* <div>
            <p className="pageTitle">Category Wise Plans</p>
          </div> */}
          <div className="flex gap-5 pb-[20px]">
            {
              MasterRetailerTypeData.map((rtl: any) => {
                return <ActionButton
                  key={rtl?.id}
                  title={rtl.retailer_type_name}
                  size="large"
                  onClick={()=>  setFilterData({
                    ...filterData,
                    retailer: rtl.id
                  })}
                  variant={rtl.id === filterData.retailer ? "primary" : 'default'}
                />
              })
            }
          </div>
          <div className="flex py-5 justify-between">
            <div className="flex gap-5">
              {groupCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Group Category"}
                hint={"Select Group Category"}
                options={groupCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {categoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Category"}
                hint={"Select Category"}
                options={categoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {/* {subCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Sub Category"}
                hint={"Select Sub Category"}
                options={subCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
              // colorcode={'#4e2fa9'} 
              />} */}
            </div>
          </div>
          <div>
            <div className="text-[18px] text-gray-700font-[600] py-[10px]">{filterData.mode} List</div>
            <div className="overflow-x-auto relative pt-[10px] pb-[40px]">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] bg-[#f9f5f2]">
                  <tr className="tableTitle color-[#2E2C34;]">
                    <th scope="col" className="py-3 px-3">
                      CATEGORY
                    </th>
                    <th scope="col" className="py-3 px-6">
                      CASH
                    </th>
                    <th scope="col" className="py-3 px-6">
                      PLATINUM
                    </th>
                    <th scope="col" className="py-3 px-6">
                      DIAMOND
                    </th>
                    <th scope="col" className="py-3 px-6">
                      GOLD
                    </th>
                    <th scope="col" className="py-3 px-6">
                      BRONZE
                    </th>
                    <th scope="col" className="py-3 px-3">
                      LAST UPDATED
                    </th>
                    <th scope="col" className="py-3 px-6">
                      ACTION
                    </th>
                    <th scope="col" className="py-3 px-6">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterData.mode === 'Category' && CategoryData?.map((item: any) => {
                    const isExistPlan = GetCategoryPlanData?.filter((itm: any) => itm.category === item.id && itm.branch === filterData.branch && itm.retailer_type[0] === filterData.retailer)[0] || {}
                    console.log("Category...",GetCategoryPlanData,isExistPlan,filterData)
                    return (
                      <ItemRow title={item.category_name} updated_date={item.updated_date} {...isExistPlan} retailer_type={[filterData.retailer]}
                      branch={filterData.branch} category={item.id} key={'cat'+item.id} handleOpen={()=>handleOpen(true)} />
                    )
                  })}
                  {filterData.mode === 'Sub Category' && SubCategoryData?.map((item: any) => {
                    const isExistPlan = SubCategoryPlanData?.filter((itm: any) => itm.sub_category === item.id && itm.branch === filterData.branch && itm.retailer_type[0] === filterData.retailer)[0] || {}
                    console.log("SubCategory...",SubCategoryPlanData,isExistPlan,filterData)
                    return (
                      <ItemRow title={item.subcategory_name} updated_date={item.updated_date} {...isExistPlan} retailer_type={[filterData.retailer]}
                      branch={filterData.branch} sub_category={item.id} key={item.id} handleOpen={()=>handleOpen(true)} />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DashboardLayout>
      <BranchPlanLog open={open} handleOpen={handleOpen} />
    </>
  );
};

export default BranchPlan;
