import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserProduts, { WholesellerProducts } from "./WholesellerProducts"
import { useDispatch } from "react-redux"
import { AppDispatch } from ".."
import { api } from "@/Axios/api"
import { toast } from "react-toastify"

export const ProductDetail: any = createAsyncThunk('ProductDetail', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/product/data/${data}/`, 'get');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}


export const ProductDetaildata = createSlice({
  name: 'ProductDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      ProductDetail.pending, (state, action) => {
        state.loading = true
      })
      builder.addCase(
        ProductDetail.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      ProductDetail.rejected, (state, action) => {
        state.loading = false
      })
    }
})

//----------- Get product pricing data --------------//
export const GetProductPricing: any = createAsyncThunk('GetProductPricing', async (id: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/${id}/productPricing/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})


export const GetGetProductPricingData = createSlice({
  name: 'GetProductPricing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetProductPricing.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetProductPricing.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetProductPricing.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- Create product pricing data --------------//
export const CreateProductPricing: any = createAsyncThunk('CreateProductPricing', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/${data.branchId}/productPricing/`,'post',data);
    toast.success("Successfully create_productPricing!")  
    return result.data
  }
  catch (error) {
    return error
  }
})

export const CreateProductPricingData = createSlice({
  name: 'CreateProductPricing',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateProductPricing.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateProductPricing.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateProductPricing.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- EditProductPricing data --------------//
export const EditProductPricing: any = createAsyncThunk('EditProductPricing', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/${data.branchId}/productPricing/${data.id}/`,'put',data);
    toast.success("Successfully Edit_ProductPricing!") 
    return result.data
  }
  catch (error) {
    return error
  }
})


export const EditProductPricingData = createSlice({
  name: 'EditProductPricing',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditProductPricing.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditProductPricing.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditProductPricing.rejected, (state, action) => {
        state.loading = false
      })
  }
})
