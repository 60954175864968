import { css } from "@emotion/css";

export const usepreviewPlansStyle = () => {
  return {
    root: css`
    width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;

      .textContainer {
        padding-top: 40px;
        
        .titleHead {
          font-family: "Manrope", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          letter-spacing: 0.2px;
          color: #2e2c34;
        }

        .titleDescription {
          font-family: "Manrope", serif;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          letter-spacing: 0.2px;
          color: #2e2c34;
        }
      }

      .card{
        background: rgba(224, 224, 234, 0.1);
        border: 1px solid #4E2FA9;
        border-radius: 12px;
        padding:0 24px;
        width:27.33%
      }
      .feature-text {
         font-style: normal;
         font-weight: 400;
         font-size: 14px;
         line-height: 180%;
         color: #121127;
         padding-left:8px;
       }
       .mb{
        margin-bottom:50px
       }
       .branches{
        height:100px;
        width:100px;
        border-radius:50%;
        background: #F9F5F2;
       }
       .branches-text{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        line-height:75px;
        font-size: 44px;
        display: flex;
        align-items: center !important;
        text-align: center !important;
        color: #FF6652;
        justify-content: center !important
       }
       span{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        display: flex;
        align-items: center !important;
        text-align: center !important;
        color: #84818A;
        justify-content: center !important;
        margin-top:-9px
       }
     
    `,

    addDialog: css`
      padding: 44px;
      width: 461px;

      .title {
        font-family: "Manrope", serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 20px;
        color: #2e2c34;
        margin-bottom: 27px;
      }

      .select-master {
        margin-top: 26px;

        .input-label {
          font-family: "Manrope", serif;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #84818a;
        }
      }

      .action-bar {
        margin-top: 41px;
        display: flex;
        gap: 12px;
      }
    `,
  };
};
