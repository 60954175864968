import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent } from "@mui/material";
import { GetCategoryListPlan, GetCategoryPlan, SubCategoryListPlan, SubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
import ItemRow from "./ItemRow";
import { SectionHeader } from "@/components/molecules/Bazaars";
import UserProduts from "@/Store/Sclicese/WholesellerProducts";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const BranchPlan = () => {
  const classes = useBranchPlanStyles();
  const wholesellerproductdata = useSelector(state => state.Wholesellerproduct.data)
  const GetCategoryPlanListData: any = useSelector<any>(state => state?.GetCategoryListPlanData?.data)
  const SubCategoryPlanListData: any = useSelector<any>(state => state?.SubCategoryListPlanData?.data)
  const GetCategoryPlanData: any = useSelector<any>(state => state?.GetCategoryPlanData?.data?.results)
  const SubCategoryPlanData: any = useSelector<any>(state => state?.SubCategoryPlanData?.data?.results)
  const ItemWisePlanData: any = useSelector<any>(state => state?.ItemWisePlanData?.data?.results)
  const GetBranch: any = useSelector<any>(state => state?.GetBranch?.data?.results)
  const [CategoryValue, setCategoryValue] = React.useState<string[]>(["All_Category"]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch()
  const localStoragedata = JSON.parse(localStorage.getItem("Wholeseller_id") || "")
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)

  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedType, setselectedType] = useState<any>({});
  const ProductBazaar = useSelector(state => state?.ProductBazaarList?.data?.results)
  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const [search, setSearch] = useState<string>("");
  const [products,setProducts] = useState<any>([]);

  // useEffect(()=>{
  //   if(Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0){
  //     handleGroupClick(CategoryGroupData[0])
  //   }
  // },[CategoryGroupData])

  // useEffect(()=>{
  //   if(Array.isArray(CategoryData) && CategoryData.length > 0){
  //     handleCategoryClick(CategoryData[0])
  //   }
  // },[CategoryData])

  // useEffect(()=>{
  //   if(Array.isArray(SubCategoryData) && SubCategoryData.length > 0){
  //     handleSubCategoryClick(SubCategoryData[0])
  //   }
  // },[SubCategoryData])


  useEffect(() => {

  }, [])

  useEffect(() => {
    dispatch(GetBranchList(localStoragedata)).then((e: any) => {
    })
    dispatch(MasterRetailerType())
    dispatch(ItemWisePlan())
  }, [])

  useEffect(() => {
    dispatch(CategoryGroup(bazaarId))
  }, [])

  useEffect(() => {
    if (bazaarId) {
      // dispatch(GetCategoryListPlan(bazaarId))
      // dispatch(SubCategoryListPlan(bazaarId))
    }
    // dispatch(GetCategoryPlan())
    // dispatch(SubCategoryPlan())
  }, [bazaarId])

 

  // useEffect(() => {
  //   if (GetCategoryPlanListData?.length && GetCategoryPlanData) {
  //     let array: any = []
  //     GetCategoryPlanListData[0]?.map((x: any) => {
  //       GetCategoryPlanData?.map((z: any) => {
  //         if (x.id == z.category) {
  //           array.push(z)
  //         }
  //       })
  //     })
  //     setMapData(array)
  //   }

  // }, [GetCategoryPlanListData?.length, GetCategoryPlanData])

  useEffect(() => {
    if (CategoryValue.includes('All_Category')) {
      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item])
      })
    }
  }, [CategoryValue, SubCategoryValue, ItemWiseValue, GetCategoryPlanListData, GetCategoryPlanData])



  const handleChange = async (event: SelectChangeEvent<typeof CategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    await setCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );
    if (value == "All_Category") {
      setCategoryValue(["All_Category"])

      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      GetCategoryPlanData?.map((item: any) => {
        if (value == item?.category_name[0]) {
          setSubCategoryValue(["All_Sub-Categories"])
        }
      })
    }
  };

  const subhandleChange = (event: SelectChangeEvent<typeof SubCategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setSubCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_Sub-Categories") {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      SubCategoryPlanListData[0]?.map((item: any) => {
        if (value == item.subcategory_name) {
          SubCategoryPlanData.map((x: any) => {
            if (item.id == x.sub_category) {
              setItemWiseValue(["All_items"])
            }
          })
        }
      })
    }
  };


  const itemhandleChange = (event: SelectChangeEvent<typeof ItemWiseValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setItemWiseValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_items") {
      setMapData(ItemWisePlanData)
    }
    else {
      ItemWisePlanData?.map((item: any) => {
        if (value == item.product_details[0].product_name) {
          setMapData([item])
        }
      })
    }
  };


  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))

  const branchFilterOptions = GetBranch?.map((item: any) => ({
    label: item?.branch_name,
    value: item?.id
  }))


  const [filterData, setFilterData] = useState({
    group_category: null,
    category: null,
    sub_category: null,
    retailer: null,
    branch: null,
    mode: 'Category'
  })

  useEffect(() => {
    if (Array.isArray(GetBranch) && GetBranch.length > 0) {
      setFilterData({
        ...filterData,
        branch: GetBranch.filter((brn: any) => brn.main_branch)[0]?.id
      })
    }
  }, [GetBranch])

  useEffect(() => {
    if (Array.isArray(MasterRetailerTypeData) && MasterRetailerTypeData.length > 0) {
      setFilterData({
        ...filterData,
        retailer: MasterRetailerTypeData[0]?.id
      })
    }
  }, [MasterRetailerTypeData])

  useEffect(()=>{
    setProducts(wholesellerproductdata);
  },[wholesellerproductdata])

  const handleChangeMaster = (selectboxName: string, id: any) => {

    if (selectboxName === 'Group Category') {

      dispatch(clearCategoryState())
      dispatch(clearSubCategoryState())
      dispatch(CategorySlice({ bazaarId: bazaarId, id }))
      setFilterData({
        ...filterData,
        group_category: id,
        category: null,
        mode: 'Category'
      })

      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === id))
    }
    if (selectboxName === 'Category') {
      dispatch(clearSubCategoryState())
      dispatch(SubCategorySlice({ bazaarId: bazaarId, category: id, id: filterData.group_category }))
      setFilterData({
        ...filterData,
        category: id,
        mode: 'Sub Category'
      })
      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === filterData.group_category && item?.category?.id === id))

    }
    if (selectboxName === 'Sub Category') {
      setFilterData({
        ...filterData,
        sub_category: id
      })

      setProducts(wholesellerproductdata?.filter((item: any) => item?.category_group?.id === filterData.group_category && item?.category?.id === filterData.category && item?.subcategory?.id === id))

    }

    if (selectboxName === 'Branch') {
      setFilterData({
        ...filterData,
        branch: id
      })
    }

  }


  useEffect(()=>{
    dispatch(UserProduts({branch:filterData.branch}))
  },[filterData.branch])
  
  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <SectionHeader />
          <div>
            <p className="py-[10px] font-semibold text-2xl">Item Wise Plans</p>
            <div className="flex gap-5 mb-[20px]">
              {/* <TwMultiSelect name="Select City" /> */}
              {/* <TwMultiSelect name="Select Branch" loopData={}/> */}
              {branchFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Branch"}
                hint={"Select Branch"}
                options={branchFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={branchFilterOptions?.filter((item: any) => filterData.branch == item?.value).map((elm: any) => elm?.value)}
                showSelectedLable

              // colorcode={'#4e2fa9'} 
              />}
            </div>
            {/* <ActionButton title="Retailer" variant="primary" />
              <ActionButton title="Retailer" variant="default" />
              <ActionButton title="Retailer" variant="default" /> */}
            {/* <div className="flex gap-5 py-[20px]">
            </div> */}

            <div className="flex justify-between">
              <div className="flex gap-5">
                {groupCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                  label={"Group Category"}
                  hint={"Select Group Category"}
                  options={groupCategoryFilterOptions}
                  handleSelect={handleChangeMaster}
                  selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                  colorcode={'#4e2fa9'}
                  showSelectedLable
                />}
                {categoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                  label={"Category"}
                  hint={"Select Category"}
                  options={categoryFilterOptions}
                  handleSelect={handleChangeMaster}
                  selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                  colorcode={'#4e2fa9'}
                  showSelectedLable
                />}
                {subCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                  label={"Sub Category"}
                  hint={"Select Sub Category"}
                  options={subCategoryFilterOptions}
                  handleSelect={handleChangeMaster}
                  selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
                // colorcode={'#4e2fa9'} 
                />}
              </div>
              <div>
                <SearchField inputdata={setSearch} />
              </div>
            </div>

            <div>
              <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                {filterData.branch ? <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] ">
                    <tr className="tableTitle color-[#2E2C34;]">
                      <th scope="col" className="py-3 px-3">
                        Item Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Base price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        update price
                      </th>
                      <th scope="col" className="py-3 px-6">
                        customer type
                      </th>
                      <th scope="col" className="py-3 px-6">
                        plans
                      </th>
                      <th scope="col" className="py-3 px-6">
                        LAST UPDATED
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ItemWisePlanData && products?.filter((item: any) => item?.product_name?.toLowerCase()?.includes(search?.toLocaleLowerCase()))?.map((item: any) => {
                      const isExist = ItemWisePlanData?.filter((itm: any) => itm?.product?.includes(item.id) && itm.branch === filterData.branch)[0] || {}
                      return (
                        <ItemRow title={item.product_name} base_price={item.product_selling_price} updated_date={item.updated_date}
                          category={item.id} product={[item.id]} {...isExist} key={item.id} branch={filterData.branch} />
                      )
                    })}
                  </tbody>
                </table> : 'select branch'}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default BranchPlan;
