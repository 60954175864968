export function removeDuplicates(array) {
    const uniqueArray = [];

    for (const item of array) {
        if (!uniqueArray?.map((item) => item?.value)?.includes(item?.value)) {
            uniqueArray.push(item);
        }
    }

    return uniqueArray;
}

export function removeEmptyKeys(obj) {
    for (const key in obj) {
        if (obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0)) {
            delete obj[key];
        } else if (typeof obj[key] === 'object') {
            removeEmptyKeys(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
                delete obj[key];
            }
        }
    }
    return obj;
}

export function getFinancialYears() {
    const currentYear = new Date().getFullYear();
    const startYear = 2023; // Change this if you want to go further back in time

    if (currentYear < startYear) {
        return [];
    }

    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
        years.push({
            label: `${year - 1}-${year}`,
            value: year,
        });
    }

    return years;
}

