import React, { useEffect, useState } from 'react';
import { AuthenticationLayout } from "@/components/layouts";
import { LoginForm, OtpForm, OtpSuccess } from "@/components/molecules/Login";
import { number, string } from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserNumberVerify from '@/Store/Sclicese/WholesellerNumberVerify';
import { AppDispatch, useDispatch, useSelector } from '@/Store';
import { OtpVerification } from '@/Store/Sclicese/OtpVerificationSlice';
const Login = () => {
  const [isOtpVerification, setIsOtpVerification] = useState<boolean>(false);
  const [loginNum, setloginNum] = useState(false)
  const [otpvar, setotpvar] = useState(false)
  const [userNumber, setUserNumber] = useState<string>('')
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const notify = () => toast("Wow so easy!");
  const dispatch = useDispatch()
  const Numberverifi = useSelector(state => state.WholesellerNumber.data.otp);
  let array: string[];
  array = []
  const inputvalues = (data: any) => {
    if (data.length == 10) {
      setUserNumber(data)
      setloginNum(true)
    }
    else {
      setloginNum(false)
    }
  }
  const otpnumber = (obj: any) => {
    if (obj.input1 !== "" && obj.input2 !== "" && obj.input3 !== "" && obj.input4 !== "" && obj.input5 !== "" && obj.input6 !== "") {
      for (let key in obj) {
        array.push(obj[key])
      }
      if ((array.join(',').replaceAll(',','')) == Numberverifi) {
        setotpvar(true);
      }
      else {
        setotpvar(false)
        array = []
      }
    }
  }
  const handleCheckLogin = () => {
    const Formdata = new FormData()
    if (!!loginNum) {
      // setIsOtpVerification(true)
      Formdata.append('wholeseller_number', '+91'+userNumber)
      dispatch(UserNumberVerify(Formdata))
      // toast.success('success!')
    } else {
      setIsOtpVerification(false)
      toast.error('wrong otp!')
    }
  }
  const handleCheckOTP = () => {
    if (otpvar) {
      const Formdata = new FormData()
      Formdata.append("wholeseller_number", `+91${userNumber}`)
      Formdata.append("wholeseller_otp", `${Numberverifi}`)
      dispatch(OtpVerification(Formdata));
      setOtpSuccess(otpvar ? true : false)
    } else {
      setOtpSuccess(otpvar ? true : false)
      toast.error('wrong otp!')
    }
  }

  useEffect(()=>{
    if(Number(Numberverifi) > 0){
      setIsOtpVerification(true)
    }
  },[Numberverifi])
  return (
    <div>
      <AuthenticationLayout>
        {!isOtpVerification ? (
          <LoginForm
            onSuccess={handleCheckLogin}
            inputvalues={inputvalues}
            Verification={isOtpVerification}
          />
        ) : (
          <>
            {!otpSuccess ?
              <OtpForm
                onGoBack={() => setIsOtpVerification(false)}
                onSuccess={handleCheckOTP}
                otp={otpnumber}
              /> :
              <OtpSuccess />
            }
          </>
        )}
      </AuthenticationLayout>
    </div>
  );
};
export default Login;