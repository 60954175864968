import AddAdvertisement from "@/screens/AddAdvertisement";
import AddAgents from "@/screens/AddAgents";
import AddBazaar from "@/screens/AddBazaar";
import AddBranch from "@/screens/AddBranch";
import AddProduct from "@/screens/AddProduct";
import AddRetailers from "@/screens/AddRetailers";
import AddWholeseller from "@/screens/AddWholeseller";
import MasterList from "@/screens/Admin/MasterList";
import Advertisement from "@/screens/Advertisement";
import Agent from "@/screens/Agent";
import BazaarReport from "@/screens/BazaarReport";
import Bazaars from "@/screens/Bazars";
import Branch from "@/screens/Branch";
import BranchPlan from "@/screens/BranchPlan";
import BulkUpload from "@/screens/BulkUpload";
import CreateOffer from "@/screens/CreateOffer";
import CreateOrder from "@/screens/CreateOrder";
import Dashboard from "@/screens/Dashboard";
import EditOrder from "@/screens/EditOrder";
import ElectronicsBazaar from "@/screens/ElectronicsBazaar";
import ItemWisePlan from "@/screens/ItemWisePLan";
import ProductPricing from "@/screens/ProductPricing";
import Login from "@/screens/Login";
import Mwb from "@/screens/Mwb/Mwb";
import Offers from "@/screens/Offers";
import Order from "@/screens/Order";
import PhotoOrder from "@/screens/PhotoOrder";
import Products from "@/screens/Products";
import Retailers from "@/screens/Retailers";
import RevenewPlan from "@/screens/RevenewPlan";
import SubCategories from "@/screens/SubCategories";
import TrackOrder from "@/screens/TrackOrder";
import Wholeseller from "@/screens/Wholeseller";
import WholesellerDashboard from "@/screens/WholesellerDashboard";
import WholesellerKYC from "@/screens/WholesellerKYC";
import WholesellerLis from "@/screens/WholesellerList";
import WholesellerMart from "@/screens/WholesellerMart";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routePaths } from "./routePaths";
import Plan from "@/screens/Plan";
import AddPlans from "@/screens/AddPlans";
import Preview from "@/screens/Preview";
import OutOfDelivery from "@/screens/OutOfdelivery";
import HostRetailersDashbord from "@/components/molecules/SubbazarDashbord/HostRetailersDashbord";
import SubbazarDashbord from "@/components/molecules/SubbazarDashbord/HostRetailersDashbord";
import AgentKYC from "@/screens/AgentKYC";
import SubCategoryWisePlan from "@/screens/SubCategoryPlan";
import WholesalerDetails from "@/screens/WholesellerDetails";
import BankDetails from "@/screens/BankDetails";
import CustomerWisePricings from "@/screens/CustomerWisePricings";
import ViewProductPricings from "@/screens/ViewProductPricings";
import RetailerDetails from "@/screens/RetailerDetails";
import Users from "@/screens/RolesAndPermissions/Users";
import RolesPermission from "@/screens/RolesAndPermissions/RolesAndPermissions";
import AddRols from "@/screens/RolesAndPermissions/AddRoles";
import AddBankDetails from "@/screens/BankDetails/AddBankDetails";
import ProductsCombo from "@/screens/ProductCombo";
import AddProductCombo from "@/screens/AddProductCombo";
import ProductStock from "@/screens/ProductStock";
import AddProductForm from "@/screens/AddProduct/AddProductForm";
import AddProductComboForm from "@/screens/AddProductCombo/AddProductComboForm";
import SelectAddonPlans from "@/screens/WholesellerDetails/WholesalerPlan/SelectAddons";
import SelectPlans from "@/screens/WholesellerDetails/WholesalerPlan/SelectPlans";
import PlanPaymentDetails from "@/screens/WholesellerDetails/WholesalerPlan/BuyAddon";
import BuyPlay from "@/screens/WholesellerDetails/WholesalerPlan/UpgradePlan";

const AppRoutes = (props: any) => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path={routePaths.root} element={<Login />} />
                    <Route path={routePaths.login} element={<Login />} />
                    <Route path={routePaths.mwb} element={<Mwb />} />
                    <Route
                        path={routePaths.dashboard}
                        element={<Dashboard />}
                    />
                    <Route
                        path={routePaths.newbazaars}
                        element={<AddBazaar />}
                    />
                    <Route
                        path={routePaths.wholeseller}
                        element={<Wholeseller />}
                    />
                    <Route
                        path={routePaths.wholesellerlist}
                        element={<WholesellerLis />}
                    />
                    <Route
                        path={routePaths.wholesellerdashboard}
                        element={<WholesellerDashboard />}
                    />
                    <Route
                        path={routePaths.wholesellermart}
                        element={<WholesellerMart />}
                    />
                    <Route
                        path={routePaths.wholesellerproducts}
                        element={<Products />}
                    />
                    <Route path={routePaths.agents} element={<Agent handleModalBackdrop={props.handleModalBackdrop} />} />
                    <Route path={routePaths.agentkyc} element={<AgentKYC />} />
                    <Route path={routePaths.addagent} element={<AddAgents />} />
                    <Route path={routePaths.editagent} element={<AddAgents />} />

                    <Route path={routePaths.addons} element={<SelectAddonPlans />} />
                    <Route path={routePaths.addonPlanPay} element={<PlanPaymentDetails />} />

                    <Route path={routePaths.plans} element={<SelectPlans />} />
                    <Route path={routePaths.plansPay} element={<BuyPlay />} />

                    <Route
                        path={routePaths.retailers}
                        element={<Retailers />}
                    />
                    <Route
                        path={routePaths.retailerDetails}
                        element={<RetailerDetails />}
                    />
                    <Route
                        path={routePaths.addretailers}
                        element={<AddRetailers />}
                    />
                    <Route
                        path={routePaths.viewproductpricings}
                        element={<ViewProductPricings />}
                    />
                    <Route
                        path={routePaths.customerwisepricings}
                        element={<CustomerWisePricings />}
                    />
                    <Route
                        path={routePaths.bankdetails}
                        element={<BankDetails />}
                    />
                    <Route
                        path={routePaths.addBankDetails}
                        element={<AddBankDetails />}
                    />
                    <Route
                        path={routePaths.editretailers}
                        element={<AddRetailers />}
                    />
                    <Route
                        path={routePaths.plan}
                        element={<Plan />}
                    />
                    <Route
                        path={routePaths.addplan}
                        element={<AddPlans />}
                    />
                    <Route
                        path={routePaths.preview}
                        element={<Preview />}
                    />
                    <Route path={routePaths.offers} element={<Offers />} />
                    <Route
                        path={routePaths.createoffer}
                        element={<CreateOffer />}
                    />
                    <Route
                        path={routePaths.Editoffer}
                        element={<CreateOffer />}
                    />
                    <Route
                        path={routePaths.addnewadvertisement}
                        element={<AddAdvertisement />}
                    />
                    <Route
                        path={routePaths.addbranch}
                        element={<AddBranch />}
                    />
                    <Route
                        path={routePaths.advertisement}
                        element={<Advertisement />}
                    />
                    <Route path={routePaths.branch} element={<Branch />} />
                    <Route path={routePaths.editbranch} element={<AddBranch />} />
                    <Route
                        path={routePaths.branchplan}
                        element={<BranchPlan />}
                    />
                    <Route
                        path={routePaths.subcategoryplan}
                        element={<SubCategoryWisePlan />}
                    />
                    <Route
                        path={routePaths.createorder}
                        element={<CreateOrder />}
                    />
                    <Route
                        path={routePaths.editorder}
                        element={<EditOrder />}
                    />
                    <Route path={routePaths.order} element={<Order />} />
                    <Route
                        path={routePaths.photoorder}
                        element={<PhotoOrder />}
                    />
                    <Route
                        path={routePaths.trackorder}
                        element={<TrackOrder />}
                    />
                    <Route
                        path={routePaths.outOfDelivery}
                        element={<OutOfDelivery />}
                    />
                    <Route
                        path={routePaths.profile}
                        element={<WholesalerDetails handleModalBackdrop={props.handleModalBackdrop} />}
                    />
                    <Route
                        path={routePaths.addproduct}
                        element={<AddProduct />}
                    />
                    <Route
                        path={routePaths.addproductform}
                        element={<AddProductForm />}
                    />
                    <Route
                        path={routePaths.editproductform}
                        element={<AddProductForm />}
                    />
                    <Route
                        path={routePaths.bulkupload}
                        element={<BulkUpload />}
                    />
                    <Route
                        path={routePaths.subcategories}
                        element={<SubCategories />}
                    />
                    <Route
                        path={routePaths.addwholeseller}
                        element={<AddWholeseller />}
                    />
                    <Route
                        path={routePaths.wholesellerkyc}
                        element={<WholesellerKYC />}
                    />
                    <Route
                        path={routePaths.electronicsbazaar}
                        element={<ElectronicsBazaar />}
                    />
                    <Route
                        path={routePaths.bazaarreport}
                        element={<BazaarReport />}
                    />
                    <Route
                        path={routePaths.itemwiseplan}
                        element={<ItemWisePlan />}
                    />
                    <Route
                        path={routePaths.SubbazarDashbord}
                        element={<SubbazarDashbord />}
                    />
                    <Route
                        path={routePaths.admin.bazaars}
                        element={<Bazaars />}
                    />
                    <Route
                        path={routePaths.admin.masterList}
                        element={<MasterList />}
                    />
                    <Route
                        path={routePaths.productpricing}
                        element={<ProductPricing />}
                    />
                    <Route
                        path={routePaths.productcombos}
                        element={<ProductsCombo />}
                    />
                    <Route
                        path={routePaths.addproductcombos}
                        element={<AddProductCombo />}
                    />

                    <Route
                        path={routePaths.addproductcombosform}
                        element={<AddProductComboForm />}
                    />
                    <Route
                        path={routePaths.editproductcombosform}
                        element={<AddProductComboForm />}
                    />

                    <Route
                        path={routePaths.productstock}
                        element={<ProductStock />}
                    />
                    <Route
                        path={routePaths.users}
                        element={<Users />}
                    />
                    <Route
                        path={routePaths.rolepermissions}
                        element={<RolesPermission />}
                    />
                    <Route
                        path={routePaths.addroles}
                        element={<AddRols />}
                    />
                </Routes>
            </Router>
        </>
    );
};

export { AppRoutes as Routes };
