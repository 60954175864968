const orderStatusFlag = {
    "PENDING": "PENDING",
    "APPROVED": "APPROVED",
    'REJECTED': 'REJECTED',
    'OUTFORDELIVERY': 'OUTFORDELIVERY',
    'INPROGRESS': 'INPROGRESS',
    'SUCCESS': 'SUCCESS',
    'PARTIALDELIVERED': 'PARTIALDELIVERED',
    'PARTIALPENDING': 'PARTIALPENDING',
    'PARTIALOUTFORDELIVERY': 'PARTIALOUTFORDELIVERY',
}

const orderByFlag = {
    "ADMIN": "admin",
    "PHOTO": "photo",
    "RETAILER": "retailer"
}

const orderTypeFlag = {
    "CASH":"Cash",
    "CREDIT":"Credit"
}

const paymentStatusDetails:any = {
    PAID:{
        label:"Paid",
        value:"PAID",
        bgColor: "#20C9AC1A",
        color: "#20C9AC"
    },
    PENDING: {
        label:"Payment Pending",
        value:"PENDING",
        bgColor: "#FFA0431A",
        color: "#FFA043"
    },
    PARTIAL: {
        label:"Partial Payment Done",
        value:"PARTIAL",
        bgColor: "#00A5FF1A",
        color: "#00A5FF"
    }
}

const orderStatusDetails = {
    [orderStatusFlag.PENDING]: {
        value: orderStatusFlag.PENDING,
        label: 'Pending Order',
        bgColor: '#FFA0431A',
        color: '#FFA043',

    },
    [orderStatusFlag.PARTIALDELIVERED]: {
        value: orderStatusFlag.PARTIALDELIVERED,
        label: 'Partially Delivered',
        bgColor: '#5542F61A',
        color: '#5542F6',

    },
    [orderStatusFlag.PARTIALPENDING]: {
        value: orderStatusFlag.PARTIALPENDING,
        label: 'Partially Pending',
        bgColor: '#5542F61A',
        color: '#5542F6',

    },
    [orderStatusFlag.APPROVED]: {
        value: orderStatusFlag.APPROVED,
        label: 'Order Accepted',
        bgColor: '#00A5FF1A',
        color: '#00A5FF',

    },
    [orderStatusFlag.REJECTED]: {
        value: orderStatusFlag.REJECTED,
        label: 'Order Rejected',
        bgColor: '#B6B4BA1A',
        color: '#84818A',

    },
    [orderStatusFlag.OUTFORDELIVERY]: {
        value: orderStatusFlag.OUTFORDELIVERY,
        label: 'Out for Delivery',
        bgColor: '#00A5FF1A',
        color: '#00A5FF',

    },
    [orderStatusFlag.PARTIALOUTFORDELIVERY]: {
        value: orderStatusFlag.PARTIALOUTFORDELIVERY,
        label: 'Partially Out for Delivery',
        bgColor: '#00A5FF1A',
        color: '#00A5FF',

    },
    [orderStatusFlag.INPROGRESS]: {
        value: orderStatusFlag.INPROGRESS,
        label: 'Partially Delivered',
        bgColor: '#5542F61A',
        color: '#5542F6',

    },
    [orderStatusFlag.SUCCESS]: {
        value: orderStatusFlag.SUCCESS,
        label: 'Delivered',
        bgColor: '#B6B4BA1A',
        color: '#84818A',

    },
}


const getOrderStatusList = (currentStatus: string) => {
    const pendingToNext = [orderStatusDetails[orderStatusFlag.APPROVED], orderStatusDetails[orderStatusFlag.REJECTED]]
    const ofdToNext = [orderStatusDetails[orderStatusFlag.INPROGRESS], orderStatusDetails[orderStatusFlag.SUCCESS]]
    if (currentStatus === orderStatusFlag.PENDING) {
        return pendingToNext
    }
    if (pendingToNext.some(st => st.value === currentStatus)) {
        return [orderStatusDetails[orderStatusFlag.OUTFORDELIVERY]]
    }
    if (currentStatus === orderStatusFlag.OUTFORDELIVERY) {
        return ofdToNext
    }
    if (currentStatus === orderStatusFlag.INPROGRESS) {
        return [orderStatusDetails[orderStatusFlag.SUCCESS]]
    }
    return Object.values(orderStatusDetails)
}

const findOrderStatus = (status: string) => {
    return orderStatusDetails[status] || {}
}

const findPaymentStatus = (status: string) => {
    return paymentStatusDetails[status] || {}
}

const getOrderByList = () => {
    return Object.keys(orderByFlag).map(key => ({
        label: key,
        value: key
    }))
}

const getOrderTypeList = () => {
    return Object.keys(orderTypeFlag).map(key => ({
        label: key,
        value: key
    }))
}

export { getOrderStatusList, findOrderStatus, findPaymentStatus ,orderStatusFlag, orderByFlag, orderTypeFlag, getOrderByList, getOrderTypeList }