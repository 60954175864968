import { useDispatch, useSelector } from '@/Store'
import { CategoryGroup } from '@/Store/Sclicese/CategoryGroupSlice'
import { CategorySlice, clearCategoryState } from '@/Store/Sclicese/CategorySlice'
import { GetRetailer } from '@/Store/Sclicese/Retailerssclices'
import { SubCategorySlice, clearSubCategoryState } from '@/Store/Sclicese/SubCategorySlice'
import { FilteredProductsWholeSeller } from '@/Store/Sclicese/filteredProductsSlice'
import CommonSelectElectronicBazaar from '@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar'
import { SearchField } from '@/components/atoms/SearchField'
import { DashboardLayout } from '@/components/layouts'
import { getUserId, getWholeSellerId } from '@/helper'
import { AppService } from '@/service/AllApiData.service'
import { Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'



export default function CustomerWisePricings() {

    const RetailerData: any = useSelector(state => state.GetRetailerdata.data)
    const [retailers, setRetailers] = useState<any>([]);
    const [products, setProducts] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [retailer, setRetailer] = useState("");
    const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [search, setSearch] = useState("");
    const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)
    const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
    const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
    const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")


    const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
        label: item?.parent_category_name,
        value: item?.id
    }))

    const categoryFilterOptions = CategoryData?.map((item: any) => ({
        label: item?.category_name,
        value: item?.id
    }))

    const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
        label: item?.subcategory_name,
        value: item?.id
    }))



    const [filterData, setFilterData] = useState({
        group_category: null,
        category: null,
        sub_category: null,
        retailer: null,
        branch: null,
        mode: 'Category'
    })


    const handleChangeMaster = (selectboxName: string, id: any) => {

        if (selectboxName === 'Group Category') {

            dispatch(clearCategoryState())
            dispatch(clearSubCategoryState())
            dispatch(CategorySlice({ bazaarId: bazaarId, id }))
            setFilterData({
                ...filterData,
                group_category: id,
                category: null,
                mode: 'Category'
            })
            setProducts(products.filter((item:any,index:number)=>item?.category_group?.id === id));
        }
        if (selectboxName === 'Category') {
            dispatch(clearSubCategoryState())
            dispatch(SubCategorySlice({ bazaarId: bazaarId, category: id, id: filterData.group_category }))
            setFilterData({
                ...filterData,
                category: id,
                mode: 'Sub Category'
            })

            setProducts(products.filter((item:any,index:number)=>item?.category_group?.id === filterData.group_category && item?.category?.id === id));
            
        }
        if (selectboxName === 'Sub Category') {
            setFilterData({
                ...filterData,
                sub_category: id
            })

            setProducts(products.filter((item:any,index:number)=>item?.category_group?.id === filterData.group_category && item?.category?.id === filterData.category && item?.subcategory?.id === id));
        }
        if (selectboxName === 'Branch') {
            setFilterData({
                ...filterData,
                branch: id
            })
        }

    }


    const RetailerOptions = RetailerData?.map((row: any) => {
        return {
            label: row?.retailer_name,
            value: row?.id,
        }
    })

    const handlePageChange = (event: any, value: any) => {
        setPageNo(value);
    };

    const getProductPricings = async (retailer_id: any) => {
        try {
            const retailer = RetailerData.find((item: any, index: number) => item.id === retailer_id);
            if (retailer?.retailer_plan === 'CASH') {
                let params = {
                    retailer_id_for_plan: retailer_id,
                    branch_id: retailer?.retailer_branch,
                    order_type: 'cash'
                }
                const response = await AppService.getProductSpecify(getUserId(), params)
                setProducts(response.data.results);
                setTotalCount(response.data.results.length)
            } else {
                let params = {
                    retailer_id_for_plan: retailer_id,
                    branch_id: retailer?.retailer_branch,
                    order_type: 'cash'
                }
                const cashResponse = await AppService.getProductSpecify(getUserId(), params);
                params.order_type = "credit";
                let creditResponse: any = await AppService.getProductSpecify(getUserId(), params);
                creditResponse = creditResponse.data.results.map((item: any, index: number) => ({ ...item, cash_selling_price: cashResponse.data.results.find((ele: any) => ele.id === item.id).product_selling_price }));
                setProducts(creditResponse);
                setTotalCount(creditResponse?.length);
            }
        } catch (err) {
            console.log("CUSTOMER WISE PRICING ERROR ====> ", err);
        }
    }


    const handleChange = (e: any, value: any) => {
        setRetailer(value);
    }

    useEffect(() => {
        if (retailer) {
            getProductPricings(retailer)
        }
    }, [retailer])


    useEffect(() => {
        dispatch(GetRetailer());
        dispatch(CategoryGroup(bazaarId))
    }, [])

    useEffect(() => {
        if (RetailerData?.length) {
            setRetailers(RetailerData);
            setRetailer(RetailerData[0]?.id);
        }
    }, [RetailerData])

    return (
        <>
            <DashboardLayout>
                <div className='mt-5 w-full'>

                    <div className="flex align-middle justify-between">
                        <p className=" text-[22px] font-bold flex items-center">Customer Wise Pricing</p>
                        <SearchField inputdata={setSearch} />
                    </div>

                    <div className='mt-5'>
                    </div>

                    <div className="flex gap-5">
                        
                        <CommonSelectElectronicBazaar
                            label={"Retailer"}
                            hint={"Select Retailer"}
                            options={RetailerOptions}
                            handleSelect={handleChange}
                            searchable
                            selectedIds={RetailerOptions?.filter((item: any) => retailer == item?.value).map((elm: any) => elm?.value)}
                        />
                        
                        {groupCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                            label={"Group Category"}
                            hint={"Select Group Category"}
                            options={groupCategoryFilterOptions}
                            handleSelect={handleChangeMaster}
                            selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                            colorcode={'#4e2fa9'}
                            showSelectedLable
                        />}
                        {categoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                            label={"Category"}
                            hint={"Select Category"}
                            options={categoryFilterOptions}
                            handleSelect={handleChangeMaster}
                            selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                            colorcode={'#4e2fa9'}
                            showSelectedLable
                        />}
                        {subCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                            label={"Sub Category"}
                            hint={"Select Sub Category"}
                            options={subCategoryFilterOptions}
                            handleSelect={handleChangeMaster}
                            selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
                        // colorcode={'#4e2fa9'} 
                        />}
                    </div>

                            <p className='mt-4'>
                                {
                                    retailer ?
                                    "Showing Results for"+RetailerOptions.find((item:any)=>item.value === retailer).label
                                    :
                                    "Please Select Retailer"
                                } 
                            </p>

                    <div className="overflow-x-auto relative pt-[10px] pb-[40px] w-full mt-5">
                        <table className="w-full text-sm text-left text-gray-500 ">
                            <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] bg-[#f9f5f2]">
                                <tr className="tableTitle color-[#2E2C34;]">
                                    <th scope="col" className="py-3 px-3">
                                        PRODUCT NAME
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        CASH
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        PLATINUM
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        DIAMOND
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        GOLD
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        BRONZE
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products?.filter((item: any, index: number) => item?.product_name?.toLowerCase()?.includes(search?.toLowerCase()))?.slice((pageNo - 1) * 10, pageNo * 10)?.map((item: any) => (
                                        <tr className="border-b align-baseline">
                                            <th
                                                scope="row"
                                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                            >
                                                <div className="flex gap-[20px] items-center ">
                                                    <p className="mb-3 text-[14px] font-[600] text-black font-Manrope cursor-pointer">
                                                        {item?.product_name}
                                                    </p>
                                                </div>
                                            </th>

                                            <td className="py-4 px-6 color-[#2E2C34;]">
                                                <p className="text-[14px] font-medium font-[600] text-black">₹ {item?.cash_selling_price || item?.product_selling_price}</p>
                                            </td>

                                            <td className="py-4 px-6 color-[#2E2C34;]">
                                                <p className="text-[14px] font-medium font-[600] text-black">{retailers?.find((item:any,index:number)=>item?.id === retailer)?.retailer_plan === "PLATINUM" ? "₹ " + item?.product_selling_price : "--"}</p>
                                            </td>

                                            <td className="py-4 px-6 color-[#2E2C34;]">
                                                <p className="text-[14px] font-medium font-[600] text-black">{retailers?.find((item:any,index:number)=>item?.id === retailer)?.retailer_plan === "DIAMOND" ? "₹ " + item?.product_selling_price : "--"}</p>
                                            </td>

                                            <td className="py-4 px-6 color-[#2E2C34;]">
                                                <p className="text-[14px] font-medium font-[600] text-black">{retailers?.find((item:any,index:number)=>item?.id === retailer)?.retailer_plan === "GOLD" ? "₹ " + item?.product_selling_price : "--"}</p>
                                            </td>

                                            <td className="py-4 px-6 color-[#2E2C34;]">
                                                <p className="text-[14px] font-medium font-[600] text-black">{retailers?.find((item:any,index:number)=>item?.id === retailer)?.retailer_plan === "BRONZE" ? "₹ " + item?.product_selling_price : "--"}</p>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={pageNo}
                        onChange={handlePageChange}
                    />

                </div>
            </DashboardLayout>
        </>
    )
}