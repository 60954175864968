import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice } from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/Store";
import { Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent } from "@mui/material";
import { GetCategoryListPlan, GetCategoryPlan, SubCategoryListPlan, SubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const SubCategoryWisePlan = () => {
  const classes = useBranchPlanStyles();
  const GetCategoryPlanListData: any = useSelector<any>(state => state?.GetCategoryListPlanData?.data)
  const SubCategoryPlanListData: any = useSelector<any>(state => state?.SubCategoryListPlanData?.data)
  const GetCategoryPlanData: any = useSelector<any>(state => state?.GetCategoryPlanData?.data?.results)
  const SubCategoryPlanData: any = useSelector<any>(state => state?.SubCategoryPlanData?.data?.results)
  const ItemWisePlanData: any = useSelector<any>(state => state?.ItemWisePlanData?.data?.results)
  const GetBranch: any = useSelector<any>(state => state?.GetBranch?.data?.results)
  const [CategoryValue, setCategoryValue] = React.useState<string[]>(["All_Category"]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>()
  const localStoragedata = JSON.parse(localStorage.getItem("Wholeseller_id") || "")
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")


  useEffect(() => {
    dispatch(GetBranchList(localStoragedata)).then((e: any) => {
      console.log('localStoragedata', e)
    })
  }, [])

  useEffect(() => {
    if (bazaarId) {
      dispatch(GetCategoryListPlan(bazaarId))
      dispatch(SubCategoryListPlan(bazaarId))
    }
    dispatch(GetCategoryPlan())
    dispatch(SubCategoryPlan())
    dispatch(ItemWisePlan())
  }, [bazaarId])

  // useEffect(() => {
  //   if (GetCategoryPlanListData?.length && GetCategoryPlanData) {
  //     let array: any = []
  //     GetCategoryPlanListData[0]?.map((x: any) => {
  //       GetCategoryPlanData?.map((z: any) => {
  //         if (x.id == z.category) {
  //           array.push(z)
  //         }
  //       })
  //     })
  //     setMapData(array)
  //   }

  // }, [GetCategoryPlanListData?.length, GetCategoryPlanData])

  useEffect(() => {
    if (CategoryValue.includes('All_Category')) {
      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item])
      })
    }
  }, [CategoryValue, SubCategoryValue, ItemWiseValue, GetCategoryPlanListData, GetCategoryPlanData])



  const handleChange = async (event: SelectChangeEvent<typeof CategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    await setCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );
    if (value == "All_Category") {
      setCategoryValue(["All_Category"])

      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      GetCategoryPlanData?.map((item: any) => {
        if (value == item?.category_name[0]) {
          setSubCategoryValue(["All_Sub-Categories"])
        }
      })
    }
  };

  const subhandleChange = (event: SelectChangeEvent<typeof SubCategoryValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setSubCategoryValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_Sub-Categories") {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    else {
      // console.log(SubCategoryPlanListData)
      SubCategoryPlanListData[0]?.map((item: any) => {
        if (value == item.subcategory_name) {
          SubCategoryPlanData.map((x: any) => {
            if (item.id == x.sub_category) {
              setItemWiseValue(["All_items"])
            }
          })
        }
      })
    }
  };


  const itemhandleChange = (event: SelectChangeEvent<typeof ItemWiseValue>) => {
    const { target: { value } } = event;
    setMapData([])
    setItemWiseValue(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value == "All_items") {
      console.log("ItemWisePlanData", ItemWisePlanData)
      setMapData(ItemWisePlanData)
    }
    else {
      ItemWisePlanData?.map((item: any) => {
        if (value == item.product_details[0].product_name) {
          setMapData([item])
        }
      })
    }
  };
  const data = [1, 2];

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div className="flex gap-5 mb-[32px] items-center justify-between">
              <p className="pageTitle">Sub-Category Wise Plans</p>
              <div className="flex gap-5 mb-[50px]">
              <TwMultiSelect name="Select City"/>
              <TwMultiSelect name="Select Branch"/>
            </div>
            </div>
          {/* <div>
            <p className="pageTitle">Category Wise Plans</p>
          </div> */}
          <div className="flex gap-5 pb-[20px]">
            <ActionButton title="Retailer" size="large" variant="primary" />
            <ActionButton title="Hotellier" size="large" variant="default" />
            <ActionButton title="Semi-Wholeseller" size="large" variant="default" />
          </div>
          <div className="flex py-5 justify-between">
            <div className="flex gap-5">
              <TwMultiSelect colorcode={'#4e2fa9'} name={'Rice'} loopData={['Option #1', 'Option #1', 'Option #1', 'Option #1']} checkbox={true}/>
              <TwMultiSelect name={'Basmati'} colorcode={'#4e2fa9'} />
            </div>
          </div>
          <div>
            <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] bg-[#f9f5f2]">
                  <tr className="tableTitle color-[#2E2C34;]">
                    <th scope="col" className="py-3 px-6">
                      SUB CATEGORY
                    </th>
                    <th scope="col" className="py-3 px-6">
                      CASH
                    </th>
                    <th scope="col" className="py-3 px-6">
                      PLATINUM
                    </th>
                    <th scope="col" className="py-3 px-6">
                      DIAMOND
                    </th>
                    <th scope="col" className="py-3 px-6">
                      GOLD
                    </th>
                    <th scope="col" className="py-3 px-6">
                      BRONZE
                    </th>
                    <th scope="col" className="py-3 px-3">
                      LAST UPDATED
                    </th>
                    <th scope="col" className="py-3 px-6">
                      ACTION
                    </th>
                    <th scope="col" className="py-3">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: any) => (
                    <tr className="border-b align-baseline">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                      >
                        <div className="flex gap-[20px] items-center ">
                          <p className="mb-3 text-[14px] font-[600] text-black font-Manrope cursor-pointer">
                            Basmati
                          </p>
                        </div>
                      </th>
                      <td className="py-4 px-3  color-[#2E2C34;]">
                        <div className="planCard">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                            <p>or</p>
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-3  color-[#2E2C34;]">
                        <div className="planCard">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                            <p>or</p>
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-3  color-[#2E2C34;]">
                      <div className="planCard">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                            <p>or</p>
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-3  color-[#2E2C34;]">
                      <div className="planCard">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                            <p>or</p>
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-3  color-[#2E2C34;]">
                      <div className="planCard">
                          <div className="flex gap-2 justify-center items-center">
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                            <p>or</p>
                            <div className="planCardChild">
                              <p>-25%</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="py-4 px-6 color-[#2E2C34;]">
                        <p className="text-[14px] font-medium font-[600] text-black">24 Jun,2021 10:30pm</p>
                      </td>
                      <td className="py-4 px-6 color-[#2E2C34;]">
                        <div className="text-center">
                          <button className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent rounded">
                            APPLY
                          </button>
                        </div>
                      </td>
                      <td className="py-4 px-3 color-[#2E2C34;]">
                        <p className="text-[14px] font-medium font-[600] text-black">Log</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default SubCategoryWisePlan;
