import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { getUserId, getWholeSellerId } from "@/helper";

const UserProduts: any = createAsyncThunk('UserProduts', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/${getUserId()}/product-specify/`, 'get',data);
    console.log(result?.data)
    return result?.data || result
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  count: number,
  loading: boolean,
  error: any,
}

const initialState: initialStateType = {
  data: [],
  loading: false,
  count: 0,
  error: null,
}

export const WholesellerProducts = createSlice({
  name: 'UserProduts',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      UserProduts.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      UserProduts.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.results
        state.count = action.payload.count
      })
    builder.addCase(
      UserProduts.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default UserProduts