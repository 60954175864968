import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { toast } from "react-toastify";
import { getWholeSellerId } from "@/helper";


//--------------- Get branch --------------//
export const GetBranchList: any = createAsyncThunk('branch_list', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/branch/?branch_wholeseller=${getWholeSellerId()}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const BranchListReducers = createSlice({
  name: 'branch_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetBranchList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetBranchList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetBranchList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//--------------- Get one branch --------------//
export const GetoneBranchList: any = createAsyncThunk('GetoneBranchList', async (id: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/branch/${id}/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const GetoneBranchListReducers = createSlice({
  name: 'GetoneBranchList',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetoneBranchList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetoneBranchList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetoneBranchList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


//--------------- Post branch --------------//
export const Createbranch: any = createAsyncThunk('Createbranch', async (data: any) => {
  try {
    const responce: any = await api(`api/wholeseller/branch/`, 'POST', data.data);
    toast.success('success!');
    data.nevigate('/branch')
    return responce.data
  }
  catch (error : any) {
    toast.error(error.response.statusText);
    return console.error(error)
  }
})



export const Createbranchdata = createSlice({
  name: 'Createbranch',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      Createbranch.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      Createbranch.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      Createbranch.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//--------------- Post branch --------------//
export const Editbrach: any = createAsyncThunk('Editbrach', async (data: any) => {
  try {
    const responce: any = await api(`api/wholeseller/branch/${data.data.id}/`, 'put', data.data);
    toast.success('success!');
    data.nevigate('/branch')
    return responce.data
  }
  catch (error : any) {
    toast.error(error.response.statusText);
    return console.error(error)
  }
})



export const Editbrachdata = createSlice({
  name: 'Editbrach',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      Editbrach.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      Editbrach.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      Editbrach.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//--------------- branch delete --------------//
export const branchdelete: any = createAsyncThunk('branchdelete', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/branch/${data}/`, 'delete');
    toast.success('successfully deleted!');
    return responce.data
  }
  catch (error : any) {
    toast.error(error.response.statusText);
    return rejectWithValue(error)
  }
})



export const branchdeletedata = createSlice({
  name: 'branchdelete',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      branchdelete.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      branchdelete.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      Createbranch.rejected, (state, action) => {
        state.loading = false
      })
  }
})
