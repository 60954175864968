import React, { useEffect, useState } from 'react'
import { DashboardLayout } from "@/components/layouts";
import { useOffersStyles } from "@/static/stylesheets/screens";
import { AddButton, GridOptionButton } from "@/components/atoms/Button";
import { SearchField } from "@/components/atoms/SearchField";
import { Switch } from "@/components/atoms/Switch";
import { useNavigate } from "react-router-dom";
import TwMultiSelect from '@/components/atoms/TwMultiSelect/TwMultiSelect';
import { Typography } from '@mui/material';
import PlanSclicese from '@/Store/Sclicese/PlanSclicese';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/Store';
import PlanSearch from '@/Store/Sclicese/PlanSearchSclicese';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { Dialog } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PlanDelete from '@/Store/Sclicese/PlanDeleteSclice';

const Plan = () => {
    const classes = useOffersStyles();
    const dispatch = useDispatch<AppDispatch>()
    const plandata = useSelector<any>(state => state.PlanSclicesedata.data.results)
    const PlanSearchdata = useSelector<any>(state => state.PlanSearchdata.data.results)
    const navigate = useNavigate();
    const [planmapdata, setPlanmapdata] = useState<any>(PlanSearchdata || [])
    const [deleteddata, setDeleteddata] = useState('')
    const [isOpen, setIsOpen] = React.useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [productdata, setProductdata] = useState<any>({});

    useEffect(() => {
        dispatch(PlanSclicese())
    }, [isOpen])

    useEffect(() => {
        setPlanmapdata(plandata)
    }, [plandata])

    const inputdata = (data: string) => {
        dispatch(PlanSearch(data))
    }

    const hendelopenmodel = (data: any) => {
        setAddModalOpen(true)
        setProductdata(data)
      }
    
      const handleDeleted = (data: any) => {
        setIsOpen(true);
        setDeleteddata(data)
      };
    
      const handleClose = () => {
        setIsOpen(false);
        dispatch(PlanDelete(deleteddata))
      };

    return (
        <div>
            <div>
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            are you sure to delete this data
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpen(false)}>No</Button>
                        <Button onClick={handleClose} autoFocus className="!bg-[#ff6652] !text-[white]">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <DashboardLayout>
                <div className={classes.root}>
                    <div>
                        <div className="flex justify-between">
                            <p className="commonTitle">Plans</p>
                            <div>
                                <SearchField inputdata={inputdata} />
                            </div>
                            <AddButton
                                label="Add Plan"
                                onClick={() => navigate("/addplan")}
                            />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className="flex gap-5 mt-5">
                            <TwMultiSelect />
                            <TwMultiSelect />
                            <TwMultiSelect />
                            <TwMultiSelect />
                            <TwMultiSelect />
                            <TwMultiSelect />
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <Typography
                            sx={{
                                fontFamily: 'Manrope',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#FF0000',
                            }}
                        >Subscriber total, active, expired, deactivated</Typography>
                    </div>
                    <div>
                        <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className='text-[#84818A] font-[14px] font-[600]'>
                                    <tr className=" color-[#2E2C34;]">
                                        <th scope="col" className="py-3 px-6">
                                            Plan Title
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Start Time
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            End Time
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Branches
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Bazaar
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            State
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            District
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            City
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Price
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Enable/Disable
                                        </th>
                                        <th scope="col" className="py-3 px-6"></th>
                                    </tr>
                                </thead>
                                <tbody className='text-[#2E2C34] font-[14px] font-[500]'>
                                    {planmapdata?.map((item: any) => (
                                        <tr className="border-b">
                                            <td
                                                scope="row"
                                            >
                                                {item.plan_name}

                                            </td>
                                            <td className="py-4 px-6 ">{item.start_time}</td>
                                            <td className="py-4 px-6 ">{item.end_time}</td>
                                            <td className="py-4 px-6 ">
                                                <div className="tag">
                                                    <p className="tagTitle">{item.branches}</p>
                                                </div>
                                            </td>
                                            <td className="py-4 px-6 ">
                                                <div className="tag">
                                                    <p className="tagTitle">{item.bazaar?.map((item: any) => item + ",")}</p>
                                                </div>
                                            </td>
                                            <td className="py-4 px-6 ">
                                                <div className="tag">
                                                    <p className="tagTitle">{item.state?.map((item: any) => item + ",")}</p>
                                                </div>
                                            </td>
                                            <td className="py-4 px-6 ">
                                                {item.district?.map((item: any) => item + ",")}
                                            </td>
                                            <td className="py-4 px-6 ">
                                                {item.city?.map((item: any) => item + ",")}
                                            </td>
                                            <td className="py-4 px-6  text-[#20C9AC;]">
                                                {item.amount}
                                            </td>
                                            <td className="py-4 px-6 ">
                                                <Switch actives={item.plan_active} />
                                            </td>
                                            <td className="py-4 px-6  cursor-pointer">
                                                <GridOptionButton icon={"vertical-options"} />
                                            </td>
                                            <td onClick={()=>handleDeleted(item.id)}>
                                                <DeleteIcon className='cursor-pointer' />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </DashboardLayout >
        </div >
    )
}

export default Plan
