import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const OtpVerification: any = createAsyncThunk('opt_verifi', async (data: any, { rejectWithValue }) => {    
  try {
    const responce: any = await api(`api/wholeseller/verify_otp/`, 'POST', data, false);
    localStorage.setItem('token', responce.data.access_token)
    localStorage.setItem('Wholeseller_id', responce.data.wholeseller_id)
    const resp: any = await api(`api/wholeseller/data/${responce.data.wholeseller_id}/`, 'get');
    if(resp.data?.wholeseller_bazaar[0]){
      localStorage.setItem('bazaarId',resp.data.wholeseller_bazaar[0]);
    }
    localStorage.setItem('user_id',resp?.data?.wholeseller_user?.id);
    localStorage.setItem('wholeseller_name',resp?.data?.wholeseller_firm_name);
    localStorage.setItem('wholeseller_contact_per',resp?.data?.wholeseller_contact_per);
    localStorage.setItem('wholeseller_image',resp?.data?.wholeseller_image);

    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}


export const OtpVerificationReducers = createSlice({
  name: 'opt_verifi',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      OtpVerification.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      OtpVerification.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      OtpVerification.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})
