import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { SectionHeader } from "@/components/molecules/Bazaars";
import { Grid } from "@mui/material";
import { Breadcrumbs } from "@/components/molecules/Breadcrumb";
import {
  BazaarsGridView,
  BazaarsListView,
} from "@/components/molecules/Bazaars/BazaarsView";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Store";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";

const Bazaars = () => {
  const [itemView, setItemView] = useState("Grid");
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector<any>(state => state.ProductBazaarList)


  useEffect(() => {
    dispatch(ProductBazaarList())
  }, [dispatch])
  
  return (
    <>
      <DashboardLayout>
        <SectionHeader />
        <Grid container rowSpacing={1} columnSpacing={1} style={{display: 'unset'}}>
          <Grid item xs={12}>
            <Breadcrumbs
              setItemView={(item: any) => setItemView(item)}
              itemType={itemView}
            />
          </Grid>
          {itemView === "Grid" ? <BazaarsGridView data={data} /> : <BazaarsListView dataList={data} />}
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Bazaars;
