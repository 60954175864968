const getWholeSellerId = () => {
    return localStorage.getItem('Wholeseller_id')
}

const getUserId = () => {
  return localStorage.getItem("user_id")
}

export {getWholeSellerId,getUserId}


export const planTypesData = [
    {
      type: 'cash_discount_type',
      value: 'cash_value',
      label: 'Cash'
    },
    {
      type: 'platinum_discount_type',
      value: 'platinum_value',
      label: 'Platinum'
    },
    {
      type: 'diamond_discount_type',
      value: 'diamond_value',
      label: 'Diamond'
    },
    {
      type: 'gold_discount_type',
      value: 'gold_value',
      label: 'Gold'
    },
    {
      type: 'bronze_discount_type',
      value: 'bronze_value',
      label: 'Bronze'
    }
  ]