import React, { useEffect, useState } from "react";
import { useSidebarPanelStyles } from "@/static/stylesheets/organisms";
import Logo from "@/static/images/mwb_bazaar_dashboard_logo.png";

interface SidebarPanelProps {
  children?: React.ReactNode;
}

const SidebarPanel: React.FC<SidebarPanelProps> = (props) => {
  const classes = useSidebarPanelStyles();
  const [logo,setLogo] = useState("");
  const [ name,setName ] = useState("");

  useEffect(()=>{
    let name = localStorage.getItem('wholeseller_name') as string;
    let logo = localStorage.getItem('wholeseller_image') as string;

    setName(name);
    setLogo(logo);
  },[])

  return (
    <div className={classes.root}>
      <div className={classes.brandLogoContainer}>
        <img src={logo} alt={"Logo"} />
        <p className="mt-3 font-bold">{name}</p>
      </div>

      <div className={classes.menuContainer}>
        <div className={"wrapper"}>{props?.children}</div>
      </div>
    </div>
  );
};

export { SidebarPanel };
