import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DashboardLayout } from "@/components/layouts";
import { Dialog, IconButton, SelectChangeEvent } from "@mui/material";
import { useCreateOrderStyles } from "@/static/stylesheets/screens";
import { MenuItem, Select } from "@mui/material";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { UploadArea } from "@/components/organisms/UploadArea";
import NewProduct from "@/components/molecules/NewProduct/NewProduct";
import MwbImage from "@/static/images/basket_image.png";
import Placeholder from "@/static/images/Screenshot 2022-07-24 at 4.00.png";
import { SearchField } from "@/components/atoms/SearchField";
import { useDispatch, useSelector } from "@/Store";
import { GetRetailer } from "@/Store/Sclicese/Retailerssclices";
import ProductSelect from "./SelectProducts";
import { CreateCart, CreateSubCart, EditSubCart, GetSubCart, cartUseSubCart } from "@/Store/Sclicese/OrderDataSlice";
import { toast, useToast } from "react-toastify";
import Loading from "@/components/LoadingCom/Loading";
import { TrashIcon } from "@heroicons/react/20/solid";
import Delete from "@mui/icons-material/Delete";
import { AlertError } from "@/alert/Alert";

const CreateOrder = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const photoOrderId = searchParams.get("photoOrderId");
  const retailerId = searchParams.get("retailerId");
  const photoOrderType = searchParams.get("photoOrderType");
  const photoOrderBranchId = searchParams.get("branchId");
  const navigate = useNavigate();
  const classes = useCreateOrderStyles();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addanotherModalOpen, setAnotherAddModalOpen] = useState(false);
  const [masterType, setMasterType] = useState<any>(retailerId && Number(retailerId));
  const [orderType, setOrderType] = useState<any>(photoOrderType && photoOrderType);
  const RetailerData: any = useSelector(state => state.GetRetailerdata.data)
  const SubCartData: any = useSelector(state => state.SubCartData.data?.results)

  const CreateCartData: any = useSelector(state => state.CreateCartData.data)
  const CreateSubCartData: any = useSelector(state => state.CreateSubCartData.data)
  const [selectedItems, setSelectedItems] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [ branchId,setBranchId ] = useState(photoOrderBranchId);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetRetailer())
  }, [])

  const handleIncrement = (index: number , maxValue: number) => {
    const arr = selectedItems || [];
    const itm = arr[index];
    if (itm.qty < maxValue) {
      if (itm) {
        arr[index].qty = itm.qty + 1
      }
      setSelectedItems([...arr])
    }
    // setQuantity(quantity + 1);
  };

  const handleDecrement = (index: number, minValue: number) => {
    // if (quantity > 1) {
    // setQuantity(quantity - 1);
    // }
    const arr = selectedItems || [];
    const itm = arr[index];
    if (itm.qty > minValue) {
      if (itm && itm.qty > 1) {
        arr[index].qty = itm.qty - 1
      }
      setSelectedItems([...arr])
    }

  };
  const handleChangeMasterType = (event: SelectChangeEvent) => {
    setMasterType(event.target.value as string);
  };

  const handleChangeOrderType = (event: SelectChangeEvent) => {
    setOrderType(event.target.value as string);
  };

  console.log(SubCartData)

  useEffect(() => {
    if (masterType) {
      dispatch(GetSubCart({ retailer: masterType }))
    }
  }, [masterType])


  const submitOrder = async () => {
    if (!masterType) {
      toast.error('select retailor');
      return;
    }

    if(!selectedItems?.length){
      AlertError("Please add products to create order")
    }

    if (!orderType) {
      toast.error('Please select order type');
      return;
    }

    try {
      setLoading(true);
      const allSubCartsPromise = selectedItems?.map((itm: any) => {
        return new Promise((resolve, reject) => {
          if (Array.isArray(SubCartData) && SubCartData.some((sb: any) => sb?.product === itm?.id)) {
            const cartId = SubCartData.filter((sb: any) => sb?.product === itm?.id)[0].id
            dispatch(EditSubCart({
              id: cartId,
              "qty": itm.qty,
              "amount": itm.product_selling_price,
              "used_in_cart": true,
              "retailer": masterType,
            })).then((data: any) => {
              resolve(data?.payload)
            })
          } else {
            dispatch(CreateSubCart({
              "product": itm.id,
              "qty": itm.qty,
              "amount": itm.product_selling_price,
              "used_in_cart": true,
              "retailer": masterType,
            })).then((data: any) => {
              resolve(data?.payload)
            })
          }
        })
      })

      console.log(allSubCartsPromise)
      const allSubCarts = (await Promise.all(allSubCartsPromise)).map((data: any) => data.id);
      console.log(allSubCarts, "----- ---- --")
      const PostData = {
        // "total_value": 100,
        // "total_items": 1,
        "order_id": (+(new Date())).toString().substr(-8),
        "payment_type": orderType,
        "payment_amount": selectedItems?.reduce((a: any, b: any) => {
          return a + (b.product_selling_price * b.qty)
        }, 0),
        "payment_by_cash_paid_to": "",
        "payment_by_cash_paid_by": "",
        "payment_by_neft_rtgs_transaction_id": null,
        "payment_by_cheque_account_holdername": "",
        "payment_by_cheque_bank_account_number": null,
        "payment_by_cheque_bank_cheque_number": null,
        // "payment_date": "2023-08-27T06:14:00",
        "payment_image": null,
        // "order_created_at": +(new Date()),
        "payment_status": "PENDING",
        "order_status": "PENDING",
        "order_status_change_at": new Date().toJSON(),
        "message": "",
        "deliver_to": null,
        "cart_items": allSubCarts,
        "retailer": masterType,
        "photoOrderId": photoOrderId
      }
      await dispatch(CreateCart(PostData))
      await dispatch(cartUseSubCart({
        cart_ids: allSubCarts
      }))
      toast.success("Order Created Successfully")
      setLoading(false);
      navigate("/order")
    } catch (err) {
      toast.error("Something went wrong!");
      setLoading(false);
      console.log("ERROR ====> ", err)
    }
  }

  return (
    <>
      <DashboardLayout>
        {
          loading ?
            <Loading />
            :
            <div className={classes.root}>
              <div>
                <div>
                  <p className="commonTitle">Create New Order</p>
                  <div className="py-5 w-[500px] flex flex-col gap-[2rem]">
                    <div>
                      <p>Retailer</p>
                      <Select
                        label="Retailer Type"
                        name="retailer_type"
                        variant={"standard"}
                        fullWidth={true}
                        value={masterType}
                        onChange={handleChangeMasterType}
                        disabled={retailerId ? true : false}
                      >
                        {
                          RetailerData?.filter((item: any) => item?.retailer_status === "KYCAPPROVED")?.map((rtl: any) => {
                            return <MenuItem value={rtl.id} onClick={()=>setBranchId(rtl?.retailer_branch)}>
                              {rtl.retailer_name}
                            </MenuItem>
                          })
                        }
                      </Select>
                    </div>
                    <div>
                      <p>Order Type</p>
                      <Select
                        label="Order Type"
                        name="order_type"
                        variant={"standard"}
                        fullWidth={true}
                        value={orderType}
                        onChange={handleChangeOrderType}
                        disabled={photoOrderType ? true : false}
                      >
                        {
                          masterType && (
                            RetailerData?.find((item: any) => item?.id === masterType)?.retailer_plan === 'CASH' ?
                              ['CASH']?.map((type: any) => {
                                return <MenuItem value={type}>
                                  {type}
                                </MenuItem>
                              }) :
                              ['CASH', 'CREDIT']?.map((type: any) => {
                                return <MenuItem value={type}>
                                  {type}
                                </MenuItem>
                              })
                          )
                        }
                      </Select>
                    </div>
                  </div>

                  {/* <div className="py-5 w-[500px]">
                <p>Order Type</p>
                <Select
                  label="Age"
                  variant={"standard"}
                  fullWidth={true}
                  // value={masterType}
                  // onChange={handleChangeMasterType}
                >
                  <MenuItem value={"Regional Wholeseller"}>Cash</MenuItem>
                  <MenuItem value={"Regional Wholeseller 1"}>Cash</MenuItem>
                </Select>
              </div> */}

                  <div>
                    <p>Choose Products</p>
                    <button className="flex gap-5 py-5 items-center align-middle text-[#5542F6]" onClick={() => {
                      if (masterType) {
                        setAddModalOpen(true)
                      }
                    }}>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.66537 5.66634H5.66536V9.66634H4.33203V5.66634H0.332031V4.33301H4.33203V0.333008H5.66536V4.33301H9.66537V5.66634Z"
                          fill="#5542F6"
                        />
                      </svg>
                      <p className="cursor-pointer">Add More Product</p>
                      {/* <p className="cursor-pointer">Select Products</p> */}
                    </button>

                    <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-[12px] text-gray-700 border-b border-gray-200 font-[600] ">
                          <tr className="color-[#2E2C34;]">
                            {/* <th scope="col" className="py-3 px-6">
                              <input type="checkbox" />
                            </th> */}
                            <th scope="col" className="py-3 px-6">
                              Sr. No
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Image
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Product Name
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Brand
                            </th>

                            <th scope="col" className="py-3 px-6 whitespace-nowrap">
                              Primary Packaging
                            </th>
                            <th scope="col" className="py-3 px-6 whitespace-nowrap">
                              Secondary Packaging
                            </th>

                            <th scope="col" className="py-3 px-6">
                              Offers
                            </th>

                            <th scope="col" className="py-3 px-6">
                              Quantity
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Selling Price
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Total Price
                            </th>
                            
                            <th scope="col" className="py-3 px-6">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedItems?.map((item: any, index: number) => (
                            <tr className="border-b ">
                              {/* <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input type="checkbox" />
                              </th> */}
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                {index + 1}
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                <img
                                  className="w-[50px] rounded-md"
                                  src={item.product_upload_front_image}
                                  alt={"Mwb Bazaar"}
                                />
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                {item.product_name}
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                {item.product_brand_name}
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px] whitespace-nowrap">
                                {item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                                ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px] whitespace-nowrap">
                                {item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                                <br />
                                ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                N/A
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{ width: '80px' }}>
                                  <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={() => handleDecrement(index, item.product_min_quantity || 0)}>-</button>
                                  <span>{item.qty}</span>
                                  <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={() => handleIncrement(index, item?.product_max_quantity || Infinity)}>+</button>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                {item.product_selling_price} ₹
                              </td>
                              <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                {item.product_selling_price * item.qty} ₹
                              </td>
                              <td className="py-4 px-6">
                                {/* <button className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent roundede" onClick={() => {
                                  setSelectedItems(selectedItems.filter((ele: any, index: number) => ele.id !== item.id))
                                }} >Delete</button> */}

                                <IconButton onClick={() => {
                                  setSelectedItems(selectedItems.filter((ele: any, index: number) => ele.id !== item.id))
                                }}>
                                    <Delete htmlColor="#FD6B09"/>
                                  </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 justify-end py-[10px]">
                  <p>Total Amount</p>
                  <p>{selectedItems?.reduce((a: any, b: any) => {
                    return a + (b.product_selling_price * b.qty)
                  }, 0)} ₹</p>
                </div>
                <div className="flex gap-5 pb-[30px]">
                  <ActionButton title="Cancel" variant="default" />
                  <ActionButton title="Create Order" variant="primary" onClick={submitOrder} />
                </div>
              </div>
              {
                (masterType && branchId && orderType) &&
                <ProductSelect addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} selectedItems={selectedItems} setSelectedItems={setSelectedItems} retailerId={masterType} branchId={branchId} orderType={orderType?.toLowerCase()} plan={RetailerData.find((item: any) => item.id === masterType)?.retailer_plan} />
              }

              <div>
                <Dialog
                  open={addanotherModalOpen}
                  maxWidth={"lg"}
                  sx={{
                    ".MuiPaper-root": {
                      borderRadius: "20px",
                    },
                  }}
                  onClose={() => setAnotherAddModalOpen(false)}
                >
                  <div>
                    <p className="title">Add Payment Details ok</p>
                    <UploadArea label={"Upload Icon"} />
                    <div>
                      <div className={"action-bar"}>
                        <ActionButton
                          variant={"default"}
                          title={"Cancel"}
                          onClick={() => setAnotherAddModalOpen(false)}
                        />

                        <ActionButton
                          variant={"primary"}
                          title={"Add"}
                          onClick={() => setAnotherAddModalOpen(false)}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
        }
      </DashboardLayout>
    </>
  );
};

export default CreateOrder;
