import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { toast } from "react-toastify";


//----------- GetCategoryPlan data --------------//
export const GetCategoryPlan: any = createAsyncThunk('GetCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/data/branch/category-wise-plan/', 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const GetCategoryPlanData = createSlice({
  name: 'GetCategoryPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- GetCategoryListPlan data --------------//
export const GetCategoryListPlan: any = createAsyncThunk('GetCategoryListPlan', async (id: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/${id}/categorylist/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})


export const GetCategoryListPlanData = createSlice({
  name: 'GetCategoryListPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetCategoryListPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetCategoryListPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetCategoryListPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- SubCategoryPlan data --------------//
export const SubCategoryPlan: any = createAsyncThunk('SubCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/data/branch/sub-category-wise-plan/', 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})


export const SubCategoryPlanData = createSlice({
  name: 'SubCategoryPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SubCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      SubCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      SubCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- SubCategoryListPlan data --------------//
export const SubCategoryListPlan: any = createAsyncThunk('SubCategoryListPlan', async (id: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/${id}/subcategorylist/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})


export const SubCategoryListPlanData = createSlice({
  name: 'SubCategoryListPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SubCategoryListPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      SubCategoryListPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      SubCategoryListPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- ItemWisePlan data --------------//
export const ItemWisePlan: any = createAsyncThunk('ItemWisePlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/data/branch/item-wise-plan/', 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const ItemWisePlanData = createSlice({
  name: 'ItemWisePlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      ItemWisePlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      ItemWisePlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      ItemWisePlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- EditCategoryPlan data --------------//
export const EditCategoryPlan: any = createAsyncThunk('EditCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/category-wise-plan/${data.id}/`,'put',data);
    toast.success("Successfully Edit_CategoryPlan!")  
    return result.data
  }
  catch (error) {
    return error
  }
})


export const EditCategoryPlanData = createSlice({
  name: 'EditCategoryPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- CreateCategoryPlan data --------------//
export const CreateCategoryPlan: any = createAsyncThunk('CreateCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/category-wise-plan/`,'post',data);
    toast.success("Successfully create_CategoryPlan!")  
    return result.data
  }
  catch (error) {
    return error
  }
})

export const CreateCategoryPlanData = createSlice({
  name: 'CreateCategoryPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- EditSubCategoryPlan data --------------//
export const EditSubCategoryPlan: any = createAsyncThunk('EditSubCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/sub-category-wise-plan/${data.id}/`,'put',data);
    toast.success("Successfully Edit_SubCategoryPlan!") 
    return result.data
  }
  catch (error) {
    return error
  }
})


export const EditSubCategoryPlanData = createSlice({
  name: 'EditSubCategoryPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditSubCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditSubCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditSubCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- createSubCategoryPlan data --------------//
export const createSubCategoryPlan: any = createAsyncThunk('EditSubCategoryPlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/sub-category-wise-plan/`,'post',data);
    toast.success("Successfully create_SubCategoryPlan!") 
    return result.data
  }
  catch (error) {
    return error
  }
})


export const createSubCategoryPlanData = createSlice({
  name: 'createSubCategoryPlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      createSubCategoryPlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      createSubCategoryPlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      createSubCategoryPlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})



//----------- EditItemWisePlan data --------------//
export const EditItemWisePlan: any = createAsyncThunk('EditItemWisePlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/item-wise-plan/${data.id}/`,'put',data);
    toast.success("Successfully Edit_ItemWisePlan!")  
    return result.data
  }
  catch (error) {
    return error
  }
})


export const EditItemWisePlanData = createSlice({
  name: 'EditItemWisePlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditItemWisePlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditItemWisePlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditItemWisePlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- CreateItemWisePlan data --------------//
export const CreateItemWisePlan: any = createAsyncThunk('EditItemWisePlan', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/branch/item-wise-plan/`,'post',data);
    toast.success("Successfully Edit_ItemWisePlan!")  
    return result.data
  }
  catch (error) {
    return error
  }
})


export const CreateItemWisePlanData = createSlice({
  name: 'CreateItemWisePlan',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateItemWisePlan.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateItemWisePlan.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateItemWisePlan.rejected, (state, action) => {
        state.loading = false
      })
  }
})