import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserProduts, { WholesellerProducts } from "./WholesellerProducts"
import { useDispatch } from "react-redux"
import { AppDispatch } from ".."
import { api } from "@/Axios/api"

export const PlanDelete: any = createAsyncThunk('PlanDelete', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/plans/data/${data}/`, 'DELETE');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}


export const PlanDeletedata = createSlice({
  name: 'PlanDelete',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      PlanDelete.pending, (state, action) => {
        state.loading = true
      })
      builder.addCase(
        PlanDelete.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      PlanDelete.rejected, (state, action) => {
        state.loading = false
      })
    }
})

export default PlanDelete