import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAddnewProductStyles } from "@/static/stylesheets/screens";
import { Autocomplete, Button, FormControl, FormControlLabel, FormLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import UploadAreaImage from "@/static/svg/ic_uploadarea.svg";
import { useDispatch, useSelector } from "@/Store";
import CreateProduct from "@/Store/Sclicese/CreateProduct";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { MasterUnit } from "@/Store/Sclicese/MasterDataSlice";
import { getUserId, getWholeSellerId } from "@/helper";
import { Add, Cancel, Delete, Remove } from "@mui/icons-material";
import { AppService } from "@/service/AllApiData.service";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { DashboardLayout } from "@/components/layouts";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { GetBranchList } from "@/Store/Sclicese/BranchSlice";
import Loading from "@/components/LoadingCom/Loading";
import { Alert, AlertError } from "@/alert/Alert";
import ProductSelect from "./SelectProducts";
import { Input } from "@mui/joy";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";

type propsType = {
  setModalOpen: (val: boolean) => void,
  selectedType: any,
  productData?: any,
  modalOpen?: boolean
}

const AddProductComboForm: React.FunctionComponent<any> = () => {
  const classes = useAddnewProductStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const QuantityUnit = useSelector(state => state?.MasterUnitData?.quantityData)
  const WeightUnit = useSelector(state => state?.MasterUnitData?.weightData)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const BranchList: any = useSelector<any>(state => state?.GetBranch?.data);
  const [branchWisePrice, setBranchWisePrice] = useState<any>([]);
  const [branchList, setBranchList] = useState<any>([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [initialState, setInitialState] = useState<any>({
    branch: null,
    product_upload_front_image: null,
    product_upload_back_image: null,
    product_upload_mrp_label_image: null,
    product_name: "",
    combo_products: [],
    product_description: "",
    // product_brand_name: "",
    product_upload_video: "",
    // product_primary_packing_value: "",
    // product_secondary_packing_value: "",
    product_total_mrp: "",
    product_selling_price: "",
    // product_per_unit_price: "",
    // product_tax_unit_price: "",
    // product_per_unit_weight_value: "",
    // product_total_weight_value: "",
    // product_type: null,
    // product_gst_no: "",
    // product_gst_type: null,
    product_hsn_code: "",
    product_added_date: new Date().toJSON(),
    product_updated_date: new Date().toJSON(),
    // branch_price: [],
    // product_primary_packing_unit: null,
    // product_secondary_packing_unit: null,
    // product_per_unit_weight_unit: null,
    // product_total_weight_unit: null,
    // product_colour: null,
    // product_size: null,
    // bazaar: null,
    // category_group: null,
    // category: null,
    // subcategory: null,
    product_updated_by: getUserId(),
  });
  const [loader, setLoader] = useState(false);
  const [selectProductModal, setSelectProductModal] = useState<any>(false);

  // const [payload, setpayload] = useState<any>({
  //   ...productData,
  //   // product_description: "",
  //   product_active: true,
  //   product_added_date: new Date().toJSON(),
  //   product_updated_date: new Date().toJSON(),
  //   product_updated_by: getUserId(),
  // })
  const [colours, setColours] = useState<any>([]);
  const [sizes, setSizes] = useState<any>([]);

  const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "")


  const getColours = async () => {
    const response = await AppService.getData('master/colour/');
    setColours(response?.data?.results || [])
  }

  const getSizes = async () => {
    const response = await AppService.getData('master/size/');
    setSizes(response?.data?.results || [])
  }

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  useEffect(() => {
    // console.log("logger123.....", payload);
    if (BranchList?.results?.length && BranchList?.results?.find((ele: any) => ele.main_branch).id) {
      setBranchWisePrice([
        {
          price: "",
          branch: BranchList?.results.find((ele: any) => ele.main_branch).id
        }
      ])
    }
  }, [BranchList])

  const handleChangeMasterType = async (event: any, type: string, handler: any) => {
    const { files, name } = event.target;
    console.log("logger123.....", name);

    const fileInput = event.target;

    if (files?.length > 0) {
      const fileSize = files[0]?.size; // in bytes
      let maxSize = 0;

      if (type === "image") {
        maxSize = 500 * 1024; // 500KB for images
      } else if (type === "video") {
        maxSize = 2 * 1024 * 1024; // 2MB for videos
      }

      if (fileSize > maxSize) {
        alert(`${type} size exceeds ${maxSize / 1024}KB. Please choose a smaller ${type}.`);
        return;
      }

      if (files) {
        await getBase64(files[0]).then(
          data => (handler({ target: { name: name, value: data } }))
        );
      }
    }
  };


  // useEffect(()=>{
  //   console.log("logger123.....",payload);
  // },[payload])

  console.log(bazaarID)
  useEffect(() => {
    dispatch(GetBranchList());
    dispatch(MasterUnit())
    getColours();
    getSizes();
    getProducts();
  }, [])


  const getProducts = async () => {
    const response = await AppService.getData(`wholeseller/data/${getUserId()}/product-specify/`);
    setAllProducts(response?.data?.results || [])
  }

  useEffect(() => {
    if (params?.id) {
      getProductData()
    }
  }, [params?.id])


  const getProductData = async () => {
    try {
      const response = await AppService.detailProduct(params.id);
      let data = response.data;
      setInitialState({
        branch: data.branch_price[0].branch,
        product_upload_front_image: data.product_upload_front_image,
        product_upload_back_image: data.product_upload_back_image,
        product_upload_mrp_label_image: data.product_upload_mrp_label_image,
        product_name: data.product_name,
        combo_products: data.combo_products.map((item:any)=>({
          price: item.price,
          quantity: item.quantity,
          ...data.combo_product_details.find((ele:any)=>ele.id === item.id)
        })),
        product_description: data.product_description,
        product_upload_video: data.product_upload_video,
        product_total_mrp: data.product_total_mrp,
        product_selling_price: data.product_selling_price,
        product_type: data.product_type,
        product_hsn_code: data.product_hsn_code,
        product_added_date: data?.product_added_date,
        product_updated_date: new Date().toJSON(),
        branch_price: data.branch_price,
        bazaar: data.bazaar.id,
        product_updated_by: data.product_updated_by.id,
      })

      setBranchWisePrice(data.branch_price)
    } catch (err: any) {
      console.log("ERROR ===> ", err)
    }
  }

  async function convertImageTobS4(imgUrl: string) {
    const imageToBase64 = require('image-to-base64/browser.js');
    let response = await imageToBase64(imgUrl);
    return "data:image/png;base64," + response;
  }

  const handelsubmit = async (values: any) => {

    values.branch_price = [
      {
        branch: values.branch,
        price: values.product_selling_price
      }
    ]

    values.combo_products = values.combo_products.map((item:any)=>(
      {
        id: item.id,
        price: item.product_selling_price,
        quantity: item.quantity
      }
    ))
    console.log("logger123...",values);
    // return;
    // if (!branchWisePrice.length) {
    //   AlertError("Branch is required")
    //   return;
    // }

    // let emptyPrices = branchWisePrice.filter((item: any) => !item.price);
    // console.log("logger123...", emptyPrices)
    // if (emptyPrices.lenth) {
    //   AlertError("Selling price is required");
    //   return;
    // }
    // return;

    setLoader(true)

    try {
      console.log("VALUES ====> ", values)
      if (values.product_upload_front_image?.includes("https")) {
        values.product_upload_front_image = await convertImageTobS4(values.product_upload_front_image);
      }
      if (values.product_upload_back_image?.includes("https")) {
        values.product_upload_back_image = await convertImageTobS4(values.product_upload_back_image);
      }
      if (values.product_upload_mrp_label_image?.includes("https")) {
        values.product_upload_mrp_label_image = await convertImageTobS4(values.product_upload_mrp_label_image);
      }


      const data = {
        ...values,
        bazaar: bazaarID,
        bazaar_id: bazaarID,
        // category_group: values.category_group || Number(searchParams.get("groupcategory")),
        // category: values?.category || Number(searchParams.get("category")),
        // subcategory: values?.subcategory || Number(searchParams.get("subcategory")),
        // product_primary_packing_unit: values.product_primary_packing_unit?.id ? values.product_primary_packing_unit?.id : values.product_primary_packing_unit,
        // product_secondary_packing_unit: values.product_secondary_packing_unit?.id ? values.product_secondary_packing_unit?.id : values.product_secondary_packing_unit,
        // product_per_unit_weight_unit: values.product_per_unit_weight_unit?.id ? values.product_per_unit_weight_unit?.id : values.product_per_unit_weight_unit,
        // product_total_weight_unit: values.product_total_weight_unit?.id ? values.product_total_weight_unit?.id : values.product_total_weight_unit,
        // branch_price: branchWisePrice,
        // product_selling_price: branchWisePrice[0].price,
        // product_per_unit_price: Math.round(values.product_total_mrp / branchWisePrice[0].price),
        product_type: 2
        // product_total_weight_value: values.product_per_unit_weight_value,
        // product_per_unit_weight_value: values.product_total_weight_value,
        // product_colour: values?.product_colour?.id ? values?.product_colour?.id : values?.product_colour,
        // product_size: values?.product_size?.id ? values?.product_size?.id : values?.product_size,
        // product_per_unit_price: (+values?.product_selling_price / +values?.product_secondary_packing_value) ? Math.round(+values?.product_selling_price / +values?.product_secondary_packing_value) : ''
      }

      // console.log(selectedType)
      if (params?.id) {
        const response = await AppService.updateProduct(params.id, data);
        Alert("Product Updated Successfully")
      } else {
        const response = await AppService.addPrdCate(data);
        console.log("RESPONSE ====> ", response);
        Alert("Product Added Successfully")
      }

      // dispatch(CreateProduct(data))
      setLoader(false)
      navigate("/productcombos")
    } catch (err: any) {
      setLoader(false)
    }

  }

  const onChangeWeightUnit = (value: any, name: string, handler: any, newValue: any) => {
    if (value === 14) {
      // handler({ target: { name: name, value: payload[name] / 1000 } })
      // handleChangeMasterType({ target: { name: "product_per_unit_weight_value", value: payload.product_per_unit_weight_value / 100 } })
    } else if (value === 13) {
      // handler({ target: { name: name, value: payload[name] * 1000 } })
      // handleChangeMasterType({ target: { name: "product_per_unit_weight_value", value: payload.product_per_unit_weight_value * 100 } })
    }
  }


  const validationSchema = Yup.object().shape({
    product_upload_front_image: Yup.string().required("This field is required"),
    product_upload_back_image: Yup.string().required("This field is required"),
    product_upload_mrp_label_image: Yup.string().required("This field is required"),
    product_name: Yup.string().required("This field is required").matches(/^[a-zA-Z0-9\s]+$/, 'This field accepts alphabet and numbers only'),
    combo_products: Yup.array().min(1, "This field is required"),
    product_description: Yup.string().required("This field is required"),
    // product_brand_name: Yup.string().required("This field is required").matches(/^[a-zA-Z0-9\s]+$/, 'This field accepts alphabet and numbers only'),
    // product_upload_video: Yup.string().required("This field is required"),
    // product_primary_packing_value: Yup.string().required("This field is required"),
    // product_secondary_packing_value: Yup.string().required("This field is required"),
    product_total_mrp: Yup.string().required("This field is required"),
    product_selling_price: Yup.string().required("This field is required"),
    // product_per_unit_price: Yup.string().required("This field is required"),
    // product_tax_unit_price: Yup.string().required("This field is required"),
    // product_per_unit_weight_value: Yup.string().required("This field is required"),
    // product_total_weight_value: Yup.string().required("This field is required"),
    // product_type: Yup.string().required("This field is required"),
    // product_gst_no: Yup.string().required("This field is required"),
    // product_gst_type: Yup.string().required("This field is required"),
    product_hsn_code: Yup.string().required("This field is required"),
    branch: Yup.number().required("This field is required"),
    // product_primary_packing_unit: Yup.string().required("This field is required"),
    // product_secondary_packing_unit: Yup.string().required("This field is required"),
    // product_per_unit_weight_unit: Yup.string().required("This field is required"),
    // product_total_weight_unit: Yup.string().required("This field is required"),
  })

  return (
    <>
      {
        loader ?
          <Loading />
          : null
      }
      <DashboardLayout>
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handelsubmit}
        >
          {
            ({
              values,
              errors,
              handleBlur,
              handleSubmit,
              handleChange,
              setFieldValue
            }) => (
              <div className="col-span-6 product_item">
                <div className="px-[35px]">
                  <h2 className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[22px] leading-[20px] tracking-[2px] py-8">{params?.id ? "Edit Product Combo" : "Add Product Combo"}</h2>
                </div>

                <div className="w-1/2 px-[35px]">
                  <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Select Branch <span className="text-red-600">*</span></label>
                  <div className="flex items-end">
                    <Autocomplete
                      disablePortal
                      id="branch_name"
                      options={BranchList?.results?.map((item: any) => ({ ...item, label: item.branch_name, value: item.id })) || []}
                      getOptionLabel={(option: any) => option.label}
                      fullWidth
                      onChange={(e: any, value: any) => {
                        console.log("logger123...", e, value)
                        // handleChange(e);
                        setFieldValue('branch', value.id)
                        setFieldValue('combo_products', [])
                      }}
                      value={BranchList?.results?.map((item: any) => ({ ...item, label: item.branch_name, value: item.id }))?.find((ele:any)=>ele.value === values.branch) || null}
                      renderInput={(params) => <TextField {...params} name="branch" variant="standard" />}
                    />


                  </div>
                  {/* <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_total_mrp" />
                        </div> */}
                </div>

                <div className="create_product">
                  <div className="px-[35px] w-full lg:w-1/2">
                    <div className="py-[22px]">
                      <label className="text-[12px] text-[#84818A] leading-[18px] font-[500]">Combo Name <span className="text-red-600">*</span></label>
                      <input type="text" className="outline-none border-b-[1px] border-[#EBEAED] w-full" name="product_name" value={values.product_name} onChange={handleChange} onBlur={handleBlur} />

                      <div className=" text-xs text-red-600 mt-2">
                        <ErrorMessage name="product_name" />
                      </div>
                    </div>
                  </div>

                  <div className="px-[35px] w-full lg:w-1/2">
                    <div className="pb-[22px]">
                      <label className="text-[12px] text-[#84818A] leading-[18px] font-[500]">Combo Description <span className="text-red-600">*</span></label>
                      <textarea className="outline-none border-[1px] px-[5px] rounded-[6px] border-[#EBEAED] w-full mt-[5px]" name="product_description" onChange={handleChange} value={values.product_description} onBlur={handleBlur} />
                      <div className=" text-xs text-red-600 mt-2">
                        <ErrorMessage name="product_description" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="px-[35px] w-full lg:w-1/2">
                    <div className="pb-[22px]">
                      <label className="text-[12px] text-[#84818A] leading-[18px] font-[500]">Brand Name <span className="text-red-600">*</span></label>
                      <input type="text" className="outline-none border-b-[1px] border-[#EBEAED] w-full" name="product_brand_name" value={values.product_brand_name} onChange={handleChange} onBlur={handleBlur} />

                      <div className=" text-xs text-red-600 mt-2">
                        <ErrorMessage name="product_brand_name" />
                      </div>

                    </div>
                  </div> */}

                  <div className="px-[35px] w-full ">
                    <div className="flex justify-end items-end ">
                      <ActionButton
                        variant="primary"
                        title="Add Products"
                        onClick={() => {
                          if (values.branch) {
                            setSelectProductModal(!selectProductModal)
                          } else {
                            AlertError("Please Select Branch")
                          }
                        }}
                      />
                    </div>
                    {
                      values?.branch ?
                        <ProductSelect addModalOpen={selectProductModal} setAddModalOpen={setSelectProductModal} selectedItems={values.combo_products} setSelectedItems={(items: any) => {
                          let data: any = items || [];
                          let totalMRP = 0;
                          let totalSelling = 0;
                          for (let i = 0; i < data.length; i++) {
                            totalMRP += data[i].product_total_mrp
                            totalSelling += data[i].product_selling_price
                          }
                          console.log("product added ====> ", totalMRP, totalSelling, data)
                          setFieldValue("product_total_mrp", totalMRP)
                          setFieldValue("product_selling_price", totalSelling)
                          setFieldValue("combo_products", items)
                        }} branch={values.branch} />
                        : null
                    }


                    <div className="mt-5 flex flex-wrap justify-evenly gap-2">

                      <div className="overflow-x-auto relative pt-[40px] pb-[40px] w-full">
                        <table className="w-full text-sm text-left text-gray-500 ">
                          <thead className="text-[12px] text-gray-700 border-b border-gray-200 font-[600] ">
                            <tr className="color-[#2E2C34;]">
                              {/* <th scope="col" className="py-3 px-6">
                              <input type="checkbox" />
                            </th> */}
                              <th scope="col" className="py-3 px-6">
                                Sr. No
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Image
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Product Name
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Brand
                              </th>

                              <th scope="col" className="py-3 px-6 whitespace-nowrap">
                                Primary Packaging
                              </th>
                              <th scope="col" className="py-3 px-6 whitespace-nowrap">
                                Secondary Packaging
                              </th>

                              <th scope="col" className="py-3 px-6">
                                Quantity
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Total MRP
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Selling Price
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Total Price
                              </th>

                              <th scope="col" className="py-3 px-6">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.combo_products?.map((item: any, index: number) => (
                              <tr className="border-b ">
                                {/* <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <input type="checkbox" />
                              </th> */}
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  {index + 1}
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  <img
                                    className="w-[50px] rounded-md"
                                    src={item.product_upload_front_image}
                                    alt={"Mwb Bazaar"}
                                  />
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  {item.product_name}
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  {item.product_brand_name}
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px] whitespace-nowrap">
                                  {item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                                  ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px] whitespace-nowrap">
                                  {item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                                  <br />
                                  ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})
                                </td>

                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  <div className="border-2 rounded-md flex align-center justify-between py-1 px-1" style={{ width: '80px' }}>
                                    <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={() => {
                                      const arr: any = values.combo_products || [];
                                      const itm: any = arr[index] || {};
                                      if (itm.quantity > 0) {
                                        if (itm && itm.quantity > (itm?.product_min_quantity || 1)) {
                                          arr[index].quantity = itm.quantity - 1
                                        }

                                        let totalMRP = 0;
                                        let totalSelling = 0;
                                        for (let i = 0; i < arr.length; i++) {
                                          totalMRP += arr[i].product_total_mrp * arr[i].quantity
                                          totalSelling += arr[i].product_selling_price * arr[i].quantity
                                        }
                                        console.log("product added ====> ", totalMRP, totalSelling, arr)

                                        setFieldValue("product_total_mrp", totalMRP)
                                        setFieldValue("product_selling_price", totalSelling)

                                        setFieldValue('combo_products', [...arr])
                                      }
                                    }}>-</button>
                                    <span>{item.quantity}</span>
                                    <button style={{ color: '#FF6652', fontSize: '20px' }} onClick={() => {
                                      const arr: any = values.combo_products || [];
                                      const itm: any = arr[index] || {};
                                      if (itm.quantity < (item?.product_max_quantity || item?.product_stocks || Infinity)) {
                                        if (itm) {
                                          arr[index].quantity = itm.quantity + 1
                                        }

                                        let totalMRP = 0;
                                        let totalSelling = 0;
                                        for (let i = 0; i < arr.length; i++) {
                                          totalMRP += arr[i].product_total_mrp * arr[i].quantity
                                          totalSelling += arr[i].product_selling_price * arr[i].quantity
                                        }
                                        console.log("product added ====> ", totalMRP, totalSelling, arr)

                                        setFieldValue("product_total_mrp", totalMRP)
                                        setFieldValue("product_selling_price", totalSelling)

                                        setFieldValue('combo_products', [...arr])
                                      }
                                    }}>+</button>
                                  </div>
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  {item.product_total_mrp * item.quantity} ₹
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  <Input sx={{ width: "100px" }} placeholder="Selling Price" value={item.product_selling_price} startDecorator="₹" onChange={(e: any) => {
                                    console.log("logger123.... ===> ", e.target.value)
                                    let data: any = values.combo_products || [];
                                    data[index].product_selling_price = e.target.value 
                                    setFieldValue('combo_products', data);
                                    let totalMRP = 0;
                                    let totalSelling = 0;
                                    for (let i = 0; i < data.length; i++) {
                                      totalMRP += data[i].product_total_mrp * data[i].quantity
                                      totalSelling += data[i].product_selling_price * data[i].quantity
                                    }
                                    setFieldValue("product_total_mrp", totalMRP)
                                    setFieldValue("product_selling_price", totalSelling)
                                  }} />
                                </td>
                                <td className="py-4 px-6 text-[14px] text-gray-700 font-[500] leading-[20px]">
                                  {item.product_selling_price * item.quantity} ₹
                                </td>
                                <td className="py-4 px-6">
                                  {/* <button className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent roundede" onClick={() => {
                                  setSelectedItems(selectedItems.filter((ele: any, index: number) => ele.id !== item.id))
                                }} >Delete</button> */}

                                  <IconButton onClick={() => {
                                    setFieldValue('combo_products', values.combo_products.filter((ele: any, index: number) => ele.id !== item.id))
                                  }}>
                                    <Delete htmlColor="#FD6B09" />
                                  </IconButton>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                    </div>
                    {/* <p className="font-Poppins text-[#84818A] font-[400] not-italic text-[10px] leading-[13px]">Combo Products</p>

                    <Select
                      // label="Age"
                      variant={"standard"}
                      multiple
                      fullWidth={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="combo_products"
                      value={values.combo_products || []}
                    // onSelect={(e)=>console.log(e)}
                    >
                      {
                        allProducts?.map((product: any) => (
                          <MenuItem value={product.id}>
                            {product.product_name}
                          </MenuItem>
                        ))
                      }
                    </Select> */}

                    <div className=" text-xs text-red-600 mt-2">
                      <ErrorMessage name="combo_products" />
                    </div>
                  </div>

                  {/* <div className="px-[35px] w-1/2">
                    <div className="py-[20px]">
                      <p className="tableTitle pt-[10px]">Packing Details</p>
                    </div>


                    <div className="flex gap-2 w-full items-end">

                      <div className="w-1/4">
                        <div>
                          <p className="font-Poppins text-[#84818A] font-[500] not-italic text-[13px] leading-[13px]"><AcUnitIcon className="py-[5px] px-[0px]" /> 1 box</p>
                        </div>
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Primary Packing <span className="text-red-600">*</span></label>
                        <input type="number" className="outline-none h-[28px] border-b-[1px] border-[#EBEAED] w-full" name="product_primary_packing_value" value={values.product_primary_packing_value} onChange={handleChange} onBlur={handleBlur} onWheel={(e: any) => e?.target?.blur()} />
                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_primary_packing_value" />
                        </div>
                      </div>

                      <div className="w-1/4">
                        <p className="font-Poppins text-[#84818A] font-[400] not-italic text-[10px] leading-[13px]">Unit <span className="text-red-600">*</span></p>
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          onChange={handleChange}
                          name="product_primary_packing_unit" value={values.product_primary_packing_unit}
                        >
                          {
                            QuantityUnit?.map(unit => (
                              <MenuItem value={unit.id}>
                                {unit.unit_name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_primary_packing_unit" />
                        </div>
                      </div>

                      <div className="w-1/4">
                        <div>
                          <p className="font-Poppins text-[#84818A] font-[500] not-italic text-[13px] leading-[13px]"><AcUnitIcon className="py-[5px] px-[0px]" /> 20 pices</p>
                        </div>
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Secondary Packing <span className="text-red-600">*</span></label>
                        <input type="number" className="outline-none border-b-[1px] h-[28px] border-[#EBEAED] w-full" name="product_secondary_packing_value" value={values.product_secondary_packing_value} onChange={handleChange} onWheel={(e: any) => e?.target?.blur()} onBlur={handleBlur} />

                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_secondary_packing_value" />
                        </div>

                      </div>

                      <div className="w-1/4">
                        <p className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Unit <span className="text-red-600">*</span></p>
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          onChange={handleChange}
                          name="product_secondary_packing_unit" value={values.product_secondary_packing_unit} onBlur={handleBlur}
                        >
                          {
                            QuantityUnit?.map(unit => (
                              <MenuItem value={unit.id}>
                                {unit.unit_name}
                              </MenuItem>
                            ))
                          }
                        </Select>

                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_secondary_packing_unit" />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="px-[35px] w-1/2">
                    <div className="py-[20px]">
                      <p className="tableTitle pt-[10px]">Price Details</p>
                    </div>

                    <div className="flex gap-2 w-full items-end pt-[20px]">
                      <div className="w-1/2">
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Total Mrp <span className="text-red-600">*</span></label>
                        <div className="flex items-end">
                          <span className="outline-none pe-[5px] border-b-[1px] h-[28px] border-[#EBEAED] pt-[2px]">Rs.</span>
                          <input disabled type="number" className="outline-none border-b-[1px] h-[28px] border-[#EBEAED]" name="product_total_mrp" value={values.product_total_mrp} onChange={handleChange} onWheel={(e: any) => e?.target?.blur()} onBlur={handleBlur} />


                        </div>
                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_total_mrp" />
                        </div>
                      </div>


                      <div className="w-1/2">
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Selling Price <span className="text-red-600">*</span></label>
                        <div className="flex items-end">
                          <span className="outline-none pe-[5px] border-b-[1px] h-[28px] border-[#EBEAED] pt-[2px]">Rs.</span>
                          <input disabled type="number" className="outline-none border-b-[1px] h-[28px] border-[#EBEAED] w-full" name="product_selling_price" value={values.product_selling_price} onChange={handleChange} onWheel={(e: any) => e?.target?.blur()} />


                        </div>
                        {/* <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_selling_price" />
                        </div> */}
                      </div>

                    </div>



                  </div>

                  {/* <div className="px-[35px] w-1/2">
                    <div className="py-[20px]">
                      <p className="tableTitle pt-[10px]">Weight Details</p>
                    </div>
                    <div className="flex gap-2 w-full items-end">
                      <div className="w-1/4">
                        <div>
                          <p className="font-Poppins text-[#84818A] font-[500] not-italic text-[13px] leading-[13px]"><AcUnitIcon className="py-[5px] px-[0px]" /> 1 KG</p>
                        </div>
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Total weight <span className="text-red-600">*</span></label>
                        <input type="number" onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()} className="outline-none h-[28px] border-b-[1px] border-[#EBEAED] w-full" value={values.product_total_weight_value} name="product_total_weight_value" onChange={(e) => {
                          handleChange(e)
                          let unit_weight = Number(e.target.value) / Number(values?.product_secondary_packing_value || 0);

                          let product_per_unit_weight_unit = values?.product_per_unit_weight_unit;

                          let product_total_weight_unit = values?.product_total_weight_unit;

                          if (product_total_weight_unit === product_per_unit_weight_unit) {
                            handleChange({ target: { name: "product_per_unit_weight_value", value: unit_weight } })
                          }

                          if (product_total_weight_unit === 14) {
                            if (product_per_unit_weight_unit === 13) {
                              handleChange({ target: { name: "product_per_unit_weight_value", value: unit_weight * 1000 } })
                            }
                          } else if (product_total_weight_unit === 13) {
                            if (product_per_unit_weight_unit === 14) {
                              handleChange({ target: { name: "product_per_unit_weight_value", value: unit_weight / 1000 } })
                            }
                          }
                        }}
                          onWheel={(e: any) => e?.target?.blur()} onBlur={handleBlur} />
                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_total_weight_value" />
                        </div>
                      </div>

                      <div className="w-1/4">
                        <p className="font-Poppins text-[#84818A] font-[400] not-italic text-[10px] leading-[13px]">Unit <span className="text-red-600">*</span></p>

                        <Select
                          // label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          onChange={(e) => {
                            if (values["product_total_weight_unit"]) {
                              onChangeWeightUnit(e.target.value, "product_total_weight_value", handleChange, values['product_total_weight_value']);
                            }
                            handleChange(e)
                            // handleChange({ target: { name: "product_total_weight_unit", value: e.target.value } })
                          }}
                          name="product_total_weight_unit"
                          value={Number(values.product_total_weight_unit)}
                        // onSelect={(e)=>console.log(e)}
                        >
                          {
                            WeightUnit?.map(unit => (
                              <MenuItem value={unit.id}>
                                {unit.unit_name}
                              </MenuItem>
                            ))
                          }
                        </Select>

                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_total_weight_unit" />
                        </div>

                      </div>

                      <div className="w-1/4">
                        <div>
                          <p className="font-Poppins text-[#84818A] font-[500] not-italic text-[13px] leading-[13px]"><AcUnitIcon className="py-[5px] px-[0px]" /> 20 GMS</p>
                        </div>
                        <label className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Weight per unit <span className="text-red-600">*</span></label>
                        <input type="number" onKeyDown={(evt) => (evt.key === 'e') && evt.preventDefault()} className="outline-none border-b-[1px] h-[28px] border-[#EBEAED] w-full" value={values.product_per_unit_weight_value} name="product_per_unit_weight_value" onChange={(e) => {
                          handleChange(e)

                          let total_weight = Number(e.target.value) * Number(values?.product_secondary_packing_value || 0);

                          let product_per_unit_weight_unit = values?.product_per_unit_weight_unit;

                          let product_total_weight_unit = values?.product_total_weight_unit;

                          if (product_total_weight_unit === product_per_unit_weight_unit) {
                            handleChange({ target: { name: "product_total_weight_value", value: total_weight } })
                          }

                          if (product_per_unit_weight_unit === 14) {
                            if (product_total_weight_unit === 13) {
                              handleChange({ target: { name: "product_total_weight_value", value: total_weight * 1000 } })
                            }
                          } else if (product_per_unit_weight_unit === 13) {
                            if (product_total_weight_unit === 14) {
                              handleChange({ target: { name: "product_total_weight_value", value: total_weight / 1000 } })
                            }
                          }

                        }} onWheel={(e: any) => e?.target?.blur()} />

                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_per_unit_weight_value" />
                        </div>

                      </div>

                      <div className="w-1/4">
                        <p className="font-Poppins text-[#84818A] font-[400] not-italic text-[10.3769px] leading-[13px]">Unit <span className="text-red-600">*</span></p>
                        <Select
                          // label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          onChange={(e) => {
                            if (values["product_per_unit_weight_unit"]) {
                              onChangeWeightUnit(e.target.value, "product_per_unit_weight_value", handleChange, values?.product_per_unit_weight_value);
                            }
                            handleChange(e)
                            // handleChange({ target: { name: "product_per_unit_weight_unit", value: e.target.value } })
                          }}
                          name="product_per_unit_weight_unit"
                          value={Number(values.product_per_unit_weight_unit)}
                        // onSelect={(e) => console.log(e)}
                        >
                          {
                            WeightUnit?.map(unit => (
                              <MenuItem value={unit.id}>
                                {unit.unit_name}
                              </MenuItem>
                            ))
                          }
                        </Select>

                        <div className=" text-xs text-red-600 mt-2">
                          <ErrorMessage name="product_per_unit_weight_unit" />
                        </div>

                      </div>
                    </div>
                  </div> */}

                  <div className="px-[35px] min-w-[500px] max-w-[600px]">
                    <div className="pt-[18px] pb-[14px]">
                      {/* <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[16px] leading-[20px]">Total Weight in Quital : {payload?.product_total_weight_unit?.id === 14 ? payload?.product_total_weight_value * 100 : payload?.product_total_weight_unit?.id === 13 ? payload?.product_total_weight_value * 10000 : ""} Quintal</p> */}
                    </div>
                  </div>


                  <div className="pt-[20px] px-[35px] min-w-[500px] max-w-[600px]">
                    <div className=" ">
                      <label className="text-[12px] text-[#84818A] leading-[18px] font-[500]">HSN Code</label>
                      <input type="text" maxLength={8} className="outline-none border-b-[1px] border-[#EBEAED] w-full" value={values.product_hsn_code} name="product_hsn_code" onChange={handleChange} onBlur={handleBlur} onWheel={(e: any) => e?.target?.blur()} />

                      <div className=" text-xs text-red-600 mt-2">
                        <ErrorMessage name="product_hsn_code" />
                      </div>

                    </div>
                  </div>


                  <div className="pb-[30px] px-[35px] mt-5">
                    <div className="pb-[8px]">
                      <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[16px] leading-[20px]">Upload Media <span className="text-red-600">*</span></p>
                    </div>
                    <div className="flex justify-between">

                      {
                        !values?.product_upload_front_image ?
                          <div className="w-[370px] py-[5px]">
                            <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                              <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="product_upload_front_image" onChange={(e: any) => handleChangeMasterType(e, "image", handleChange)} />
                              <div>
                                <img src={UploadAreaImage} alt={"Logo"} />
                              </div>
                              <div>
                                <p>Upload Image</p>
                                <p>Supports JPG, PNG, and JPEG</p>
                              </div>
                            </div>

                            <div className=" text-xs text-red-600 mt-2">
                              <ErrorMessage name="product_upload_front_image" />
                            </div>
                          </div> :
                          <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                            <img src={values?.product_upload_front_image} width={'100px'} className="rounded-md" />
                            <IconButton onClick={() => setFieldValue("product_upload_front_image", "")} >
                              <Cancel />
                            </IconButton>
                          </div>
                      }
                    </div>

                    <div className="flex justify-between">
                      {
                        !values?.product_upload_back_image ?
                          <div className="w-[370px] py-[5px]">
                            <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                              <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="product_upload_back_image" onChange={(e: any) => handleChangeMasterType(e, "image", handleChange)} />
                              <div>
                                <img src={UploadAreaImage} alt={"Logo"} />
                              </div>
                              <div>
                                <p>Upload Image</p>
                                <p>Supports JPG, PNG, and JPEG</p>
                              </div>
                            </div>

                            <div className=" text-xs text-red-600 mt-2">
                              <ErrorMessage name="product_upload_back_image" />
                            </div>

                          </div>
                          :
                          <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                            <img src={values?.product_upload_back_image} width={'100px'} className="rounded-md" />
                            <IconButton onClick={() => setFieldValue("product_upload_back_image", "")}  >
                              <Cancel />
                            </IconButton>
                          </div>
                      }
                    </div>

                    <div className="flex justify-between">

                      {
                        !values?.product_upload_mrp_label_image ?
                          <div className="w-[370px] py-[5px]">
                            <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                              <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="product_upload_mrp_label_image" onChange={(e: any) => handleChangeMasterType(e, "image", handleChange)} />
                              <div>
                                <img src={UploadAreaImage} alt={"Logo"} />
                              </div>
                              <div>
                                <p>Upload Image</p>
                                <p>Supports JPG, PNG, and JPEG</p>
                              </div>
                            </div>

                            <div className=" text-xs text-red-600 mt-2">
                              <ErrorMessage name="product_upload_mrp_label_image" />
                            </div>

                          </div>
                          :
                          <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                            <img src={values?.product_upload_mrp_label_image} width={'100px'} className="rounded-md" />
                            <IconButton onClick={() => setFieldValue("product_upload_mrp_label_image", "")}  >
                              <Cancel />
                            </IconButton>
                          </div>
                      }
                    </div>

                    <div className="flex justify-between">

                      {
                        !values?.product_upload_video ?
                          <div className="w-[370px] py-[5px]">
                            <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                              <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".mp4, .avi, .mov" name="product_upload_video" onChange={(e: any) => handleChangeMasterType(e, "video", handleChange)} />
                              <div>
                                <img src={UploadAreaImage} alt={"Logo"} />
                              </div>
                              <div>
                                <p>Upload Video</p>
                                <p>Supports MP4, AVI and MOV </p>
                              </div>
                            </div>

                            <div className=" text-xs text-red-600 mt-2">
                              <ErrorMessage name="product_upload_video" />
                            </div>

                          </div>
                          :
                          <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                            <video src={values?.product_upload_video} width={'100px'} className="rounded-md" />
                            <IconButton onClick={() => setFieldValue("product_upload_video", "")}  >
                              <Cancel />
                            </IconButton>
                          </div>
                      }
                    </div>


                  </div>

                  <div className="px-[35px] w-full lg:w-1/2 mt-5 flex justify-end gap-5">
                    {/* <span className="font-Manrope font-[500] text-[16px] leading-[20px] not-italic p-3 cursor-pointer" onClick={() => }>Cancel</span> */}
                    <ActionButton
                      onClick={() => { navigate("/productcombos") }}
                      variant="default"
                      title="Cancel"
                    />

                    <ActionButton
                      onClick={handleSubmit}
                      variant="primary"
                      title={params?.id ? "Update Product Combo" : "Add Product Combo"}
                    />
                  </div>
                </div>
              </div>
            )
          }
        </Formik>
      </DashboardLayout>
    </>
  );
};

export default AddProductComboForm;