import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { getWholeSellerId } from "@/helper";
import { toast } from "react-toastify";

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const GetOrderData: any = createAsyncThunk('OrderData', async (page: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/carts/?wholeseller=${getWholeSellerId()}&page=${page || 1}`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const OrderData = createSlice({
  name: 'OrderData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetOrderData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetOrderData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetOrderData.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const GetPhotoOrder: any = createAsyncThunk('GetPhotoOrder', async (page: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/click_photo_order/?wholeseller=${getWholeSellerId()}&page=${page || 1}`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const GetPhotoOrderSlice = createSlice({
  name: 'GetPhotoOrder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetPhotoOrder.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetPhotoOrder.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetPhotoOrder.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const GetSingleOrderData: any = createAsyncThunk('SingleOrderData', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/carts/${data}/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const SingleOrderData = createSlice({
  name: 'SingleOrderData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetSingleOrderData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetSingleOrderData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetSingleOrderData.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const CreateSubCart: any = createAsyncThunk('CreateSubCart', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/subcarts/`, 'post',{
      ...data,
      created_by_wholeseller:true,
      wholeseller: Number(getWholeSellerId()),
    });
    return result.data
  }
  catch (error) {
    return error
  }
})

export const CreateSubCartData = createSlice({
  name: 'CreateSubCart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateSubCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateSubCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateSubCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})


export const CreateCart: any = createAsyncThunk('CreateCart', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/carts/`, 'post',{
      ...data,
      created_by_wholeseller:true,
      wholeseller: Number(getWholeSellerId())
    });
    return result.data
  }
  catch (error) {
    return error
  }
})

export const CreateCartData = createSlice({
  name: 'CreateCart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const EditCart: any = createAsyncThunk('EditCart', async (data: any, { rejectWithValue,dispatch }) => {
  try {
    const result: any = await api(`api/retailer/carts/${data.id}/`, 'put',{
      ...data,
      // created_by_wholeseller:true,
      // wholeseller: Number(getWholeSellerId())
    });
    dispatch(GetSingleOrderData(data.id))
    toast.success('Success')
    return result.data
  }
  catch (error:any) {
    toast.error(error?.response?.statusText)
    return error
  }
})

export const EditCartData = createSlice({
  name: 'EditCart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const EditSubCart: any = createAsyncThunk('EditSubCart', async (data: any, { rejectWithValue,dispatch }) => {
  try {
    const result: any = await api(`api/retailer/subcarts/${data.id}/`, 'put',{
      ...data,
      // created_by_wholeseller:true,
      // wholeseller: Number(getWholeSellerId())
    });
    if(data.orderId){
      dispatch(GetSingleOrderData(data.orderId))
      toast.success('Success')
    }
    return result.data
  }
  catch (error:any) {
    toast.error(error?.response?.statusText)
    return error
  }
})

export const EditSubCartData = createSlice({
  name: 'EditSubCart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditSubCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditSubCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditSubCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const GetSubCart : any = createAsyncThunk('SubCartData', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/subcarts/retailer/${data.retailer}/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const SubCartData = createSlice({
  name: 'SubCartData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetSubCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetSubCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetSubCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})


export const cartUseSubCart : any = createAsyncThunk('cartUseSubCart', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/update_subcart_used_in_cart/`, 'post', data);
    return result.data
  }
  catch (error) {
    return error
  }
})

export const cartUseSubCartData = createSlice({
  name: 'cartUseSubCart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      cartUseSubCart.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      cartUseSubCart.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      cartUseSubCart.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const CreateOutForDelivery : any = createAsyncThunk('CreateOutForDelivery', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/out_for_delivery/`, 'post', data);
    return result.data
  }
  catch (error) {
    return error
  }
})

export const CreateOutForDeliveryData = createSlice({
  name: 'CreateOutForDelivery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateOutForDelivery.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateOutForDelivery.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateOutForDelivery.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export const GetOutForDelivery : any = createAsyncThunk('GetOutForDelivery', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/out_for_delivery/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const GetOutForDeliveryData = createSlice({
  name: 'GetOutForDelivery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetOutForDelivery.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetOutForDelivery.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetOutForDelivery.rejected, (state, action) => {
        state.loading = false
      })
  }
})