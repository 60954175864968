import { AddButton, GridOptionButton } from '@/components/atoms/Button'
import { useOffersStyles } from '@/static/stylesheets/screens';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Plan = () => {
    const classes = useOffersStyles();
    const data = [1, 2, 3, 4, 5];
    const navigate = useNavigate();
    return (
        <div>
            <div className={classes.root}>
                <div>
                    <div className="flex justify-end">

                        <AddButton
                            label="Create New Plan"
                            onClick={() => navigate("/addplan")}
                        />
                    </div>
                </div>
                <div>
                    <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-[12px] text-[#84818A] bg-gray-50 font-[600]">
                                <tr className=" color-[#84818A;]">
                                    <th scope="col" className="py-3 px-6">
                                        Plan Name
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Bazaar
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        State
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        District
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        City
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Duration
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Plan Time Slot
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Price
                                    </th>

                                    <th scope="col" className="py-3 px-6"></th>
                                </tr>
                            </thead>
                            <tbody className='text-[#2E2C34;]'>
                                {data.map((item: any) => (
                                    <tr className="border-b ">
                                        <td
                                            className="py-4 px-6"
                                        >
                                            Delhi, UP Weekly Plan

                                        </td>
                                        <td className="py-4 px-6 ">Electronics Bazaar,
                                            Computer Bazaar</td>
                                        <td className="py-4 px-6 ">
                                            <p>UP, Delhi</p>
                                        </td>
                                        <td className="py-4 px-6 ">
                                            <div className="tag">
                                                <p className="tagTitle">Ghaziabad, Noida, Delhi</p>
                                            </div>
                                        </td>
                                        <td className="py-4 px-6 ">Ghaziabad, Noida, Delhi</td>
                                        <td className="py-4 px-6 ">7 days</td>

                                        <td className="py-4 px-6 ">
                                            <Typography sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '5px 18px',
                                                borderRadius: '18px',
                                                border: '1px solid #E1E1E1',
                                                fontSize: '14px',
                                                color: ' #2E2C34'
                                            }}>9AM-6PM</Typography>
                                            <Typography mt={1} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '5px 18px',
                                                borderRadius: '18px',
                                                border: '1px solid #E1E1E1',
                                                fontSize: '14px',
                                                color: ' #2E2C34'
                                            }}>9AM-6PM</Typography>
                                        </td>
                                        <td className="py-4 px-6">Rs. 10,000</td>

                                        <td className="py-4 px-6 cursor-pointer">
                                            <GridOptionButton icon={"vertical-options"} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plan
