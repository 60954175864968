import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { toast } from "react-toastify"



type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const AgentList: any = createAsyncThunk('agent_list', async (page: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/agent/?page=${page || 1}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const AgentListReducers = createSlice({
  name: 'agent_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      AgentList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      AgentList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      AgentList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export const AgentSingle: any = createAsyncThunk('agent_single', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/agent/${data.id}/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const AgentSingleSlice = createSlice({
  name: 'agent_single',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      AgentSingle.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      AgentSingle.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      AgentSingle.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


// ---------------------- Create agent data --------------------
export const CreateAgentData: any = createAsyncThunk('create_agent', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/agent/`, 'POST', data.data)
    toast.success("Create agent data!")
    data.navigate('/agents')
    return responce.data
  }
  catch (error : any) {
    toast.error(error.response.statusText)
    return console.error(error)
  }
})

export const CreateAgentReducers = createSlice({
  name: 'create_agent',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateAgentData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateAgentData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateAgentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})



// ---------------------- Edit agent data --------------------
export const EditAgentData: any = createAsyncThunk('edit_agent', async (data: any, { rejectWithValue }) => {
  console.log(data)
  try {
    const responce: any = await api(`api/wholeseller/agent/${data.data.id}/`, 'put',data.data)
    toast.success("Edit agent data!")
    data.navigate('/agents')
    return responce.data
  }
  catch (error :any) {
    toast.error(error.response.statusText)
    return rejectWithValue(error)
  }
})

export const EditAgentReducers = createSlice({
  name: 'edit_agent',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditAgentData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditAgentData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditAgentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

// ---------------------- Edit agency data --------------------
export const EditAgentAgencyData: any = createAsyncThunk('edit_agent', async (data: any, { rejectWithValue }) => {
  console.log(data)
  try {
    const responce: any = await api(`api/agency/data/${data.data.id}/`, 'put',data.data)
    toast.success("Edit agent data!")
    // data.navigate('/agents')
    return responce.data
  }
  catch (error :any) {
    toast.error(error.response.statusText)
    return rejectWithValue(error)
  }
})

export const EditAgentAgencyReducers = createSlice({
  name: 'edit_agent',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditAgentAgencyData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditAgentAgencyData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditAgentAgencyData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


// ---------------------- Delete agent data --------------------
export const DeleteAgentData: any = createAsyncThunk('delete_agent', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/agent/${data}/`, 'delete')
    toast.success("successfully deleted!")
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const DeleteAgentReducers = createSlice({
  name: 'delete_agent',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DeleteAgentData.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      DeleteAgentData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DeleteAgentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})