import React from "react";
import { useDashboardLayoutStyles } from "@/static/stylesheets/layouts";
import { AdminSidebar } from "@/components/molecules/AdminSidebar";
import { Header } from "@/components/molecules/Header";
import Mainbar from "../molecules/Mainbar/Mainbar";

interface DashboardLayoutProps {
  children?: React.ReactNode;
  sectionName?:String
}

const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const classes = useDashboardLayoutStyles();
  const pathName = window.location.pathname.split('/')[1]



  return (
    <div className={classes.root}>
      {/* <Mainbar /> */}
      {/* {pathName != 'SubbazarDashbord' ? <AdminSidebar />: ''} */}
      <AdminSidebar />
      <div className={classes.mainContainer}>
        <Header /> 
        <div className={pathName == "addproduct" ? `${classes.contentContainer} !p-0` : classes.contentContainer}>{props?.children}</div>
      </div>
    </div>
  );
};

export { DashboardLayout };
