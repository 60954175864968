import { DashboardLayout } from '@/components/layouts'
import { usepreviewPlansStyle } from '@/static/stylesheets/molecules/previewPlansStyle';
import { Typography } from '@mui/material';
import CheckImage from '../../static/images/Check cr-fr.png'
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Preview = () => {
    const classes = usepreviewPlansStyle();
    const navigate = useNavigate()
    return (
        <DashboardLayout>
            <div className={classes.root}>
                <div className="container">

                    <div className="textContainer flex">
                        <div onClick={() => navigate('/addplan')}>
                            <ArrowBackIos style={{ color: '#84818A', cursor: 'pointer' }} className='mt-1' />
                        </div>
                        <p className="titleHead">Plan Preview</p>
                    </div>
                    <div className="overflow-x-auto relative pt-[43px] pb-[4px]">
                        <div className='flex justify-between gap-2'>
                            <div className='card'>
                                <Typography sx={{
                                    fontFamily: 'Manrope',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    lineHeight: '22px',
                                    letterSpacing: '0.2px',
                                    color: '#4E2FA9',
                                    marginTop: '40px'
                                }}>Individual</Typography>
                                <Typography sx={{
                                    fontFamily: 'Manrope',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    lineHeight: '33px',
                                    letterSpacing: '0.2px',
                                    color: '#121127;',
                                    marginTop: '22px'
                                }}>Free</Typography>
                                <Typography sx={{
                                    fontFamily: 'Manrope',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '180%',
                                    color: '#121127;',
                                    marginTop: '15px'
                                }}>Available for All Locations</Typography>
                                <Typography sx={{
                                    fontFamily: 'Manrope',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    lineHeight: '180%',
                                    color: '#121127',
                                    marginTop: '50px'
                                }}>Features</Typography>
                                <ul className='mb'>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>25 Projects</span> </li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Up to 10,000 subscribers</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Advanced analytics </span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>24-hour support response time</span></li>
                                </ul>
                            </div>

                            <div className='card'>
                                <div className='flex justify-between'>
                                    <div>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            letterSpacing: '0.2px',
                                            color: '#4E2FA9',
                                            marginTop: '40px'
                                        }}>Team</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            lineHeight: '33px',
                                            letterSpacing: '0.2px',
                                            color: '#121127;',
                                            marginTop: '22px'
                                        }}>₹24 /month</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '180%',
                                            color: '#121127;',
                                            marginTop: '15px'
                                        }}>Available for UP, Delhi, Punjab</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            lineHeight: '180%',
                                            color: '#121127',
                                            marginTop: '50px'
                                        }}>Features</Typography>
                                    </div>
                                    <div className='branches mt-5'>
                                        <div className='branches-text'>12 </div>
                                        <span>Branches</span>
                                    </div>
                                </div>
                                <ul className='mb'>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>25 Projects</span> </li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Up to 10,000 subscribers</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Advanced analytics </span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>24-hour support response time</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Marketing advisor</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Custom integration</span></li>
                                </ul>
                            </div>
                            <div className='card'>
                                <div className='flex justify-between'>
                                    <div>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            letterSpacing: '0.2px',
                                            color: '#4E2FA9',
                                            marginTop: '40px'
                                        }}>Individual</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            lineHeight: '33px',
                                            letterSpacing: '0.2px',
                                            color: '#121127;',
                                            marginTop: '22px'
                                        }}>₹48 /month</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '180%',
                                            color: '#121127;',
                                            marginTop: '15px'
                                        }}>Available for UP, Delhi, Punjab</Typography>
                                        <Typography sx={{
                                            fontFamily: 'Manrope',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '24px',
                                            lineHeight: '180%',
                                            color: '#121127',
                                            marginTop: '50px'
                                        }}>Features</Typography>
                                    </div>
                                    <div className='branches mt-5'>
                                        <div className='branches-text'>12 </div>
                                        <span>Branches</span>
                                    </div>
                                </div>

                                <ul className='mb'>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Unlimited projects</span> </li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Unlimited subscribers</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Custom analytics </span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>1-hour support response time</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Marketing advisor</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>1Custom integration</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Marketing advisor</span></li>
                                    <li className='flex mt-4 '><img src={CheckImage} /> <span className='feature-text'>Tracking customers flow</span></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout >
    )
}

export default Preview
