import React from "react";
import { BazaarCard } from "@/components/molecules/Bazaars";
import { Grid } from "@mui/material";
import { useBazaarsStyles } from "@/static/stylesheets/screens";

interface IProps {
  data?: any,
}

const BazaarsGridView = (props: IProps) => {
  const { data: bazar } = props;
  const newData = bazar.data.results
  const classes = useBazaarsStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2}>
          {newData?.length > 0 && newData.map((item: any) => (
            <Grid item xs={4}>
              <BazaarCard item={item} />
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export { BazaarsGridView };
