import React from "react";
import { useBazaarListStyles } from "@/static/stylesheets/molecules/";
import LogoDot from "@/static/icons/ic_dot.png";
interface IProps {
  dataList?: any,
}

const BazaarsListView = (props: IProps) => {
  const { dataList: list } = props;
  const TableData = list.data.results
  
  const classes = useBazaarListStyles();

  return (
    <div className={classes.root}>
      <table>
        <tr>
          <th className="nameTitle">BAZAAR NAME</th>
          <th className="categoryTitle">GROUP CATEGORY</th>
          <th className="wholesellerTitle">WHOLESELLERS</th>
          <th className="agentTitle">AGENTS</th>
          <th className="statesTitle">STATES</th>
          <th className="revenewEarned">REVENUE EARNED</th>
          <th className="billsTitle">NO. OF BILLS</th>
          <th></th>
        </tr>
        {TableData?.length > 0 && TableData?.map((item: any) => (
          <tr>
            <td>
              <div className="brandData">
                <img className="brandLogo" src={item.bazaar_image} alt={"Logo"} />
                {item.bazaar_name}
              </div>
            </td>
            <td>{item.category_group.map((category:any) => category.parent_category_name+', ')}</td>
            <td>{item.wholesellers}</td>
            <td>{item.agents}</td>
            <td>{item.states}</td>
            <td>{item.earnings? `Rs. ${item.earnings}` : 'Rs. 0'}</td>
            <td>{item.bills? `Rs. ${item.bills}` : 'Rs. 0'}</td>
            <td>
              <img src={LogoDot} alt={"Logo"} />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export { BazaarsListView };
