import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardLayout } from "@/components/layouts";
import { useAddnewProductStyles } from "@/static/stylesheets/screens";
import UploadImg from "@/static/svg/ic_bulkupload.svg";
import { useDispatch, useSelector } from "@/Store";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { AnyAction } from "@reduxjs/toolkit";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import FilteredProducts, { clearFilteredProducts } from "@/Store/Sclicese/filteredProductsSlice";
import AddProductForm from "./AddProductForm";
import { SearchField } from "@/components/atoms/SearchField";
import { AlertError } from "@/alert/Alert";

const AddProduct = () => {
  const classes = useAddnewProductStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedType, setselectedType] = useState<any>({});
  const ProductBazaar = useSelector(state => state?.ProductBazaarList?.data?.results)
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)
  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const FilteredProductsData = useSelector(state => state?.FilteredProductsReducer?.data?.results)
  const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "")
  const [clickedProduct, setClickedProduct] = useState({})
  const [subCategorySearch, setSubCategorySearch] = useState("");
  const [productsSearch, setProductsSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [groupCategorySearch, setGroupCateorySearch] = useState("");


  useEffect(() => {
    dispatch(CategoryGroup(bazaarID))
  }, [])

  useEffect(() => {
    if (Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0) {
      handleGroupClick(CategoryGroupData[0])
    }
  }, [CategoryGroupData])

  useEffect(() => {
    if (Array.isArray(CategoryData) && CategoryData.length > 0) {
      handleCategoryClick(CategoryData[0])
    }
  }, [CategoryData])

  useEffect(() => {
    if (Array.isArray(SubCategoryData) && SubCategoryData.length > 0) {
      handleSubCategoryClick(SubCategoryData[0])
    }
  }, [SubCategoryData])

  const handleGroupClick = (data: any) => {
    setselectedType((prevdata: any) => ({ selectedGroup: data }));
    dispatch(clearCategoryState())
    dispatch(clearFilteredProducts())
    dispatch(CategorySlice({ bazaarId: bazaarID, id: data?.id }))
  }

  const handleCategoryClick = (data: any) => {
    setselectedType((prevdata: any) => ({ ...prevdata, selectedCategory: data, selectedSubcategory: undefined }));
    dispatch(clearSubCategoryState())
    dispatch(clearFilteredProducts())
    dispatch(SubCategorySlice({ bazaarId: bazaarID, category: data?.id, id: selectedType['selectedGroup']?.id }))
  }
  const handleSubCategoryClick = (data: any) => {
    setselectedType((prevdata: any) => ({ ...prevdata, selectedSubcategory: data }));
    dispatch(clearFilteredProducts())
    dispatch(FilteredProducts({ bazaar: bazaarID, category_group: selectedType["selectedCategory"]?.category_group, category: selectedType["selectedCategory"]?.id, subcategory: data?.id, product_type: 1 }))
  }

  const handleFilteredProducts = () => {
    dispatch(FilteredProducts({ bazaar: bazaarID, categoryGroup: selectedType["selectedCategory"]?.category_group, category: selectedType["selectedCategory"]?.id, subCategory: selectedType["selectedSubcategory"]?.id, product_type: 1 }))
  }

  const SearchProducts = async (search:string) => {
    dispatch(clearFilteredProducts())
    dispatch(FilteredProducts({ bazaar: bazaarID, category_group: selectedType["selectedCategory"]?.category_group, category: selectedType["selectedCategory"]?.id, subcategory: selectedType['selectedSubcategory']?.id, search: search, product_type: 1 }))
  }

  return (
    <>
      <DashboardLayout>
        <div className={`${classes.root} !p-0`}>
          <div>
            <div className="flex gap-5 justify-between items-center pb-[26px] ps-[46px] pt-[18px]">
              <p className="commonTitle tracking-[2px]">Add New Product</p>
              <div className="border-2 p-3 rounded-md cursor-pointer mx-[50px]">
                <div
                  onClick={() => navigate("/bulkupload/product")}
                  className="flex gap-5"
                >
                  <img src={UploadImg} alt={"Logo"} />
                  <p>Bulk Upload</p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gap-1">
              <div className="bg-[#e7e7e7] product_item col-span-2">
                <div className="py-[15px] ps-[30px]">
                  <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[12px] leading-[20px] tracking-[3px]">GROUP</p>
                </div>

                <SearchField inputwidth={"100%"} className=" justify-center mb-2" inputdata={setGroupCateorySearch}  />

                <div className="product_list">
                  {
                    CategoryGroupData?.length > 0 && CategoryGroupData?.filter((item:any)=>item?.parent_category_name?.toLowerCase()?.includes(groupCategorySearch?.toLowerCase()))?.map((item: any) => (
                      <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedGroup"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => { handleGroupClick(item) }}>
                        <div className="flex gap-5 items-center">
                          <img
                            style={{ width: '32px', height: '32px' }}
                            className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                            src={item.parent_category_ref_image}
                            alt={"Logo"}
                          />
                          <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedGroup"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.parent_category_name}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>


              <div className="bg-[#e7e7e7] product_item col-span-2">
                <div className="py-[15px] ps-[30px]">
                  <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[12px] leading-[20px] tracking-[3px]">CATEGORY</p>
                </div>

                <SearchField inputwidth={"100%"} className=" justify-center mb-2" inputdata={setCategorySearch}  />

                <div className="product_list">
                  {
                    CategoryGroupData?.length > 0 && CategoryData?.filter((item:any)=>item?.category_name?.toLowerCase()?.includes(categorySearch?.toLowerCase()))?.map((item: any) => (
                      <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedCategory"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => handleCategoryClick(item)}>
                        <div className="flex gap-5 items-center">
                          <img
                            style={{ width: '32px', height: '32px' }}
                            className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                            src={item?.category_ref_image}
                            alt={"Logo"}
                          />
                          <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedCategory"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.category_name}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>


              <div className=" product_item col-span-2 bg-[#e7e7e7]">
                <div className="py-[15px] ps-[30px]">
                  <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[12px] leading-[20px] tracking-[3px]">SUB-CATEGORY</p>
                </div>

                <SearchField inputwidth={"100%"} className=" justify-center mb-2" inputdata={setSubCategorySearch}  />

                <div className="product_list">
                  {
                    CategoryData?.length > 0 && SubCategoryData?.length > 0 && SubCategoryData?.filter((item:any)=>item?.subcategory_name?.toLowerCase()?.includes(subCategorySearch?.toLowerCase()))?.map((item: AnyAction) => (
                      <div className={`py-[9px] cursor-pointer px-[23px] ${item?.id === selectedType["selectedSubcategory"]?.id && ("bg-[#4E2FA9] text-[#fff]")}`} onClick={() => handleSubCategoryClick(item)}>
                        <div className="flex gap-5 items-center">
                          <img
                            style={{ width: '32px', height: '32px' }}
                            className="rounded-[4px] border-solid border-[1px] border-[#E1E1E1]"
                            src={item?.subcategory_ref_image}
                            alt={"Logo"}
                          />
                          <p className={`font-Manrope font-[500] not-italic text-[14px] leading-[20px] ${item?.id === selectedType["selectedSubcategory"]?.id ? ("text-[#fff]") : 'text-gray-900'}`}>{item.subcategory_name}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

                <div className="product_item final_product col-span-6">
                  <div className="">
                    <p className="font-Manrope text-[#2E2C34] font-[700] not-italic text-[12px] leading-[20px] tracking-[3px] bg-[#F5F5F5] py-[15px] ps-[30px]">PRODUCTS</p>
                  </div>

                  <SearchField inputwidth={"100%"} className=" justify-start mb-2" inputdata={SearchProducts}  />

                  <div className="product_lists">
                    {selectedType["selectedCategory"]?.category_group && selectedType["selectedCategory"]?.id && selectedType["selectedSubcategory"]?.id && <div className="py-[9px] px-[16px]">
                      <button className="flex gap-5 p-2 items-center align-middle text-[#5542F6]" onClick={() => {
                        console.log("SELECTED TYPE =====> ",selectedType)
                        if(selectedType.selectedGroup.id && selectedType.selectedCategory.id && selectedType.selectedSubcategory.id){
                          navigate(`/addproduct/form?groupcategory=${selectedType.selectedGroup.id}&category=${selectedType.selectedCategory.id}&subcategory=${selectedType.selectedSubcategory.id}`)
                        } else {
                          AlertError("Please Select Group Category, Category and Sub Category")
                        }
                        }}>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.66537 5.66634H5.66536V9.66634H4.33203V5.66634H0.332031V4.33301H4.33203V0.333008H5.66536V4.33301H9.66537V5.66634Z"
                            fill="#5542F6"
                          />
                        </svg>
                        <p className="cursor-pointer">Add More Product</p>
                      </button>
                    </div>}
                    <div className="product_list">
                      {
                        FilteredProductsData?.length > 0 && FilteredProductsData?.map((item: any) => (
                          <div className="py-[9px] cursor-pointer px-[23px]" onClick={() => {
                            setClickedProduct(item);
                            setModalOpen(true)
                          }}>
                            <div className="flex gap-5 items-center">
                              <img
                                style={{ width: '36px', height: '36px' }}
                                className="rounded-md"
                                src={item.product_upload_front_image}
                                alt={"Logo"}
                              />
                              <p className="text-gray-900 whitespace-nowrap text-[14px] font-[600]">{item.product_name}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddProduct;