import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { DashboardLayout } from "@/components/layouts";
import { useaddAgentStyle } from "@/static/stylesheets/molecules/addagent";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SalesManIcon from "@/static/icons/icon_salesman.svg";
import indivitualIcon from "@/static/icons/icon_indivitual.svg";
import AgancyIcon from "@/static/icons/icon_agancy.svg";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import LogoPrev from "@/static/icons/ic_previous.png";
import { useNavigate, useParams } from "react-router-dom";
import { number } from "prop-types";
import { removeDuplicates } from "@/helper/util";
import DistrictPicker from "@/components/LocationPickers/DistrictPicker";
import { CityPickerNew } from "@/components/LocationPickers/CityPickerNew";

const AddAgents = () => {
  const classes = useaddAgentStyle();
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("user") || '{}')
  const [paymentType, setPaymentType] = React.useState("individual");
  const [, setMasterType] = useState("Regional Wholeseller");
  const [AllState, setAllState] = React.useState([]);
  const [allDis, setAllDis] = React.useState<any>([]);
  const [allCity, setAllCity] = React.useState<any>([]);
  const [singleState, setSingleState] = React.useState<any>([]);
  const [singleDis, setSingleDis] = React.useState([]);

  const [initialValues, setInitialValues] = useState<any>({
    // wholeseller_agent_description: "",
    wholeseller_agent_name: "",
    wholeseller_agent_type: "WHOLESELLER_AGENT_INDIVIDUAL",
    wholeseller_agent_number: "",
    wholeseller_agent_firm_name: "",
    // wholeseller_agent_altranate_mobile_number: "",
    wholeseller_agent_email: "",
    // wholeseller_agent_gender: "MALE",
    // wholeseller_agent_date_of_birth: "",
    // wholeseller_agent_address: "",
    // wholeseller_agent_landmark: "",
    // wholeseller_agent_pincode: "",
    // wholeseller_agent_adharcard_no: "",
    // wholeseller_agent_adhar_front_image: null,
    // wholeseller_agent_adhar_back_image: null,
    // wholeseller_agent_pancard_image: null,
    // wholeseller_agent_pancard_no: "",
    // wholeseller_agent_image: null,
    wholeseller_agent_active: true,
    // agency: 2,
    wholeseller_agent_state: "",
    wholeseller_agent_city: "",
    wholeseller_agent_district: "",
    // wholeseller_agent_commision: 0,
    wholeseller_agent_bazaar: user.wholeseller_bazaar || [],
    wholeseller_agent_assigned_state: "",
    wholeseller_agent_assigned_city: [],
    wholeseller_agent_assigned_district: [],
  });
  const [singleCity, setSingleCity] = React.useState([]);
  const [AllBazaar, setAllBazaar] = React.useState<any>([]);
  const [count, setCount] = useState<any>(1)
  const [bazaarList, setBazaarList] = useState([]);
  const [bazaar, setBazaar] = useState<any>();
  const [stateList1, setStateList1] = useState<any[]>([]);
  const [states1, setStates1] = useState<any>();
  const [districts1, setDistricts1] = useState<any>([]);
  const [districtList1, setDistrictList1] = useState<any[]>([]);
  const [cityList1, setCityList1] = useState<any[]>([]);

  const { id } = useParams()
  const agency = JSON.parse(localStorage.getItem("agency") || '{}')
  const allowedBazaars = agency?.wholeseller_agent_bazaar_data?.map((item: any, index: number) => (
    {
      label: item.bazaar_name,
      value: item.id
    }
  ))
  const allowedStates = agency?.wholeseller_agent_assigned_state_names?.map((item: any, index: number) => (
    {
      label: item.state,
      value: item.id
    }
  ))
  const allowedDistrict = agency?.wholeseller_agent_assigned_district_names?.map((item: any, index: number) => (
    {
      label: item.district,
      value: item.id
    }
  ))
  const allowedCity = agency?.wholeseller_agent_assigned_city_names?.map((item: any, index: number) => (
    {
      label: item.city,
      value: item.id
    }
  ))

  // const superAdmin = localStorage.getItem("superAdmin") ? JSON.parse(localStorage.getItem("superAdmin") || "") : false;

  const superAdmin = true;

  const handleChangeMasterType = (event: SelectChangeEvent) => {
    setMasterType(event.target.value as string);
  };

  const validationSchema = Yup.object().shape({
    wholeseller_agent_name: Yup.string()
      .min(2, "Too Short!")
      .max(40, "Too Long!")
      .required("Agent name is required"),
    wholeseller_agent_firm_name: Yup.string().when("wholeseller_agent_type", {
      is: "WHOLESELLER_AGENT_AGENCY",
      then: (schema) => schema.required("Agency name is required")
    }),
    // wholeseller_agent_firm_name: Yup.string().when("wholeseller_agent_type", (value: string) => {
    //   console.log("drdfhdfhdfh", value);
    //   // if (value == "Agent") {
    //   //   return Yup.string().required("jygsdyfg");
    //   // }
    // }),
    wholeseller_agent_email: Yup.string()
      .email("Invalid email format")
      .required("Email address is required*")
      .test('unique-email', 'Email already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentEmailExists(value);
        return !isDuplicate;
      }),
    wholeseller_agent_number: Yup.number()
      .positive("Phone no must be positive")
      .required("Phone no is required")
      .test('unique-mobile-number', 'Mobile number already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentNumberExists(value);
        return !isDuplicate;
      }),
    wholeseller_agent_state: Yup.string().required("State is required"),
    wholeseller_agent_district: Yup.string().required("District is required"),
    wholeseller_agent_city: Yup.string().required("City is required"),
    wholeseller_agent_bazaar: Yup.array().min(1, "Pick at least 1 Assigned state"),
    // .required("Bazaar is required"),
    wholeseller_agent_assigned_state: Yup.number().required("Pick at least 1 Assigned state"),
    // .of(Yup.string())
    // .required("Assigned State is required"),
    wholeseller_agent_assigned_city: Yup.array().min(1, "Pick at least 1 Assigned city"),
    wholeseller_agent_assigned_district: Yup.array().min(
      1,
      "Pick at least 1 Assigned  district"
    ),
    // wholeseller_agent_type: Yup.string(),
    // wholeseller_agent_firm_name: Yup.string().when("wholeseller_agent_type",{
    //   is: "AGENCY",
    //   then: Yup.string().required("ewfwef")
    // })
  });

  // const {
  //   handleChange,
  //   handleSubmit,
  //   handleReset,
  //   values,
  //   errors,
  //   touched,
  //   setValues,
  //   setFieldValue,
  // } = useFormik({
  //   initialValues,
  //   // validationSchema,
  //   onSubmit: (values) => {
  //     console.log("val--on submit", values);
  //   },
  // });

  const handleFormSubmit = async (values: any) => {
    if (values.wholeseller_agent_type == "WHOLESELLER_AGENT_INDIVIDUAL") {
      delete values.wholeseller_agent_firm_name;
    } else if (values.wholeseller_agent_type == "WHOLESELLER_AGENT_SALESMAN") {
      delete values.wholeseller_agent_firm_name;
    }
    console.log("Valuessss---|||||||", values);
    values.wholeseller_agent_number = values.wholeseller_agent_number.includes(values.wholeseller_agent_number) ? values.wholeseller_agent_number : "+" + values.wholeseller_agent_number;
    values.wholeseller_agent_assigned_state = [values.wholeseller_agent_assigned_state]
    try {
      if (!id) {
        const response = await AppService.addAgent(values);
        if (response) {
          Alert("Successfully added!!!");
          navigate('/agents')
        }
      } else {
        const response = await AppService.patchAgentKyc(id, values);
        if (response) {
          Alert("Successfully Update!!!");
          navigate('/agents')
        }
      }
    } catch (error: any) {
      let message: any = [];
      error.response.data.errors?.map((x: any) => {
        message.push(x.detail)
      })
      AlertError(`1. ${message}`);
    }
  };

  const getAllLists = async () => {
    const responseJson = await AppService.getAllStates();
    // console.log(responseJson.data.bazaar);
    setAllState(responseJson.data.results);
    setSingleState(responseJson.data.results);
  };

  const getAllDis = async (param: any, type: any) => {
    console.log("ppppppppparrararams", param);

    const responseJson = await AppService.getAllDistricByState(param);
    console.log("all Districtss and single-----");
    // console.log(responseJson.data.bazaar);
    // setAllDis(responseJson.data.results);

    if (type == "multiple") {
      console.log("all Districtss and single-----ALLL");
      setAllDis(responseJson.data.results);
    } else {
      console.log("all Districtss and single-----SINGLL");
      setSingleDis(responseJson.data.results);
    }
  };

  const getAllCity = async (param: any, type: any) => {
    const responseJson = await AppService.getAllCityByDis(param);
    if (type == "multiple") {
      setAllCity(responseJson.data.results);
      if(districts1){

        let allCities: any = user?.wholeseller_bazaar_data?.[0].bazaar_city_names.filter((ele:any)=>districts1?.includes(ele.district))|| []

        let cities = responseJson.data.results.map((item: any, index: number) => {
          // console.log("LOGGER123====> efwefwef", bazaarWiseData[index].allCities[index]?.city, allCities)
          return (
            {
              ...item,
              city: item?.city?.filter((ele: any) => allCities.map((item: any) => item.id).includes(ele.id)) || []
            }
          )
        })
        // let cities = user?.wholeseller_bazaar_data?.[0].bazaar_city_names.filter((ele:any)=>districts1?.includes(ele.district))
        setAllCity(cities)
      }
    } else {
      setSingleCity(responseJson.data.results);
    }
  };

  useEffect(() => {
    getAllBazaar()
    setBazaar(user.wholeseller_bazaar || [])
  }, [])

  useEffect(() => {
    if (bazaar?.join(",")) {
      getStatesByBazaar({ ids: bazaar?.join(",") })
    }
  }, [bazaar]);

  useEffect(() => {
    // if (states1?.join(",")) {
    // getDistrictByStates1({ ids: states1 });
    if(states1){
      let districts = user?.wholeseller_bazaar_data?.[0].bazaar_district_names.filter((ele:any)=>ele.state === states1)
      setAllDis([
        {
          district: districts,
          state: states1,
          state_name: states1
        }
      ])
    }
    // getAllDis(
    //   {
    //     ids: states1
    //   },
    //   "multiple"
    // );
    // }
  }, [states1]);

  useEffect(() => {
    if (districts1?.join(",")) {  
      // getAllCity1({ ids: districts1?.join(",") });
      console.log("jjjjjjjjj", { ids: districts1?.join(",") }, districts1)
      getAllCity({ ids: districts1?.join(",") }, "multiple");
    }
  }, [districts1]);

  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();

    let tempBazaar = await responseJson.data.map((row: any) => {
      const alloweIds = allowedBazaars?.map((item: any) => item.value)
      if (alloweIds?.includes(row.id) || superAdmin) {
        return {
          label: row.bazaar_name,
          value: row.id,
        }
      }
      return null
    }).filter((item: any) => item !== null)
    console.log("LOGGER123....=====> ",tempBazaar)
    setBazaarList(tempBazaar);
  };


  const getStatesByBazaar = async (params: any) => {
    setStateList1([]);
    setDistrictList1([]);
    setCityList1([]);
    try {
      const responseJson = await AppService.getAllStateByBazaar(params);

      const allowedIds = allowedStates?.map((item: any) => item.value)

      const tempState = removeDuplicates(responseJson.data.results.flatMap((row: any) =>
        row.bazaar_state_data.map((ele: any) => {
          // const stateItem = singleState?.find((item: any) => item.id === id);
          if ((allowedIds?.includes(ele.id)) || superAdmin) {
            return {
              label: ele?.state,
              value: ele.id,
            };
          }
          return null // Handle cases where a matching state is not found
        })
      ).filter((item: any) => item !== null));

      console.log("logger123...", allowedIds, tempState);

      setStateList1(tempState);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };



  const getDistrictByStates1 = async (params: any) => {
    const responseJson = await AppService.getAllDistricByState(params);

    const allowedIds = allowedDistrict?.map((item: any) => item?.value);
    const dists = responseJson.data.results?.flatMap((ele: any) => {
      return ele?.district.map((item: any) => {
        if (allowedIds?.includes(item?.id) || superAdmin) {
          return {
            label: item?.district,
            value: item?.id
          };
        }
        return null;
      }).filter(Boolean);
    });

    const flattenedDists = dists.filter(Boolean);
    console.log("allowed123", flattenedDists, dists);

    setDistrictList1(flattenedDists);
  };



  const getAllCity1 = async (params: any) => {
    const responseJson = await AppService.getAllCityByDis(params);
    console.log("districts", responseJson.data.results);
    const allowedIds = allowedCity?.map((item: any) => item?.value);
    const cities = responseJson.data.results.flatMap((ele: any) => {
      return ele.city.map((item: any) => {
        if (allowedIds?.includes(item.id) || superAdmin) {
          return {
            label: item.city,
            value: item.id
          };
        }
        return null;
      }).filter(Boolean);
    });

    console.log("allowed", allowedIds, cities);
    setCityList1(cities);
  };


  const getOneAgent = async (id: any) => {
    try {
      const response = await AppService.getAgentById(id);
      console.log("Resss=====in agent edit", response.data);
      setInitialValues((prev: any) => ({
        ...prev,
        wholeseller_agent_name: response.data?.wholeseller_agent_name,
        wholeseller_agent_type: response.data?.wholeseller_agent_type,
        wholeseller_agent_number: response.data?.wholeseller_agent_number,
        wholeseller_agent_firm_name: response.data?.wholeseller_agent_firm_name || "",
        wholeseller_agent_email: response.data?.wholeseller_agent_email,
        wholeseller_agent_active: response.data?.wholeseller_agent_active,
        wholeseller_agent_state: response.data?.wholeseller_agent_state,
        wholeseller_agent_city: response.data?.wholeseller_agent_city,
        wholeseller_agent_district: response.data?.wholeseller_agent_district,
        wholeseller_agent_bazaar: response.data?.wholeseller_agent_bazaar,
        wholeseller_agent_assigned_state: response.data?.wholeseller_agent_assigned_state[0],
        wholeseller_agent_assigned_city: response.data?.wholeseller_agent_assigned_city,
        wholeseller_agent_assigned_district: response.data?.wholeseller_agent_assigned_district,
      }))
      setBazaar(response.data?.wholeseller_agent_bazaar)
      setStates1(response.data?.wholeseller_agent_assigned_state)
      setDistricts1(response.data?.wholeseller_agent_assigned_district)
      getAllDis(
        {
          ids:
            response.data?.wholeseller_agent_state.length > 1
              ? response.data?.wholeseller_agent_state.join(",")
              : response.data?.wholeseller_agent_state,
        },
        "single"
      );
      getAllCity(
        {
          ids:
            response.data?.wholeseller_agent_district.length > 1
              ? response.data?.wholeseller_agent_district.join(",")
              : response.data?.wholeseller_agent_district,
        },
        "single"
      )
    } catch (error) {
      console.log("errrrrrr in kyc", error);
    }
  };

  useEffect(() => {
    getAllLists();
  }, []);

  useEffect(() => {
    if (id) {
      getOneAgent(id)
    }
  }, [id])


  console.log("all Bazaar of agent form------raw", AllBazaar);
  console.log("all Districtss of agent form------raw", allDis);
  console.log("Singleee Districtss of agent form------raw", singleDis);
  console.log("all Citiessss of agent form------raw", allCity);
  console.log("Sinnnngleee Citiessss of agent form------raw", singleCity);
  console.log("initialValuesinitialValuesinitialValues", initialValues);

  const handalchanges = (e: any) => {
    setInitialValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const handleStateChange = (list: any, e: any, setState: any, city = false, type: any = "") => {

    console.log("logger12345...", e, list)

    // const allLength = city ? allCity.flatMap((item:any)=>item.city.map((item:any)=>item.id)) : allDis.flatMap((item:any)=>item.district.map((item:any)=>item.id))
    // console.log("list.....123...",e, list, type, list.filter((item:any)=>!e.includes(item)) )

    if (e.length > 1) {
      if (type?.all === true) {
        if (city) {
          let body = { target: { name: "wholeseller_agent_assigned_city", value: [...list, ...e] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_agent_assigned_district", value: [...list, ...e] } }
          setState(body)
          setDistricts1([...list, ...e])
        }
      } else {
        if (city) {
          let body = { target: { name: "wholeseller_agent_assigned_city", value: [...list.filter((item: any) => !e.includes(item))] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_agent_assigned_district", value: list.filter((item: any) => !e.includes(item)) } }
          setState(body)
          setDistricts1(list.filter((item: any) => !e.includes(item)))
        }
      }
    } else {
      if (list.includes(e[0] || e)) {
        if (city) {
          let body = { target: { name: "wholeseller_agent_assigned_city", value: [...list.filter((item: any) => item !== e[0] || e)] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_agent_assigned_district", value: [...list.filter((item: any) => item !== e[0] || e)] } }
          setState(body)
          setDistricts1([...list.filter((item: any) => item !== e[0] || e)])
        }
      } else {

        if (city) {
          let body = { target: { name: "wholeseller_agent_assigned_city", value: [...list, e[0] || e] } }
          setState(body)
        } else {
          let body = { target: { name: "wholeseller_agent_assigned_district", value: [...list, e[0] || e] } }
          setState(body)
          setDistricts1([...list, e[0] || e])
        }

      }
    }


    console.log("logger123..", list)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setValues,
          touched,
          errors,
          resetForm
        }) => {
          return (
            <Form>
              <DashboardLayout>
                <div className={classes.root}>
                  <div className="container">
                    <div className="textContainer" style={{ alignItems: "center", display: "flex" }}>
                      <button onClick={() => navigate("/agents")} className="icon" style={{ marginRight: "15px" }}>
                        <img src={LogoPrev} alt={"Logo"} style={{ width: "7px", height: "12px" }} />
                      </button>
                      <p className="titleHead">{id ? "Edit" : "Add"} Agent</p>
                    </div>
                    <div className="radio-actionButton">
                      <div className={`${values.wholeseller_agent_type === 'WHOLESELLER_AGENT_INDIVIDUAL' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="wholeseller_agent_type"
                            value={values.wholeseller_agent_type}
                            onChange={() => {
                              resetForm();
                              setFieldValue("wholeseller_agent_type", "WHOLESELLER_AGENT_INDIVIDUAL");
                            }
                            }
                          >
                            <FormControlLabel
                              value="Cash "
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.wholeseller_agent_type === "WHOLESELLER_AGENT_INDIVIDUAL"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={indivitualIcon} alt={"Logo"} />
                                  Individual
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className={`${values.wholeseller_agent_type === 'WHOLESELLER_AGENT_AGENCY' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="wholeseller_agent_type"
                            value={values.wholeseller_agent_type}
                            onChange={() => {
                              resetForm();
                              setFieldValue("wholeseller_agent_type", "WHOLESELLER_AGENT_AGENCY");
                            }}
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.wholeseller_agent_type === "WHOLESELLER_AGENT_AGENCY"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={AgancyIcon} alt={"Logo"} /> Agency
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className={`${values.wholeseller_agent_type === 'WHOLESELLER_AGENT_SALESMAN' ? "border border-[#4e2fa9] p-[10px] w-[205px] flex justify-center items-center h-[60px] rounded-md bg-[#f9fafb]" : "radio-button"}`}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="wholeseller_agent_type"
                            value={values.wholeseller_agent_type}
                            onChange={() => {
                              resetForm()
                              setFieldValue("wholeseller_agent_type", "WHOLESELLER_AGENT_SALESMAN")
                            }
                            }
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio sx={{
                                color: '#84818A',
                                '&.Mui-checked': {
                                  color: '#4e2fa9',
                                },
                              }} />}
                              checked={values.wholeseller_agent_type === "WHOLESELLER_AGENT_SALESMAN"}
                              label={
                                <div className="flex gap-4 items-center">
                                  <img src={SalesManIcon} alt={"Logo"} /> Salesman
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    {values.wholeseller_agent_type === "WHOLESELLER_AGENT_INDIVIDUAL" ? (
                      <>
                        {console.log("vaaaaaaaaaaa=======>", values)}

                        {/* <Form onSubmit={handleSubmit}> */}
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handalchanges(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_name"
                                  value={values.wholeseller_agent_name}
                                  onBlur={handleBlur}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_name" />
                                </Box>
                              </div>
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.wholeseller_agent_number}
                                  onChange={(phone) =>
                                    setFieldValue("wholeseller_agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_number" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State</p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="wholeseller_agent_state"
                                  value={values.wholeseller_agent_state}
                                  onBlur={handleBlur}

                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_district", "")
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District</p>

                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="wholeseller_agent_district"
                                  value={values.wholeseller_agent_district}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City</p>

                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="wholeseller_agent_city"
                                value={values.wholeseller_agent_city}
                                onBlur={handleBlur}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="wholeseller_agent_city" />
                              </Box>
                            </div>

                            <div className="w-[50%]">
                              <div>
                                <p className="title-main">Email Address</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_email"
                                  value={values.wholeseller_agent_email}
                                  onBlur={handleBlur}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_email" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar</p>
                                <Select
                                  label="Bazaar"
                                  multiple={true}
                                  variant={"standard"}
                                  disabled
                                  fullWidth={true}
                                  value={values.wholeseller_agent_bazaar}
                                  onBlur={handleBlur}
                                  name="wholeseller_agent_bazaar"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setBazaar(e.target.value)
                                    setFieldValue("wholeseller_agent_assigned_state", "")
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setStates1("")
                                    setDistricts1([])
                                  }}
                                >
                                  {bazaarList.map((items: any, index: any) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_bazaar" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">Assigned States</p>

                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="wholeseller_agent_assigned_state"
                                  value={values.wholeseller_agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setStates1(e.target.value)
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setDistricts1([])
                                  }}
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_state" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                {/* <p className="title-main">Assigned Districts</p> */}
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    // placeholder={"Select District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.wholeseller_agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.wholeseller_agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("wholeseller_agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_district" />
                                </Box>
                              </div>
                              <div>
                                {/* <p className="title-main">Assigned Cities</p> */}

                                <CityPickerNew label={"Assigned Cities"}
                                  // placeholder={"Select Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.wholeseller_agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.wholeseller_agent_assigned_city}
                                  selectedcity={values.wholeseller_agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.wholeseller_agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </Form> */}
                      </>
                    ) : null}

                    {values.wholeseller_agent_type === "WHOLESELLER_AGENT_AGENCY" ? (
                      <>
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_name"
                                  value={values.wholeseller_agent_name}
                                  onBlur={handleBlur}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_name" />
                                </Box>
                              </div>

                              <div>
                                <p className="title-main">Agency Name</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_firm_name"
                                  value={values.wholeseller_agent_firm_name}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_firm_name" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.wholeseller_agent_number}
                                  onChange={(phone) =>
                                    setFieldValue("wholeseller_agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_number" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">Email Address</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_email"
                                  value={values.wholeseller_agent_email}
                                  onBlur={handleBlur}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_email" />
                                </Box>
                              </div>

                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State</p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="wholeseller_agent_state"
                                  value={values.wholeseller_agent_state}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_district", "")
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District</p>
                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="wholeseller_agent_district"
                                  value={values.wholeseller_agent_district}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City</p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="wholeseller_agent_city"
                                value={values.wholeseller_agent_city}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="wholeseller_agent_city" />
                              </Box>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar</p>
                                <Select
                                  label="Bazaar"
                                  multiple={true}
                                  variant={"standard"}
                                  disabled
                                  fullWidth={true}
                                  value={values.wholeseller_agent_bazaar}
                                  onBlur={handleBlur}
                                  name="wholeseller_agent_bazaar"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_assigned_state", "")
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setBazaar(e.target.value)
                                  }}
                                >
                                  {bazaarList.map((items: any, index: any) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_bazaar" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">Assigned States</p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="wholeseller_agent_assigned_state"
                                  value={values.wholeseller_agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setStates1(e.target.value)

                                  }}
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_state" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                {/* <p className="title-main">Assigned Districts</p> */}
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    // placeholder={"Select District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.wholeseller_agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.wholeseller_agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("wholeseller_agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_district" />
                                </Box>
                              </div>
                              <div>
                                {/* <p className="title-main">Assigned Cities</p> */}

                                <CityPickerNew label={"Assigned Cities"}
                                  // placeholder={"Select Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.wholeseller_agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.wholeseller_agent_assigned_city}
                                  selectedcity={values.wholeseller_agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.wholeseller_agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {values.wholeseller_agent_type === "WHOLESELLER_AGENT_SALESMAN" ? (
                      <>
                        <div className={"w-[50%]"}>
                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Agent Person</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_name"
                                  value={values.wholeseller_agent_name}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_name" />
                                </Box>
                              </div>
                              <div>
                                <PhoneInput
                                  country={"in"}
                                  value={values.wholeseller_agent_number}
                                  onChange={(phone) =>
                                    setFieldValue("wholeseller_agent_number", phone)
                                  }
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_number" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">State</p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  multiple={false}
                                  name="wholeseller_agent_state"
                                  value={values.wholeseller_agent_state}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_district", "")
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllDis(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleState?.map((items: any) => (
                                    <MenuItem key={items.id} value={items.id}>
                                      {items.state}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_state" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">District</p>

                                <Select
                                  label="Bazaar"
                                  multiple={false}
                                  variant={"standard"}
                                  fullWidth={true}
                                  name="wholeseller_agent_district"
                                  value={values.wholeseller_agent_district}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_city", "")
                                    getAllCity(
                                      {
                                        ids:
                                          e.target.value.length > 1
                                            ? e.target.value.join(",")
                                            : e.target.value,
                                      },
                                      "single"
                                    );
                                  }}
                                >
                                  {singleDis?.map((items: any) => {
                                    return items?.district.map((item: any) => {
                                      return (
                                        <MenuItem value={item.id}>
                                          {item.district}
                                        </MenuItem>
                                      );
                                    });
                                  })}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_district" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="w-[50%]">
                              <p className="title-main">City</p>
                              <Select
                                label="Age"
                                variant={"standard"}
                                fullWidth={true}
                                multiple={false}
                                name="wholeseller_agent_city"
                                value={values.wholeseller_agent_city}
                                onChange={(e: any) => {
                                  handleChange(e);
                                }}
                              >
                                {singleCity?.map((items: any) => {
                                  return items?.city.map((item: any) => {
                                    return (
                                      <MenuItem value={item.id}>
                                        {item.city}
                                      </MenuItem>
                                    );
                                  });
                                })}
                              </Select>
                              <Box sx={{ color: "red" }}>
                                <ErrorMessage name="wholeseller_agent_city" />
                              </Box>
                            </div>

                            <div className="w-[50%]">
                              <div>
                                <p className="title-main">Email Address</p>
                                <TextField
                                  sx={{
                                    "& *": {
                                      fontFamily: "Manrope !important",
                                      fontSize: "14px",
                                    },
                                  }}
                                  variant="standard"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  fullWidth={true}
                                  name="wholeseller_agent_email"
                                  value={values.wholeseller_agent_email}
                                  onBlur={handleBlur}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_email" />
                                </Box>
                              </div>
                            </div>

                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                <p className="title-main">Bazaar</p>

                                <Select
                                  label="Bazaar"
                                  multiple={true}
                                  variant={"standard"}
                                  disabled
                                  fullWidth={true}
                                  value={values.wholeseller_agent_bazaar}
                                  onBlur={handleBlur}
                                  name="wholeseller_agent_bazaar"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_assigned_state", "")
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setBazaar(e.target.value)
                                  }}
                                >
                                  {bazaarList.map((items: any, index: any) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_bazaar" />
                                </Box>
                              </div>
                              <div>
                                <p className="title-main">Assigned States</p>
                                <Select
                                  label="Age"
                                  variant={"standard"}
                                  fullWidth={true}
                                  // multiple={true}
                                  name="wholeseller_agent_assigned_state"
                                  value={values.wholeseller_agent_assigned_state}
                                  onBlur={handleBlur}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    setFieldValue("wholeseller_agent_assigned_district", [])
                                    setFieldValue("wholeseller_agent_assigned_city", [])
                                    setStates1(e.target.value)

                                  }}
                                >
                                  {stateList1?.map((items: any, index: number) => (
                                    <MenuItem key={index} value={items.value}>
                                      {items.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_state" />
                                </Box>
                              </div>
                            </div>
                          </div>

                          <div className="formContainer">
                            <div className="grid grid-cols-2 gap-4 w-full">
                              <div>
                                {/* <p className="title-main">Assigned Districts</p> */}
                                <div className="Selectdistrict">
                                  <DistrictPicker
                                    label={"Assigned District"}
                                    // placeholder={"Select District"}
                                    hint={"Search"}
                                    variant={"outlined"}
                                    states={stateList1.map((item: any) => ({ state: item.label, id: item.value }))}
                                    selectedStates={[states1]}
                                    districts={allDis}
                                    selectedDistricts={values.wholeseller_agent_assigned_district}
                                    onSelectItem={(e: any, type: any) => {
                                      handleStateChange(values.wholeseller_agent_assigned_district, e, handleChange, false, type)
                                      setFieldValue("wholeseller_agent_assigned_city", [])
                                    }}
                                  />

                                </div>

                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_district" />
                                </Box>
                              </div>
                              <div>
                                {/* <p className="title-main">Assigned Cities</p> */}

                                <CityPickerNew label={"Assigned Cities"}
                                  // placeholder={"Select Assigned Cities"}
                                  hint={"Search"}
                                  variant={"outlined"}
                                  states={allDis}
                                  selectedStates={districts1}
                                  selectedDistricts={values.wholeseller_agent_assigned_district}
                                  districts={allCity}
                                  selectedCity={values.wholeseller_agent_assigned_city}
                                  selectedcity={values.wholeseller_agent_assigned_city}
                                  onSelectItem={(id: any, type: { all: boolean }) => handleStateChange(values.wholeseller_agent_assigned_city, id, handleChange, true, type)}
                                />
                                <Box sx={{ color: "red" }}>
                                  <ErrorMessage name="wholeseller_agent_assigned_city" />
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="flex gap-5 py-7">
                      <ActionButton
                        title="Cancel"
                        type="reset"
                        variant="primary"
                      />
                      {/* <button type="submit"> Save</button> */}
                      <ActionButton
                        type="submit"
                        title="Save"
                        variant="default"
                      />
                    </div>
                  </div>
                </div>
              </DashboardLayout>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default AddAgents;
