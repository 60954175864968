import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { Dialog, Pagination } from "@mui/material";
import { usePhotosOrderStyles } from "@/static/stylesheets/screens";
import { UploadArea } from "@/components/organisms/UploadArea";
import photoIcon from "@/static/svg/ic_photo.svg";
import { GridOptionButton } from "@/components/atoms/Button";
import OrderListImg from "@/static/images/mwb_order.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "@/Store";
import { GetPhotoOrder } from "@/Store/Sclicese/OrderDataSlice";
import moment from "moment";


const PhotoOrder = () => {
  const classes = usePhotosOrderStyles();
  const data = [1, 2];
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState<null | string>(null);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const photoOrderList = useSelector(state => state.photoOrderList.data?.results)
  const totalCount = useSelector(state => state.photoOrderList.data?.count)
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    dispatch(GetPhotoOrder(currentPage))
  }, [])

  const handlePageChange = (event: any, value: any) => {
    dispatch(GetPhotoOrder(value))
    setCurrentPage(value);
  };

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div>
            <div>
              <p className="commonTitle">Photo Orders ({totalCount && totalCount})</p>
            </div>

            {/* <div className="flex gap-5 py-5">
              <TwMultiSelect />
              <TwMultiSelect />
              <TwMultiSelect />
            </div> */}

            <div>
              <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-[12px] text-[#84818A;] text-gray-700 bg-gray-50 font-[600] ">
                    <tr className=" color-[#2E2C34;]">
                      <th scope="col" className="py-3 px-6">
                        S. No
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Date
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Retailer Firm Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Manager Nme
                      </th>
                      <th scope="col" className="py-3 px-6">
                        City
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6"></th>
                    </tr>
                  </thead >
                  <tbody className="text-[#2E2C34;]">
                    {photoOrderList?.map((item: any, index: number) => {
                      return <tr className="border-b">
                        <th
                          onClick={() => setAddModalOpen(true)}
                          scope="row"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap  cursor-pointer"
                        >
                          {((currentPage - 1) * 10) + (index + 1)}
                        </th>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {moment(item.created_at).format('DD/MM/YYYY h:mm a')}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {item?.retailer?.retailer_firmName}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                        {item?.retailer?.retailer_name}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] cursor-pointer">
                        {item?.retailer?.retailer_city_name}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] cursor-pointer">
                          <div className="flex gap-5 align-middle" onClick={() => setOpenPhotoModal(item.order_image)}>
                            <div>
                              <img src={photoIcon} alt={"Logo"} />
                            </div>
                            <div>
                              <p className="font-[14px] text-[#4E2FA9] border-b border-[#4E2FA9]">
                                View Photo
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] cursor-pointer">
                          <ActionButton title="Create Order" onClick={() => navigate(`/createorder?photoOrderId=${item?.id}&retailerId=${item?.retailer?.id}&photoOrderType=${item?.payment_type}&branchId=${item?.retailer?.retailer_branch}`)} />
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] cursor-pointer">
                          <GridOptionButton icon={"vertical-options"} />
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
                <div
                  className="flex items-center justify-between py-4"
                  style={{ display: "flex", marginLeft: 0 }}
                >
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Dialog
              open={!!openPhotoModal}
              maxWidth={"lg"}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "20px",
                },
              }}
              onClose={() => setOpenPhotoModal(null)}
            >
              <div className="p-10">
                <a className="flex gap-5 justify-center py-5" href={openPhotoModal || ''} target="_blank">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.33203 21.6663C1.7987 21.6663 1.33203 21.4663 0.932031 21.0663C0.532031 20.6663 0.332031 20.1997 0.332031 19.6663V14.8997H2.33203V19.6663H19.6654V14.8997H21.6654V19.6663C21.6654 20.1997 21.4654 20.6663 21.0654 21.0663C20.6654 21.4663 20.1987 21.6663 19.6654 21.6663H2.33203ZM10.9987 16.5663L4.56536 10.133L5.9987 8.69967L9.9987 12.6997V0.333008H11.9987V12.6997L15.9987 8.69967L17.432 10.133L10.9987 16.5663Z"
                      fill="#4E2FA9"
                    />
                  </svg>
                  <p>Download Photo</p>
                </a>

                <div>
                  {openPhotoModal && <img
                    className="w-[500px] rounded-md"
                    src={openPhotoModal}
                    alt={"Logo"}
                  />}
                </div>
              </div>
            </Dialog>
          </div>

          <div>
            <Dialog
              open={addModalOpen}
              maxWidth={"lg"}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "20px",
                },
              }}
              onClose={() => setAddModalOpen(false)}
            >
              <div>
                <p className="title">Add Payment Details</p>
                <UploadArea label={"Upload Icon"} />
                <div>
                  <div className={"action-bar"}>
                    <ActionButton
                      variant={"default"}
                      title={"Cancel"}
                      onClick={() => setAddModalOpen(false)}
                    />

                    <ActionButton
                      variant={"primary"}
                      title={"Add"}
                      onClick={() => setAddModalOpen(false)}
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default PhotoOrder;
