import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

enum UnitType {
    "WEIGHT"="WEIGHT",
    "QUANTITY"="QUANTITY"
}

type UnitItemType = {
    "id": number,
    "unit_type": UnitType,
    "unit_name": string
}

export const MasterUnit: any = createAsyncThunk('master_unit_slice', async (data,{ rejectWithValue }) => {
  try {
    const responce: any = await api(`api/master/unit-all/`, 'get');
    return responce
  }
  catch (error) {
    return rejectWithValue(error)
  }
})


type initialStateType = {
  weightData: UnitItemType[],
  quantityData: UnitItemType[],
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    weightData: [],
    quantityData: [],
    loading: false,
    error: null,
}

export const MasterUnitData = createSlice({
  name: 'master_unit_slice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      MasterUnit.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      MasterUnit.fulfilled, (state, action) => {
        state.loading = false
        state.quantityData = action.payload.data?.filter((itm:UnitItemType) => itm.unit_type == UnitType.QUANTITY)
        state.weightData = action.payload.data?.filter((itm:UnitItemType) => itm.unit_type == UnitType.WEIGHT)
      })
    builder.addCase(
      MasterUnit.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


export const MasterRetailerType: any = createAsyncThunk('master_retailer_type', async (data,{ rejectWithValue }) => {
  try {
    const responce: any = await api(`api/master/retailer-type/`, 'get');
    return responce
  }
  catch (error) {
    return rejectWithValue(error)
  }
})


type initialRetailerStateType = {
  data:any,
  loading: boolean,
  error: any,
}

const initialRetailerState:initialRetailerStateType = {
    data: [],
    loading: false,
    error: null,
}

export const MasterRetailerTypeData = createSlice({
  name: 'master_retailer_slice',
  initialState:initialRetailerState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      MasterRetailerType.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      MasterRetailerType.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data?.results
      })
    builder.addCase(
      MasterRetailerType.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})
