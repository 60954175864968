import { AddButton, GridOptionButton } from '@/components/atoms/Button';
import { ActionButton } from '@/components/atoms/Button/ActionButton';
import { UploadArea } from '@/components/organisms/UploadArea';
import { useMasterListStyles } from '@/static/stylesheets/screens';
import { Dialog, MenuItem, Select, SelectChangeEvent, Switch } from '@mui/material';
import React, { useState } from 'react'
import AdvertisementClient from './AdvertisementClient';
import Ads from './Ads';
import Plan from './Plan';
import Invoice from './Invoice';
interface AdvertisementListGridProps {
    type?: "advertisementClient" | "ads" | "plan" | "invoice";
  }

const AdvertisementListGrid : React.FC<AdvertisementListGridProps> = (props) => {
    const classes = useMasterListStyles();
    const { type } = props;
    const [addModalOpen, setAddModalOpen] = useState(false);
  
    const [masterType, setMasterType] = useState("Regional advertisementClient");
  
    const handleChangeMasterType = (event: SelectChangeEvent) => {
      setMasterType(event.target.value as string);
    };
  
    return (
      <>
       <div>
        {
            type === 'advertisementClient'? 
            <AdvertisementClient/>
            :
            type === "ads"?
            <Ads/>
            :
            type === "plan"? 
            <Plan/>
            :
            <Invoice/>
        }
       </div>
      </>
    );
  };

export default AdvertisementListGrid
