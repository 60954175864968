import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { getWholeSellerId } from "@/helper";

export const GetWholeseller: any = createAsyncThunk('GetWholeseller', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/data/' + getWholeSellerId() + '/', 'get');
    if (result.data?.wholeseller_bazaar[0]) {
      if (window.location.pathname === '/') {
        window.location.href = '/dashboard'
      }
      localStorage.setItem('bazaarId', result.data.wholeseller_bazaar[0]);
      localStorage.setItem('user', JSON.stringify(result.data));
      localStorage.setItem('user_id', result.data.wholeseller_user?.id);
      localStorage.setItem('wholeseller_name', result?.data?.wholeseller_firm_name);
      localStorage.setItem('wholeseller_contact_per',result?.data?.wholeseller_contact_per);
    localStorage.setItem('wholeseller_image',result?.data?.wholeseller_image);
    }
    return result.data
  }
  catch (error) {
    if (window.location.pathname !== '/') {
      window.location.href = '/'
    }
    return error
  }
})

export const Wholesellerdata = createSlice({
  name: 'GetWholeseller',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetWholeseller.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetWholeseller.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetWholeseller.rejected, (state, action) => {
        state.loading = false
      })
  }
})