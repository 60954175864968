import React from "react";
import UploaderFrame from "@/static/icons/uploader-frame.png";
import { useUploadAreaStyles } from "@/static/stylesheets/organisms/uploadAreadStyles";

interface UploadAreaCustomProps {
  label?: string;
  img?: string;
}

const UploadAreaCustom: React.FC<UploadAreaCustomProps> = (props) => {
  const classes = useUploadAreaStyles();

  return (
    <div className={classes.root}>
      <div className={"uploader"}>
        <div className={"icon"}>
          <img src={props.img ? props.img : UploaderFrame} alt={"Uploader"} />
        </div>
        <div className={"content1"}>
          <div className={"title"}>{props?.label}</div>
          <div className={"subtitle"}>
            Image can be size of 512 PX by 512 PX Only
          </div>
        </div>
      </div>
    </div>
  );
};

export { UploadAreaCustom };
