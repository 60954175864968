import React, { useEffect, useState } from "react";
import { useDispatch, AppDispatch, useSelector } from "@/Store";
import { CreateCategoryPlan, CreateItemWisePlan, EditCategoryPlan, EditItemWisePlan, EditSubCategoryPlan, createSubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { CreateProductPricing, EditProductPricing } from "@/Store/Sclicese/ProductDetail";
import { Checkbox } from "@mui/material";

type props = {
  title: string,
  updated_date: any,
  branch?: number,
  category?: number,
  id?: any,
  product:any,
  base_price:any,
  new_base_price: any,
  setShowLog?:any,
  reload?:any,
  selectedProducts?:any,
  setSelectedProducts?:any
}
const ItemRow = ({ title, updated_date, 
  base_price,
  branch,
  category,
  id,
  product,
  new_base_price,
  setShowLog,
  reload,
  selectedProducts,
  setSelectedProducts
 }: props) => {

  const dispatch = useDispatch();
  const [data, setData] = useState<any>({
    new_base_price: new_base_price
  })

  const handleChange = (plan: any, value: any, type: string) => {
    setData({
      ...data,
      [plan.value]:value,
      [plan.type]: type
    })
  }

  const handleSubmit = async () => {
    const postData = {
      ...data,
      product,
      branchId: branch
    }
    if(id && id > 0){
      postData.id = id
     await dispatch(EditProductPricing(postData));
    }else{
     await dispatch(CreateProductPricing(postData));
    }
    reload();
  }

  const checkData = (plan: any, value: any) => {
      if(value === true) {
        value="RUPEE";
      }else{
        value=undefined;
      }
    setData({
      ...data,
      [plan.value]:null,
      [plan.type]: value,
    })
  }

  const checkRetailerData = (rtl: any, value: any) => {
    let tmpArr = data.retailer_type || []
    if(value === true) {
     tmpArr?.push(rtl.id)
    }else{
      tmpArr = tmpArr?.filter((t:any) => t !== rtl.id)
    }
    setData({
      ...data,
      retailer_type: tmpArr
    })
}


  return (
    <tr className="border-b align-baseline">
      <th
      scope="row"
      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
    >
      <div className="flex gap-[20px] items-center ">
        <Checkbox
          checked={selectedProducts?.includes(product)}
          onChange={()=>{
            selectedProducts?.includes(product) ?  setSelectedProducts(selectedProducts.filter((item:any)=>item !== product) || []) : setSelectedProducts([...selectedProducts, product])
          }}
          // onChange={selectedProducts?.includes(product) ?  setSelectedProducts(selectedProducts.filter((item:any)=>item !== product) || []) : setSelectedProducts([...selectedProducts, product]) }
        />
      </div>
    </th>
    <th
      scope="row"
      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
    >
      <div className="flex gap-[20px] items-center ">
        <p className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope cursor-pointer">
          {title}
        </p>
      </div>
    </th>
    <td className="py-4 px-6  color-[#2E2C34;]">
      <div className="priceTitle">Rs. {base_price}</div>
    </td>
    <td className="py-4 px-6  color-[#2E2C34;]">
      <div className="priceTitle">Rs. 0</div>
    </td>
    {/* <td className="py-4 px-6  color-[#2E2C34;]">
      <div className="priceTitle">Rs. {base_price}</div>
    </td> */}
    {/* <td className="py-4 px-6  color-[#2E2C34;]">
        <div className="border border-[#e5e7eb] w-[100px] rounded py-2 px-6">
          <input value={`${data.new_base_price}`} style={{ width: '60px', border: 'none' }} onChange={(e) => setData({...data, new_base_price: e.target.value})}/>
         </div>
    </td> */}
    {/* <td className="py-4 px-6 color-[#2E2C34;]">
        <button onClick={() => handleSubmit()} className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent rounded">
          APPLY
        </button>
    </td> */}
    <td className="py-4 px-6 color-[#2E2C34;]">
      <p className="text-[14px] font-medium font-[600] text-black">24 Jun,2021 10:30pm</p>
    </td>

    <td className="py-4 px-6 color-[#2E2C34;]">
        <button className="bg-transparent hover:bg-purple-600 text-purple-600 font-semibold hover:text-white py-2 px-4 border border-purple-600 hover:border-transparent rounded" onClick={()=>setShowLog(id)}>
          Logs
        </button>
    </td>
  </tr>
  );
};

export default ItemRow;
