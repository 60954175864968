import { AddButton, GridOptionButton } from '@/components/atoms/Button'
import { useOffersStyles } from '@/static/stylesheets/screens';
import { useNavigate } from 'react-router-dom';
import basketImg from '../../../static/images/basket_image.png'


const Ads = () => {
  const classes = useOffersStyles();
  const data = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  return (
    <div>
      <div className={classes.root}>
        <div>
          <div className="flex justify-end">
            <AddButton
              label="Create New Ad"
              onClick={() => navigate("/addplan")}
            />
          </div>
        </div>
        <div>
          <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-[12px] text-[#84818A] bg-gray-50 font-[600]">
                <tr className="color-[#2E2C34;]">
                  <th scope="col" className="py-3 px-6">
                    Ad Name
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Start Date
                  </th>
                  <th scope="col" className="py-3 px-6">
                    End Date
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Created for
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Active for
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Status
                  </th>

                  <th scope="col" className="py-3 px-6"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item: any) => (
                  <tr className="border-b ">
                    <td
                      className="py-4 px-6 text-[#2E2C34;]"
                    >
                      <div className='flex gap-2 align-center '>
                        <img
                          className="w-[50px] rounded-md"
                          src={basketImg}
                          alt='basketImg' />
                      <div className="flex align-center mt-3">
                        Wingreens Mart
                      </div>
                      </div>
                    </td>
                    <td className="py-4 px-6  text-[#2E2C34;]">22-07-2022, 4:00 PM</td>
                    <td className="py-4 px-6  text-[#2E2C34;]">
                      <p>22-07-2022, 4:00 PM</p>
                    </td>
                    <td className="py-4 px-6  text-[#2E2C34;]">
                      Global Mart
                    </td>
                    <td className="py-4 px-6  text-[#2E2C34;]">India</td>
                    <td className="py-4 px-6  text-[#20C9AC;]">Active</td>

                    <td className="py-4 px-6  text-[#2E2C34;] cursor-pointer">
                      <GridOptionButton icon={"vertical-options"} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ads
