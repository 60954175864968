import { SearchField } from '@/components/atoms/SearchField';
import { AppService } from '@/service/AllApiData.service';
import { findOrderStatus, findPaymentStatus } from '@/static/data';
import { Pagination } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';


interface props{
    id:any
}

const RetailerOrders: React.FC<props> = ({id}) => {
    const navigate = useNavigate();
    const [totalCount, SetTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [orderData,setOrderData] = useState([]);
    const [search, setSearch] = useState("");
    
    const getOrderList = async (params:any={}) => {
        params = {
            ...params,
            retailer: id
        }
        try{
            let response = await AppService.getRetailerOrders(params);
            setOrderData(response.data.results);
            SetTotalCount(response.data.count);
        } catch (error:any) {
            console.log("RETAILER_ORDER_LIST ====> ",error?.message);
        }
    }

    useEffect(()=>{
        let params = {};
        if(search){
            params = {
                search
            }
        }
        getOrderList(params);
    },[search])

    const handlePageChange = (event: any, value: any) => {
        getOrderList({
            page: value
        });
        setCurrentPage(value);
      };

    return(
        <>
        <div className='w-full flex justify-end items-center '>
            <SearchField inputdata={setSearch}/>
        </div>
        <div className="overflow-x-auto relative pt-[40px] pb-[40px]">
                <table className="w-full text-sm text-left text-gray-500 ">
                  <thead className="text-[12px] text-[#84818A;] text-gray-700  font-[600] ">
                    <tr className="color-[#2E2C34;] border-b">
                      <th className="py-[35px]">
                        Sr. No
                      </th>
                      <th className="py-[35px]">
                        Date
                      </th>
                      <th className="py-[35px]">
                        Order ID
                      </th>
                      <th className="py-[35px]">
                        Order Type
                      </th>
                       <th className="py-[35px]">
                        Items
                      </th>
                      <th className="py-[35px]">
                        Amount
                      </th>
                      
                      <th className="py-[35px]">
                        Order Status
                      </th>
                      <th className="py-[35px]">
                        Payment Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[#2E2C34;]">
                    {orderData?.map((item: any, index: number) => (
                      <tr className="border-b">
                        <td
                          scope="row"
                          className="py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          <div className="flex gap-[20px] items-center ">
                            {/* onClick={() => setAddModalOpen(true)} */}
                            <p
                              className="mb-0 text-[14px] cursor-pointer"
                            >
                              {((currentPage - 1) * 10) + (index + 1)}
                            </p>
                          </div>
                        </td>
                        <td className=" color-[#2E2C34;]">
                          <p>{moment(item.order_created_at).format('DD/MM/YY')}</p>
                        </td>
                        <td className="py-4  color-[#2E2C34;]">
                          <div className="flex gap-2 items-center">
                            <p onClick={()=>navigate('/editorder/' + item?.id)} className="text-[#4E2FA9] underline cursor-pointer">#{item.order_id}</p>
                          </div>
                        </td>
                        <td>{item.payment_type}</td>
                        <td>{item.total_items}</td>
                        <td>{item.payment_amount} ₹</td>

                        <td>
                        <div className={`bg-[${findOrderStatus(item.order_status)?.bgColor}] text-[${findOrderStatus(item.order_status)?.color}] flex justify-center rounded-md py-[4px] px-[8px] w-[max-content]`}>
                              <p
                                className="cursor-pointer font-semibold text-[12px] m-0"
                              >
                                {findOrderStatus(item.order_status)?.label}
                              </p>
                            </div>
                        </td>

                        <td>
                        <div className={`bg-[${findPaymentStatus(item.payment_status)?.bgColor}] text-[${findPaymentStatus(item.payment_status)?.color}] flex justify-center rounded-md py-[4px] px-[8px] w-[max-content]`}>
                              <p
                                className="cursor-pointer font-semibold text-[12px] m-0"
                              >
                                {findPaymentStatus(item.payment_status)?.label}
                              </p>
                            </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className="flex items-center justify-between py-4"
                  style={{ display: "flex", marginLeft: 0 }}
                >
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
        </>
    )
}

export default RetailerOrders;