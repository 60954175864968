import axios from "axios"
export const baseURL = `https://api.mwbbazaar.com/`;
export const api = (url: any, method: any, data: any = null, hasToken:boolean = true) => {
    const token = localStorage.getItem('token');
    const Instanse:any = axios.create({
        baseURL,
    })
    let config: any = {}
    if (hasToken) {
        config= {
            url,
            method,
            data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }
    } else {
        config= {
            url,
            method,
            data
        }
    }

    if(method === "get"){
        config= {
            url,
            method,
            params:data,
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }
    }
    
    return Instanse.request(config)
}

// axios.get("",{he})