import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserProduts, { WholesellerProducts } from "./WholesellerProducts"
import { useDispatch } from "react-redux"
import { AppDispatch } from ".."
import { api } from "@/Axios/api"

export const DeleteProduct: any = createAsyncThunk('DeleteProduct', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/product/data/${data}/`, 'DELETE');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}


export const Deleteproductdata = createSlice({
  name: 'DeleteProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DeleteProduct.pending, (state, action) => {
        state.loading = true
      })
      builder.addCase(
        DeleteProduct.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DeleteProduct.rejected, (state, action) => {
        state.loading = false
      })
    }
})

export default DeleteProduct