import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useCreateOfferStyles } from "@/static/stylesheets/screens/createOfferStyle";
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import UploadAreaImage from "@/static/svg/ic_uploadarea.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch, useSelector } from "@/Store";
import { CreateOffersSlice, DetailOffers, EditOffersSlice } from "@/Store/Sclicese/GetOffersSlice";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { GetRetailer } from "@/Store/Sclicese/Retailerssclices";
import { array } from "prop-types";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { GetCategoryListPlan, SubCategoryListPlan } from "@/Store/Sclicese/PlanSlice";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";

const CreateOffer = () => {
  const classes = useCreateOfferStyles();
  const navigate = useNavigate();
  const { editId } = useParams()
  const [payload, setpayload] = useState<any>({
    category: "",
    category_group: "",
    create_offer_by_item: "",
    customer: "CASH",
    customer_type: "",
    offer_base_price: "",
    offer_coupon_code: "",
    offer_discount_value: "",
    offer_discount_value_type: "PERCENTAGE",
    offer_discounted_price: "",
    offer_end_date: "",
    offer_for: "",
    offer_image: "",
    offer_max_qty: "",
    offer_min_qty: "",
    offer_start_date: "",
    subcategory: "",
    Item: "",
    mode: 'Category'
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
    const value = e.target.value;

    setpayload({
      ...payload,
      offer_discount_value: value,
      offer_discount_value_type: inputType
    });
  };

  const [categoryId, setCategoryId] = useState();
  const ProductBazaar: any = useSelector(state => state?.ProductBazaarList?.data?.results)
  const DetailOffersReducers: any = useSelector(state => state?.DetailOffersReducers?.data)
  const parentCategory: any = useSelector(state => state?.GroupCategoryData?.data?.results)
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)
  const CategoryData: any = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const GetRetailerData: any = useSelector(state => state?.GetRetailerdata.data.results)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const dispatch = useDispatch<AppDispatch>();
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")

  useEffect(() => {
    dispatch(ProductBazaarList());
    dispatch(MasterRetailerType())
    dispatch(GetRetailer())
    if (editId) {
      dispatch(DetailOffers(editId))
    }
    dispatch(CategoryGroup(bazaarId))
  }, [editId, dispatch, bazaarId])

  useEffect(() => {
    if (bazaarId) {
      dispatch(GetCategoryListPlan(bazaarId))
      dispatch(SubCategoryListPlan(bazaarId))
      dispatch(CategorySlice({ bazaarId: bazaarId }))
    }
    // dispatch(GetCategoryPlan())
    // dispatch(SubCategoryPlan())
  }, [bazaarId, dispatch])

  useEffect(() => {
    if (CategoryData) {
      setCategoryId(payload?.category)
    }
  }, [CategoryData, payload?.category])


  useEffect(() => {
    if (editId) {
      console.log(DetailOffersReducers, "====ddd")
      setpayload((prev: any) => ({ ...prev, ...DetailOffersReducers }))
    }
  }, [DetailOffersReducers, editId])


  // useEffect(() => {
  //   if (ProductBazaar) {
  //     dispatch(GroupCategory({ bazaarId: bazaarID}));
  //     dispatch(CategoryGroup({ bazaarId: bazaarID }));
  //     if (parentCategory) {
  //       dispatch(CategorySlice({ bazaarId: bazaarID, id: parentCategory && parentCategory[0].id }));
  //       if (categoryId) {
  //         dispatch(SubCategorySlice({ bazaarId: bazaarID, id: parentCategory && parentCategory[0].id, category: categoryId }));
  //       }
  //     }
  //   }
  // }, [ProductBazaar?.length, CategoryData?.length, parentCategory?.length, categoryId])

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  const handleChangeMasterType = async (e: any) => {
    const { files, value, name } = e.target;
    if (files) {
      await getBase64(files[0]).then(
        data => (setpayload((prev: any) => ({ ...prev, [name]: data }))
        )
      );
    }
    else if (Number(value)) {
      payload[name] = Number(value)
      setpayload((prev: any) => ({ ...prev, [name]: Number(value) }))
    }
    else {
      setpayload((prev: any) => ({ ...prev, [name]: value }))
    }
  };

  console.log(payload)


  const handleSubmit = () => {
    const data = { data: payload, navigate: navigate }
    if (editId) {
      dispatch(EditOffersSlice(data));
    }
    else {
      dispatch(CreateOffersSlice(data));
    }
  }

  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))



  const inputvalue = (e: any) => {
    const { name, value } = e.target;
    if (name === 'category_group') {
      dispatch(clearCategoryState())
      dispatch(clearSubCategoryState())
      dispatch(CategorySlice({ bazaarId: bazaarId, value }))
      setpayload({
        ...payload,
        category_group: value,
        category: null,
        mode: 'Category'
      })
    }
    if (name === 'category') {
      dispatch(clearSubCategoryState())
      dispatch(SubCategorySlice({ bazaarId: bazaarId, category: value, id: payload.category_group }))
      setpayload({
        ...payload,
        category: value,
        mode: 'subcategory'
      })
    }
    if (name === 'subcategory') {
      setpayload({
        ...payload,
        subcategory: value
      })
    }
    if (name === 'customer_type') {
      setpayload({
        ...payload,
        customer_type: value
      })
    }

    // if (name === 'Branch') {
    //   setFilterData({
    //     ...filterData,
    //     branch: value
    //   })
    // }

  }

  useEffect(() => {
    dispatch(CategoryGroup(bazaarId))
    dispatch(CategorySlice({ bazaarId: bazaarId }))
    dispatch(SubCategorySlice({ bazaarId: bazaarId, category: payload.category, id: payload.category_group }))
  }, [bazaarId, dispatch, payload.category, payload.category_group, setpayload])

  // category_group
  // console.log(groupCategoryFilterOptions?.filter((item: any) => payload.category_group == item?.value).map((elm: any) => elm?.value))
  // category
  // categoryFilterOptions?.filter((item: any) => payload.category == item?.value).map((elm: any) => elm?.value)
  // subcategory
  // subCategoryFilterOptions?.filter((item: any) => payload.subcategory == item?.value).map((elm: any) => elm?.value)

  return (
    <>
      <DashboardLayout>
        <div className={`${classes.root} !w-full overflow-auto`}>
          <div className="w-[60%] mb-10">
            <div>
              <p className="HeadTitle">{editId ? "Edit Offer" : "Create New Offer"}</p>
            </div>

            <div className="pt-[53px] pb-[9px]">
              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Create offer by Item</label>
                  <Select
                    sx={{
                      border: 0,
                      boxShadow: "none",
                    }}
                    className={'!text-[14px]'}
                    label="offer by item"
                    variant={"standard"}
                    fullWidth={true}
                    value={payload.create_offer_by_item}
                    name='create_offer_by_item'
                    onChange={handleChangeMasterType}
                  >
                    {/* <MenuItem value={"ITEM"}>Item</MenuItem> */}
                    <MenuItem value={"GROUP"}>Group</MenuItem>
                    <MenuItem value={"CATEGORY"}>Category</MenuItem>
                    <MenuItem value={"SUBCATEGORY"}>Sub Category</MenuItem>
                  </Select>
                </div>
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Group</label>
                  <Select
                    label="Group Category"
                    variant={"standard"}
                    fullWidth={true}
                    value={payload.category_group}
                    name="category_group"
                    onChange={inputvalue}
                  >
                    {
                      groupCategoryFilterOptions && groupCategoryFilterOptions?.map((item: any) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Category</label>
                  <Select
                    label="Category"
                    variant={"standard"}
                    fullWidth={true}
                    value={payload.category}
                    name="category"
                    onChange={inputvalue}
                  >
                    {
                      categoryFilterOptions && categoryFilterOptions?.map((item: any) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>


              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Sub Category</label>
                  <Select
                    label="subcategory"
                    variant={"standard"}
                    fullWidth={true}
                    value={payload.subcategory}
                    name="subcategory"
                    onChange={inputvalue}
                  >
                    {
                      subCategoryFilterOptions?.length > 0 ? subCategoryFilterOptions?.map((item: any) => (
                        <MenuItem value={item.value}>
                          {item.label}
                        </MenuItem>
                      )) : <p className="text-center py-2 text-[#84818A] text-[14px]">Please select Category</p>
                    }
                  </Select>
                </div>
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Item</label>
                  <input type="text" name="offer_name" value={payload?.offer_name} onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" />
                </div>
              </div>



              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Base Price</label>
                  <input type="number" name="offer_base_price" value={payload?.offer_base_price} onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" />
                </div>

                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Discount By</label>
                  <div className="flex">
                    <div className="planCardChild flex">
                      <input
                        type="number"
                        name="offer_discount_value_percentage"
                        onChange={(e) => handleChange(e, 'PERCENTAGE')}
                        className="text-[#2E2C34] text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px] w-[73px]"
                        value={payload.offer_discount_value_type === 'PERCENTAGE' ? payload.offer_discount_value : ''}
                      />
                    </div>%
                    <span className="text-[12px] font-[500] text-[#84818A] mx-[16px]">or</span>
                    <div className="planCardChild flex">
                      <input
                        type="number"
                        name="offer_discount_value_rupee"
                        onChange={(e) => handleChange(e, 'AMOUNT')}
                        className="text-[#2E2C34] text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px] w-[73px]"
                        value={payload.offer_discount_value_type === 'AMOUNT' ? payload.offer_discount_value : ''}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Discounted Price</label>
                  <input type="number" name="offer_discounted_price" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_discounted_price} />
                </div>

                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Coupon Code</label>
                  <input type="text" name="offer_coupon_code" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_coupon_code} />
                </div>
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Start Date</label>
                  <input type="Date" name="offer_start_date" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_start_date} />
                </div>

                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">End Date</label>
                  <input type="date" name="offer_end_date" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_end_date} />
                </div>
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Min. Quantity</label>
                  <input type="number" name="offer_min_qty" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_min_qty} />
                </div>

                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Max. Quantity</label>
                  <input type="number" name="offer_max_qty" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_max_qty} />
                </div>
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Customer Type</label>
                  {/* <Select
                    label="customer_type"
                    variant={"standard"}
                    fullWidth={true}
                    name="customer_type"
                    value={payload?.customer_type}
                    onChange={handleChangeMasterType}
                  >
                    {
                      GetRetailerData && GetRetailerData?.map((item: any) => (
                        <MenuItem value={item.retailer_type} key={item.id}>
                          {item.retailer_name}
                        </MenuItem>
                      ))
                    }
                  </Select> */}
                  <Select
                    sx={{
                      border: 0,
                      boxShadow: "none",
                    }}
                    className={'!text-[14px]'}
                    label="offer item"
                    variant={"standard"}
                    fullWidth={true}
                    name="customer_type"
                    value={MasterRetailerTypeData?.filter((item: any) => payload.customer_type == item?.id).map((elm: any) => elm?.id)}
                    onChange={inputvalue}
                  >
                    {
                      MasterRetailerTypeData.map((rtl: any) => {
                        return <MenuItem value={rtl.id}>{rtl.retailer_type_name}</MenuItem>
                      })
                    }
                  </Select>
                </div>

                {/* <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Select Customer</label>
                  <Select
                    label="customer"
                    variant={"standard"}
                    fullWidth={true}
                    name="customer"
                    value={payload?.customer}
                    onChange={handleChangeMasterType}
                  >
                    {
                      GetRetailerData && GetRetailerData?.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.retailer_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div> */}
              </div>

              <div className="formContainer">
                <div className="w-2/4">
                  <label className="text-[12px] text-[#84818A] leading-[18px]">Offer for</label>
                  <input type="text" name="offer_for" onChange={handleChangeMasterType} className="text-[#2E2C34] w-full text-[14px] font-[500] outline-none border-b border-[#EBEAED] h-[32px]" value={payload?.offer_for} />
                </div>
                <div className="w-2/4"></div>
              </div>
            </div>

            <div className="flex gap-[24px]">
              <div className="w-2/4">
                <div className="flex gap-3 justify-center relative align-middle items-center border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                  <input type="file" name="offer_image" onChange={handleChangeMasterType} className="absolute w-full h-full cursor-pointer opacity-0" />
                  <div>
                    <img src={payload?.offer_image} alt={"Logo"} />
                  </div>
                  <div>
                    <p>Upload Image</p>
                    <p>Supports JPG, PNG, and JPEG</p>
                  </div>
                </div>
              </div>
              <div className="w-2/4"></div>
            </div>
          </div>
          <div className="actionButton flex gap-[24px]">
            <button
              className="border-2 px-5 py-2 rounded-md"
              color="inherit"
              onClick={() => navigate('/offers')}
            >Cancel</button>
            <button className="bg-[#4e2fa9] border-[#4e2fa9] text-white px-5 py-2 border-2 rounded-md" onClick={handleSubmit}>Create</button>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default CreateOffer;
