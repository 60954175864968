import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const CategorySlice: any = createAsyncThunk('category_slice', async (data: any, { rejectWithValue }) => {
  try {
    let apiurl = `api/category/filter/?bazaar=${data.bazaarId}`;
    if(data.id){
      apiurl = apiurl + `&category_group=${data.id}`
    }
    const responce: any = await api(apiurl, 'get');
    // localStorage.setItem('categoryId', JSON.stringify(responce.data.results[0]?.id));
    return responce
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const CategorySliceData = createSlice({
  name: 'category_slice',
  initialState,
  reducers: {
    clearCategoryState : (state) => {
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      CategorySlice.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CategorySlice.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload.data
      })
    builder.addCase(
      CategorySlice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export const { clearCategoryState } = CategorySliceData.actions
