import Image1 from './TableImage/Table_1.png'
import Image2 from './TableImage/Table_2.png'
import Image3 from './TableImage/Table_3.png'
import Image4 from './TableImage/Table_4.png'
import Image5 from './TableImage/Table_5.png'
import Image6 from './TableImage/Table_6.png'


export const Top_Retailers_array = ["name", "city", "Orders", "Sales"];
export const Top_Poducts_array = ["ITEM", "Most purchased by" , "PRICE", "UNITS SOLD", "SALES"];
export const Categories_array = ["ITEM", "Most purchased by" , "UNITS SOLD", "SALES"];
export const dashborad_Retailers_array = ["name", "Bazaar" , "City", "Order" ,"Sales"];
export const dashborad_Poducts_array = ["ITEM", "Bazaar" , "PRICE", "SOLD" ,"Sales"];

export const Ads_Performance_array = ["name", "Products", "Bazaar" , "City", "PRICE" ,"SOLD" ,"AMOUNT SPENT"];

export const Ads_Performance_data = [
  {
    name : "Name of the ad",
    Products : "Nikon Camera",
    Bazaar : "Electronic",
    City : "City Name",
    PRICE : '₹49',
    SOLD : '5,951',
    AMOUNT_SPENT : '₹15,302.00'
  },
  {
    name : "Name of the ad",
    Products : "Aedle VK-X - Premium Customisable Wir…",
    Bazaar : "Electronic",
    City : "City Name",
    PRICE : '₹49',
    SOLD : '5,951',
    AMOUNT_SPENT : '₹15,302.00'
  },
  {
    name : "Name of the ad",
    Products : "Aedle VK-X - Premium Customisable Wir…",
    Bazaar : "Electronic",
    City : "City Name",
    PRICE : '₹49',
    SOLD : '5,951',
    AMOUNT_SPENT : '₹15,302.00'
  },
  {
    name : "Name of the ad",
    Products : "Aedle VK-X - Premium Customisable Wir…",
    Bazaar : "Electronic",
    City : "City Name",
    PRICE : '₹49',
    SOLD : '5,951',
    AMOUNT_SPENT : '₹15,302.00'
  },
  {
    name : "Name of the ad",
    Products : "Aedle VK-X - Premium Customisable Wir…",
    Bazaar : "Electronic",
    City : "City Name",
    PRICE : '₹49',
    SOLD : '5,951',
    AMOUNT_SPENT : '₹15,302.00'
  }
]



export const dashborad_Poducts_data = [
  {
    ITEM: "Wingreens Mart",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹10,331.70",
    image : Image1
  },
  {
    ITEM: "Aedle VK-X - Premium Customisable Wir…",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹10,331.70",
    image : Image2
  }, {
    ITEM: "Nikon D750 FX-format ",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹7,715.89",
    image : Image3
  }, {
    ITEM: "Minimalist wireless headphone",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹9,917.45",
    image : Image4
  }, {
    ITEM: "Shinobi watch 2 - Cream white",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹8,466.02",
    image : Image5
  }, {
    ITEM: "Polaroid Pronto 600",
    Bazaar: "Electronic",
    PRICE: "₹49",
    SOLD: "6,643",
    SALES: "₹1,302.00",
    image : Image6
  }
]

export const dashborad_Retailers_data = [
  {
    ITEM: "Wingreens Mart",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹10,331.70",
    image : Image1
  },
  {
    ITEM: "Aedle VK-X - Premium Customisable Wir…",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹10,331.70",
    image : Image2
  }, {
    ITEM: "Nikon D750 FX-format ",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹7,715.89",
    image : Image3
  }, {
    ITEM: "Minimalist wireless headphone",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹9,917.45",
    image : Image4
  }, {
    ITEM: "Shinobi watch 2 - Cream white",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹8,466.02",
    image : Image5
  }, {
    ITEM: "Polaroid Pronto 600",
    Bazaar: "Electronic",
    City: "City Name",
    Orders: "6,643",
    SALES: "₹1,302.00",
    image : Image6
  }
]
export const Top_Retailers_data = [
  {
    ITEM: "Wingreens Mart",
    PRICE: "₹49",
    ORDERS: "5,951",
    SALES: "₹15,302.00",
    image : Image1
  },
  {
    ITEM: "Aedle VK-X - Premium Customisable Wir…",
    PRICE: "₹49",
    ORDERS: "6,643",
    SALES: "₹10,331.70",
    image : Image2
  }, {
    ITEM: "Nikon D750 FX-format ",
    PRICE: "₹49",
    ORDERS: "4,609",
    SALES: "₹7,715.89",
    image : Image3
  }, {
    ITEM: "Minimalist wireless headphone",
    PRICE: "₹49",
    ORDERS: "5,002",
    SALES: "₹9,917.45",
    image : Image4
  }, {
    ITEM: "Shinobi watch 2 - Cream white",
    PRICE: "₹49",
    ORDERS: "7,545",
    SALES: "₹8,466.02",
    image : Image5
  }, {
    ITEM: "Polaroid Pronto 600",
    PRICE: "₹49",
    ORDERS: "4,714",
    SALES: "₹1,302.00",
    image : Image6
  }]

  export const Top_Poducts_data = [
    {
      ITEM: "Amazon Echo Plus (3nd Gen) - Premium…",
      PRICE: "₹49",
      ORDERS: "5,951",
      SALES: "₹15,302.00",
      Most_purchased_by : {
        name: "Laxmi Traders",
        price : "Rs. 12000",
        Parsantage : "16.30%"
      },
      image : Image1
    },
    {
      ITEM: "Aedle VK-X - Premium Customisable Wir…",
      PRICE: "₹49",
      ORDERS: "6,643",
      SALES: "₹10,331.70",
      Most_purchased_by : {
        name: "Laxmi Traders",
        price : "Rs. 12000",
        Parsantage : "16.30%"
      },
      image: Image2
    }, {
      ITEM: "Nikon D750 FX-format  ",
      PRICE: "₹49",
      ORDERS: "4,609",
      SALES: "₹7,715.89",
      Most_purchased_by : {
        name: "Laxmi Traders",
        price : "Rs. 12000",
        Parsantage : "16.30%"
      },
      image : Image3
    }, {
      ITEM: "Minimalist wireless headphone",
      PRICE: "₹49",
      ORDERS: "5,002",
      SALES: "₹9,917.45",
      Most_purchased_by : {
        name: "Laxmi Traders",
        price : "Rs. 12000",
        Parsantage : "16.30%"
      },
      image : Image4
    }, {
      ITEM: "Shinobi watch 2 - Cream white",
      PRICE: "₹49",
      ORDERS: "7,545",
      SALES: "₹8,466.02",
      Most_purchased_by : {
        name: "Laxmi Traders",
        price : "Rs. 12000",
        Parsantage : "16.30%"
      },
      image : Image5
    }]

    export const Categories_data = [
      {
        ITEM: "Amazon Echo Plus (3nd Gen) - Premium…",
        ORDERS: "5,951",
        SALES: "₹15,302.00",
        Most_purchased_by : {
          name: "Laxmi Traders",
          price : "Rs. 12000",
          Parsantage : "16.30%"
        },
        image : Image1
      },
      {
        ITEM: "Aedle VK-X - Premium Customisable Wir…",
        ORDERS: "6,643",
        SALES: "₹10,331.70",
        Most_purchased_by : {
          name: "Laxmi Traders",
          price : "Rs. 12000",
          Parsantage : "16.30%"
        },
        image: Image2
      }, {
        ITEM: "Nikon D750 FX-format  ",
        ORDERS: "4,609",
        SALES: "₹7,715.89",
        Most_purchased_by : {
          name: "Laxmi Traders",
          price : "Rs. 12000",
          Parsantage : "16.30%"
        },
        image: Image3
      }, {
        ITEM: "Minimalist wireless headphone",
        ORDERS: "5,002",
        SALES: "₹9,917.45",
        Most_purchased_by : {
          name: "Laxmi Traders",
          price : "Rs. 12000",
          Parsantage : "16.30%"
        },
        image: Image4
      }, {
        ITEM: "Shinobi watch 2 - Cream white",
        ORDERS: "7,545",
        SALES: "₹8,466.02",
        Most_purchased_by : {
          name: "Laxmi Traders",
          price : "Rs. 12000",
          Parsantage : "16.30%"
        },
        image : Image5
      }]


      export const Ads_Performance = [
        {
          Name : "Name of the ad",
          Products : "Nikon Camera",
          City : "City Name",
          PRICE : "₹49",
          SOLD : "5,951",
          AMOUNT_SPENT : "₹15,302.00"
        },{
          Name : "Name of the ad",
          Products : "Aedle VK-X - Premium Customisable Wir…",
          City : "City Name",
          PRICE : "₹49",
          SOLD : "6,643",
          AMOUNT_SPENT : "₹10,331.70"
        },{
          Name : "Name of the ad",
          Products : "Amazon Echo Plus (3nd Gen) - Premium…",
          City : "City Name",
          PRICE : "₹49",
          SOLD : "4,609",
          AMOUNT_SPENT : "₹7,715.89"
        },{
          Name : "Name of the ad",
          Products : "Amazon Echo Plus (3nd Gen) - Premium…",
          City : "City Name",
          PRICE : "₹49",
          SOLD : "5,002",
          AMOUNT_SPENT : "₹9,917.45"
        },{
          Name : "Name of the ad",
          Products : "Amazon Echo Plus (3nd Gen) - Premium…",
          City : "City Name",
          PRICE : "₹49",
          SOLD : "7,545",
          AMOUNT_SPENT : "₹8,466.02"
        }
      ]
