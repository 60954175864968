import { api } from "@/Axios/api";
import { getUserId, getWholeSellerId } from "@/helper";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const Searchproduct: any = createAsyncThunk('Searchproduct', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/wholeseller/data/${getUserId()}/product-specify/?search=${data}`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const Searchproductdata = createSlice({
  name: 'Searchproduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      Searchproduct.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      Searchproduct.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      Searchproduct.rejected, (state, action) => {
        state.loading = false
      })
  }
})