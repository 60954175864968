import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { getUserId, getWholeSellerId } from "@/helper";

const FilteredProducts: any = createAsyncThunk('FilteredProducts', async (data: any, { rejectWithValue }) => {
  try {
    data = {
      ...data,
      product_updated_by: getUserId()
    }
    const result: any = await api(`api/product/filter/`, 'get', data);
    return result.data
  }
  catch (error) {
    return error
  }
})

export const FilteredProductsWholeSeller: any = createAsyncThunk('FilteredProductsWholeseller', async (data: any, { rejectWithValue }) => {
  try {
    console.log("logger123...456",data?.params)
    const result: any = await api(`api/wholeseller/data/${getUserId()}/product-specify/`, 'get',data?.params || {} );
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState: initialStateType = {
  data: [],
  loading: false,
  error: null,
}

export const FilteredProductsReducer = createSlice({
  name: 'FilteredProducts',
  initialState,
  reducers: {
    clearFilteredProducts: (state) => {
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      FilteredProducts.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      FilteredProducts.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      FilteredProducts.rejected, (state, action) => {
        state.loading = false
      })
    builder.addCase(
      FilteredProductsWholeSeller.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      FilteredProductsWholeSeller.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      FilteredProductsWholeSeller.rejected, (state, action) => {
        state.loading = false
      })
  }
})
export const { clearFilteredProducts } = FilteredProductsReducer.actions
export default FilteredProducts