import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usAgentStyles } from "@/static/stylesheets/screens/agentStyle";
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
    TextField,
    Dialog
} from "@mui/material";
import { useDispatch, useSelector } from "@/Store";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { HiPhone } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import complete from "@/static/icons/complete.svg";
import fill from "@/static/icons/fill.svg";
import { Alert, AlertError } from "@/alert/Alert";
import { AppService } from "@/service/AllApiData.service";
import { EditRetaier, GetRetailer } from "@/Store/Sclicese/Retailerssclices";
import { baseURL } from "@/Axios/api";
import { Switch } from "@/components/atoms/Switch";


async function convertImageTobS4(imgUrl: string) {
    const imageToBase64 = require('image-to-base64/browser.js');
    let response = await imageToBase64(imgUrl);
    return "data:image/png;base64," + response;
}

type Anchor = 'right';
const RetailerKyc = (props: any) => {

    const kycData = props.kycData;

    console.log(kycData, "--------", props)
    const classes = usAgentStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [getAllAgent, setGetAllAgent] = useState<any>([]);
    const [data, setData] = useState(kycData);
    const [isshowmore, setisshowmore] = useState(false);
    const [ rejectModal, setRejectModal ] = useState(false);
    const [rejectReason, setRejectReason] = useState("");


    const handleKycApproveAgent = async (index: number, wholeseller_agent_status: string) => {
        let textmsg = ""
        if(!rejectReason && wholeseller_agent_status == 'KYCREJECTED'){
            AlertError("Please give some reason to reject");
            setRejectModal(false);
            return;
        }

        if (wholeseller_agent_status == 'KYCAPPROVED') {
            textmsg = "Do You want to approve retailer KYC ?";
        }
        if (wholeseller_agent_status == 'KYCREJECTED') {
            textmsg = "Do You want to reject retailer KYC ?";
        }
        if (window.confirm(textmsg)) {
            const postData = {
                id: data.id,
                retailer_status: wholeseller_agent_status == 'KYCAPPROVED' ? 'KYCAPPROVED' : 'KYCREJECTED',
                retailer_status_reason: rejectReason
            }
            console.log("logger123 ====> ",postData)
            await dispatch(EditRetaier({ data: postData, navigate }));
            await dispatch(GetRetailer());
            props.handleOpen(false);
            // let agentData = getAllAgent[index];
            // agentData.wholeseller_agent_status = wholeseller_agent_status;
            // if (agentData.wholeseller_agent_pancard_image.includes("https")) {
            //     agentData.wholeseller_agent_pancard_image = await convertImageTobS4(agentData.wholeseller_agent_pancard_image);
            // }
            // if (agentData.wholeseller_agent_adhar_front_image.includes("https")) {
            //     agentData.wholeseller_agent_adhar_front_image = await convertImageTobS4(agentData.wholeseller_agent_adhar_front_image);
            // }
            // if (agentData.wholeseller_agent_adhar_back_image.includes("https")) {
            //     agentData.wholeseller_agent_adhar_back_image = await convertImageTobS4(agentData.wholeseller_agent_adhar_back_image);
            // }
            // if (agentData.wholeseller_agent_image.includes("https")) {
            //     agentData.wholeseller_agent_image = await convertImageTobS4(agentData.wholeseller_agent_image);
            // }
            // const responseJson = await AppService.kycApproveAgent(agentData.id, agentData);
        }

    }

    const handleStatus = async (e: any, id: string) => {
        // console.log(item, 'itemitem')
        let textmsg = ""
        if (e.target.checked) {
          console.log()
          textmsg = `Do you want to activate this retailer?`;
        } else {
          textmsg = `Do you want to deactivate this retails?`;
        }
    
        if (window.confirm(textmsg)) {
          let body = {
            retailer_active: e.target.checked
          }
          let response = await AppService.patchRetailer(id, body);
          dispatch(GetRetailer());
          if (!e.target.checked) {
            Alert("Retailer activated successfully")
            props.handleOpen(false)
          } else {
            Alert("Retailer deactivated successfully")
            props.handleOpen(false)
          }
        }
      }

    const anchor = 'right'
    return (
        <div>
            <React.Fragment key={anchor}>
                {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

                <Dialog
                                open={rejectModal}
                                maxWidth={"lg"}
                                sx={{
                                    ".MuiPaper-root": {
                                        borderRadius: "6px",
                                    },
                                }}
                                onClose={() => setRejectModal(false)}
                            >
                                <div className={classes.addDialog}>
                                    <div className="flex gap-5 justify-between">
                                        <p className="rejectModalTitle">Reject KYC</p>
                                        <div
                                            onClick={() => setRejectModal(false)}
                                            className="cursor-pointer"
                                        >
                                            <svg
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                                                <line
                                                    x1="24.7305"
                                                    y1="12.423"
                                                    x2="12.4268"
                                                    y2="24.7266"
                                                    stroke="#84818A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                                <line
                                                    x1="24.3104"
                                                    y1="24.7266"
                                                    x2="12.0068"
                                                    y2="12.4229"
                                                    stroke="#84818A"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="pt-[20px]">
                                        <p className="fieldText">Reason</p>
                                        <TextField
                                            sx={{
                                                "& *": {
                                                    fontFamily: "Manrope !important",
                                                    fontSize: "14px",
                                                },
                                            }}
                                            variant="standard"
                                            fullWidth={true}
                                            // name="agency_name"
                                            value={rejectReason}
                                            onChange={(e:any)=>setRejectReason(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex gap-4 justify-center items-center pt-[30px] pb-[10px]">
                                        <ActionButton
                                            variant={"default"}
                                            title={"Cancel"}
                                            onClick={() => setRejectModal(false)}
                                        />

                                        <ActionButton
                                            variant={"primary"}
                                            title={"Reject KYC"}
                                            onClick={() => handleKycApproveAgent(data.index, 'KYCREJECTED')}
                                        />
                                    </div>
                                </div>
                            </Dialog>

                <Drawer
                    anchor={anchor}
                    open={props.open}
                    onClose={() => props.handleOpen(false)}
                >
                    <Box
                        sx={{ width: 400 }}
                        role="presentation"
                    // onClick={}
                    // onKeyDown={}
                    >
                        <div className={classes.nativeDialog}>
                            <div className="modalHead" style={{ padding: "30px 30px 15px" }}>
                                <p className="modalTitle">{data?.retailer_status}</p>
                                <div onClick={() => props.handleOpen(false)}>
                                    <svg
                                        width="36"
                                        height="36"
                                        viewBox="0 0 36 36"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="18" cy="18" r="18" fill="#fff" />
                                        <line
                                            x1="24.7305"
                                            y1="12.423"
                                            x2="12.4268"
                                            y2="24.7266"
                                            stroke="#84818A"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                        <line
                                            x1="24.3104"
                                            y1="24.7266"
                                            x2="12.0068"
                                            y2="12.4229"
                                            stroke="#84818A"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="headTitle" style={{ padding: "0 30px" }}>
                                <div>
                                    <p className="martTitle">{data?.retailer_name}</p>
                                    <p className="martDescription">Retailer</p>
                                </div>

                                <div>
                                    <span style={{ fontSize: "16px", fontWeight: 600 }}>{data?.retailer_active === true ? "Active" : "Inactive"}</span>
                                    <Switch actives={data?.retailer_active} onChange={(e: any) => handleStatus(e, data?.id)} />
                                </div>
                            </div>
                            <div className="datContainer" style={{ padding: "22px 30px" }}>
                                <p className="dataTitle">Contact Detail</p>
                                <div>
                                    <p className="metaData">{data?.retailer_name}</p>
                                    <p className="metaData flex gap-4 justify-end items-center py-[5px]">
                                        <HiPhone />
                                        {data?.retailer_number_and_details[0]?.retailer_number}
                                    </p>
                                    <p className="metaData"> {data?.retailer_email}</p>
                                </div>
                            </div>


                            <div className="datContainer" style={{ padding: "22px 30px" }}>
                                <p className="dataTitle"> Address</p>
                                <div>
                                    <p className="metaData"> {data?.retailer_address}</p>
                                    <p className="metaData"> {data?.retailer_landmark},{data?.retailer_city_name[0]?.city}, {data?.retailer_district_name?.[0]?.district}, {data?.retailer_state_name?.[0]?.state}</p>
                                    <p className="dataDescription flex gap-4 justify-end items-center py-[5px]">
                                        <FaMapMarkerAlt />
                                        Show Directions
                                    </p>
                                </div>
                            </div>


                            <div className="datContainer" style={{ padding: "22px 30px" }}>
                                <p className="dataTitle">Branch</p>
                                <div>
                                    <div className="flex gap-2">
                                        {data?.retailer_branch_name?.map((item: any, key: any) => (
                                            <div className="group relative" key={key + 1}>
                                                <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm">
                                                    {item.branch_name}
                                                </button>
                                                <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                                                    {item.branch_name}
                                                </span>
                                            </div>
                                        ))}


                                        {/* <div className="group relative">
                             <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm">
                                 electronics
                             </button>
                             <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 ">
                                 You hover me!
                             </span>
                         </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="datContainer" style={{ padding: "22px 30px" }}>
                                <div>
                                    <p className="dataTitle" style={{ paddingBottom: "15px" }}>Aadhar</p>
                                    <p className="dataTitle">PAN</p>
                                </div>
                                <div>
                                    <p className="metaData" style={{ paddingBottom: "15px" }}>{data?.retailer_adharcard_no}</p>
                                    <p className="metaData">{data?.retailer_pancard_no}</p>
                                </div>
                            </div>
                            <div className="py-[30px] px-[40px] font-semibold text-sm">
                                <p className="dataTitle">Documents</p>
                                <div className="flex justify-between p-10 gap-2">
                                    <img className=" max-w-[100px] rounded-lg shadow-md" src={data?.retailer_adhar_front_image} />
                                    <img className=" max-w-[100px] rounded-lg shadow-md" src={data?.retailer_pancard_image} />
                                    <img className=" max-w-[100px] rounded-lg shadow-md" src={data?.retailer_gst_image} />
                                    <img className=" max-w-[100px] rounded-lg shadow-md" src={data?.retailer_image} />
                                </div>
                            </div>
                            <div className={"action-bar"} style={{ padding: "22px 30px", flexWrap: "wrap", marginTop: 0 }}>
                                <ActionButton
                                    variant={"default"}
                                    title={"Cancel"}
                                    onClick={() => props.handleOpen(false)}
                                />

                                {/* {(data?.retailer_status === "PENDING" || data?.retailer_status === "REJECTED") && <ActionButton
                                    variant={"primary"}
                                    title={"Approve"}
                                    onClick={() => handleKycApproveAgent(data.index, 'KYCAPPROVED')}
                                />}

                                {(data?.retailer_status === "PENDING" || data?.retailer_status === "REJECTED") && <ActionButton
                                    variant={"primary"}
                                    title={"Reject"}
                                    onClick={() => handleKycApproveAgent(data.index, 'KYCREJECTED')}
                                />} */}

                                {(data?.retailer_status === "CREATED" || data?.retailer_status === "PENDING" || data?.retailer_status === "REJECTED") && <ActionButton
                                    variant={"primary"}
                                    title={"Approve"}
                                    onClick={() => handleKycApproveAgent(data.index, 'KYCAPPROVED')}
                                />}

                                {(data?.retailer_status === "CREATED" || data?.retailer_status === "PENDING" || data?.retailer_status === "REJECTED") && <ActionButton
                                    variant={"primary"}
                                    title={"Reject"}
                                    onClick={() => setRejectModal(true)}
                                />}
                            </div>
                        </div>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
};

export default RetailerKyc;
