import React, { useEffect, useState } from "react";
import { useSummarycardStyle } from "@/static/stylesheets/molecules/summarycardStyle";
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import DashboardCard from "./DashboardCard";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { BazaarReportCard } from "../../Bazaars/BazaarReportCard";
import ProductData from "./ProductData";
import { Categories_array, Categories_data, Top_Poducts_array, Top_Poducts_data, Top_Retailers_array } from "./Tabledata";
import { Top_Retailers_data } from "./Tabledata";
import { Ads_Performance } from "./Tabledata";
import TableComponets from "./TableComponets";
import { DashboardReport, adsPerformance, newRetailers, totalIncome, totalOrder, totalProduct } from "@/Store/Sclicese/DashboardSclice";
import { useDispatch, useSelector } from "@/Store";


const SummaryCard = () => {
  const classes = useSummarycardStyle();
  const dispatch = useDispatch()
  const adsPerformancedata:any = useSelector(state => state.adsPerformancedata.data["Ads Performance"])
  const totalOrderdata:any = useSelector(state => state.totalOrderdata.data["total order"])
  const totalProductdata:any = useSelector(state => state.totalProductdata.data["Total Products"])
  const totalIncomedata:any = useSelector(state => state.totalIncomedata.data["total revenue"])
  const newRetailersdata:any = useSelector(state => state.newRetailersdata.data["New Retailers"])
  const localStoragedata = JSON.parse(localStorage.getItem("Wholeseller_id") || "")
  const DashboardReportData = useSelector(state => state.DashboardReport.data)
  useEffect(()=>{
    dispatch(DashboardReport())
    dispatch(adsPerformance(localStoragedata))
    dispatch(totalOrder(localStoragedata))
    dispatch(totalProduct(localStoragedata))
    dispatch(totalIncome(localStoragedata))
    dispatch(newRetailers(localStoragedata))
  },[]) 
  return (
    <div className="py-[4px]">
      <div className="grid grid-cols-4 gap-5">
        <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
          <DashboardCard arrow={"up"} title={"Total Products"} amount={totalProductdata}/>
        </div>
        <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
          <DashboardCard arrow={"up"} title={"Total Orders"} amount={DashboardReportData?.total_orders }/>
        </div>
        <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
          <DashboardCard arrow={"up"} title={"Total Revenue"} amount={DashboardReportData?.total_revenue}/>   
        </div>
        <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
          <DashboardCard arrow={"up"} title={"Total Retailers"} amount={DashboardReportData?.new_retailers?.length}/>
        </div> 
      </div>

      <div className="grid grid-cols-12 gap-4 text-[#FFFFFF] pt-[23px]">
          <div className="col-span-7 bg-[#4E2FA9] rounded-[5px] relative">
              <p className="font-Manrope font-[600] text-[20px] leading-[125%] px-[28px] pt-[28px] pb-[10px] tracking-[0.5px]">0 Pending orders</p>
              <p className="font-Manrope font-[400] text-[10px] leading-[150%] px-[28px] tracking-[0.5px] pb-[25px]">You have 0 pending orders for approval</p>
              <button className="absolute right-[31px] bottom-[33px] bg-[#FF6652] gap-[10px] w-[72px] h-[32px] rounded-[4px]">View</button>
          </div>
          <div className="col-span-5 bg-[#4E2FA9] rounded-[5px] relative">
              <p className="font-Manrope font-[600] text-[20px] leading-[125%] px-[28px] pt-[28px] pb-[10px] tracking-[0.5px]">0 Approval Requests</p>
              <p className="font-Manrope font-[400] text-[10px] leading-[150%] px-[28px] tracking-[0.5px] pb-[25px]">You have 0 payment request</p>
              <button className="absolute right-[31px] bottom-[33px] bg-[#FF6652] gap-[10px] w-[72px] h-[32px] rounded-[4px]">View</button>
          </div>
      </div>

      <div className="pt-[30px]">
          <div>
            <p className="font-Manrope font-[600] text-[18px] leading-[25px] tracking-[0.5px] text-[#2E2C34]">Bazar Report</p>
          </div>

          <div className="bazaarButtons pt-[10px]">
            <ActionButton variant={"primary"} title={"Today"} />
            <ActionButton variant={"default"} title={"This Week"} />
            <ActionButton variant={"default"} title={"Last Week"} />
            <ActionButton variant={"default"} title={"This Month"} />
            <ActionButton variant={"default"} title={"Last Month"} />
            <ActionButton variant={"default"} title={"Select Date Range"} />
          </div>
      </div>

      <div className="pt-[25px]">
      <div className="bazaarReport">
            <BazaarReportCard
              title="Total Retailers"
              count="0"
              revenue="Total revenue Earned"
              prize="Rs. 0"
              bills="No of Bills"
              billsNumber="0"
              />
            {/* <BazaarReportCard
              title="Total Retailers"
              count="0"
              revenue="Total revenue Earned"
              prize="Rs. 0"
              bills="No of Bills"
              billsNumber="0"
              /> */}
          </div>
      </div>

      <div>
        <ProductData headingdata={Top_Retailers_array} bodydata={Top_Retailers_data} tital='Top Retailers'/>
      </div>

      <div>
        <ProductData headingdata={Top_Poducts_array} bodydata={"Top_Poducts_data"} tital='Top Poducts'/>
      </div>

      <div>
        <ProductData headingdata={Categories_array} bodydata={"Categories_data"} tital='Categories'/>
      </div>

      <div>
        <ProductData headingdata={Categories_array} bodydata={"Sub_Categories"} tital='Sub Categories'/>
      </div>

      <div>
        <ProductData headingdata={Categories_array} bodydata={"Sub_Categories"} tital='Sub Categories'/>
      </div>

      <div className="px-[35px] py-[22px] border-solid border-[#E1E1E1] border-[1px] rounded-[4px] my-[25px]">
        <div className="flex justify-between pb-[30px]">
          <p className="font-Manrope font-[600] text-[16px] leading-[22px] text-[#2E2C34] tracking-[0.5px]">Ads Performance</p>
          <button className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#5542F6] tracking-[0.5px]">View all</button>
        </div>
        <div>
          <table className="w-full">
            <thead>
              <tr className="border-b-2 w-full !p-0">
                <th className="text-left font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">Name</th>
                <th className="text-left font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">Products</th>
                <th className="text-left font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">City</th>
                <th className="text-left font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">PRICE</th>
                <th className="text-left font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">SOLD</th>
                <th className="font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px]">AMOUNT SPENT</th>
              </tr>
            </thead>
            <tbody>
              {
                adsPerformancedata?.map((data: any )=>{
                    return(
                      <tr>
                        <td className="py-[35px] font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Name}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Product}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.City}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Price}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Sold}</td>
                        <td className="text-center font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data["Amount Spend"]}</td>
                      </tr>
                    )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="grid grid-cols-2 gap-4 pt-[10px]">
        <div>
          <TableComponets/>
        </div>
        <div>
          <TableComponets/>
        </div>
      </div>

    </div>
  );
};

export { SummaryCard };
