import React, { useEffect, useState } from "react";
import { useDispatch, AppDispatch, useSelector } from "@/Store";
import { CreateCategoryPlan, CreateItemWisePlan, EditCategoryPlan, EditItemWisePlan, EditSubCategoryPlan, createSubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { CreateProductPricing, EditProductPricing } from "@/Store/Sclicese/ProductDetail";
import { MenuItem, Select } from "@mui/material";
import { AppService } from "@/service/AllApiData.service";
import { Alert } from "@/alert/Alert";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import moment from "moment";

type props = {
    title: string,
    updated_date: any,
    branch?: number,
    category?: number,
    id?: any,
    product: any,
    stock: any,
    new_stock: any,
    setShowLog?: any,
    reload?: any,
    item?: any
}
const ItemRow = ({ title, updated_date,
    stock,
    branch,
    category,
    id,
    product,
    new_stock,
    setShowLog,
    reload,
    item
}: props) => {

    const dispatch = useDispatch();
    const [data, setData] = useState<any>({
        new_stock: new_stock,
        trigger: "1",
        product_min_quantity: item?.product_min_quantity || "",
        product_max_quantity: item?.product_max_quantity || ""
    })

    const handleChange = (plan: any, value: any, type: string) => {
        setData({
            ...data,
            [plan.value]: value,
            [plan.type]: type
        })
    }

    const handleSubmit = async () => {
        const postData = {
            ...data,
            product,
            // branchId: branch
            qty: data.new_stock || 0,
            trigger: Number(data.trigger)
        }
        let param = {
            product
        }
        const response = await AppService.UpdateProductStock(postData);
        const res = await AppService.GetProductStockDetails(param)
        Alert("Stock Updated Successfully")
        reload();
    }

    const checkData = (plan: any, value: any) => {
        if (value === true) {
            value = "RUPEE";
        } else {
            value = undefined;
        }
        setData({
            ...data,
            [plan.value]: null,
            [plan.type]: value,
        })
    }

    const checkRetailerData = (rtl: any, value: any) => {
        let tmpArr = data.retailer_type || []
        if (value === true) {
            tmpArr?.push(rtl.id)
        } else {
            tmpArr = tmpArr?.filter((t: any) => t !== rtl.id)
        }
        setData({
            ...data,
            retailer_type: tmpArr
        })
    }


    return (
        <tr className="border-b align-baseline">
            <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
            >
                <div className="flex gap-[20px] items-center ">
                    <p className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope cursor-pointer">
                        {title}
                    </p>
                </div>
            </th>
            <td className="py-4 px-6  color-[#2E2C34;]">
                <div className="priceTitle">{stock}</div>
            </td>
            <td className="py-4 px-6  color-[#2E2C34;]">
                <CommonSelectElectronicBazaar
                    label={"Stock Operation"}
                    hint={"Select Stock Operation"}
                    options={[
                        {
                            label: "Increase By",
                            value: "1"
                        },
                        {
                            label: "Decrease By",
                            value: "2"
                        },
                    ]}
                    handleSelect={(e, id) => setData({ ...data, trigger: id })}
                    selectedIds={[data.trigger]}
                    showSelectedLable
                />

            </td>
            <td className="py-4 px-6  color-[#2E2C34;]">
                {/* <div className="bg-[#FFF0EE] p-[10px] flex justify-center rounded-md w-[160px]"> */}
                {/* <p className="updatePrice"></p> */}
                <div className="border border-[#e5e7eb] w-[100px] rounded py-2 px-6">
                    <input value={`${data.new_stock}`} style={{ width: '60px', border: 'none' }} onChange={(e) => setData({ ...data, new_stock: e.target.value })} />
                </div>
                {/* </div> */}
            </td>
            <td className="py-4 px-6  color-[#2E2C34;]">
                {/* <div className="bg-[#FFF0EE] p-[10px] flex justify-center rounded-md w-[160px]"> */}
                {/* <p className="updatePrice"></p> */}
                <div className="border border-[#e5e7eb] w-[100px] rounded py-2 px-6">
                <input value={`${data.product_min_quantity}`} style={{ width: '60px', border: 'none' }} onChange={(e) => setData({ ...data, product_min_quantity: e.target.value })} />
                </div>
                {/* </div> */}
            </td>
            <td className="py-4 px-6  color-[#2E2C34;]">
                {/* <div className="bg-[#FFF0EE] p-[10px] flex justify-center rounded-md w-[160px]"> */}
                {/* <p className="updatePrice"></p> */}
                <div className="border border-[#e5e7eb] w-[100px] rounded py-2 px-6">
                <input value={`${data.product_max_quantity}`} style={{ width: '60px', border: 'none' }} onChange={(e) => setData({ ...data, product_max_quantity: e.target.value })} />
                </div>
                {/* </div> */}
            </td>
            <td className="py-4 px-6 color-[#2E2C34;]">
                <button onClick={() => handleSubmit()} className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent rounded">
                    APPLY
                </button>
            </td>
            <td className="py-4 px-6 color-[#2E2C34;]">
                <p className="text-[14px] font-medium font-[600] text-black">{moment(data?.product_updated_date).format("DD-MM-YYYY, HH:MM:SS A")}</p>
            </td>

            <td className="py-4 px-6 color-[#2E2C34;]">
                <button className="bg-transparent hover:bg-purple-600 text-purple-600 font-semibold hover:text-white py-2 px-4 border border-purple-600 hover:border-transparent rounded" onClick={() => setShowLog(id)}>
                    Logs
                </button>
            </td>
        </tr >
    );
};

export default ItemRow;
