import React, { useRef, useState } from "react";
import { useBulkuploadStyles } from "@/static/stylesheets/screens";
import { DashboardLayout } from "@/components/layouts";
import UploadLogo from "@/static/svg/ic_upload.svg";
import DownloadLogo from "@/static/svg/ic_download.svg";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { AppService } from "@/service/AllApiData.service";
import { getUserId, getWholeSellerId } from "@/helper";
import { Alert, AlertError } from "@/alert/Alert";
import Loading from "@/components/LoadingCom/Loading";

const BulkUpload = () => {
  const classes = useBulkuploadStyles();
  const navigate = useNavigate()
  const uploaderRef = useRef<any>();
  const zipUploaderRef = useRef<any>();
  const [file, setFile] = useState<any>();
  const [zipFile, setZipFiles] = useState<any>();
  const [loader, setLoader] = useState(false);


  const submitBulkUpload = async () => {
    
    if (file?.length && zipFile?.length) {
      setLoader(true);
      try {
        let formData = new FormData();
        formData.append("excel_file", file?.[0]);

        if (zipFile?.length) {
          formData.append("images", zipFile?.[0]);
        }

        formData.append("wholeseller_id", getWholeSellerId() || "");
        const response = await AppService.productsBulkUpload(formData);
        console.log("RESPONSE ====> ", response)
        Alert(response.data.message);
        navigate('/wholesellerproducts');
        // getAllLists();
        setFile(null);
        setZipFiles(null);
        // setShowBulkUpload(false)
        setLoader(false);
      } catch (error: any) {
        let message = error.response.data.type + "\n"
        error.response.data.errors.map((row: any) => {
          message += row.attr + " : " + row.detail + "\n"
        })

        AlertError(message);
        // setFile(null)
        // setShowBulkUpload(false)
        setLoader(false);
      }
    } else {
      AlertError("Please select both excel and zip file")
      // setShowBulkUpload(false)
      // setFile(null)
    }
  }

  return (
    <>
      <DashboardLayout>
        {
          loader ? 
          <Loading/> : null
        }
        <div className={classes.root}>
          <div className="flex gap-4 items-center">
            <div className="cursor-pointer" onClick={() => navigate('/addproduct')}>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
                  fill="#84818A"
                />
              </svg>
            </div>
            <div>
              <p className="mainTitle">Bulk Upload</p>
            </div>
          </div>
          <div className="container mt-10">

          <div className="uploadArea relative" onClick={() => uploaderRef?.current?.click()}>
              <input type="file" className="hidden w-full h-full cursor-pointer opacity-0" ref={uploaderRef} onChange={(e: any) => setFile(e.target.files)} accept=".xlsx, .xls" />
              <div className="placeholder pt-[35px]">
                <img className="!w-[50px]" src={UploadLogo} alt={"Logo"} />
              </div>
              <div className="contentContainer">
                {
                  file?.[0]?.name ?
                    <p className="headTitle">
                      Change Excel here
                    </p>
                    :
                    <p className="headTitle">Upload Excel here</p>
                }
                <p className="headDescription py-4">
                  {
                    file?.length &&
                    "Selected " + file[0]?.name
                  }
                  {/* Image can be size of 512 PX by 512 PX Only */}
                </p>
              </div>
            </div>
            
            <div className="gap-5 actonButton py-[35px]" onClick={()=>window.open("/templates/Product-Bulkimport.xlsx","_blank")} >
              <img src={DownloadLogo} alt={"Logo"} />
              <p className="downloadTitle cursor-pointer">
                Download Excel templates
              </p>
            </div>


            <div className="uploadArea relative" onClick={() => zipUploaderRef?.current?.click()}>
              <input type="file" className=" hidden w-full h-full cursor-pointer opacity-0" ref={zipUploaderRef} onChange={(e: any) => setZipFiles(e.target.files)} accept=".zip" />
              <div className="placeholder pt-[35px]">
                <img className="!w-[50px]" src={UploadLogo} alt={"Logo"} />
              </div>
              <div className="contentContainer">
                {
                  zipFile?.[0]?.name ?
                    <p className="headTitle">
                      Change zip file here
                    </p>
                    :
                    <p className="headTitle">Upload Zip file here</p>
                }
                <p className="headDescription py-4">
                  {
                    zipFile?.length &&
                    "Selected " + zipFile[0]?.name
                  }
                  {/* Image can be size of 512 PX by 512 PX Only */}
                </p>
              </div>
            </div>

            <div className="gap-5 actonButton py-[35px]" onClick={()=>window.open("/templates/Bulk-Upload-Products-Zip-template.zip","_blank")}>
              <img src={DownloadLogo} alt={"Logo"} />
              <p className="downloadTitle cursor-pointer">
                Download Zip file templates
              </p>
            </div>

                  <div className="flex justify-end items-center gap-4">
                  <ActionButton
                    variant="primary"
                    title="Cancel"
                  />

                  <ActionButton
                    variant="primary"
                    title="Upload"
                    onClick={submitBulkUpload}
                  />
                  </div>

          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export { BulkUpload };
