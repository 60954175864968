import React, { useState, useEffect } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useProductsStyles } from "@/static/stylesheets/screens/productsStyles";
import { AddButton, GridOptionButton } from "@/components/atoms/Button";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { SearchField } from "@/components/atoms/SearchField";
import { Dialog, Grid, Pagination, SelectChangeEvent, MenuItem, Select, TextField, Box, IconButton } from "@mui/material";
import { Alert, AlertError } from "@/alert/Alert";
import barIcon from "@/static/svg/ic_barcode.svg";
import pieIcon from "@/static/svg/ic_piechart.svg";
import ChartIcon from "@/static/svg/ic_chart.svg";
import { useNavigate } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import LogoContract from "@/static/icons/uploader-frame.png";
import * as Yup from "yup";
import { readFileAsBase64 } from "@/helper/base64";
import calendar from "@/static/icons/calendar.svg";
import fill from "@/static/icons/fill.svg";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import Pinimage from "@/static/images/Vector (9).png";
import { ErrorMessage, Form, Formik } from "formik";
import UploaderFrame from "@/static/icons/uploader-frame.png";
import { useProductListStyles } from "@/static/stylesheets/molecules";
import closeicon from "@/static/images/Vector (10).png"
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import MwbImage from "@/static/images/basket_image.png";
import { Switch } from "@/components/atoms/Switch";
import ProductImage from "@/static/images/placeholder.jpg";
import barCodeImage from "@/static/images/mwb_barcode.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import plusImg from './image/plusImg.png'
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import UserProduts from "@/Store/Sclicese/WholesellerProducts";
import { AppDispatch, useDispatch, useSelector } from "@/Store";
import DeleteProduct from "@/Store/Sclicese/Deleteproducts";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Searchproduct } from "@/Store/Sclicese/SearchProduct";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { GetTallyCredentials } from "@/Store/Sclicese/TallyService";
import ProductDetailModal from "./ProductsDetail";
import { CategorySlice } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice } from "@/Store/Sclicese/SubCategorySlice";
import { Cell, Pie, PieChart } from "recharts";
import { Edit, Visibility } from "@mui/icons-material";




interface report {
  complete: number,
  inProgress: number,
  cancel: number
}

const Products = (props: any) => {
  const wholesellerproductdata = useSelector(state => state.Wholesellerproduct.data)
  const totalCount = useSelector(state => state.Wholesellerproduct.count)
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event: any, value: any) => {
    dispatch(UserProduts({
      page: currentPage,
      product_type: 1
    }))
    setCurrentPage(value);
  };
  // const wholesellerproductdata = []
  const Searchproductdata = useSelector<any>(state => state.Searchproductdata.data.results)
  const TallyServiceData = useSelector<any>(state => state.TallyServiceData.data)
  const navigate = useNavigate();
  const classes = useProductsStyles();
  const dispatch = useDispatch()
  const [data, setData] = useState<any>([])
  const [deleteddata, setDeleteddata] = useState('')
  const [inputdatas, setinputdatas] = useState('')
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [productdata, setProductdata] = useState<any>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [tallyConfirm, setTallyConfirm] = React.useState(false);
  const [tallyModal, setTallyModal] = React.useState(false);
  const [anotherModalOpen, setAnotherModalOpen] = useState(false);
  const [selectOptionsModalOpen, setselectOptionsModalOpen] = useState(false);
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)
  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const [filterData, setFilterData] = useState<any>({
    stock: [],
    brand: [],
    group_category: [],
    category: [],
    sub_category: [],
    weight: [],
    price: [],
    status: []
  });
  const [showTallySyncPreview, setShowTallySyncPreview] = useState(false);

  const [salesReport, setSalesReport] = useState<report>({
    complete: 0,
    inProgress: 0,
    cancel: 0
  });

  const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "")

  useEffect(() => {
    dispatch(CategoryGroup(bazaarID))
    dispatch(CategorySlice({ bazaarId: bazaarID }))
    dispatch(SubCategorySlice({ bazaarId: bazaarID }))
  }, [])

  console.log(wholesellerproductdata, "-------")

  useEffect(() => {
    dispatch(UserProduts({
      page: currentPage,
      product_type: 1
    }))
  }, [isOpen])

  useEffect(() => {
    if (wholesellerproductdata?.length && inputdatas == '') {
      setData(wholesellerproductdata)
    }
    else {
      setData(Searchproductdata)
    }
  }, [wholesellerproductdata?.length, inputdatas])


  const hendelopenmodel = (data: any) => {
    setAddModalOpen(true)
    setProductdata(data)
  }

  const handleDeleted = (data: any) => {
    setIsOpen(true);
    setDeleteddata(data)
  };

  const handleClose = async () => {
    await dispatch(DeleteProduct(deleteddata))
    setIsOpen(false);
  };

  const inputdata = async (data: any) => {
    await dispatch(Searchproduct(data))
    setinputdatas(data)
    if (data == '') {
      setData(wholesellerproductdata)
    }
    else {
      setData(Searchproductdata)
    }
  }

  const handleChange = (selectboxName: string, id: any) => {
    if (selectboxName === 'Stock') {
      const isExist = filterData.stock.includes(id)
      setFilterData({
        ...filterData,
        stock: [...filterData.stock, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Group Category') {
      const isExist = filterData.group_category.includes(id)
      setFilterData({
        ...filterData,
        group_category: [...filterData.group_category, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Category') {
      const isExist = filterData.category.includes(id)
      setFilterData({
        ...filterData,
        category: [...filterData.category, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Sub-Category') {
      const isExist = filterData.sub_category.includes(id)
      setFilterData({
        ...filterData,
        sub_category: [...filterData.sub_category, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Brand') {
      const isExist = filterData.brand.includes(id)
      setFilterData({
        ...filterData,
        brand: [...filterData.brand, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Weight') {
      const isExist = filterData.weight.includes(id)
      setFilterData({
        ...filterData,
        weight: [...filterData.weight, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Price Range') {
      const isExist = filterData.price.includes(id)
      setFilterData({
        ...filterData,
        price: [...filterData.price, id].filter(val => val !== id || !isExist)
      })
    }
    if (selectboxName === 'Active/Inactive') {
      const isExist = filterData.status.includes(id)
      setFilterData({
        ...filterData,
        status: [...filterData.status, id].filter(val => val !== id || !isExist)
      })
    }
  }

  const stockFilterOptions = wholesellerproductdata?.map((item: any) => item?.product_stocks)?.filter((item: any) => item && !isNaN(item)).map((row: any) => {
    return {
      label: row,
      value: row,
    }
  })

  const brandFilterOptions = wholesellerproductdata?.map((item: any) => item?.product_brand_name).map((row: any) => {
    return {
      label: row,
      value: row,
    }
  })

  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))

  const weightFilterOptions = wholesellerproductdata?.map((item: any) => item?.product_total_weight_value).map((row: any) => {
    return {
      label: row,
      value: row,
    }
  })

  const priceFilterOptions = wholesellerproductdata?.map((item: any) => item?.product_total_mrp).map((row: any) => {
    return {
      label: row,
      value: row,
    }
  })

  const [filteredData, setFilteredData] = useState<any>([]);

  useEffect(() => {

    let filteredList = data;

    if (filterData.stock.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.stock.includes(item.product_stocks)
      })
    }
    if (filterData.brand.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.brand.includes(item.product_brand_name)
      })
    }
    if (filterData.weight.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.weight.includes(item.product_total_weight_value)
      })
    }
    if (filterData.price.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.price.includes(item.product_total_mrp)
      })
    }
    if (filterData.group_category.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.group_category.includes(item.category_group.id)
      })
    }

    if (filterData.category.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.category.includes(item.category.id)
      })
    }

    if (filterData.sub_category.length > 0) {
      filteredList = filteredList?.filter((item: any) => {
        return filterData.sub_category.includes(item.subcategory.id)
      })
    }

    console.log("FILTERED LIST ====> ", filteredList);

    setFilteredData(filteredList);

  }, [filterData])

  useEffect(() => {
    setFilteredData(data);
  }, [data])

  console.log(TallyServiceData)


  const [chartData, setChartData] = useState<any>([
    { name: 'Complete', value: 0 },
    { name: 'In Progress', value: 0 },
    { name: 'Cancel', value: 0 }
  ]);

  useEffect(() => {
    if (!salesReport.complete && !salesReport.inProgress && !salesReport.cancel) {
      setChartData(
        [
          { name: 'Complete', value: 100 },
          { name: 'In Progress', value: 100 },
          { name: 'Cancel', value: 100 }
        ]
      )
    } else {
      setChartData(
        [
          { name: 'Complete', value: salesReport.complete },
          { name: 'In Progress', value: salesReport.inProgress },
          { name: 'Cancel', value: salesReport.cancel }
        ]
      )
    }
  }, [salesReport])

  const COLORS = ['#00CAAD', '#4D2DA5', '#FF6457'];
  // const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <>

      <div>
        <Dialog
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              are you sure to delete this data
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>No</Button>
            <Button onClick={handleClose} autoFocus className="!bg-[#ff6652] !text-[white]">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={showTallySyncPreview}
          onClick={() => setShowTallySyncPreview(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // style={{
          //   maxWidth:"auto !important"
          // }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Tally Import Preview"}
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
              Are you sure to import products from tally?
            </DialogContentText> */}

            <div>
              <div className="overflow-x-auto relative pb-[40px]">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-[12px] text-[#84818A] font-[600] border-b-2">
                    <tr className=" color-[#2E2C34;]">
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                        Sr. No.
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                        Product Image
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Product Name
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Brand
                      </th>
                      {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Branch
                      </th> */}
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Group Category
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Category
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Sub-Category
                      </th>
                      {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Weight
                      </th> */}
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Total MRP
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Selling Price
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Primary Packaging Unit (Weight)
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Secondary Packaging Unit (Weight)
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Stock
                      </th>
                      {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] text-center whitespace-nowrap">
                        Enable/Disable
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((item: any, index: number) => (
                      <tr className="border-b">

                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {index + 1}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <img
                            className="w-[50px] rounded-md"
                            src={item.product_upload_mrp_label_image}
                            alt={"Mwb Bazaar"}
                          />
                        </td>

                        <td className="py-4 px-6  color-[#2E2C34;]">{item.product_name}</td>
                        {/* <th
                          scope="row"
                          className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div className="flex gap-[20px] items-center ">
                            <img
                              className="w-[50px] rounded-md"
                              src={item.product_upload_mrp_label_image}
                              alt={"Mwb Bazaar"}
                            />
                            <p
                              onClick={() => hendelopenmodel(item)}
                              className=" text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 cursor-pointer m-0"
                            >
                              {item.product_name}
                            </p>
                          </div>
                        </th> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">{item.product_brand_name}</td>
                        {/* <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_brand_name}
                        </td> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.category_group?.parent_category_name}</p>
                          </div>
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.category?.category_name}</p>
                          </div>
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.subcategory?.subcategory_name}</p>
                          </div>
                        </td>
                        {/* <td className="py-4 px-6  color-[#2E2C34;]">{item.product_total_weight_value} {item?.product_per_unit_weight_unit?.unit_name}</td> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_total_mrp}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_selling_price}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                          {item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                          ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                          {item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                          <br />
                          ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <p className="stockStatusTitle whitespace-nowrap">{item.product_stocks} {item?.product_primary_packing_unit?.unit_name}</p>
                        </td>
                        {/* <td className="color-[#2E2C34;]">
                          <div className="actionMenu">
                            <div
                              onClick={() => {
                                setSalesReport({
                                  ...salesReport,
                                  complete: item?.completed_orders,
                                  inProgress: item?.in_progress_orders,
                                  cancel: item?.cancel_count
                                })
                                setAnotherModalOpen(true);
                              }
                              }
                            >
                              <img src={pieIcon} alt={"Logo"} width={50} height={50} />
                            </div>

                            <div
                              onClick={() => {
                                setProductdata(item.id)
                                setAddModalOpen(true)
                              }}
                              className="cursor-pointer"
                            >
                              <img src={barIcon} alt={"Logo"} width={50} height={50} />
                            </div>

                            <div>
                              <Switch actives={item.product_active} />
                            </div>
                            <div onClick={() => navigate("/editproduct/" + item.id)}>
                              <Edit className="cursor-pointer" />
                            </div>
                            <div onClick={() => handleDeleted(item.id)}>
                              <DeleteIcon className="cursor-pointer" />
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div
                  className="flex items-center justify-between py-4"
                  style={{ display: "flex", marginLeft: 0 }}
                >
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div> */}
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTallySyncPreview(false)}>Cancel</Button>
            <Button onClick={() => { setShowTallySyncPreview(false) }} autoFocus className="!bg-[#ff6652] !text-[white]">
              Sync Now
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={tallyConfirm}
          onClick={() => setTallyConfirm(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Import Tally"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to import products from tally?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setTallyConfirm(false)}>No</Button>
            <Button onClick={() => { setShowTallySyncPreview(true); setTallyConfirm(false) }} autoFocus className="!bg-[#ff6652] !text-[white]">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={tallyModal}
          onClose={() => setTallyModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Tally Credentials"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>use this credentials</p>
              <div>
                Username : {TallyServiceData?.tally_username} <br />
                Password : {TallyServiceData?.tally_password}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setTallyModal(false)}>No</Button> */}
            <Button onClick={() => setTallyModal(false)} autoFocus className="!bg-[#ff6652] !text-[white]">
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>


      <DashboardLayout>
        {Array.isArray(wholesellerproductdata) && wholesellerproductdata?.length != 0 ? <div className={`${classes.root} h-full`}>
          <div className="">
            <div className="grid grid-cols-12">
              <div className="headTitle flex items-center col-span-5">
                <p className="font-[600] font-Manrope not-italic text-[24px] leading-[20px] text-[#2E2C34] tracking-[1px] whitespace-nowrap">All Products ({totalCount})</p>

                <SearchField icontype={false} inputdata={inputdata} className="ml-[40px]" />
              </div>
              <div className="flex col-span-7 justify-end  ">
                <div className="me-[16px]">
                  <AddButton
                    label="Add Products"
                    onClick={() => navigate("/addproduct")}
                  />
                </div>
                <div className="flex flex-row gap-4">
                  <AddButton
                    label="Import from Tally"
                    variant="primary"
                    onClick={() => {
                      setTallyConfirm(true);
                    }}
                  />
                  <ActionButton
                    type="button"
                    variant="primary"
                    title="View Credentials"
                    startIcon={<Visibility />}
                    onClick={() => {
                      dispatch(GetTallyCredentials())
                      setTallyModal(true)
                    }}
                  />

                </div>
              </div>
            </div>

            <div className="productFilters flex flex-wrap p-[2px] pt-[28px] justify-between w-full items-center">
              {/* <TwMultiSelect name='Stock' loopData={['out of stock','available']} /> */}
              <CommonSelectElectronicBazaar
                label={"Stock"}
                hint={"Select Stock"}
                options={stockFilterOptions}
                handleSelect={handleChange}
                selectedIds={stockFilterOptions?.filter((item: any) => filterData.stock.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Brand"}
                hint={"Select Brand"}
                options={brandFilterOptions}
                handleSelect={handleChange}
                selectedIds={brandFilterOptions?.filter((item: any) => filterData.brand.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Group Category"}
                hint={"Select Group Category"}
                options={groupCategoryFilterOptions}
                handleSelect={handleChange}
                selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Category"}
                hint={"Select Category"}
                options={categoryFilterOptions}
                handleSelect={handleChange}
                selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Sub-Category"}
                hint={"Select Sub-Category"}
                options={subCategoryFilterOptions}
                handleSelect={handleChange}
                selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Weight"}
                hint={"Select Weight"}
                options={weightFilterOptions}
                handleSelect={handleChange}
                selectedIds={weightFilterOptions?.filter((item: any) => filterData.weight.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Price Range"}
                hint={"Select Price Range"}
                options={priceFilterOptions}
                handleSelect={handleChange}
                selectedIds={priceFilterOptions?.filter((item: any) => filterData.price.includes(item?.value)).map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Status"}
                hint={"Select Active/Inactive"}
                options={[{
                  label: 'Enable',
                  value: 'true'
                },
                {
                  label: 'Disable',
                  value: 'false'
                }
                ]}
                handleSelect={handleChange}
              // selectedIds={stockFilterOptions?.filter((item: any) => filterData.stock.includes(item?.value)).map((elm: any) => elm?.value)}
              />

              <CommonSelectElectronicBazaar
                label={"Tally/Web"}
                hint={"Select Tally/Web"}
                options={[{
                  label: 'Tally',
                  value: 'tally'
                },
                {
                  label: 'Web',
                  value: 'web'
                }
                ]}
                handleSelect={handleChange}
              // selectedIds={stockFilterOptions?.filter((item: any) => filterData.stock.includes(item?.value)).map((elm: any) => elm?.value)}
              />

              {/* <TwMultiSelect name='Category' />
              <TwMultiSelect name='Sub-Category' />
              <TwMultiSelect name='Weight' />
              <TwMultiSelect name='Price Range' />
              <TwMultiSelect name='Status' /> */}

            </div>

            <div>
              <div className="overflow-x-auto relative pb-[40px]">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-[12px] text-[#84818A] font-[600] border-b-2">
                    <tr className=" color-[#2E2C34;]">
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                        Sr. No.
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]  whitespace-nowrap ">
                        Product Image
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Product Name
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Brand
                      </th>
                      {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Branch
                      </th> */}
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Group Category
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Category
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Sub-Category
                      </th>
                      {/* <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px]">
                        Weight
                      </th> */}
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Total MRP
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Selling Price
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Primary Packaging Unit (Weight)
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Secondary Packaging Unit (Weight)
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] whitespace-nowrap">
                        Stock
                      </th>
                      <th scope="col" className="pb-[28px] pt-[38px] px-6 font-Manrope not-italic font-[600] text-[14px] leading-[20px] text-[#84818A] tracking-[1px] text-center whitespace-nowrap">
                        Enable/Disable
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((item: any, index: number) => (
                      <tr className="border-b">

                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {index + 1}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <img
                            className="w-[50px] rounded-md"
                            src={item.product_upload_mrp_label_image}
                            alt={"Mwb Bazaar"}
                          />
                        </td>

                        <td className="py-4 px-6  color-[#2E2C34;]">{item.product_name}</td>
                        {/* <th
                          scope="row"
                          className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div className="flex gap-[20px] items-center ">
                            <img
                              className="w-[50px] rounded-md"
                              src={item.product_upload_mrp_label_image}
                              alt={"Mwb Bazaar"}
                            />
                            <p
                              onClick={() => hendelopenmodel(item)}
                              className=" text-[14px] font-[600] text-gray-700 font-Manrope dark:text-gray-400 cursor-pointer m-0"
                            >
                              {item.product_name}
                            </p>
                          </div>
                        </th> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">{item.product_brand_name}</td>
                        {/* <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_brand_name}
                        </td> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.category_group?.parent_category_name}</p>
                          </div>
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.category?.category_name}</p>
                          </div>
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <div className="tag">
                            <p className="tagTitle whitespace-nowrap">{item.subcategory?.subcategory_name}</p>
                          </div>
                        </td>
                        {/* <td className="py-4 px-6  color-[#2E2C34;]">{item.product_total_weight_value} {item?.product_per_unit_weight_unit?.unit_name}</td> */}
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_total_mrp}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          {item.product_selling_price}
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                          {item?.product_primary_packing_value} {item?.product_primary_packing_unit?.unit_name} <br />
                          ({item?.product_total_weight_value} {item?.product_total_weight_unit?.unit_name})
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;] whitespace-nowrap">
                          {item?.product_secondary_packing_value} {item?.product_secondary_packing_unit?.unit_name} / {item?.product_primary_packing_unit?.unit_name}
                          <br />
                          ({item?.product_per_unit_weight_value} {item?.product_per_unit_weight_unit?.unit_name} / {item?.product_secondary_packing_unit?.unit_name})
                        </td>
                        <td className="py-4 px-6  color-[#2E2C34;]">
                          <p className="stockStatusTitle whitespace-nowrap">{item.product_stocks} {item?.product_primary_packing_unit?.unit_name}</p>
                        </td>
                        <td className="color-[#2E2C34;]">
                          <div className="actionMenu" style={{
                            width: "225px"
                          }}>
                            <div
                              onClick={() => {
                                setSalesReport({
                                  ...salesReport,
                                  complete: item?.completed_orders,
                                  inProgress: item?.in_progress_orders,
                                  cancel: item?.cancel_count
                                })
                                setAnotherModalOpen(true);
                              }
                              }
                            >
                              <img src={pieIcon} alt={"Logo"} width={50} height={50} />
                            </div>

                            <div
                              onClick={() => {
                                setProductdata(item.id)
                                setAddModalOpen(true)
                              }}
                              className="cursor-pointer"
                            >
                              <img src={barIcon} alt={"Logo"} width={50} height={50} />
                            </div>

                            <div>
                              <Switch actives={item.product_active} />
                            </div>
                            <div onClick={() => navigate("/editproduct/" + item.id)}>
                              <Edit className="cursor-pointer" />
                            </div>
                            <div onClick={() => handleDeleted(item.id)}>
                              <DeleteIcon className="cursor-pointer" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="flex items-center justify-between py-4"
                style={{ display: "flex", marginLeft: 0 }}
              >
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </div>
            </div>

            <ProductDetailModal open={addModalOpen} handleOpen={setAddModalOpen} product_id={productdata} />


            <div>
              <Dialog
                open={anotherModalOpen}
                maxWidth={"lg"}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "6px",
                  },
                }}
                onClose={() => setAnotherModalOpen(false)}
              >
                <div className={`${classes.addDialog} w-[869px] !p-0 !px-[56px]`}>
                  <div className="flex justify-between modalHead items-center">
                    <p className="reportTitle tracking-[1px] py-[35px]">Sales Report</p>
                    <div onClick={() => {
                      setAnotherModalOpen(false)
                      setSalesReport({
                        complete: 0,
                        inProgress: 0,
                        cancel: 0
                      })
                    }}>
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                        <line
                          x1="24.7305"
                          y1="12.4228"
                          x2="12.4268"
                          y2="24.7265"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                        <line
                          x1="24.3104"
                          y1="24.7266"
                          x2="12.0068"
                          y2="12.4229"
                          stroke="#84818A"
                          stroke-width="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="flex gap-10 pt-[20px] py-[100px] items-center">
                    <div className="relative">
                      <div className="text-center absolute w-full h-full z-10 flex flex-col justify-center items-center">
                        <p className="font-bold text-[25px]">
                          {salesReport.complete + salesReport.inProgress + salesReport.cancel}
                        </p>
                        <p>Order</p>
                      </div>
                      {/* <img src={ChartIcon} alt={"chart"} width={'265px'} /> */}

                      <PieChart width={265} height={400} >
                        <Pie
                          data={chartData}
                          cx={120}
                          cy={200}
                          innerRadius={90}
                          outerRadius={100}
                          fill="#8884d8"
                          paddingAngle={2}
                          dataKey="value"
                        >
                          {chartData.map((entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </div>
                    <div className="flex gap-[200px] justify-between">
                      <div>
                        <li className="text-[#00CAAD] py-[10px]">
                          <span className="text-[#2E2C34] font-Manrope font-[500] text-[18px] leading-[31px] tracking-[1px]">Complete</span>
                        </li>
                        <li className="text-[#4D2DA5] py-[10px]">
                          <span className="text-[#2E2C34] font-Manrope font-[500] text-[18px] leading-[31px] tracking-[1px]">In Progress</span>
                        </li>
                        <li className="text-[#FF6457] py-[10px]">
                          <span className="text-[#2E2C34] font-Manrope font-[500] text-[18px] leading-[31px] tracking-[1px]">Cancel</span>
                        </li>
                      </div>
                      <div>
                        <p className="py-[10px] text-[#84818A] font-Manrope font-[500] text-[21px] leading-[31px] tracking-[1px]">{salesReport.complete}</p>
                        <p className="py-[10px] text-[#84818A] font-Manrope font-[500] text-[21px] leading-[31px] tracking-[1px]">{salesReport.inProgress}</p>
                        <p className="py-[10px] text-[#84818A] font-Manrope font-[500] text-[21px] leading-[31px] tracking-[1px]">{salesReport.cancel}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div> :
          <div className="relative w-full">
            <div className="absolute top-[75px] left-[45%] translate-x-[-50%]">
              <div className="flex justify-center">
                <img src={plusImg} alt="" />
              </div>

              <div className="text-center pt-[50px]">
                <p className="font-[700] text-[24px] leading-[42px] text-[#2E2C34] font-Manrope pb-[10px]">Let’s Get Started</p>
                <p className="font-[500] text-[18px] leading-[25px] text-[#84818A] font-Manrope">Start adding products and retailers</p>
              </div>

              <div className="pt-[30px] ">
                <button onClick={() => setselectOptionsModalOpen(true)} className="bg-[#FF6652] mx-auto mb-[15px] rounded-[8px] px-[32px] py-[12px] text-[white] font-[700] text-[16px] leading-[22px] flex items-center font-Manrope "><AddIcon className="me-[10px]" />Add Products</button>
                <Link to={'/retailers'}><button className="bg-[#FF6652] mx-auto rounded-[8px] px-[32px] py-[12px] text-[white] font-[700] text-[16px] leading-[22px] flex items-center font-Manrope"><AddIcon className="me-[10px]" />Add Retailers</button></Link>
              </div>
            </div>
          </div>
        }
        <Dialog
          open={selectOptionsModalOpen}
          maxWidth={"md"}
          sx={{
            ".MuiPaper-root": {
              borderRadius: "6px",
            },
          }}
          onClose={() => setselectOptionsModalOpen(false)}
        >
          <div className={`${classes.addDialog} w-[325px] !p-0 !px-[56px] relative`}>
            <div className="flex justify-between modalHead items-center gap-[1rem]">
              <p className="tracking-[1px] font-bold pt-[35px]">Please Select a Method</p>
              <div onClick={() => setselectOptionsModalOpen(false)} className="absolute top-[1rem] right-[1rem]">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                  <line
                    x1="24.7305"
                    y1="12.4228"
                    x2="12.4268"
                    y2="24.7265"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                  <line
                    x1="24.3104"
                    y1="24.7266"
                    x2="12.0068"
                    y2="12.4229"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>

            <div className="flex gap-10 py-[50px] justify-center items-center">
              <div className="relative">
                <div className="flex flex-col justify-center items-center gap-[0.5rem]">
                  <div className="me-[16px] w-full m-auto">
                    <AddButton
                      label="Add Products"
                      widthFull
                      onClick={() => navigate("/addproduct")}
                    />
                  </div>
                  <p>or</p>
                  <div className="w-full">
                    <AddButton
                      label="Import from Tally"
                      variant="primary"
                      onClick={() => {
                        dispatch(GetTallyCredentials())
                        setTallyModal(true)
                      }
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </DashboardLayout>
    </>
  );
};

export default Products;
