import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useWholesalerDetailsStyle } from "@/static/stylesheets/screens";
import { MdArrowBackIosNew } from "react-icons/md";
import { Avatar } from "@mui/material";
import { FeaturesTab } from "@/components/organisms/FeaturesTab";
import { FaUserAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
// import WholesalerProfile from "./WholesalerProfile";
import { Grid } from "@mui/material";
import iconm from "@/static/images/Group 36025.png";
import moment from "moment";
import RetailerProfile from "./RetailerProfile";
import RetailerReports from "./RetailerReports";
import RetailerPayments from "./RetailerPayments";
import RetailerOrders from "./RetailerOrders";
// import WholesalerRetailer from "./WholesalerRetailer";
// import WholesalerBranches from "./WholesalerBranches";
// import WholesalerProducts from "./WholesalerProducts";
// import WholesalerReport from "./WholesalerReport";

const RetailerDetails = (props: any) => {
  const classes = useWholesalerDetailsStyle();
  const navigate = useNavigate();
  const params = useParams();
  const {id} = params
  const [data, setData] = useState<any>();
  console.log('data', data)
  const handleModalBackdrop = props?.handleModalBackdrop

  const getsingleRetailer = async (id: any) => {
    try {
      const response = await AppService.getRetailerById(id);
      setData(response.data);
    } catch (error) {
      console.log("errrrrrr in kyc", error);
    }
  };

  useEffect(() => {
    getsingleRetailer(id);
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className={classes.root}>
          <div>
            {/*Grid Box*/}
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={3}>
                {/*portion one*/}
                <div className="flex gap-4">
                  <MdArrowBackIosNew className="cursor-pointer" style={{ color: "#84818A" }} onClick={() => navigate('/retailers')} />
                  <div>
                    {data?.retailer_image ? <img src={data?.retailer_image} style={{ width: "80px", height: "80px", borderRadius: "20px", border: "1px solid #e1e1e1" }} /> : <Avatar sx={{ width: 80, height: 80, borderRadius: 4 }}>Logo</Avatar>}
                    <p className="agentHeadtitle py-[5px]" style={{ textTransform: "capitalize", paddingTop: "10px" }}>{data?.retailer_firm_name}</p>
                    <p className="agentSubtitle py-[5px]">Retailer</p>
                    <div className="statusTitle text-center cursor-pointer mt-[7px] rounded-md">
                      {data?.retailer_status == 'CREATED' && <div className="bg-[#e5f5ff] flex justify-center rounded-md p-[10px] w-[150px]">
                        <p className="text-[#28a1ff]">{data?.retailer_status}</p>
                      </div>}
                      {data?.retailer_status == 'PENDING' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
                        <p className="text-[#FFA043]">{data?.retailer_status}</p>
                      </div>}
                      {data?.retailer_status == 'KYCAPPROVED' && <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]">
                        <p className="text-[#00e0c0]">{data?.retailer_status}</p>
                      </div>}
                      {data?.retailer_status == 'KYCREJECTED' && <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]">
                        <p className="text-[#ff0000]">{data?.retailer_status}</p>
                      </div>}
                      {data?.retailer_status == 'APPROVED' && <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
                        <p className="text-[#FFA043]">{data?.retailer_status}</p>
                      </div>}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={9} md={9} sm={9}>
                {/*portion two*/}
                <Grid container spacing={2}>
                <Grid item lg={7} md={7} sm={7}>
                    <div className="p-[20px] rounded-md w-full" style={{ display: "inline-block", height:"100%", border: "1px solid #e1e1e1" }}>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                          <Avatar sx={{ width: 32, height: 32, borderRadius: "8px", background: "#f6f6f6" }}>
                              <FaUserAlt color="#86838b"/>
                            </Avatar>
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Contact Person</p>
                            <p className="agentSubtitle">{data?.retailer_contact_per}</p>
                          </div>
                        </div>

                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                          <Avatar sx={{ width: 32, height: 32, borderRadius: "8px", background: "#f6f6f6" }}>
                              <HiPhone color="#86838b"/>
                            </Avatar>
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Phone Number</p>
                            <p className="agentSubtitle">{data?.retailer_altranate_number}</p>
                          </div>
                        </div>

                        <div className="flex gap-5 col-span-2" style={{ alignItems: "center" }}>
                          <div>
                          <Avatar sx={{ width: 32, height: 32, borderRadius: "8px", background: "#f6f6f6" }}>
                              <FaUserAlt color="#86838b"/>
                            </Avatar>
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Firm Address</p>
                            <p className="agentSubtitle">{data?.retailer_address}</p>
                          </div>
                        </div>

                      </div>
                      
                    </div>
                  </Grid>
                 
                  <Grid item lg={5} md={5} sm={5}>
                    <div className="p-[20px] rounded-md" style={{ display: "inline-block", height:"100%", border: "1px solid #e1e1e1" }}>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                            <img src={iconm} alt="icon" />
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Plan</p>
                            <p className="agentSubtitle">{data?.retailer_plan}</p>
                          </div>
                        </div>

                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                            <img src={iconm} alt="icon" />
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Credit Amount</p>
                            <p className="agentSubtitle">Rs. {data?.retailer_credit_amount || 0}</p>
                          </div>
                        </div>

                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                            <img src={iconm} alt="icon" />
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Credit Days</p>
                            <p className="agentSubtitle">{data?.retailer_credit_days || 0} Days</p>
                          </div>
                        </div>

                        <div className="flex gap-5" style={{ alignItems: "center" }}>
                          <div>
                            <img src={iconm} alt="icon" />
                          </div>
                          <div>
                            <p className="agentTitle" style={{ paddingBottom: "5px" }}>Credit Bills Allowed</p>
                            <p className="agentSubtitle">Rs. {data?.retailer_credit_limit || 0}</p>
                          </div>
                        </div>

                      </div>
                      
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/*Tab*/}
          <div className="mt-[50px] border-t-2">
            <div className="pt-[20px]">
              <FeaturesTab
              value={0}
                items={[
                  {
                    label: "Profile",
                    content: <RetailerProfile data={data} id={id} />,
                  },
                  {
                    label: "Orders",
                    content: <RetailerOrders id={id}  />,
                  },
                  {
                    label: "Payments",
                    content: <RetailerPayments id={id}  handleModalBackdrop={handleModalBackdrop} />,
                  },
                  {
                    label: "Reports",
                    content: <RetailerReports id={id}  />,
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </DashboardLayout >
    </>
  );
};

export default RetailerDetails;
