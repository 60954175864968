import { api } from "@/Axios/api"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { json } from "react-router-dom"
import { toast } from "react-toastify";

export const CreateProduct: any = createAsyncThunk('CreateProduct', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/product/data/`, 'POST', data.data);
    toast.success("success!")
    data.navigate('/wholesellerproducts')
    return responce.data
  }
  catch (error : any) {
    toast.error(error.response.statusText)
    return rejectWithValue(error)
  }
})


type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const CreateProductdata = createSlice({
  name: 'CreateProduct',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateProduct.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateProduct.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateProduct.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default CreateProduct