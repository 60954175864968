import React, { useState, useEffect } from "react";
import { Dialog, Grid, Pagination, SelectChangeEvent, MenuItem, Select, TextField, Box } from "@mui/material";

import barCodeImage from "@/static/images/mwb_barcode.png";
import { AppDispatch, useDispatch, useSelector } from "@/Store";
import { useProductsStyles } from "@/static/stylesheets/screens/productsStyles";
import { ProductDetail } from "@/Store/Sclicese/ProductDetail";
import Barcode from "react-barcode";

type props = {
  product_id: any,
  open: any,
  handleOpen: any
}
const ProductDetailModal = ({ product_id, open, handleOpen }: props) => {
  const dispatch = useDispatch()
  const classes = useProductsStyles();
  const productdata = useSelector(state => state.ProductDetaildata.data)
  console.log(product_id, "-------")
  useEffect(() => {
    if (product_id) {
      dispatch(ProductDetail(product_id))
    }
  }, [product_id])

  return (
    <div>
      <Dialog
        open={open}
        maxWidth={"lg"}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "6px",
          },
        }}
        onClose={() => handleOpen(false)}
      >
        <div className={classes.addDialog}>
          <div className="pb-[10px] flex justify-end modalHead">
            <div className="flex w-full justify-end">
              <div onClick={() => handleOpen(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                  <line
                    x1="24.7305"
                    y1="12.423"
                    x2="12.4268"
                    y2="24.7266"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                  <line
                    x1="24.3104"
                    y1="24.7266"
                    x2="12.0068"
                    y2="12.4229"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="flex gap-5 pt-[20px]">
            <div>
              <img src={productdata.product_upload_front_image} alt={"Product"} />
            </div>
            <div>
              <img src={productdata.product_upload_front_image} alt={"Product"} />
            </div>
            <div>
              <img src={productdata.product_upload_front_image} alt={"Product"} />
            </div>
          </div>

          <div className="flex justify-between pt-[20px]">
            <div>
              <p className="modalTitle tracking-[1px]">
                {productdata?.product_name}
              </p>
              <p className="modalSubtitile tracking-[1px]">Brand- {productdata?.product_brand_name}</p>
              <p className="modalPrize tracking-[1px]">Base Price: Rs. {productdata?.product_selling_price}</p>

              <div className="flex items-center gap-8">
                <div className="flex gap-5 items-center">
                  <p className="groupTitle tracking-[1px]">Group</p>
                  <div className="statusTag">
                    <p className="tagTitle tracking-[1px]">{productdata?.category_group?.parent_category_name}</p>
                  </div>
                </div>

                <div className="flex gap-5 items-center">
                  <p className="groupTitle tracking-[1px]">Category</p>
                  <div className="statusTag">
                    <p className="tagTitle tracking-[1px]">{productdata?.category?.category_name}</p>
                  </div>
                </div>

                <div className="flex gap-5 items-center">
                  <p className="groupTitle tracking-[1px]">Sub Category</p>
                  <div className="statusTag">
                    <p className="tagTitle tracking-[1px]">{productdata?.subcategory?.subcategory_name}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="barCode">
              {/* <img src={barCodeImage} alt={"Go Back"} /> */}
              <Barcode value={productdata?.product_barcode_number} />
            </div>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Price Details</p>
            <div className="flex gap-5">
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Total MRP</p>
                <p>{productdata?.product_total_mrp}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Selling Price</p>
                <p>{productdata?.product_selling_price}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Unit Price</p>
                <p> {productdata?.product_selling_price / productdata?.product_secondary_packing_value}</p>
              </div>
            </div>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Total Weight Details</p>
            <div className="flex gap-5">
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Total Weight</p>
                <p>{productdata?.product_total_weight_value} {productdata?.product_total_weight_unit?.unit_name}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Primary Packaging</p>
                <p>{productdata?.product_primary_packing_value} {productdata?.product_primary_packing_unit?.unit_name}</p>
              </div>
              {/* <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Total MRP</p>
                <p>₹ {productdata?.product_total_mrp}</p>
              </div> */}
            </div>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Unit Weight Detail</p>
            <div className="flex gap-5">
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Per Unit Weight</p>
                <p>{productdata?.product_per_unit_weight_value} {productdata?.product_per_unit_weight_unit?.unit_name}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">No. of Units</p>
                <p>{productdata?.product_secondary_packing_value} {productdata?.product_secondary_packing_unit?.unit_name}</p>
              </div>
              {/* <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">MRP</p>
                <p>₹ {productdata?.product_per_unit_price}</p>
              </div> */}
            </div>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Total Weight in Quital : {productdata?.product_total_weight_unit?.id === 14 ? productdata?.product_total_weight_value * 100 : productdata?.id === 13 ? productdata?.product_total_weight_value * 10000 : ""} Quintal</p>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Tax Details</p>
            <div className="flex gap-5">
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">GST</p>
                <p>{productdata?.product_gst_no}%</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">HSN Code</p>
                <p>{productdata?.product_hsn_code}</p>
              </div>
            </div>
          </div>

          <div className="py-[20px]">
            <p className="commonTitle tracking-[1px]">Product Details</p>
            <div className="flex gap-5">
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Product Min Quantity</p>
                <p>{productdata?.product_min_quantity} {productdata?.product_primary_packing_unit?.unit_name}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Product Max Quantity</p>
                <p>{productdata?.product_max_quantity} {productdata?.product_primary_packing_unit?.unit_name}</p>
              </div>
              <div className="me-6">
                <p className="commonSybtitle pt-[8px] tracking-[1px]">Current Stock</p>
                <p>{productdata?.product_stocks} {productdata?.product_primary_packing_unit?.unit_name}</p>
              </div>
            </div>
          </div>


        </div>
      </Dialog>
    </div>
  );
};

export default ProductDetailModal;
