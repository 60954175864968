import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useBranchPlanStyles } from "@/static/stylesheets/screens";
import { PlanLogDataTable } from "@/components/molecules/PlanLogDataTable";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { CategorySlice, clearCategoryState } from "@/Store/Sclicese/CategorySlice";
import { SubCategorySlice, clearSubCategoryState } from "@/Store/Sclicese/SubCategorySlice";
import { useDispatch, useSelector } from "@/Store";
import { AppDispatch } from "@/Store";
import { Box, Checkbox, Dialog, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, Select, SelectChangeEvent } from "@mui/material";
import { GetCategoryListPlan, GetCategoryPlan, SubCategoryListPlan, SubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { ItemWisePlan } from "@/Store/Sclicese/PlanSlice";
import { GetBranchList, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import { SearchField } from "@/components/atoms/SearchField";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { MasterRetailerType } from "@/Store/Sclicese/MasterDataSlice";
import LogItemRow from "./LogItemRow";




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const BranchPlanLog = ({open,handleOpen}: {
  open : boolean,
  handleOpen : any
}) => {
  const GetCategoryPlanListData: any = useSelector<any>(state => state?.GetCategoryListPlanData?.data)
  const SubCategoryPlanListData: any = useSelector<any>(state => state?.SubCategoryListPlanData?.data)
  const GetCategoryPlanData: any = useSelector<any>(state => state?.GetCategoryPlanData?.data?.results)
  const SubCategoryPlanData: any = useSelector<any>(state => state?.SubCategoryPlanData?.data?.results)
  const ItemWisePlanData: any = useSelector<any>(state => state?.ItemWisePlanData?.data?.results)
  const GetBranch: any = useSelector<any>(state => state?.GetBranch?.data?.results)
  const [CategoryValue, setCategoryValue] = React.useState<string[]>(["All_Category"]);
  const [SubCategoryValue, setSubCategoryValue] = React.useState<string[]>([]);
  const [ItemWiseValue, setItemWiseValue] = React.useState<string[]>([]);
  const [MapData, setMapData] = useState<any>([]);
  const dispatch = useDispatch()
  const bazaarId = JSON.parse(localStorage.getItem("bazaarId") || "")
  const CategoryGroupData = useSelector(state => state?.CategoryGroup?.data?.results)

  const CategoryData = useSelector(state => state?.CategoryData?.data?.results)
  const SubCategoryData = useSelector(state => state?.SubCategoryData?.data?.results)
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)




  // useEffect(()=>{
  //   if(Array.isArray(CategoryGroupData) && CategoryGroupData.length > 0){
  //     handleGroupClick(CategoryGroupData[0])
  //   }
  // },[CategoryGroupData])

  // useEffect(()=>{
  //   if(Array.isArray(CategoryData) && CategoryData.length > 0){
  //     handleCategoryClick(CategoryData[0])
  //   }
  // },[CategoryData])

  // useEffect(()=>{
  //   if(Array.isArray(SubCategoryData) && SubCategoryData.length > 0){
  //     handleSubCategoryClick(SubCategoryData[0])
  //   }
  // },[SubCategoryData])




  useEffect(() => {
    dispatch(GetBranchList())
    dispatch(MasterRetailerType())
  }, [])

  useEffect(() => {
    dispatch(CategoryGroup(bazaarId))
  }, [])

  useEffect(() => {
    if (bazaarId) {
      // dispatch(GetCategoryListPlan(bazaarId))
      // dispatch(SubCategoryListPlan(bazaarId))
    }
    dispatch(GetCategoryPlan())
    dispatch(SubCategoryPlan())
    // dispatch(ItemWisePlan())
  }, [bazaarId])

  // useEffect(() => {
  //   if (GetCategoryPlanListData?.length && GetCategoryPlanData) {
  //     let array: any = []
  //     GetCategoryPlanListData[0]?.map((x: any) => {
  //       GetCategoryPlanData?.map((z: any) => {
  //         if (x.id == z.category) {
  //           array.push(z)
  //         }
  //       })
  //     })
  //     setMapData(array)
  //   }

  // }, [GetCategoryPlanListData?.length, GetCategoryPlanData])

  useEffect(() => {
    if (CategoryValue.includes('All_Category')) {
      let array: any = []
      GetCategoryPlanListData[0]?.map((x: any) => {
        GetCategoryPlanData?.map((z: any) => {
          if (x.id == z.category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (SubCategoryValue.includes("All_Sub-Categories")) {
      let array: any = []
      SubCategoryPlanListData[0]?.map((x: any) => {
        SubCategoryPlanData?.map((z: any) => {
          if (x.id == z.sub_category) {
            array.push(z)
          }
        })
      })
      setMapData(array)
    }
    if (ItemWiseValue.includes("All_items")) {
      ItemWisePlanData?.map((item: any) => {
        setMapData([...item])
      })
    }
  }, [CategoryValue, SubCategoryValue, ItemWiseValue, GetCategoryPlanListData, GetCategoryPlanData])


  const groupCategoryFilterOptions = CategoryGroupData?.map((item: any) => ({
    label: item?.parent_category_name,
    value: item?.id
  }))

  const categoryFilterOptions = CategoryData?.map((item: any) => ({
    label: item?.category_name,
    value: item?.id
  }))

  const subCategoryFilterOptions = SubCategoryData?.map((item: any) => ({
    label: item?.subcategory_name,
    value: item?.id
  }))

  const branchFilterOptions = GetBranch?.map((item: any) => ({
    label: item?.branch_name,
    value: item?.id
  }))

  const [filterData, setFilterData] = useState({
    group_category: null,
    category: null,
    sub_category: null,
    retailer: null,
    branch: null,
    mode:'Category'
  })

  useEffect(()=>{
    if(Array.isArray(GetBranch) && GetBranch.length > 0){
      setFilterData({
        ...filterData,
        branch: GetBranch.filter((brn:any) => brn.main_branch)[0]?.id
      })
    }
  },[GetBranch])

  useEffect(()=>{
    if(Array.isArray(MasterRetailerTypeData) && MasterRetailerTypeData.length > 0){
      setFilterData({
        ...filterData,
        retailer: MasterRetailerTypeData[0]?.id
      })
    }
  },[MasterRetailerTypeData])

  const handleChangeMaster = (selectboxName: string, id: any) => {

    if (selectboxName === 'Group Category') {

      dispatch(clearCategoryState())
      dispatch(clearSubCategoryState())
      dispatch(CategorySlice({ bazaarId: bazaarId, id }))
      setFilterData({
        ...filterData,
        group_category: id,
        category: null,
        mode:'Category'
      })
    }
    if (selectboxName === 'Category') {
      dispatch(clearSubCategoryState())
      dispatch(SubCategorySlice({ bazaarId: bazaarId, category: id, id: filterData.group_category }))
      setFilterData({
        ...filterData,
        category: id,
        mode:'Sub Category'
      })
    }
    if (selectboxName === 'Sub Category') {
      setFilterData({
        ...filterData,
        sub_category: id
      })
    }

    if (selectboxName === 'Branch') {
      setFilterData({
        // ...filterData,
        mode:filterData.mode,
        group_category: filterData.group_category,
        category: filterData.category,
        sub_category: filterData.sub_category,
        retailer: filterData.retailer,
        branch: id
      })

    }

  }
  return (
    <>
      <Dialog
      open={open}
      maxWidth={"lg"}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "6px",
        },
      }}
      >
      <div className="p-10">
          {/* <div className="flex mb-[32px] items-center justify-between">
            <p className="pageTitle" style={{fontSize : '30px'}} >Log</p>
          </div> */}
          <div className="pb-[10px] flex justify-end modalHead">
          <p className="pageTitle" style={{fontSize : '30px'}} >Log</p>
            <div className="flex w-full justify-end">
              <div onClick={() => handleOpen(false)}>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                  <line
                    x1="24.7305"
                    y1="12.423"
                    x2="12.4268"
                    y2="24.7266"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                  <line
                    x1="24.3104"
                    y1="24.7266"
                    x2="12.0068"
                    y2="12.4229"
                    stroke="#84818A"
                    stroke-width="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex py-5 justify-between">
            <div className="flex gap-5">
              {groupCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Group Category"}
                hint={"Select Group Category"}
                options={groupCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={groupCategoryFilterOptions?.filter((item: any) => filterData.group_category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {categoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Category"}
                hint={"Select Category"}
                options={categoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={categoryFilterOptions?.filter((item: any) => filterData.category == item?.value).map((elm: any) => elm?.value)}
                colorcode={'#4e2fa9'}
                showSelectedLable
              />}
              {/* {subCategoryFilterOptions?.length > 0 && <CommonSelectElectronicBazaar
                label={"Sub Category"}
                hint={"Select Sub Category"}
                options={subCategoryFilterOptions}
                handleSelect={handleChangeMaster}
                selectedIds={subCategoryFilterOptions?.filter((item: any) => filterData.sub_category == item?.value).map((elm: any) => elm?.value)}
              // colorcode={'#4e2fa9'} 
              />} */}
            </div>
          </div>
          <div>
            <div className="overflow-x-auto relative pt-[10px] pb-[40px]">
              <table className="w-full text-sm text-left text-gray-500 ">
                <thead className="text-[12px] text-gray-700 bg-gray-50 font-[600] bg-[#f9f5f2]">
                  <tr className="tableTitle color-[#2E2C34;]">
                    <th scope="col" className="py-3 px-3">
                      CATEGORY
                    </th>
                    <th scope="col" className="py-3 px-6">
                      CASH
                    </th>
                    <th scope="col" className="py-3 px-6">
                      PLATINUM
                    </th>
                    <th scope="col" className="py-3 px-6">
                      DIAMOND
                    </th>
                    <th scope="col" className="py-3 px-6">
                      GOLD
                    </th>
                    <th scope="col" className="py-3 px-6">
                      BRONZE
                    </th>
                    <th scope="col" className="py-3 px-3">
                      LAST UPDATED
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterData.mode === 'Category' && CategoryData?.map((item: any) => {
                    const isExistPlan = GetCategoryPlanData?.filter((itm: any) => itm.category === item.id && itm.branch === filterData.branch)[0] || {}
                    return (
                      <LogItemRow title={item.category_name} updated_date={item.updated_date} {...isExistPlan} retailer_type={[filterData.retailer]}
                      branch={filterData.branch} category={item.id} key={'cat'+item.id} />
                    )
                  })}
                  {filterData.mode === 'Sub Category' && SubCategoryData?.map((item: any) => {
                    const isExistPlan = SubCategoryPlanData?.filter((itm: any) => itm.sub_category === item.id && itm.branch === filterData.branch)[0] || {}
                    return (
                      <LogItemRow title={item.subcategory_name} updated_date={item.updated_date} {...isExistPlan} retailer_type={[filterData.retailer]}
                      branch={filterData.branch} sub_category={item.id} key={item.id} />
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default BranchPlanLog;
