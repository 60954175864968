import { combineReducers } from "@reduxjs/toolkit";
import { WholesellerNumberVerify } from './Sclicese/WholesellerNumberVerify'
import { WholesellerProducts } from './Sclicese/WholesellerProducts'
import { SaidBarItems } from './Sclicese/SaidBarItems'
import { GroupCategoryData } from './Sclicese/GroupCategorySlice'
import { ProductBazaarListReducers } from './Sclicese/BazaarListSlice'
import { OtpVerificationReducers } from './Sclicese/OtpVerificationSlice'
import { CategoryGroupSliceReducers } from './Sclicese/CategoryGroupSlice'
import { CategorySliceData } from './Sclicese/CategorySlice'
import { SubCategorySliceData } from './Sclicese/SubCategorySlice'
import { CreateProductdata } from './Sclicese/CreateProduct'
import { Deleteproductdata } from './Sclicese/Deleteproducts'
import { FilteredProductsReducer } from './Sclicese/filteredProductsSlice'
import { PlanSclicesedata } from './Sclicese/PlanSclicese'
import { PlanSearchdata } from './Sclicese/PlanSearchSclicese'
import { PlanDeletedata } from './Sclicese/PlanDeleteSclice'
import { CreateOffersReducers, DetailOffersReducers, GetOffersReducers, SearchOffersdata } from './Sclicese/GetOffersSlice'
import { AgentListReducers, AgentSingleSlice, CreateAgentReducers, DeleteAgentReducers, EditAgentReducers } from './Sclicese/AgentListSlice'
import { StateListReducers } from './Sclicese/StateListSlice'
import { FilteredDistrictListReducers } from './Sclicese/FilteredDistrictListSlice'
import { FilteredCityListReducers } from './Sclicese/FilteredCityListSlice'
import { GetRetailerdata, SearchRetailerdata, getIdRetaierdata, getretailerNumberdata } from './Sclicese/Retailerssclices'
import { BranchListReducers, Createbranchdata, GetoneBranchListReducers } from './Sclicese/BranchSlice'
import { CityListReducers } from './Sclicese/CityListSlice'
// import { StateListReducersdata } from './Sclicese/StateListSclice'
import { DistrictListReducers } from './Sclicese/DistrictListSlice'
import { Wholesellerdata } from './Sclicese/WholesellerSlice'
import { TopProductsListdata, categoriesListdata, subCategoriesListdata ,adsPerformancedata ,topBranchesdata, totalProductdata,newRetailersdata ,totalOrderdata , totalIncomedata, DashboardReportSlice} from './Sclicese/DashboardSclice'
import { Searchproductdata } from './Sclicese/SearchProduct'
import { CreateCategoryPlanData, CreateItemWisePlanData, EditCategoryPlanData, EditItemWisePlan, EditItemWisePlanData, EditSubCategoryPlanData, GetCategoryListPlanData, GetCategoryPlanData, ItemWisePlanData, SubCategoryListPlanData, SubCategoryPlanData, createSubCategoryPlanData } from './Sclicese/PlanSlice'
import { MasterRetailerTypeData, MasterUnitData } from "./Sclicese/MasterDataSlice";
import { TallyServiceData } from "./Sclicese/TallyService";
import { CreateProductPricingData, EditProductPricingData, GetGetProductPricingData, ProductDetaildata } from "./Sclicese/ProductDetail";
import { CreateCartData, CreateSubCartData, EditCartData, EditSubCartData, GetOutForDeliveryData, GetPhotoOrderSlice, OrderData, SingleOrderData, SubCartData } from "./Sclicese/OrderDataSlice";


const rootReducer = combineReducers({
    WholesellerNumber: WholesellerNumberVerify.reducer,
    OtpVerification: OtpVerificationReducers.reducer,
    Wholesellerproduct: WholesellerProducts.reducer,
    SaidbarApidata: SaidBarItems.reducer,
    ProductBazaarList: ProductBazaarListReducers.reducer,
    Searchproductdata : Searchproductdata.reducer,
    GroupCategoryData: GroupCategoryData.reducer,
    CategoryGroup: CategoryGroupSliceReducers.reducer,
    CategoryData: CategorySliceData.reducer,
    SubCategoryData: SubCategorySliceData.reducer,
    CreateProductdata: CreateProductdata.reducer,
    Deleteproductdata: Deleteproductdata.reducer,
    FilteredProductsReducer: FilteredProductsReducer.reducer,
    PlanSclicesedata: PlanSclicesedata.reducer,
    PlanSearchdata: PlanSearchdata.reducer,
    PlanDeletedata: PlanDeletedata.reducer,
    GetOffersData: GetOffersReducers.reducer,
    CreateOffers: CreateOffersReducers.reducer,
    DetailOffersReducers : DetailOffersReducers.reducer,
    AgentListReducers: AgentListReducers.reducer,
    StateListData: StateListReducers.reducer,
    FilteredDistrictListReducers: FilteredDistrictListReducers.reducer,
    FilteredCityListReducers: FilteredCityListReducers.reducer,
    GetRetailerdata: GetRetailerdata.reducer,
    SearchRetailerdata: SearchRetailerdata.reducer,
    SearchOffersdata: SearchOffersdata.reducer,
    GetBranch: BranchListReducers.reducer,
    GetoneBranchListReducers : GetoneBranchListReducers.reducer,
    Createbranchdata:Createbranchdata.reducer,
    CityListData: CityListReducers.reducer,
    // StateListData: StateListReducersdata.reducer,
    DistrictListdata: DistrictListReducers.reducer,
    getIdRetaierdata: getIdRetaierdata.reducer,
    Wholesellerdata: Wholesellerdata.reducer,
    CreateAgentData: CreateAgentReducers.reducer,
    DeleteAgentData: DeleteAgentReducers.reducer,
    EditAgentData: EditAgentReducers.reducer,
    TopProductsListdata : TopProductsListdata.reducer,
    categoriesListdata: categoriesListdata.reducer,
    subCategoriesListdata: subCategoriesListdata.reducer,
    adsPerformancedata : adsPerformancedata.reducer,
    topBranchesdata : topBranchesdata.reducer,
    totalProductdata : totalProductdata.reducer,
    totalOrderdata : totalOrderdata.reducer,
    totalIncomedata : totalIncomedata.reducer,
    newRetailersdata : newRetailersdata.reducer,
    GetCategoryPlanData : GetCategoryPlanData.reducer,
    SubCategoryPlanData : SubCategoryPlanData.reducer,
    GetCategoryListPlanData : GetCategoryListPlanData.reducer,
    SubCategoryListPlanData : SubCategoryListPlanData.reducer,
    ItemWisePlanData : ItemWisePlanData.reducer,
    getretailerNumberdata : getretailerNumberdata.reducer, 
    MasterUnitData:MasterUnitData.reducer,
    MasterRetailerTypeData:MasterRetailerTypeData.reducer,
    TallyServiceData: TallyServiceData.reducer,
    CreateCategoryPlanData: CreateCategoryPlanData.reducer,
    EditCategoryPlanData: EditCategoryPlanData.reducer,
    createSubCategoryPlanData: createSubCategoryPlanData.reducer,
    EditSubCategoryPlanData: EditSubCategoryPlanData.reducer,
    ProductDetaildata: ProductDetaildata.reducer,
    CreateItemWisePlanData: CreateItemWisePlanData.reducer,
    EditItemWisePlan: EditItemWisePlanData.reducer,
    OrderData: OrderData.reducer,
    CreateSubCartData:CreateSubCartData.reducer,
    CreateCartData: CreateCartData.reducer,
    SingleOrderData: SingleOrderData.reducer,
    EditCartData:EditCartData.reducer,
    EditSubCartData:EditSubCartData.reducer,
    SubCartData: SubCartData.reducer,
    GetGetProductPricingData: GetGetProductPricingData.reducer,
    CreateProductPricingData: CreateProductPricingData.reducer,
    EditProductPricingData: EditProductPricingData.reducer,
    GetOutForDeliveryData: GetOutForDeliveryData.reducer,
    SingleAgentData:AgentSingleSlice.reducer,
    photoOrderList:GetPhotoOrderSlice.reducer,
    DashboardReport:DashboardReportSlice.reducer
  });
  
  export default rootReducer;