import { SearchField } from '@/components/atoms/SearchField';
import { AppService } from '@/service/AllApiData.service';
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react'


interface props {
    id: any,
    handleModalBackdrop?: any
}
export default function RetailerPayments({ id, handleModalBackdrop }: props) {

    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState<number>(currentYear);
    const [search, seatSearch] = useState("");
    const years = Array.from({ length: currentYear - 2000 + 1 }, (_, index) => 2000 + index);

    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedYear(event.target.value as number);
    };
    const [data, setData] = useState<any>([]);
    const [ totalOrdersAmount, setTotalOrderAmount ] = useState<any>(0);
    const [ totalOrders, setTotalOrders ] = useState<any>(0);

    const getPayments = async (param={}) => {
        let params = {
            ...param,
            wholeseller: id,
        }
        const response = await AppService.getWholesalerPayments(params);
        setData(response.data.results);
    }

    const getOrders = async (param={}) => {
        let params = {
            ...param,
            wholeseller: id,
        }
        const response = await AppService.getOrders(params);
        setTotalOrders(response.data.results?.length);
        let amount = 0;
        response.data.results.map((item:any,index:number)=>{
            amount = amount+item.payment_amount
        })
        setTotalOrderAmount(amount);
    }


    useEffect(()=>{
        let params:any = {
            year: selectedYear
        }
        getOrders(params);
        if(search){
            params = {
                ...params,
                search
            }
        }
        getPayments(params)
    },[search,selectedYear])

    return (
        <>
            <div className='w-full  flex justify-between items-center mb-10'>
                <div>
                    <FormControl>
                        <Select
                            labelId="year-select-label"
                            id="year-select"
                            value={selectedYear}
                            onChange={(e: any) => handleYearChange(e)}
                            className="year-selection"
                            style={{ padding: "3px 15px", paddingLeft: "5px" }}
                        >
                            {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <button className=' bg-[#5542F6] text-[#fff] text-center rounded py-[7px] px-[24px] ml-4'>Update</button>
                </div>


                <SearchField inputdata={seatSearch} />

            </div>

            <div style={{ background: "#F7F7F7" }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                        <div style={{ padding: "20px 24px", borderRight: "1px solid #e1e1e1" }}>
                            <p className="statusTitle" style={{ color: "#2E2C34" }}>Total Orders</p>
                            <div className="flex gap-3 pt-[15px]">
                                <p className="blancetitle">{totalOrders}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop: 0 }}>
                        <div style={{ padding: "20px 24px" }}>
                            <p className="statusTitle" style={{ color: "#2E2C34" }}>Order Amount</p>
                            <div className="flex gap-3 pt-[15px]">
                                <p className="blancetitle">₹ {totalOrdersAmount}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>


            <div className="citycontainer p-[20px] mt-5" style={{ paddingTop: 0, height: "100%" }}>
                <div className="flex justify-between py-[20px]">
                    <p className="cityThead">Transaction History</p>
                </div>
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b">
                                <th className="text-start py-3 cityTablehead">Date</th>
                                <th className="text-center py-3 cityTablehead">Details</th>
                                <th className="text-center py-3 cityTablehead">Transaction Id</th>
                                <th className="text-end py-3 cityTablehead">Amount</th>
                                <th className="text-end py-3 cityTablehead">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.length ?
                                    data?.map((item: any, index: number) => (
                                        <tr className='border-b'>
                                            <td className='py-4 text-start'>{item.payment_date}</td>
                                            <td className='py-4 text-center'>{item.paid_to}</td>
                                            <td className='py-4 text-center'>{item.id}</td>
                                            <td className='py-4 text-center'>{item.amount} ₹</td>
                                            <td className='py-4 flex justify-end items-center'>
                                                {
                                                    item?.payment_received ?
                                                        <div className={`bg-[#00A5FF1A] text-[#00A5FF] flex justify-center rounded-md py-[4px] px-[8px] w-[max-content]`}>
                                                            <p
                                                                className="cursor-pointer text-[#00A5FF] font-semibold text-[12px] m-0"
                                                            >
                                                                PAID
                                                            </p>
                                                        </div>
                                                        :
                                                        <div className={`bg-[#FFA0431A] text-[#FFA043] flex justify-center rounded-md py-[4px] px-[8px] w-[max-content]`}>
                                                            <p
                                                                className="cursor-pointer text-[#FFA043] font-semibold text-[12px] m-0"
                                                            >
                                                                PENDING
                                                            </p>
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <p className='text-center' style={{ paddingTop: "15px", fontSize: "14px" }}>No Transaction yet</p>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}