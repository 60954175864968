import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { useaddBranchStyles } from "@/static/stylesheets/screens";
import ArrowIcon from "@/static/svg/ic_arrow.svg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "@/Store";
import { CategorySlice } from "@/Store/Sclicese/CategorySlice";
import { AppDispatch } from "@/Store";
import { ProductBazaarList } from "@/Store/Sclicese/BazaarListSlice";
import { CategoryGroup } from "@/Store/Sclicese/CategoryGroupSlice";
import { GroupCategory } from "@/Store/Sclicese/GroupCategorySlice";
import { SubCategorySlice } from "@/Store/Sclicese/SubCategorySlice";
import { GetCityList, GetCityListFromDistrict } from "@/Store/Sclicese/CityListSlice";
import { GetstateList } from "@/Store/Sclicese/StateListSlice";
import { GetdistrictList, GetdistrictListFromState } from "@/Store/Sclicese/DistrictListSlice";
import { GetWholeseller } from "@/Store/Sclicese/WholesellerSlice";
import { Createbranch, Editbrach, GetoneBranchList } from "@/Store/Sclicese/BranchSlice";
import PhoneInput from "react-phone-input-2";
import { string } from "prop-types";
import { ListItemDecorator } from "@mui/joy";

const AddBranch = () => {
  const classes = useaddBranchStyles();
  const { id } = useParams()
  const dispatch = useDispatch();
  const nevigate = useNavigate();
  const [newBranch, setNewBranch] = useState<any>({ branch_name: "", manager_name: "", branch_phone: "", category_name: "", subcategory_name: "", item_name: "", address: "", address_line2: "", landmark: "", pincode_no: "", created_at: "", city: "", state: "", district: "", branch_wholeseller: "" });
  const [categoryId, setCategoryId] = useState();
  const ProductBazaar: any = useSelector(state => state?.ProductBazaarList?.data?.results)
  const parentCategory: any = useSelector(state => state?.GroupCategoryData?.data?.results)
  const CategoryData: any = useSelector(state => state?.CategoryGroup?.data?.results)
  const SubCategoryData: any = useSelector(state => state?.SubCategoryData?.data?.results)
  // const CityListData: any = useSelector(state => state?.CityListData?.data)
  // const DistrictListData: any = useSelector(state => state?.DistrictListdata?.data)
  // const StateListData: any = useSelector(state => state?.StateListData?.data)
  const Wholesellerdata: any = useSelector(state => state?.Wholesellerdata?.data)
  const GetoneBranchListdata: any = useSelector(state => state?.GetoneBranchListReducers.data)
  const bazaarID = JSON.parse(localStorage.getItem("bazaarId") || "")


  useEffect(() => {
    console.log(Wholesellerdata, "-------")
  }, [Wholesellerdata])
  useEffect(() => {
    dispatch(ProductBazaarList());
    dispatch(GetstateList());
    dispatch(GetWholeseller())
    setNewBranch({
      ...newBranch,
      branch_wholeseller: Number(localStorage.getItem("Wholeseller_id") || 0)
    })
    console.log("wholeseller_ID", localStorage.getItem("Wholeseller_id"))
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(GetoneBranchList(id))
    }
  }, [id])


  useEffect(() => {
    if (id) {
      setNewBranch({
        ...GetoneBranchListdata,
        state: GetoneBranchListdata.state.id,
        district: GetoneBranchListdata.district.id,
        city: GetoneBranchListdata.city.id
      })
    }
  }, [GetoneBranchListdata])

  console.log("Createbranchdata", newBranch)
  useEffect(() => {
    if (ProductBazaar) {
      if (parentCategory) {
        dispatch(CategorySlice({ bazaarId: bazaarID }));
        if (categoryId) {
          dispatch(SubCategorySlice({ bazaarId: bazaarID }));
        }
      }
    }
  }, [ProductBazaar?.length, CategoryData?.length, parentCategory?.length, categoryId])

  const handleChangeMasterType = (e: any) => {
    const { name, value } = e.target;
    if (name == 'state') {
      dispatch(GetdistrictListFromState(value))
    }
    if (name == 'district') {
      dispatch(GetCityListFromDistrict(value))
    }
    if (Number(value)) {
      setNewBranch((prev: any) => ({ ...prev, [name]: Number(value) }))
    }
    else {
      newBranch.created_at = new Date().toJSON().slice(0, 10)
      setNewBranch((prev: any) => ({ ...prev, [name]: value }))
    }
  };

  const setBranchPhonefun = (e: any) => {
    newBranch.branch_phone = `+${e}`
  }

  const handalClick = () => {
    const data = { data: newBranch, nevigate: nevigate }
    if (id) {
      dispatch(Editbrach(data))
    }
    else {
      dispatch(Createbranch(data))
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className={`${classes.root} add_branch`}>
          <div>
            <div className="mb-[53px] flex itens-center">
              <div className="cursor-pointer flex me-5" onClick={() => nevigate('/branch')}>
                <img src={ArrowIcon} alt={"Logo"} />
              </div>
              <p className="commonTitle">Add New Branch</p>
            </div>

            <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">Branch name</label>
                <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="branch_name" onChange={handleChangeMasterType} value={newBranch.branch_name} />
              </div>

              <div className="w-1/2">
                <label className="branchLabel">Manager name</label>
                <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="manager_name" onChange={handleChangeMasterType} value={newBranch.manager_name} />
              </div>
            </div>
            <div className="formContainer mb-[32px]">
              <div className="grid grid-cols-2 items-end gap-4 w-full">
                <div className="">
                  <label className="branchLabel">Branch Phone</label>
                  <PhoneInput
                    country={"in"}
                    value={newBranch?.branch_phone}
                    placeholder="Enter Phone Number"
                    onChange={(e: any) => setBranchPhonefun(e)}
                    containerStyle={{
                      width: "100%",
                    }}
                  />
                </div>

                {/* <div className="">
                  <label className="branchLabel">Category</label>
                  <Select
                    label="Category"
                    variant={"standard"}
                    fullWidth={true}
                    value={newBranch.category_name}
                    name="category_name"
                    onChange={handleChangeMasterType}
                  >
                    {
                      CategoryData && CategoryData?.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.category_name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div> */}
              </div>
            </div>
            {/* <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">Sub Category</label>
                <Select
                  label="Sub Category"
                  variant={"standard"}
                  fullWidth={true}
                  value={newBranch.subcategory_name}
                  name="subcategory_name"
                  onChange={handleChangeMasterType}
                >
                  {
                    SubCategoryData?.length > 0 ? SubCategoryData?.map((item: any) => (
                      <MenuItem value={item.id}>
                        {item.subcategory_name}
                      </MenuItem>
                    )) : <p className="text-center py-2 text-[#84818A] text-[14px]">Please select Category</p>
                  }
                </Select>
              </div>

              <div className="w-1/2">
                <label className="branchLabel">Item</label>
                <input type="number" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="item_name" value={newBranch.item_name} onChange={handleChangeMasterType} />
              </div>
            </div> */}
            <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">Address Line 1</label>
                <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="address" value={newBranch.address} required onChange={handleChangeMasterType} />
              </div>

              <div className="w-1/2">
                <label className="branchLabel">Address Line 2</label>
                <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="address_line2" value={newBranch.address_line2} onChange={handleChangeMasterType} />
              </div>
            </div>
            <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">Pincode</label>
                <input type="number" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="pincode_no" value={newBranch.pincode_no} onChange={handleChangeMasterType} />
              </div>

              <div className="w-1/2">
                <label className="branchLabel">Landmark</label>
                <input type="text" className="outline-none border-b-[1px] h-[32px] border-[#EBEAED] w-full" name="landmark" value={newBranch.landmark} onChange={handleChangeMasterType} />
              </div>
            </div>
            <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">State</label>

                <Autocomplete
                  options={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_state_names || []}
                  getOptionLabel={(option: any) => option.state || ''}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="State"
                      variant="standard"
                      fullWidth
                      name="state"
                    />
                  )}
                  value={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_state_names.find((item: any) => item.id === newBranch.state) || null}
                onChange={(event:any, newValue:any) => handleChangeMasterType({target:{name: 'state',value: newValue.id}})}
                />

                {/* <Select
                  label="State"
                  variant={"standard"}
                  fullWidth={true}
                  name="state"
                  value={newBranch.state}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_state_names?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.state}
                      </MenuItem>
                    ))
                  }
                </Select> */}
              </div>
              <div className="w-1/2">
                <label className="branchLabel">district</label>

                <Autocomplete
                  options={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names?.filter((item: any) => item.state === newBranch.state) || []}
                  getOptionLabel={(option: any) => option.district || ''}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="State"
                      variant="standard"
                      fullWidth
                      name="state"
                    />
                  )}
                  value={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names?.filter((item: any) => item.state === newBranch.state).find((item: any) => item.id === newBranch.district) || null}
                onChange={(event:any, newValue:any) => handleChangeMasterType({target:{name: 'district',value: newValue.id}})}
                />

                {/* <Select
                  label="State"
                  variant={"standard"}
                  fullWidth={true}
                  name="district"
                  value={newBranch.district}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_district_names?.filter((item: any) => item.state === newBranch.state)?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.district}
                      </MenuItem>
                    ))
                  }
                </Select> */}
              </div>
            </div>

            <div className="formContainer mb-[32px]">
              <div className="w-1/2">
                <label className="branchLabel">City</label>



                <Autocomplete
                  options={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names?.filter((item: any) => item.district === newBranch.district) || []}
                  getOptionLabel={(option: any) => option.city || ''}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="State"
                      variant="standard"
                      fullWidth
                      name="state"
                    />
                  )}
                  value={Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names?.filter((item: any) => item.district === newBranch.district).find((item: any) => item.id === newBranch.city) || null}
                onChange={(event:any, newValue:any) => handleChangeMasterType({target:{name: 'city',value: newValue.id}})}
                />

                {/* <Select
                  label="City"
                  variant={"standard"}
                  fullWidth={true}
                  name="city"
                  value={newBranch.city}
                  onChange={handleChangeMasterType}
                >
                  {
                    Wholesellerdata?.wholeseller_bazaar_data?.[0]?.bazaar_city_names?.filter((item: any) => item.district === newBranch.district)?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.city}
                      </MenuItem>
                    ))
                  }
                </Select> */}
              </div>
              {/* <div className="w-1/2">
                <label className="branchLabel">Branch Wholeseller</label>
                <Select
                  label="City"
                  variant={"standard"}
                  fullWidth={true}
                  value={newBranch.branch_wholeseller}
                  name="branch_wholeseller"
                  onChange={handleChangeMasterType}
                >
                  {
                    [Wholesellerdata]?.map((item: any, i: number) => (
                      <MenuItem value={item.id} key={i}>
                        {item.wholeseller_firm_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div> */}
            </div>



            <div className="flex gap-5 py-5">
              <ActionButton title="Submit" variant="primary" onClick={handalClick} />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddBranch;
