// import { DashboardLayout } from '@/components/layouts'
// import React, { useState } from 'react'
// import PhoneInput from 'react-phone-input-2'
// import UploadAreaImage from "@/static/svg/ic_uploadarea.svg";
// import { IconButton } from '@mui/material';
// import { Cancel } from '@mui/icons-material';
// import * as Yup from "yup"
// import { Formik } from 'formik';
// import { AppService } from '@/service/AllApiData.service';
// import { getWholeSellerId } from '@/helper';
// import { toast } from 'react-toastify';

// export default function AddBankDetails() {

//     const [payload, setPayload] = useState<any>({
//         accountName: "",
//         accountNumber: "",
//         ifscCode: "",
//         bankName: "",
//         phoneNumber: "",
//         upiId1: "",
//         upiId2: "",
//         upiId3: "",
//         qrCode1: "",
//         qrCode2: "",
//         qrCode3: ""
//     })

//     const handleChangeMasterType = async (event: any, handleChange:any) => {
//         const { files, value, name } = event.target;

//         const fileInput = event.target;
//         if (fileInput?.files?.length > 0) {
//             const fileSize = fileInput.files[0].size; // in bytes
//             const maxSize = 2 * 1024 * 1024; // 2MB in bytes

//             if (fileSize > maxSize) {
//                 alert("Image size exceeds 2MB. Please choose a smaller image.");
//                 return;
//             }
//         }

//         if (files) {
//             await getBase64(files[0]).then(
//                 data => handleChange(data)
//             );
//         }

//     }

//     function getBase64(file: any) {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = error => reject(error);
//         });
//     }

//     const handleSubmit = async () => {
//         console.log("logger123...", payload);
//         try{
//             let body = {
//                 account_number: payload.accountNumber,
//                 ifsc_code: payload.ifscCode,
//                 account_name: payload.accountName,
//                 bank_name: payload.bankName,
//                 phone_number: payload.phoneNumber,
//                 qr_code_1: payload.qrCode1,
//                 qr_code_2: payload.qrCode2,
//                 qr_code_3: payload.qrCode3,
//                 upi_id_1: payload.upiId1,
//                 upi_id_2: payload.upiId2,
//                 upi_id_3: payload.upiId3,
//                 wholeseller: getWholeSellerId()
//             }

//             let response = await AppService.addBank(body);
//             toast.success("Payment Details Added Successfully");

//         } catch (err) {
//             console.log("ERROR ====> ",err)
//         }
//     }


//     // const formSchema = Yup.object().shape({
//     //     accountName: Yup.string().required('Account name is required'),
//     //     accountNumber: Yup.string().required('Account number is required'),
//     //     ifscCode: Yup.string().required('IFSC code is required'),
//     //     bankName: Yup.string().required('Bank name is required'),
//     //     phoneNumber: Yup.string().required('Phone number is required'),
//     //     upiId1: Yup.string().required('UPI id 1 is required'),
//     //     upiId2: Yup.string().required('UPI id 2 is required'),
//     //     upiId3: Yup.string().required('UPI id 3 is required'),
//     //     qrCode1: Yup.string().required('QR code 1 is required'),
//     //     qrCode2: Yup.string().required('QR code 2 is required'),
//     //     qrCode3: Yup.string().required('QR code 3 is required')
//     // })

//     return (
//         <>
//             <DashboardLayout>
//             {/* <Formik
//         initialValues={payload}
//         onSubmit={handleSubmit}
//         validationSchema={formSchema}
//         enableReinitialize={true}
//       >
//         {({
//           values,
//           handleChange,
//           handleBlur,
//           setFieldValue,
//           touched,
//           errors,
//         }) => (

//         )}

//                 </Formik> */}
//                 <div>
//                     <div className="flex align-middle justify-between">
//                         <p className=" text-[22px] font-bold flex items-center">Bank Details</p>
//                     </div>

//                     <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5'>
//                         <div className=''>
//                             <div className="w-full">
//                                 <label className="text-[12px] text-[#84818A] leading-[18px]">Account Number </label>
//                                 <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="accountNumber" onChange={handleChangeMasterType}/>
//                             </div>
//                         </div>
//                         <div className=''>
//                             <div className="w-full">
//                                 <label className="text-[12px] text-[#84818A] leading-[18px]">IFSC Code</label>
//                                 <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="ifscCode"  onChange={handleChangeMasterType} />
//                             </div>
//                         </div>
//                         <div className=''>
//                             <div className="w-full">
//                                 <label className="text-[12px] text-[#84818A] leading-[18px]">Account Name</label>
//                                 <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="accountName"  onChange={handleChangeMasterType}/>
//                             </div>
//                         </div>
//                         <div className=''>
//                             <div className="w-full">
//                                 <label className="text-[12px] text-[#84818A] leading-[18px]">Bank Name</label>
//                                 <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="bankName"  onChange={handleChangeMasterType} />
//                             </div>

//                         </div>

//                         <div>

//                             <label className="text-[12px] text-[#84818A] leading-[18px]">Phone Number</label>
//                             <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="phoneNumber" onChange={handleChangeMasterType} />
//                         </div>

//                     </div>


//                     <div className="flex flex-col items-start mt-5">

//                         <div className=' grid grid-cols-2 gap-5'>
//                             <div>
//                             {
//                             !payload?.qrCode1 ?
//                                 <div className="w-[370px] py-[5px]">
//                                     <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
//                                         <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="qrCode1" onChange={handleChangeMasterType} />
//                                         <div>
//                                             <img src={UploadAreaImage} alt={"Logo"} />
//                                         </div>
//                                         <div>
//                                             <p>Upload UPI QR Code 1</p>
//                                             <p>Supports JPG, PNG, and JPEG</p>
//                                         </div>
//                                     </div>
//                                 </div> :
//                                 <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
//                                     <img src={payload?.qrCode1} width={'100px'} className="rounded-md" />
//                                     <IconButton onClick={() => setPayload({ ...payload, qrCode1: "" })} >
//                                         <Cancel />
//                                     </IconButton>
//                                 </div>
//                         }
//                             </div>

//                             <div className=' flex items-center'>
//                                 <div className="w-full">
//                                     <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 1</label>
//                                     <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId1" onChange={handleChangeMasterType} />
//                                 </div>
//                             </div>
//                         </div>

//                         <div className=' grid grid-cols-2 gap-5'>
//                             <div>
//                             {
//                             !payload?.qrCode2 ?
//                                 <div className="w-[370px] py-[5px]">
//                                     <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
//                                         <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="qrCode2" onChange={handleChangeMasterType} />
//                                         <div>
//                                             <img src={UploadAreaImage} alt={"Logo"} />
//                                         </div>
//                                         <div>
//                                             <p>Upload UPI QR Code 2</p>
//                                             <p>Supports JPG, PNG, and JPEG</p>
//                                         </div>
//                                     </div>
//                                 </div> :
//                                 <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
//                                     <img src={payload?.qrCode2} width={'100px'} className="rounded-md" />
//                                     <IconButton onClick={() => setPayload({ ...payload, qrCode2: "" })} >
//                                         <Cancel />
//                                     </IconButton>
//                                 </div>
//                         }
//                             </div>

//                             <div className=' flex items-center'>
//                                 <div className="w-full">
//                                     <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 2</label>
//                                     <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId2" onChange={handleChangeMasterType} />
//                                 </div>
//                             </div>
//                         </div>

//                         <div className=' grid grid-cols-2 gap-5'>
//                             <div>
//                             {
//                             !payload?.qrCode3 ?
//                                 <div className="w-[370px] py-[5px]">
//                                     <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
//                                         <input type="file" className="absolute w-full h-full cursor-pointer opacity-0" accept=".png, .jpg, .jpeg" name="qrCode3" onChange={handleChangeMasterType} />
//                                         <div>
//                                             <img src={UploadAreaImage} alt={"Logo"} />
//                                         </div>
//                                         <div>
//                                             <p>Upload UPI QR Code 3</p>
//                                             <p>Supports JPG, PNG, and JPEG</p>
//                                         </div>
//                                     </div>
//                                 </div> :
//                                 <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
//                                     <img src={payload?.qrCode3} width={'100px'} className="rounded-md" />
//                                     <IconButton onClick={() => setPayload({ ...payload, qrCode3: "" })} >
//                                         <Cancel />
//                                     </IconButton>
//                                 </div>
//                         }
//                             </div>

//                             <div className=' flex items-center'>
//                                 <div className="w-full">
//                                     <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 3</label>
//                                     <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId3" onChange={handleChangeMasterType} />
//                                 </div>
//                             </div>
//                         </div>

//                     </div>

//                     <div className='d-flex justify-start  items- mt-5'>
//                         <button className='btn btn-primary bg-[#ff6652] text-white px-5 py-2 rounded-md'>
//                             Cancel
//                         </button>
//                         <button className='btn btn-primary bg-[#4e2fa9] text-white px-5 py-2 rounded-md ml-5' onClick={handleSubmit}>
//                             Save
//                         </button>
//                     </div>

//                 </div>
//             </DashboardLayout>
//         </>
//     )
// }











import { DashboardLayout } from '@/components/layouts';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import UploadAreaImage from '@/static/svg/ic_uploadarea.svg';
import { IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import { AppService } from '@/service/AllApiData.service';
import { getWholeSellerId } from '@/helper';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function AddBankDetails() {
    const initialValues = {
        accountName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        phoneNumber: '',
        upiId1: '',
        upiId2: '',
        upiId3: '',
        qrCode1: '',
        qrCode2: '',
        qrCode3: '',
    };

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        accountName: Yup.string().required('Account name is required'),
        accountNumber: Yup.string().matches(/^\d{9,18}$/, 'Invalid account number').required('Account number is required'),
        ifscCode: Yup.string().matches(/^[A-Za-z\d]{4}[A-Za-z\d]{7}$/, 'Invalid IFSC code').required('IFSC code is required'),
        bankName: Yup.string().required('Bank name is required'),
        phoneNumber: Yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Phone number is required'),
        upiId1: Yup.string().matches(/^[\w.-]+@[\w.-]+$/, 'Invalid UPI ID').required('UPI id 1 is required'),
        upiId2: Yup.string().matches(/^[\w.-]+@[\w.-]+$/, 'Invalid UPI ID').required('UPI id 2 is required'),
        upiId3: Yup.string().matches(/^[\w.-]+@[\w.-]+$/, 'Invalid UPI ID').required('UPI id 3 is required'),
        qrCode1: Yup.mixed().required('QR code 1 is required'),
        qrCode2: Yup.mixed().required('QR code 2 is required'),
        qrCode3: Yup.mixed().required('QR code 3 is required'),
    });

    const handleSubmit = async (values: any) => {
        console.log('logger123...', values);
        try {

            let body = {
                account_number: values.accountNumber,
                ifsc_code: values.ifscCode,
                account_name: values.accountName,
                bank_name: values.bankName,
                phone_number: values.phoneNumber,
                qr_code_1: values.qrCode1,
                qr_code_2: values.qrCode2,
                qr_code_3: values.qrCode3,
                upi_id_1: values.upiId1,
                upi_id_2: values.upiId2,
                upi_id_3: values.upiId3,
                wholeseller: getWholeSellerId(),
            };

            let response = await AppService.addBank(body);
            toast.success('Payment Details Added Successfully');
            navigate("/bank-details");
        } catch (err) {
            console.log('ERROR ====> ', err);
        }
    };

    const getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


    const handleChangeMasterType = async (event: any, handleChange:any) => {
        const { files, value, name } = event.target;

        const fileInput = event.target;
        if (fileInput?.files?.length > 0) {
            const fileSize = fileInput.files[0].size; // in bytes
            const maxSize = 2 * 1024 * 1024; // 2MB in bytes

            if (fileSize > maxSize) {
                alert("Image size exceeds 2MB. Please choose a smaller image.");
                return;
            }
        }

        if (files) {
            await getBase64(files[0]).then(
                data => handleChange(data)
            );
        }

    }

    return (
        <DashboardLayout>
            <div>
                <div className="flex align-middle justify-between mb-5">
                    <p className="text-[22px] font-bold flex items-center">Add Bank Details</p>
                </div>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
                    {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                        <form onSubmit={handleSubmit} className='max-w-3xl'>
                            
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                            <div className="w-full">
                                <label className="text-[12px] text-[#84818A] leading-[18px]">Account Number</label>
                                <Field type="text" name="accountNumber" className="outline-none border-b-[1px] border-[#84818A] w-full" />
                                <ErrorMessage name="accountNumber" component="div" className="text-red-500" />
                            </div>

                            <div className="w-full">
                                <label className="text-[12px] text-[#84818A] leading-[18px]">IFSC Code</label>
                                <Field type="text" name="ifscCode" className="outline-none border-b-[1px] border-[#84818A] w-full" />
                                <ErrorMessage name="ifscCode" component="div" className="text-red-500" />
                            </div>

                            <div className="w-full">
                                <label className="text-[12px] text-[#84818A] leading-[18px]">Account Name</label>
                                <Field type="text" name="accountName" className="outline-none border-b-[1px] border-[#84818A] w-full" />
                                <ErrorMessage name="accountName" component="div" className="text-red-500" />
                            </div>

                            <div className="w-full">
                                <label className="text-[12px] text-[#84818A] leading-[18px]">Bank Name</label>
                                <Field type="text" name="bankName" className="outline-none border-b-[1px] border-[#84818A] w-full" />
                                <ErrorMessage name="bankName" component="div" className="text-red-500" />
                            </div>

                            <div className="w-full">
                                <label className="text-[12px] text-[#84818A] leading-[18px]">Phone Number</label>
                                <Field type="text" name="phoneNumber" className="outline-none border-b-[1px] border-[#84818A] w-full" />
                                <ErrorMessage name="phoneNumber" component="div" className="text-red-500" />
                            </div>
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
                                <div className="">
                                    <div className="w-full">
                                        {
                                            !values?.qrCode1 ? (
                                                <div className="w-[370px] py-[5px]">
                                                    <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                                                        <input
                                                            type="file"
                                                            className="absolute w-full h-full cursor-pointer opacity-0"
                                                            accept=".png, .jpg, .jpeg"
                                                            name="qrCode1"
                                                            onChange={(e)=> handleChangeMasterType(e,handleChange('qrCode1'))}
                                                        />
                                                        <div>
                                                            <img src={UploadAreaImage} alt={'Logo'} />
                                                        </div>
                                                        <div>
                                                            <p>Upload UPI QR Code 1</p>
                                                            <p>Supports JPG, PNG, and JPEG</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                                                    <img src={values?.qrCode1} width={'100px'} className="rounded-md" />
                                                    <IconButton onClick={() => setFieldValue('qrCode1', '')}>
                                                        <Cancel />
                                                    </IconButton>
                                                </div>
                                            )
                                        }

                                        <ErrorMessage name="qrCode1" component="div" className="text-red-500" />
                                    </div>
                                </div>

                                <div className='w-[370px] flex items-center'>
                                <div className="w-full">
                                    <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 1</label>
                                    <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId1" onChange={handleChange('upiId1')} />
                                    <ErrorMessage name="upiId1" component="div" className="text-red-500" />
                                </div>
                            </div>
                            
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
                                <div className="">
                                    <div className="w-full">
                                        {
                                            !values?.qrCode2 ? (
                                                <div className="w-[370px] py-[5px]">
                                                    <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                                                        <input
                                                            type="file"
                                                            className="absolute w-full h-full cursor-pointer opacity-0"
                                                            accept=".png, .jpg, .jpeg"
                                                            name="qrCode2"
                                                            onChange={(e)=> handleChangeMasterType(e,handleChange('qrCode2'))}
                                                        />
                                                        <div>
                                                            <img src={UploadAreaImage} alt={'Logo'} />
                                                        </div>
                                                        <div>
                                                            <p>Upload UPI QR Code 2</p>
                                                            <p>Supports JPG, PNG, and JPEG</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                                                    <img src={values?.qrCode2} width={'100px'} className="rounded-md" />
                                                    <IconButton onClick={() => setFieldValue('qrCode2', '')}>
                                                        <Cancel />
                                                    </IconButton>
                                                </div>
                                            )
                                        }

                                        <ErrorMessage name="qrCode2" component="div" className="text-red-500" />
                                    </div>
                                </div>

                                <div className='w-[370px] flex items-center'>
                                <div className="w-full">
                                    <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 2</label>
                                    <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId2" onChange={handleChange('upiId2')} />
                                    <ErrorMessage name="upiId2" component="div" className="text-red-500" />
                                </div>
                            </div>
                            
                            </div>

                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
                                <div className="">
                                    <div className="w-full">
                                        {
                                            !values?.qrCode3 ? (
                                                <div className="w-[370px] py-[5px]">
                                                    <div className="flex gap-3 justify-center align-middle items-center relative border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer">
                                                        <input
                                                            type="file"
                                                            className="absolute w-full h-full cursor-pointer opacity-0"
                                                            accept=".png, .jpg, .jpeg"
                                                            name="qrCode3"
                                                            onChange={(e)=> handleChangeMasterType(e,handleChange('qrCode3'))}
                                                        />
                                                        <div>
                                                            <img src={UploadAreaImage} alt={'Logo'} />
                                                        </div>
                                                        <div>
                                                            <p>Upload UPI QR Code 3</p>
                                                            <p>Supports JPG, PNG, and JPEG</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-[370px] my-[5px] py-[5px] border-[#E1E1E1;] border-2 border-dashed rounded-md p-5 bg-[#F9FAFB] cursor-pointer flex flex-row justify-between items-center">
                                                    <img src={values?.qrCode3} width={'100px'} className="rounded-md" />
                                                    <IconButton onClick={() => setFieldValue('qrCode3', '')}>
                                                        <Cancel />
                                                    </IconButton>
                                                </div>
                                            )
                                        }

                                        <ErrorMessage name="qrCode3" component="div" className="text-red-500" />
                                    </div>
                                </div>

                                <div className='w-[370px] flex items-center'>
                                <div className="w-full">
                                    <label className="text-[12px] text-[#84818A] leading-[18px]">UPI Id 3</label>
                                    <input type="text" className="outline-none border-b-[1px] border-[#84818A] w-full" name="upiId3" onChange={handleChange('upiId3')} />
                                    <ErrorMessage name="upiId3" component="div" className="text-red-500" />
                                </div>
                            </div>
                            
                            </div>

                                <div className="d-flex justify-start items- mt-5">
                                    <button type="button" className="btn btn-primary bg-[#ff6652] text-white px-5 py-2 rounded-md" onClick={()=>navigate("/bank-details")}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary bg-[#4e2fa9] text-white px-5 py-2 rounded-md ml-5">
                                        Save
                                    </button>
                                </div>
                            
                        </form>
                    )}
                </Formik>
            </div>
        </DashboardLayout>
    );
}
