import { css } from "@emotion/css";

export const useBranchPlanStyles = () => ({
  root: css`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;

    .pageTitle {
      font-family: "Manrope", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #2e2c34;
    }
    .CategoryClass .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
      top: -8px;
    }
  `,

  addDialog: css`
  padding: 44px;
  width: 461px;

  .title {
    font-family: "Manrope", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    color: #2e2c34;
    margin-bottom: 27px;
  }

  .select-master {
    margin-top: 26px;

    .input-label {
      font-family: "Manrope", serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #84818a;
    }
  }

  .action-bar {
    margin-top: 41px;
    display: flex;
    gap: 12px;
  }
`,
});
