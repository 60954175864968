import React, { useEffect, useState } from "react";
import { usePlancardStyles } from "@/static/stylesheets/molecules/plancardStyle";
import LogoGo from "@/static/icons/ic_arrow_go.png";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { Dialog } from "@mui/material";
import successCheckIcon from "@/static/icons/green-tick.svg";
import close from '@/static/icons/close-icon.svg';
import { AppService } from "@/service/AllApiData.service";
import IcCheckMark from "@/static/svg/ic_checkmark.svg";
import moment from "moment";


const PlanCard = ({ disable = false, ...props }: any) => {
  const classes = usePlancardStyles();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [AllDistrict, setAllDistrict] = useState<any>([]);
  const [allFeatures, setAllFeatures] = useState<any>([]);

  const data = props.item

  useEffect(() => {
    // fetchplanDistrictDetails()
    // fetchplanFeaturesDetails()
  }, [])

  const fetchplanDistrictDetails = async () => {
    try {
      const promises = data.district?.map(async (id: any) => {
        const [res1] = await Promise.all([
          AppService.getAllDistricById(id.id)
        ])
        return { ...id, ...res1?.data };
      })
      const temp = await Promise.all(promises);
      setAllDistrict(temp)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const fetchplanFeaturesDetails = async () => {
    try {
      const promises = data?.plan_features?.map(async (id: any) => {
        const [res1] = await Promise.all([
          AppService.getPlanFeaturesById(id.id)
        ])
        return { ...id, ...res1?.data };
      })
      const temp = await Promise.all(promises);
      setAllFeatures(temp)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  const AddPlan = () => {
    if (!disable && !props?.active) {
      setAddModalOpen(false)
      props.setSelectedPlan(data.id?.toString());
      localStorage.setItem("planId", data?.id);
    }

  }

  return (
    <div id={classes.root} className="h-full min-w-[300px] max-h-[670px]">
      <div className="container h-full" onClick={() => !props?.active && !props?.disabled ? setAddModalOpen(true) : null}>


        <div className="h-full">
          <div className={`border border-[#4E2FA9] rounded-xl p-[30px] h-full relative`}>

            {
              (props?.selectedPlan === data?.id?.toString()) &&
              <img
                className="w-[22px] absolute top-[15px] right-[15px]"
                src={IcCheckMark}
                alt={"Logo"}
              />
            }

            {
              props?.active ? 
              <div className="flex items-center">
                <p className="text-[#00caad] font-bold">Current Plan</p>
                <img
                  className="w-[22px] absolute top-[15px] right-[15px]"
                  src={IcCheckMark}
                  alt={"Logo"}
                />
                </div>
               : null
            }

            {data?.plantype !== "FREE" ? (
              <div
                className="flex justify-between"
                style={{ alignItems: "center" }}
              >
                <div>
                  <p className="text-[#4E2FA9] text-[16px] font-bold">
                    {data?.plan_name}
                  </p>
                  {/* <p className="text-[#121127] text-[24px] font-semibold pt-3">
                        {data?.plan_choice == "FREE" ? (
                          data?.plan_choice
                        ) : (
                          <>
                            {" "}
                            <span
                              className="text-[#7A7A86]"
                              style={{ fontSize: "18px" }}
                            >
                              ₹{data?.amount} / for /{" "}
                              {data.plan_periods_in_days}
                            </span>{" "}
                          </>
                        )}
                      </p> */}
                  <p className="text-[#121127] text-[18px] font-semibold pt-3">
                    {/* {data?.bazaar_name} */}
                  </p>
                </div>
                <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                  < p className="text-[#FF6652] text-[28px] font-bold leading-none">
                    {data?.plan_choice == "FREE" ? (
                      

                      <>
                         <p
                          className="text-[#FF6652] text-[28px]"
                        >
                          {data?.plan_choice}

                        </p>
                        {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {data.plan_periods_in_days}
                      </p> */}
                        <p className="text-[#84818A] text-xs mt-1 font-semibold">
                          {data?.plan_periods_in_days} Days
                        </p>
                      </>
                    ) : (
                      <>
                        {" "}
                        <p
                          className="text-[#FF6652] text-[28px]"
                        >
                          ₹{data?.amount}

                        </p>
                        {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {data.plan_periods_in_days}
                      </p> */}
                        <p className="text-[#84818A] text-xs mt-1 font-semibold">
                          {data?.plan_periods_in_days} Days
                        </p>
                      </>
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex gap-5 justify-between">
                <div>
                  <p className="text-[#4E2FA9] text-[16px] font-semibold">
                    Individual
                  </p>
                  <p className="text-[#121127] text-[24px] font-semibold pt-2">
                    Free
                  </p>
                </div>
              </div>
            )}
            <div>
              <p className="text-[#121127] text-[14px] font-semibold pt-4 text-start">
                Available for {" "}
                {data?.bazaar_assigned_data[0].state_names?.join(", ")}
              </p>
              
              {
              props?.active ? 
              <button style={{ color: "#00A5FF", fontWeight: 600, fontSize: "13px", lineHeight: "18px", background: "rgba(0, 165, 255, 0.1)", padding: "6px 15px", margin: "20px 0 10px", borderRadius: "4px" }}>Expiry day: {moment().add(props?.no_of_days_remaining_plan_expiry, "days").format("DD MMM, YYYY")}</button> : null
            }
              {/* <p className="text-[#121127] text-[14px] font-semibold pt-4">
                        {data?.district} - {data?.city}
                      </p> */}
              {/* <p className="text-[#121127] text-[14px] font-semibold pt-4">
                    {data?.branches} {" "}Branches
                  </p> */}
              {/* <p className="text-[#121127] text-[14px] font-semibold pt-4">
                    Plan Tally - {data?.plan_tally ? "Yes" : "No"}
                  </p> */}
            </div>
            <div className="mt-[40px]">
              <p className="font-[#121127] text-[24px] font-bold mb-2 text-start ">
                Features
              </p>
              <div
                className="flex gap-5 pb-6 all-features-div"
                style={{ overflowY: "auto" }}
              >
                <ul>
                  {/* {previewData?.featureData?.length > 0 ? (
                        previewData?.featureData.map((it: any) => ( */}

                  {
                    data?.plan_tally &&
                    <>
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            Tally Support
                          </p>
                        </div>
                      </li>
                    </>
                  }

                  {
                    data?.whatsapp_notification_limit &&
                    <>
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.whatsapp_notification_limit} Whatsapp Notification
                          </p>
                        </div>
                      </li>
                    </>
                  }

                  {
                    data?.sms_notification_limit &&
                    <li className="mb-[5px]">
                      <div className="flex gap-4 items-center">
                        <img
                          className="w-[15px]"
                          src={IcCheckMark}
                          alt={"Logo"}
                        />
                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                          {data?.sms_notification_limit} SMS Notification
                        </p>
                      </div>
                    </li>
                  }

                  {
                    data?.agents_limit &&
                    <li className="mb-[5px]">
                      <div className="flex gap-4 items-center">
                        <img
                          className="w-[15px]"
                          src={IcCheckMark}
                          alt={"Logo"}
                        />
                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                          {data?.agents_limit} Agents
                        </p>
                      </div>
                    </li>
                  }

                  {
                    data?.products_limit &&
                    <li className="mb-[5px]">
                      <div className="flex gap-4 items-center">
                        <img
                          className="w-[15px]"
                          src={IcCheckMark}
                          alt={"Logo"}
                        />
                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                          {data?.products_limit} Products
                        </p>
                      </div>
                    </li>
                  }

                  {
                    data?.orders_limit ?
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.orders_limit} Order
                          </p>
                        </div>
                      </li> : ""
                  }

                  {
                    data?.branches ?
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.branches} Branches
                          </p>
                        </div>
                      </li> : ""
                  }

                  {
                    data?.retailers_limit &&
                    <li className="mb-[5px]">
                      <div className="flex gap-4 items-center">
                        <img
                          className="w-[15px]"
                          src={IcCheckMark}
                          alt={"Logo"}
                        />
                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                          {data?.retailers_limit} Retailers
                        </p>
                      </div>
                    </li>
                  }

                  {
                    data?.users_limit &&
                    <li className="mb-[5px]">
                      <div className="flex gap-4 items-center">
                        <img
                          className="w-[15px]"
                          src={IcCheckMark}
                          alt={"Logo"}
                        />
                        <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                          {data?.users_limit} Users
                        </p>
                      </div>
                    </li>
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="plancard">
          <div className="plan-area">
            <div>
              <p className="planTitle">{data?.plan_name}</p>
              <p className="planSubtitle">₹{data?.amount || 0} /month</p>
            </div>

            <div>
              <div className="circle">
                <p>{data?.plan_periods_in_days || 0}</p>
              </div>
              <p className="circle-label">days</p>
            </div>
          </div>

          <p className="planDescription">Available for {AllDistrict.map((item: any, index: number) => {
            const lastItemIndex = AllDistrict.length - 1;
            return <span key={index}>{item.district}{index !== lastItemIndex && ', '}</span>
          })}</p>
          <div className="actionButton" style={{ alignItems: "center" }}>
            <p>View Features</p>
            <img src={LogoGo} alt={"Logo"} style={{ height: "17px", width: "12px" }} />
          </div>
        </div> */}
      </div>

      {
        !disable &&
        <Dialog open={addModalOpen} maxWidth={"lg"} sx={{ ".MuiPaper-root": { borderRadius: "20px", }, }}>
          <div className={classes.addDialog}>
            <div className="modalContainer">
              <div className="modalHead">
                <div>{data?.plan_name}</div>
                <div onClick={() => setAddModalOpen(false)}><img src={close} alt="close" /></div>
              </div>

              <div className="planHead ">
                <div>
                  {/* <p>
                    ₹{data?.amount || 0} /month
                  </p> */}
                  {/* <p className="planDescription">Available for {AllDistrict.map((item: any, index: number) => {
                  const lastItemIndex = AllDistrict.length - 1;
                  return <span key={index} style={{ fontWeight: 400, fontSize: "14px", color: "#121127 "}}>{item.district}{index !== lastItemIndex && ', '}</span>
                })}</p> */}

                  <p className="planDescription pt-0">Available for {" "}
                    <span style={{ fontWeight: 400, fontSize: "14px", color: "#121127 " }}>
                      {data?.bazaar_assigned_data[0].state_names?.join(", ")}
                    </span>
                  </p>

                  {/* <p className="text-[#121127] text-[14px] font-semibold pt-0">
                    {data?.branches} {" "}Branches
                  </p>
                  <p className="text-[#121127] text-[14px] font-semibold pt-0">
                    Plan Tally - {data?.plan_tally ? "Yes" : "No"}
                  </p> */}


                </div>

                <div className="bg-[#F9F5F2] top-[20px] left-[215px] rounded-full flex flex-col w-[100px] h-[100px] text-center items-center justify-center">
                  <p className="text-[#FF6652] text-[28px] font-bold leading-none">
                    {data?.plan_choice == "FREE" ? (
                      data?.plan_choice
                    ) : (
                      <>
                        {" "}
                        <p
                          className="text-[#FF6652] text-[28px]"
                        >
                          ₹{data?.amount}

                        </p>
                        {/* <p className="text-[#84818A] text-xs mt-1 font-semibold">
                           For {itm.plan_periods_in_days}
                      </p> */}
                        <p className="text-[#84818A] text-xs mt-1 font-semibold">
                          {data?.plan_periods_in_days} Days
                        </p>
                      </>
                    )}
                  </p>
                </div>
              </div>

              <div className="FeatureHead">
                <p className="font-[#121127] text-[24px] font-bold mb-2 text-start ">
                  Features
                </p>
                <div
                  className="flex gap-5 pb-6 all-features-div"
                  style={{ overflowY: "auto" }}
                >
                  <ul>
                    {/* {previewData?.featureData?.length > 0 ? (
                        previewData?.featureData.map((it: any) => ( */}

                    {
                      data?.plan_tally &&
                      <>
                        <li className="mb-[5px]">
                          <div className="flex gap-4 items-center">
                            <img
                              className="w-[15px]"
                              src={IcCheckMark}
                              alt={"Logo"}
                            />
                            <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                              Tally Support
                            </p>
                          </div>
                        </li>
                      </>
                    }

                    {
                      data?.whatsapp_notification_limit &&
                      <>
                        <li className="mb-[5px]">
                          <div className="flex gap-4 items-center">
                            <img
                              className="w-[15px]"
                              src={IcCheckMark}
                              alt={"Logo"}
                            />
                            <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                              {data?.whatsapp_notification_limit} Whatsapp Notification
                            </p>
                          </div>
                        </li>
                      </>
                    }

                    {
                      data?.sms_notification_limit &&
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.sms_notification_limit} SMS Notification
                          </p>
                        </div>
                      </li>
                    }

                    {
                      data?.agents_limit &&
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.agents_limit} Agents
                          </p>
                        </div>
                      </li>
                    }

                    {
                      data?.products_limit &&
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.products_limit} Products
                          </p>
                        </div>
                      </li>
                    }

                    {
                      data?.orders_limit ?
                        <li className="mb-[5px]">
                          <div className="flex gap-4 items-center">
                            <img
                              className="w-[15px]"
                              src={IcCheckMark}
                              alt={"Logo"}
                            />
                            <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                              {data?.orders_limit} Order
                            </p>
                          </div>
                        </li> : ""
                    }

                    {
                      data?.branches ?
                        <li className="mb-[5px]">
                          <div className="flex gap-4 items-center">
                            <img
                              className="w-[15px]"
                              src={IcCheckMark}
                              alt={"Logo"}
                            />
                            <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                              {data?.branches} Branches
                            </p>
                          </div>
                        </li> : ""
                    }

                    {
                      data?.retailers_limit &&
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.retailers_limit} Retailers
                          </p>
                        </div>
                      </li>
                    }

                    {
                      data?.users_limit &&
                      <li className="mb-[5px]">
                        <div className="flex gap-4 items-center">
                          <img
                            className="w-[15px]"
                            src={IcCheckMark}
                            alt={"Logo"}
                          />
                          <p className="text-[#121127] text-[14px] font-normal py-[5px]">
                            {data?.users_limit} Users
                          </p>
                        </div>
                      </li>
                    }

                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className={"action-bar"}>
                <ActionButton
                  variant={"default"}
                  title={"Cancel"}
                  onClick={() => setAddModalOpen(false)}
                />

                <ActionButton
                  variant={"primary"}
                  title={"Add"}
                  onClick={() => AddPlan()}
                />
              </div>
            </div>
          </div>
        </Dialog>

      }
    </div>
  );
};

export { PlanCard };
