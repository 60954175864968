import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const ProductBazaarList: any = createAsyncThunk('bazaar_list', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/bazaar/data/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const ProductBazaarListReducers = createSlice({
  name: 'bazaar_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      ProductBazaarList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      ProductBazaarList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      ProductBazaarList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})
