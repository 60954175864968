import React from "react";
import { useBazaarCardStyles } from "@/static/stylesheets/molecules";
import Logo from "@/static/icons/ic_laptop.png";
import { useNavigate } from "react-router-dom";

interface IProps {
  item?: any,
}

const BazaarCard = (props: IProps) => {
  const item = props.item
  const classes = useBazaarCardStyles();
  const navigate = useNavigate();

  return (
    <div
      className={classes.root}
      onClick={() => navigate("/electronicsbazaar")}
    >
      <div className={classes.container}>
        <div className={classes.bazaarContainer}>
          <div className={classes.bazaarCard}>
            <img className="brandLogo" src={item?.bazaar_image} alt={"Logo"} />
            <p className="headTitle">{item?.bazaar_name}</p>
            <p className="headSubtitle">{item?.bazaar_description}</p>
          </div>

          <div className={classes.wholeSellerContainer}>
            <div className={classes.wholesellerCard}>
              <div className="container">
                <p className="headTitle">{item?.wholesellers}</p>
                <p className="headSubtitle">Wholesellers</p>
              </div>

              <div className="container">
                <p className="headTitle">{item?.agents}</p>
                <p className="headSubtitle">Agents</p>
              </div>

              <div className="container">
                <p className="headTitle">{item?.states}</p>
                <p className="headSubtitle">States</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.revinerContainer}>
          <div className={classes.reveniewCard}>
            <div className="container">
              <div>
                <p className="headTitle">Total Revenue Earned</p>
                <p className="headSubtitle">{item?.earnings ? `Rs. ${item?.earnings}` : `Rs. 0`}</p>
              </div>

              <div>
                <p className="headTitle">No. of Bills</p>
                <p className="headSubtitle">{item?.bills ? item.bills : 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BazaarCard };
