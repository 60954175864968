import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

const SaidbarApi: any = createAsyncThunk('SaidbarApi', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/data/232/bazaar-list/', 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})




export const SaidBarItems = createSlice({
  name: 'SaidbarApi',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SaidbarApi.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      SaidbarApi.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      SaidbarApi.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default SaidbarApi