import React, { useEffect, useState } from "react";
import { useAuthenticationLayoutStyles } from "@/static/stylesheets/layouts";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface LoginFormProps {
  onSuccess?(): void;
  inputvalues?: any;
  Verification?: boolean;
}

const LoginForm: React.FC<LoginFormProps> = (props: any) => {
  const classes = useAuthenticationLayoutStyles();
  const [inputvalue, setinputvalue] = useState<string>('');
  const [showerror, setshowerror] = useState(true);

  useEffect(() => {
    props.inputvalues(inputvalue)
    if (inputvalue.length > 0 && inputvalue.length < 10) {
      setshowerror(true)
    }
    else {
      setshowerror(false)
    }
  }, [inputvalue])

  const Numberfuction = (e:any) =>{
    let pattern = /^(\d{0,10})$/;
    if(e.target.value.match(pattern)){
      setinputvalue(e.target.value)
    }
  }


  return (
    <>
      <div className={classes.loginTitle}>Whole Seller Login</div>
      <div className={classes.hint}>
        Enter your registered mobile number to get OTP
      </div>

      <div className={classes.phoneTextInput}>
        <select className="!bg-[white]">
          <option>+91</option>
          <option>+88</option>
        </select>
        <input placeholder={"Mobile number"} type={"text"} value={inputvalue} onChange={(e:any) => Numberfuction(e)} autoComplete="on" />
      </div>
      {showerror ? <span className="text-[red] font-[400] text-[14px]">*Please enter a valid phone number</span> : ''}

      <div className={classes.submitButtonContainer}>
        <button className={classes.submitButton} onClick={() => {props?.onSuccess()}}>
          Sign In
        </button>
      </div>
    </>
  );
};

export { LoginForm };
