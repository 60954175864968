import React, { useRef, useState, useEffect } from "react";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { useKycFormStyles } from "@/static/stylesheets/molecules";
import {
  Box,
  Avatar,
  FormControl,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
} from "@mui/material";
// import CityPicker from "@/components/atoms/LocationPickers/CityPicker";
// import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import IcImage from "@/static/svg/ic_image.svg";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PhoneInput from "react-phone-input-2";
import { AppService } from "@/service/AllApiData.service";
import { Alert, AlertError } from "@/alert/Alert";
import { readFileAsBase64 } from "@/helper/base64";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import GoogleMap from "../../../../components/molecules/AgentsKycForm/IndivitualKYCForm/GoogleMap.js";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GOOGLE_MAP_API_KEY } from "../../../../helper/constants";
interface SalesManKYCFormType {
  data?: any;
  id?: any;
}
async function convertImageTobS4(imgUrl: string) {
  const imageToBase64 = require('image-to-base64/browser.js');
  let response = await imageToBase64(imgUrl);
  return "data:image/png;base64," + response;
}
const SalesManKYCForm: React.FC<SalesManKYCFormType> = ({ data, id }) => {
  const classes = useKycFormStyles();
  const navigate = useNavigate()
  const [masterType, setMasterType] = useState("Regional Wholeseller");
  const [AllState, setAllState] = React.useState([]);
  const [allCity, setAllCity] = React.useState([]);
  const [allDis, setAllDis] = React.useState([]);
  const [allBazaar, setAllBazaar] = React.useState([]);
  const [singleState, setSingleState] = React.useState([]);
  const [singleDis, setSingleDis] = React.useState([]);
  const [singleCity, setSingleCity] = React.useState([]);
  const [imageModal, setImageModal] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [locationModal, setLocationModal] = React.useState(false);
  const [geoCordLat, setGeoCordLat] = useState(21.1702401);
  const [geoCordLng, setGeoCordLng] = useState(72.83106070000001);
  const [locationname, setLocationname] = useState("");
  const [currentlocation, setCurrentlocation] = useState("");
  const [commonLocation, setCommonLocation] = useState("");

  const handleChangeMasterType = (event: SelectChangeEvent) => {
    setMasterType(event.target.value as string);
  };

  console.log("ddddd", data);
  const filePickerRef = useRef<any>(null);
  const cardPickerRef = useRef<any>(null);
  const cardPickerRef1 = useRef<any>(null);
  const cardPickerRef2 = useRef<any>(null);
  function setFieldValue(phone: string) { }

  const SignupSchema = Yup.object().shape({
    wholeseller_agent_name: Yup.string().required("Agent name is required"),
    wholeseller_agent_contact_person: Yup.string().required("Contact person is required"),
    wholeseller_agent_number: Yup.number()
      .typeError("Phone no must be in digit")
      .integer()
      .positive("Phone no must be positive")
      .required("Phone no is required"),
    wholeseller_agent_altranate_mobile_number: Yup.string()
      .trim()
      .required("Phone no is required")
      .matches(/^\d{2}[0-9]{10}$/, 'Invalid mobile number')
      .test('unique-mobile-number', 'Mobile number already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentNumberExists(value);
        return !isDuplicate;
      }),
    wholeseller_agent_email: Yup.string()
      .required("Email is required")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Invalid email format"
      ),
    wholeseller_agent_bazaar: Yup.array().min(1, "Pick at least 1 Bazaar"),
    wholeseller_agent_gender: Yup.string().required("Email is required"),
    wholeseller_agent_date_of_birth: Yup.string().required("Date of birth is required"),
    wholeseller_agent_address: Yup.string().required("Address is required"),
    wholeseller_agent_landmark: Yup.string().required("Landmark is required"),
    wholeseller_agent_state: Yup.string().required("State is required"),
    wholeseller_agent_district: Yup.string().required("District is required"),
    wholeseller_agent_city: Yup.string().required("City is required"),
    wholeseller_agent_pincode: Yup.string()
      .min(6, "Too Short!")
      .required("Pincode is required"),
    wholeseller_agent_adharcard_no: Yup.string()
      .min(12, "Too Short!")
      .required("Aadhaarcard number is required")
      .test('unique-adhaar', 'Aadhaar already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentAdhaarExists(value);
        return !isDuplicate;
      }),
    wholeseller_agent_pancard_no: Yup.string()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN card number").required("Pancard num is required")
      .test('unique-pancard', 'Pan card already registered with mwb', async function (value) {
        const isDuplicate = await AppService.isAgentPanExists(value);
        return !isDuplicate;
      }),
    wholeseller_agent_image: Yup.string().required("Profile picture is required"),
    wholeseller_agent_adhar_front_image: Yup.string().required("Aadhaar card front is required"),
    wholeseller_agent_adhar_back_image: Yup.string().required("Aadhaar card back is required"),
    wholeseller_agent_pancard_image: Yup.string().required("PAN card is required"),
  });

  const initialValues = {
    // wholeseller_agent_description: "",
    wholeseller_agent_name: data?.wholeseller_agent_name || "",
    wholeseller_agent_contact_person: data?.wholeseller_agent_contact_person || "",
    wholeseller_agent_type: data?.wholeseller_agent_type || "",
    wholeseller_agent_number: data?.wholeseller_agent_number || "",
    wholeseller_agent_altranate_mobile_number: data?.wholeseller_agent_altranate_mobile_number || "",
    wholeseller_agent_email: data?.wholeseller_agent_email || "",
    wholeseller_agent_gender: "MALE" || data?.wholeseller_agent_gender || "",
    wholeseller_agent_image: data?.wholeseller_agent_image || "",
    wholeseller_agent_date_of_birth: data?.wholeseller_agent_date_of_birth || "",
    wholeseller_agent_address: data?.wholeseller_agent_address || "",
    wholeseller_agent_landmark: data?.wholeseller_agent_landmark || "",
    wholeseller_agent_pincode: data?.wholeseller_agent_pincode || "",
    wholeseller_agent_adharcard_no: data?.wholeseller_agent_adharcard_no || "",
    wholeseller_agent_adhar_front_image: data?.wholeseller_agent_adhar_front_image || "",
    wholeseller_agent_adhar_back_image: data?.wholeseller_agent_adhar_back_image || "",
    wholeseller_agent_pancard_image: data?.wholeseller_agent_pancard_image || "",
    wholeseller_agent_pancard_no: data?.wholeseller_agent_pancard_no || "",
    // wholeseller_agent_image: null,
    // wholeseller_agent_active: false,
    // agency: 2,
    wholeseller_agent_state: data?.wholeseller_agent_state || "",
    wholeseller_agent_city: data?.wholeseller_agent_city || "",
    wholeseller_agent_district: data?.wholeseller_agent_district || "",
    // wholeseller_agent_commision: 0,
    wholeseller_agent_bazaar: data?.wholeseller_agent_bazaar || [],
    wholeseller_agent_assigned_state: data?.wholeseller_agent_assigned_state || [],
    wholeseller_agent_assigned_city: data?.wholeseller_agent_assigned_city || [],
    wholeseller_agent_assigned_district: data?.wholeseller_agent_assigned_district || [],
    get_wholeseller_agent_location_json_data: data?.get_wholeseller_agent_location_json_data || "",
    wholeseller_agent_status: data?.wholeseller_agent_status == "CREATED" ? 'PENDING' : data?.wholeseller_agent_status
  };

  const handleFormSubmit = async (values: any) => {
    values.get_wholeseller_agent_location_json_data = commonLocation ? commonLocation : "test locations";
    if (values.wholeseller_agent_pancard_image.includes("https")) {
      values.wholeseller_agent_pancard_image = await convertImageTobS4(data?.wholeseller_agent_pancard_image);
    }
    if (values.wholeseller_agent_adhar_front_image.includes("https")) {
      values.wholeseller_agent_adhar_front_image = await convertImageTobS4(data?.wholeseller_agent_adhar_front_image);
    }
    if (values.wholeseller_agent_adhar_back_image.includes("https")) {
      values.wholeseller_agent_adhar_back_image = await convertImageTobS4(data?.wholeseller_agent_adhar_back_image);
    }
    if (values.wholeseller_agent_image.includes("https")) {
      values.wholeseller_agent_image = await convertImageTobS4(data?.wholeseller_agent_image);
    }
    console.log("values submittttttttteddd", values);
    try {
      values.wholeseller_agent_number = "+" + values.wholeseller_agent_number;
      values.wholeseller_agent_altranate_mobile_number = "+" + values.wholeseller_agent_altranate_mobile_number;
      const response = await AppService.updateAgentKyc(id, values);
      if (response) {
        Alert("Successfully updated!!!");
        navigate('/agents')
      }
    } catch (error: any) {
      console.log("error===>in updating kyc", error);
      let message = error.response.data.type + "\n"
      error.response.data.errors.map((row: any) => {
        message += row.attr + " : " + row.detail + "\n"
      })

      AlertError(message);
    }
  };
  const insertPicture = async (e: any, type: any, setFieldValue: any) => {
    console.log("insertPicture ddddddddidnt rrrrrrrr:");
    if (e.target.files[0]) {
      // console.log("insertPicture  rrrrrrrr:");
      const url = await readFileAsBase64(e.target.files[0]);
      console.log("insertPicture =====>", url);
      setFieldValue(type, url);
    } else {
      console.log("dddddidnt runn=====");
    }
  };
  const removePicture = async (values: any, type: any, setFieldValue: any) => {
    if (values[type]) {
      setFieldValue(type, null);
    }
  };
  const getAllState = async (param: any = {}, type = "single") => {
    if (type == "single") {
      const responseJson = await AppService.getAllStates();
      setSingleState(responseJson.data.results);
    } else {
      const responseJson = await AppService.getAllStateByBazaar(param)
      setAllState(responseJson.data.results.flatMap((item: any) => item.bazaar_state_data.map((ele: any) => ele)));
    }
  };

  const getAllDis = async (param: any = {}, type = "single") => {
    const responseJson = await AppService.getAllDistricByState(param)
    if (type == "single") {
      setSingleDis(responseJson.data.results.flatMap((item: any) => item.district.map((ele: any) => ele)));
    } else {
      setAllDis(responseJson.data.results.flatMap((item: any) => item.district.map((ele: any) => ele)));
    }
  };

  const getAllCity = async (param: any = {}, type = "single") => {
    const responseJson = await AppService.getAllCityByDis(param)
    if (type == "single") {
      setSingleCity(responseJson.data.results.flatMap((item: any) => item.city.map((ele: any) => ele)));
    } else {
      setAllCity(responseJson.data.results.flatMap((item: any) => item.city.map((ele: any) => ele)));
    }
  };

  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();
    setAllBazaar(responseJson.data);
  };

  // useEffect(() => {
  //   getAllDis({ ids: data?.wholeseller_agent_state }, "");
  // }, [data?.wholeseller_agent_state]);
  // useEffect(() => {
  //   getAllCity({ ids: data?.wholeseller_agent_district }, "");
  // }, [data?.wholeseller_agent_district]);

  useEffect(() => {
    if (data) {
      getAllBazaar();
      getAllState({}, "single");
      getAllDis({ ids: data.wholeseller_agent_state }, "single");
      getAllCity({ ids: data.wholeseller_agent_district }, "single");
      getAllState({ ids: data?.wholeseller_agent_bazaar?.join(",") }, "multiple");
      getAllDis({ ids: data.wholeseller_agent_assigned_state.join(",") }, "multiple");
      getAllCity({ ids: data.wholeseller_agent_assigned_district.join(",") }, "multiple");
    }
  }, [data]);

  const getCoordinates = (e: any) => {
    let arrayOne = [];

    setGeoCordLat(e.latLng.lat());
    setGeoCordLng(e.latLng.lng());
    arrayOne.push(e.latLng.lat(), e.latLng.lng());
    // console.log("arrayOnearrayOne",arrayOne)
    // setGeoArr(arrayOne);
  };

  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      console.log("Available");
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

        fetch(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          position.coords.latitude +
          "," +
          position.coords.longitude +
          "&key=" + GOOGLE_MAP_API_KEY
        )
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(
              "responseJson==>",
              responseJson.results[0].formatted_address
            );
            setCurrentlocation(responseJson.results[0].formatted_address);
          });
      });
    } else {
      console.log("Not Available");
    }
  };

  // useEffect(() => {
  //   getAllDis({ ids: data?.wholeseller_agent_state }, "");
  // }, [data?.wholeseller_agent_state]);
  // useEffect(() => {
  //   getAllCity({ ids: data?.wholeseller_agent_district }, "");
  // }, [data?.wholeseller_agent_district]);
  // useEffect(() => {
  //   getAllLists();
  //   getAllBazaar();
  //   getAllDis()
  //   getAllCity();
  // }, []);
  useEffect(() => {
    if (locationname) {
      setCurrentlocation("");
    }
  }, [locationname]);

  useEffect(() => {
    if (currentlocation) {
      setLocationname("");
    }
  }, [currentlocation]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={SignupSchema}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          touched,
          errors,
        }) => (
          <Form>
            <div className={classes.root}>
              <div className="headContainer">
                {!values.wholeseller_agent_image ? (
                  <Avatar sx={{ width: 100, height: 100 }}>Logo</Avatar>
                ) : (
                  <img src={values.wholeseller_agent_image} style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                )}
                <div className="relative">
                  <ActionButton
                    onClick={() => filePickerRef?.current?.click()}
                    variant="default"
                    title="Upload new picture"
                  />
                  <input
                    className="w-0 h-0 absolute"
                    type={"file"}
                    ref={filePickerRef}
                    onChange={(e: any) =>
                      insertPicture(e, "wholeseller_agent_image", setFieldValue)
                    }
                  />
                </div>
                <p className="buttonTitle cursor-pointer" onClick={() =>
                  removePicture(values, "wholeseller_agent_image", setFieldValue)
                }>Remove picture</p>
              </div>
              <Box sx={{ color: "red" }}>
                <ErrorMessage name="wholeseller_agent_image" />
              </Box>
              <div className="field">
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Agent Name</p>
                        <Input
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_name}
                          onChange={handleChange}
                          name="wholeseller_agent_name"
                          onBlur={handleBlur}
                          disabled={id ? true : false}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_name" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Contact Person</p>
                        <Input
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_contact_person}
                          onChange={handleChange}
                          name="wholeseller_agent_contact_person"
                          onBlur={handleBlur}
                          error={Boolean(
                            errors.wholeseller_agent_contact_person &&
                            touched.wholeseller_agent_contact_person
                          )}
                          disabled={id ? true : false}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_contact_person" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Phone Number</p>
                        <PhoneInput
                          country={"in"}
                          onChange={(phone) => setFieldValue("wholeseller_agent_number", phone)}
                          value={values?.wholeseller_agent_number}
                          disabled={id ? true : false}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_number" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Alternative Phone Number</p>
                        <PhoneInput
                          country={"in"}
                          onChange={(phone) =>
                            setFieldValue("wholeseller_agent_altranate_mobile_number", phone)
                          }
                          disabled={data?.wholeseller_agent_altranate_mobile_number ? true : false}
                          value={values?.wholeseller_agent_altranate_mobile_number}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_altranate_mobile_number" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Email Address</p>
                        <Input
                          id="standard-adornment-amount"
                          type="email"
                          value={values.wholeseller_agent_email}
                          onChange={handleChange}
                          name="wholeseller_agent_email"
                          disabled
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_email" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Gender</p>
                        <Select
                          label="Bazaar"
                          labelId="Bazaar"
                          variant={"standard"}
                          fullWidth={true}
                          value={values.wholeseller_agent_gender}
                          onChange={handleChange}
                          name="wholeseller_agent_gender"
                        >
                          <MenuItem value={"MALE"}>Male</MenuItem>
                          <MenuItem value={"FEMALE"}>Female</MenuItem>
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_gender" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle" style={{ paddingBottom: "8px" }}>Date of Birth</p>
                        <input
                          className="border-b-2 border-[#84818A] w-full"
                          type={"date"}
                          value={values.wholeseller_agent_date_of_birth}
                          onChange={handleChange}
                          name="wholeseller_agent_date_of_birth"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_date_of_birth" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Address</p>
                        <Input
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_address}
                          onChange={handleChange}
                          name="wholeseller_agent_address"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_address" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Landmark</p>
                        <Input
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_landmark}
                          onChange={handleChange}
                          name="wholeseller_agent_landmark"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_landmark" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="formTitle">State</p>
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          multiple={false}
                          name="wholeseller_agent_state"
                          value={values.wholeseller_agent_state}
                          onBlur={handleBlur}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                            setAllCity([]);
                            setAllDis([]);
                            // getAllDis(
                            //   {
                            //     ids:
                            //       e.target.value.length > 1
                            //         ? e.target.value.join(",")
                            //         : e.target.value,
                            //   },
                            //   "single"
                            // );
                          }}
                        >
                          {singleState?.map((items: any) => (
                            <MenuItem key={items.id} value={items.id}>
                              {items.state}
                            </MenuItem>
                          ))}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_state" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="formTitle">District</p>
                        {/* <DistrictPicker
                      placeholder={"All Districts"}
                      variant={"outlined"}
                    /> */}
                        <Select
                          label="Bazaar"
                          multiple={false}
                          variant={"standard"}
                          fullWidth={true}
                          name="wholeseller_agent_district"
                          value={values.wholeseller_agent_district}
                          onBlur={handleBlur}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                            // getAllCity(
                            //   {
                            //     ids:
                            //       e.target.value.length > 1
                            //         ? e.target.value.join(",")
                            //         : e.target.value,
                            //   },
                            //   "single"
                            // );
                          }}
                        >
                          {singleDis?.map((item: any) => {
                            // return items?.district.map((item: any) => {
                            return (
                              <MenuItem value={item.id}>{item.district}</MenuItem>
                            );
                            // });
                          })}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_district" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="formTitle">City</p>
                        {/* <CityPicker
                      placeholder={"All Cities"}
                      variant={"outlined"}
                    /> */}
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          multiple={false}
                          name="wholeseller_agent_city"
                          value={values.wholeseller_agent_city}
                          onBlur={handleBlur}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                          }}
                        >
                          {singleCity?.map((item: any) => {
                            // return items?.city.map((item: any) => {
                            return (
                              <MenuItem value={item.id}>{item.city}</MenuItem>
                            );
                            // });
                          })}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_city" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" style={{ width: "100%" }}>
                        <p className="formTitle">Pin code</p>
                        <Input
                          onKeyPress={(event) => {
                            const target = event.target as HTMLInputElement;
                            if (target.value.length >= 6 || !/\d/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          id="standard-adornment-amount"
                          type="number"
                          value={values.wholeseller_agent_pincode}
                          onChange={handleChange}
                          name="wholeseller_agent_pincode"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_pincode" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="title-main">Bazaar</p>
                        <Select
                          label="Bazaar"
                          labelId="Bazaar"
                          variant={"standard"}
                          fullWidth={true}
                          value={values.wholeseller_agent_bazaar}
                          onChange={handleChange}
                          name="wholeseller_agent_bazaar"
                          className="w-1/2"
                          disabled
                          multiple={true}
                        >
                          {allBazaar.map((items: any) => (
                            <MenuItem key={items.id} value={items.id}>
                              {items.bazaar_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_bazaar" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="title-main">Assigned States</p>
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          multiple={true}
                          name="wholeseller_agent_assigned_state"
                          value={values.wholeseller_agent_assigned_state}
                          onBlur={handleBlur}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                            // getAllDis(
                            //   {
                            //     ids:
                            //       e.target.value.length > 1
                            //         ? e.target.value.join(",")
                            //         : e.target.value.join(","),
                            //   },
                            //   "multiple"
                            // );
                          }}
                        >
                          {AllState.map((items: any) => (
                            <MenuItem key={items.id} value={items.id}>
                              {items.state}
                            </MenuItem>
                          ))}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_assigned_state" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="title-main">Assigned Districts</p>
                        <Select
                          label="Bazaar"
                          multiple={true}
                          variant={"standard"}
                          fullWidth={true}
                          onBlur={handleBlur}
                          name="wholeseller_agent_assigned_district"
                          value={values.wholeseller_agent_assigned_district}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                            // getAllCity(
                            //   {
                            //     ids:
                            //       e.target.value.length > 1
                            //         ? e.target.value.join(",")
                            //         : e.target.value.join(","),
                            //   },
                            //   "multiple"
                            // );
                          }}
                        >
                          {allDis?.map((item: any) => {
                            // return items?.district.map((item: any) => {
                            return (
                              <MenuItem value={item.id}>
                                {item.district}
                              </MenuItem>
                            );
                            // });
                          })}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_assigned_district" />
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <div className="w-full">
                        <p className="title-main">Assigned Cities</p>
                        <Select
                          label="Age"
                          variant={"standard"}
                          fullWidth={true}
                          multiple={true}
                          name="wholeseller_agent_assigned_city"
                          onBlur={handleBlur}
                          value={values.wholeseller_agent_assigned_city}
                          disabled
                          onChange={(e: any) => {
                            handleChange(e);
                            // getAllCity({
                            //   ids:
                            //     e.target.value.length > 1
                            //       ? e.target.value.join(",")
                            //       : e.target.value,
                            // });
                          }}
                        >
                          {allCity?.map((item: any) => {
                            // return items?.city.map((item: any) => {
                            return (
                              <MenuItem value={item.id}>
                                {item.city}
                              </MenuItem>
                            );
                            // });
                          })}
                        </Select>
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_assigned_city" />
                        </Box>

                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" className="w-full">
                        <p className="formTitle">Aadhaar Number</p>
                        <Input
                          onKeyPress={(event) => {
                            const target = event.target as HTMLInputElement;
                            if (
                              target.value.length >= 12 ||
                              !/\d/.test(event.key)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_adharcard_no}
                          onChange={handleChange}
                          name="wholeseller_agent_adharcard_no"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_adharcard_no" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item lg={3.25} md={5} sm={6} className="bazaarplan-div">
                    <div style={{ paddingTop: "30px", paddingRight: "15px" }}>
                      <FormControl variant="standard" className="w-full">
                        <p className="formTitle">Pan Number</p>
                        <Input
                          id="standard-adornment-amount"
                          type="text"
                          value={values.wholeseller_agent_pancard_no.toUpperCase()}
                          onChange={handleChange}
                          name="wholeseller_agent_pancard_no"
                          onBlur={handleBlur}
                        />
                        <Box sx={{ color: "red" }}>
                          <ErrorMessage name="wholeseller_agent_pancard_no" />
                        </Box>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="mapButton mt-8 items-center" style={{ flexWrap: "wrap" }}>
                <p>Set Location via Google Maps</p>
                <ActionButton
                  variant="primary"
                  title="Set Location"
                  onClick={() => {
                    setLocationModal(true);
                    console.log("valuessssssss------in kyc", values);
                  }}
                />
              </div>
              <div className="docContainer">
                <p className="docTitle">Documents</p>
                <div className="Attachment-file" style={{ flexWrap: "wrap" }}>
                  <div style={{ textAlign: "center" }}>
                    <p className="pb-[10px]">Aadhaar Card Front</p>
                    <Avatar
                      onClick={() => cardPickerRef?.current?.click()}
                      className="cursor-pointer border-2"
                      sx={{
                        width: 110,
                        height: 100,
                        borderRadius: 2,
                        background: "white",
                      }}
                      variant="square"
                      style={{ margin: "0 auto" }}
                    >
                      <div className="flex gap-2">
                        {!values.wholeseller_agent_adhar_front_image ? (
                          <img src={IcImage} alt={"Logo"} />
                        ) : (
                          <img src={values.wholeseller_agent_adhar_front_image} />
                        )}
                        <p className="browseButton">Browse</p>
                      </div>
                    </Avatar>
                    <input
                      className="w-0 h-0 absolute"
                      type={"file"}
                      ref={cardPickerRef}
                      onChange={(e: any) =>
                        insertPicture(
                          e,
                          "wholeseller_agent_adhar_front_image",
                          setFieldValue
                        )
                      }
                    />
                    <div className="w-[110px] border-2 p-[10px] rounded-md flex gap-4" style={{ margin: "0 auto", marginTop: "15px" }}>
                      <div
                        onClick={() => {
                          setImageModal(true);
                          setImage(values.wholeseller_agent_adhar_front_image);
                        }}
                      >
                        <RemoveRedEyeOutlinedIcon className="cursor-pointer" />
                      </div>
                      <div className="border-l-2"></div>
                      <div
                        onClick={() => {
                          removePicture(
                            values,
                            "wholeseller_agent_adhar_front_image",
                            setFieldValue
                          );
                        }}
                      >
                        <DeleteOutlinedIcon className="cursor-pointer text-[red]" />
                      </div>
                    </div>
                    <Box sx={{ color: "red" }}>
                      <ErrorMessage name="wholeseller_agent_adhar_front_image" />
                    </Box>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <p className="pb-[10px]">Aadhaar Card Back</p>
                    <Avatar
                      onClick={() => cardPickerRef1?.current?.click()}
                      className="cursor-pointer border-2"
                      sx={{
                        width: 110,
                        height: 100,
                        borderRadius: 2,
                        background: "white",
                      }}
                      variant="square"
                      style={{ margin: "0 auto" }}
                    >
                      <div className="flex gap-2">
                        {!values.wholeseller_agent_adhar_back_image ? (
                          <img src={IcImage} alt={"Logo"} />
                        ) : (
                          <img src={values.wholeseller_agent_adhar_back_image} />
                        )}
                        <p className="browseButton">Browse</p>
                      </div>
                    </Avatar>
                    <input
                      className="w-0 h-0 absolute"
                      type={"file"}
                      ref={cardPickerRef1}
                      onChange={(e: any) =>
                        insertPicture(
                          e,
                          "wholeseller_agent_adhar_back_image",
                          setFieldValue
                        )
                      }
                    />
                    <div className="w-[110px] border-2 p-[10px] rounded-md flex gap-4" style={{ margin: "0 auto", marginTop: "15px" }}>
                      <div
                        onClick={() => {
                          setImageModal(true);
                          setImage(values.wholeseller_agent_adhar_back_image);
                        }}
                      >
                        <RemoveRedEyeOutlinedIcon className="cursor-pointer" />
                      </div>
                      <div className="border-l-2"></div>
                      <div
                        onClick={() => {
                          removePicture(
                            values,
                            "wholeseller_agent_adhar_back_image",
                            setFieldValue
                          );
                        }}
                      >
                        <DeleteOutlinedIcon className="cursor-pointer text-[red]" />
                      </div>
                    </div>
                    <Box sx={{ color: "red" }}>
                      <ErrorMessage name="wholeseller_agent_adhar_back_image" />
                    </Box>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <p className="pb-[10px]">Pan Card</p>
                    <Avatar
                      onClick={() => cardPickerRef2?.current?.click()}
                      className="cursor-pointer border-2"
                      sx={{
                        width: 110,
                        height: 100,
                        borderRadius: 2,
                        background: "white",
                      }}
                      variant="square"
                      style={{ margin: "0 auto" }}
                    >
                      <div className="flex gap-2">
                        {!values.wholeseller_agent_pancard_image ? (
                          <img src={IcImage} alt={"Logo"} />
                        ) : (
                          <img src={values.wholeseller_agent_pancard_image} />
                        )}
                        <p className="browseButton">Browse</p>
                      </div>
                    </Avatar>
                    <input
                      className="w-0 h-0 absolute"
                      type={"file"}
                      ref={cardPickerRef2}
                      onChange={(e: any) =>
                        insertPicture(e, "wholeseller_agent_pancard_image", setFieldValue)
                      }
                    />
                    <div className="w-[110px] border-2 p-[10px] rounded-md flex gap-4" style={{ margin: "0 auto", marginTop: "15px" }}>
                      <div
                        onClick={() => {
                          setImageModal(true);
                          setImage(values.wholeseller_agent_pancard_image);
                        }}
                      >
                        <RemoveRedEyeOutlinedIcon className="cursor-pointer" />
                      </div>
                      <div className="border-l-2"></div>
                      <div
                        onClick={() => {
                          removePicture(
                            values,
                            "wholeseller_agent_pancard_image",
                            setFieldValue
                          );
                        }}
                      >
                        <DeleteOutlinedIcon className="cursor-pointer text-[red]" />
                      </div>
                    </div>

                    <Box sx={{ color: "red" }}>
                      <ErrorMessage name="wholeseller_agent_pancard_image" />
                    </Box>

                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5 pt-[10px] pb-[40px]">
              <ActionButton title="Cancel" variant="primary" />
              <ActionButton type="submit" title="Submit" variant="default" />
            </div>
          </Form>
        )}
      </Formik >
      <Dialog
        open={imageModal}
        maxWidth={"lg"}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
        onClose={() => {
          setImageModal(false);
          setImage("");
        }}
      >
        {/* <div className={classes.addDialog}> */}
        <img src={image} />

        <div className={"action-bar"}>
          <ActionButton
            variant={"default"}
            title={"Cancel"}
            onClick={() => {
              setImageModal(false);
              setImage("");
            }}
          />

          {/* <ActionButton
            variant={"primary"}
            title={"Submit"}
            onClick={() => {
              setImageModal(false);
              setImage("");
            }}
          /> */}
        </div>
        {/* </div> */}
      </Dialog>
      <Dialog
        open={locationModal}
        // maxWidth={"lg"}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "10px",
            width: "80%",
            height: "80%",
          },
        }}
        onClose={() => {
          setLocationModal(false);
        }}
      >
        {/* <div className={classes.addDialog}> */}

        <div className={"action-bar"}>
          <div className="cat">
            <p
              className="formTitle font-semibold"
              style={{ margin: "5px 20px", textAlign: "center" }}
            >
              Search Location
            </p>
            <GoogleMap
              // setLocationname={setLocationname}
              selectlocation={setLocationname}
              lat={geoCordLat}
              setLat={setGeoCordLat}
              setLng={setGeoCordLng}
              lng={geoCordLng}
              getCoordinates={getCoordinates}
            />
          </div>

          <div className=" w-full justify-center pb-3">
            <div
              className="mapButton mt-8 items-center"
              style={{ textAlign: "center" }}
            >
              <p>Current location</p>
              <ActionButton
                variant="primary"
                title="Current location"
                onClick={() => getCurrentLocation()}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <Input
                id="standard-adornment-amount"
                type="text"
                value={locationname ? locationname : currentlocation}
                onChange={(e) => setCurrentlocation(e.target.value)}
                name="agent_pancard_no"
              />
            </div>
          </div>

          <div className=" gap-2" style={{ display: 'flex', justifyContent: 'end', margin: '20px' }}>
            <ActionButton
              variant={"default"}
              title={"Cancel"}
              onClick={() => {
                setLocationModal(false);
              }}
            />

            <ActionButton
              variant={"primary"}
              title={"Submit"}
              onClick={() => {
                if (locationname && !currentlocation) {
                  setCommonLocation(locationname);
                } else if (currentlocation && !locationname) {
                  setCommonLocation(currentlocation);
                }
                setLocationModal(false);
              }}
            />
          </div>
        </div>
        {/* </div> */}
      </Dialog>
    </>
  );
};

export { SalesManKYCForm };
