import React from "react";
import { SidebarPanel } from "@/components/organisms/SidebarPanel";
import { MenuItem } from "@/components/organisms/SidebarPanel/MenuItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ListIcon from "@mui/icons-material/List";
import SettingsIcon from "@mui/icons-material/Settings";
import BadgeIcon from "@mui/icons-material/Badge";
import { routePaths } from "@/routes";
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ViewListIcon from '@mui/icons-material/ViewList';
import { BsBank } from "react-icons/bs";
import { UserIcon } from "@heroicons/react/20/solid";

const AdminSidebar = () => {
  return (
    <>
      <SidebarPanel>
        <MenuItem
          href={routePaths.dashboard}
          icon={<DashboardIcon />}
          label={"Dashboard"}
        />
        <MenuItem
          href={routePaths.branch}
          icon={<PersonIcon />}
          label={"Branch"}
        />
        <MenuItem
          href={routePaths.wholesellerproducts}
          icon={<ShoppingCartIcon />}
          label={"All Products"}
        />
        <MenuItem
          href={routePaths.productcombos}
          icon={<ShoppingCartIcon />}
          label={"Product Combo"}
        />
        <MenuItem
          href={routePaths.productstock}
          icon={<ShoppingCartIcon />}
          label={"Product Stock"}
        />
        <MenuItem
          href={routePaths.productpricing}
          icon={<ShoppingCartIcon />}
          label={"Product Pricing"}
        />
        <MenuItem href={"/order"} icon={<ViewListIcon />} label={"Order"} />
        <MenuItem href={"/retailers"} icon={<PersonIcon />} label={"Retailers"} />
        <MenuItem href={"/agents"} icon={<PersonIcon />} label={"Agents"} />
        <MenuItem href={"/offers"} icon={<LocalOfferIcon />} label={"Offers"} />
        <MenuItem
          href={"/branchplan"}
          icon={<CardMembershipIcon />}
          label={"Plans"}
        />
        <MenuItem
          href={"/itemwiseplan"}
          icon={<CardMembershipIcon />}
          label={"Item Plans"}
        />

        <MenuItem
          href={"/view-product-pricings"}
          icon={<CardMembershipIcon />}
          label={"View Product Pricing"}
        />

        <MenuItem
          href={"/customer-wise-pricings"}
          icon={<PersonIcon />}
          label={"Customer Wise Pricing"}
        />

        <MenuItem
          href={"/bank-details"}
          icon={<BsBank />}
          label={"Bank Details"}
        />
        <MenuItem href={"/mwb"} icon={<SettingsIcon />} label={"Routes"} />
        <MenuItem
          href={"/users"}
          icon={<SettingsIcon />}
          label={"Roles & Permissions"}
        />
        {/* <MenuItem
          href={"/trackorder"}
          icon={<SettingsIcon />}
          label={"Activity Tracking"}
        />
        <MenuItem
          href={routePaths.admin.masterList}
          icon={<SettingsIcon />}
          label={"Master"}
        /> */}
      </SidebarPanel>
    </>
  );
};

export { AdminSidebar };
