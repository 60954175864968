import { css } from "@emotion/css";

export const useGridOptionButtonStyles = () => ({
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  `,
});
