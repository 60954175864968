import React, { useEffect, useState } from "react";
import { useDispatch, AppDispatch, useSelector } from "@/Store";
import { CreateCategoryPlan, CreateItemWisePlan, EditCategoryPlan, EditItemWisePlan, EditSubCategoryPlan, createSubCategoryPlan } from "@/Store/Sclicese/PlanSlice";
import { toast } from "react-toastify";

type props = {
  title: string,
  updated_date: any,
  cash_discount_type?: string,
  cash_value?: number,
  platinum_discount_type?: string,
  platinum_value?: number,
  diamond_discount_type?: string,
  diamond_value?: number,
  gold_discount_type?: string,
  gold_value?: number,
  bronze_discount_type?: string,
  bronze_value?: number,
  retailer_type?: number[],
  branch?: number,
  category?: number,
  sub_category?: number,
  id?: any,
  product: any,
  base_price: any,
  disabled?:boolean
}

const planTypes = [
  {
    type: 'cash_discount_type',
    value: 'cash_value',
    label: 'Cash'
  },
  {
    type: 'platinum_discount_type',
    value: 'platinum_value',
    label: 'Platinum'
  },
  {
    type: 'diamond_discount_type',
    value: 'diamond_value',
    label: 'Diamond'
  },
  {
    type: 'gold_discount_type',
    value: 'gold_value',
    label: 'Gold'
  },
  {
    type: 'bronze_discount_type',
    value: 'bronze_value',
    label: 'Bronze'
  }
]

const ItemRow = ({ title, updated_date, cash_discount_type,
  cash_value,
  platinum_discount_type,
  platinum_value,
  diamond_discount_type,
  diamond_value,
  gold_discount_type,
  gold_value,
  bronze_discount_type,
  bronze_value,
  retailer_type,
  branch,
  category,
  sub_category,
  id,
  product,
  base_price,
  disabled
}: props) => {


  const dispatch = useDispatch();
  const MasterRetailerTypeData = useSelector(state => state?.MasterRetailerTypeData.data)
  const [data, setData] = useState<any>({
    cash_discount_type,
    cash_value,
    platinum_discount_type,
    platinum_value,
    diamond_discount_type,
    diamond_value,
    gold_discount_type,
    gold_value,
    bronze_discount_type,
    bronze_value,
    retailer_type,
    plan_type: cash_discount_type || bronze_discount_type || platinum_discount_type || gold_discount_type || diamond_discount_type || 'RUPEE',
    plan_value: cash_value || bronze_value || platinum_value || gold_value || diamond_value || null
  })


  const handleChange = (plan: any, value: any, type: string) => {
    if (plan == 'plan_type') {
      const updateObj = {
        ...data,
        plan_type: type,
        plan_value: value
      }
      planTypes.map(plan => {
        if (data[plan.type]) {
          updateObj[plan.value] = value
          updateObj[plan.type] = data.plan_type
        }
      })
      setData(updateObj)
      console.log("logger123456...",plan,value,type,data,updateObj)
      return
    }
    setData({
      ...data,
      [plan.value]: value,
      [plan.type]: type
    })
  }


  const handleSubmit = () => {
    console.log("logger123...456",data)
    const postData = {
      ...data,
      last_update_date: new Date().toJSON(),
      branch,
      product
    }
    if (id && id > 0) {
      postData.id = id
      dispatch(EditItemWisePlan(postData));
    } else {
      dispatch(CreateItemWisePlan(postData));
    }

    // if(category){
    //   postData.category = category
    //   submitCategoryPlan(postData)
    // }
    // if(sub_category){
    //   postData.sub_category = sub_category
    //   submitSubCategoryPlan(postData)
    // }
  }

  const submitCategoryPlan = (postData: any) => {
    if (id && id > 0) {
      postData.id = id
      dispatch(EditCategoryPlan(postData));
    } else {
      dispatch(CreateCategoryPlan(postData));
    }
  }

  const submitSubCategoryPlan = (postData: any) => {
    if (id && id > 0) {
      postData.id = id
      dispatch(EditSubCategoryPlan(postData));
    } else {
      dispatch(createSubCategoryPlan(postData));
    }
  }


  const checkData = (plan: any, value: any) => {
    if (!data.plan_value || !(data.plan_value > 0)) {
      toast.error('Please Update Price First');
      return
    }
    setData({
      ...data,
      [plan.value]: value ? data.plan_value : null,
      [plan.type]: value ? data.plan_type : null,
    })

  }



  const checkRetailerData = (rtl: any, value: any) => {
    if (!(data.plan_value > 0)) {
      toast.error('Please Update Price First');
      return;
    }
    const updatedRetailerType = (data.retailer_type || []).slice(); // Create a copy of retailer_type
    if (value === true) {
      updatedRetailerType.push(rtl.id);
    } else {
      const index = updatedRetailerType.indexOf(rtl.id);
      if (index !== -1) {
        updatedRetailerType.splice(index, 1);
      }
    }
    setData({
      ...data,
      retailer_type: updatedRetailerType
    });
  }

  return (
    <tr className="border-b align-baseline">
      <th
        scope="row"
        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
      >
        <div className="flex gap-[20px] items-center ">
          <p className="mb-3 text-[14px] font-[600] text-gray-700 font-Manrope cursor-pointer">
            {title}
          </p>
        </div>
      </th>
      <td className="py-4 px-6  color-[#2E2C34;]">
        <div className="priceTitle">Rs. {base_price}</div>
      </td>
      <td className="py-4 px-6  color-[#2E2C34;]">
        <div>
          <div className="bg-[#FFF0EE] p-[10px] flex justify-center rounded-md ">
            <p className="updatePrice">Rs. {(Number(base_price) + Number(isNaN(Number(((data.plan_type === "PERCENTAGE" ? base_price * data.plan_value / 100 : data.plan_value)))) ? 0 : Number(((data.plan_type === "PERCENTAGE" ? base_price * data.plan_value / 100 : data.plan_value))))).toFixed(2)}</p>
          </div>
          <div className="flex gap-2 justify-center items-center mt-2">
            <div className="planCardChild flex">
              <input disabled={disabled} style={{ width: '30px' }} value={data.plan_type === 'PERCENTAGE' ? data.plan_value : ''} onChange={(e) => handleChange('plan_type', e.target.value, 'PERCENTAGE')} className="border p-1 rounded-md" />
            </div>
            <p>% or</p>
            <div className="planCardChild flex">
              <input disabled={disabled} style={{ width: '40px' }} value={data.plan_type === 'RUPEE' ? data.plan_value : ''} onChange={(e) => handleChange('plan_type', e.target.value, 'RUPEE')} className="border p-1 rounded-md" />
            </div>
          </div>
        </div>
      </td>
      <td className="py-4 px-6  color-[#2E2C34;]">
        <div className="h-full">
          <div className="w-full h-[120px] overflow-y-auto border border-gray p-3 rounded-md">
            {
              MasterRetailerTypeData?.map((rtl: any) => {
                return <div className="formControl flex gap-[10px] items-center mb-2">
                  <input disabled={disabled} type="checkbox" onChange={(e => checkRetailerData(rtl, e.target.checked))} checked={!!data?.retailer_type?.includes(rtl.id)} />
                  <p>{rtl.retailer_type_name}</p>
                </div>
              })
            }
          </div>
        </div>
      </td>
      <td className="py-4 px-6  color-[#2E2C34;]">
        <div className="h-full">
          <div className="w-full h-[120px] overflow-y-auto border border-gray p-3 rounded-md">
            {planTypes.map((plan) => {
              return <div className="formControl flex gap-[10px] items-center mb-2">
                <input disabled={disabled} type="checkbox" onChange={(e) => checkData(plan, e.target.checked)} checked={!!data?.[plan.type]} />
                <p>{plan.label}</p>
              </div>
            })}
          </div>
        </div>
      </td>
      <td className="py-4 px-6  color-[#2E2C34;]">
        <div className="tag">
          <p className="tagTitle">24 Jun,2021 10:30pm</p>
          <div>
            <p>Log</p>
          </div>
        </div>
      </td>
      <td className="py-4 px-6 color-[#2E2C34;]">
        <div className="text-center">
          <button onClick={() => handleSubmit()} className="bg-transparent hover:bg-[#FD6B09] text-[#FD6B09] font-semibold hover:text-white py-2 px-4 border border-[#FD6B09] hover:border-transparent rounded">
            APPLY
          </button>
          <p className="py-2 text-center cursor-pointer">
            Reset
          </p>
        </div>
      </td>
    </tr>
  );
};

export default ItemRow;
