import React from "react";
import ReactApexChart from "react-apexcharts";

type MyProps = {
  // using `interface` is also ok
  message?: any;
};
type MyState = {
  series: any
  options: any
};


export default class DaskbordChart extends React.Component<MyProps, MyState> {

  state: MyState = {    
    series: [{
      name: 'Electronic',
      data: [80, 40, 20, 40, 61, 58, 63, 60, 66, 35 , 55]
    }, {
      name: 'Computer',
      data: [40, 85, 101, 98, 87, 100, 91, 80, 94, 50, 10]
    }, {
      name: 'Fashion',
      data: [80, 41, 36, 26, 45, 48, 52, 53, 41, 20, 20]
    },{
      name: 'Grocery',
      data: [20, 41, 36, 26, 45, 48, 52, 53, 41, 20, 20]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '45%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 6,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  }
  render() {
    return (
      <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={300} />
      </div>
    );
  }
}