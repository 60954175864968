export const routePaths = {
  root: `/`,
  login: `/login`,
  dashboard: `/dashboard`,
  mwb: `/mwb`,
  newbazaars: `/newbazaars`,
  wholeseller: `/wholeseller`,
  wholesellerdashboard: `/wholesellerdashboard`,
  wholesellermart: `/wholesellermart`,
  wholesellerproducts: `/wholesellerproducts`,
  itemwiseplan: `/itemwiseplan`,
  offers: `/offers`,
  createoffer: `/createoffer`,
  Editoffer: `/createoffer/:editId`,
  agents: `/agents`,
  agentkyc: `/agentkyc/:id`,
  addagent: `/addagent`,
  editagent: `/editagent/:id`,
  retailers: `/retailers`,
  addretailers: `/addretailers`,
  editretailers: `/addretailers/:editId`,
  wholesellerlist: `/wholesellerlist`,
  renewplan: `/renewplan`,
  plan :`/plan`,
  addplan:`/addplan`,
  preview:`/previewplan`,
  addwholeseller: `/addwholeseller`,
  wholesellerkyc: `/wholesellerkyc`,
  electronicsbazaar: `/electronicsbazaar`,
  addproduct: `/addproduct`,
  addproductform: `/addproduct/form`,
  editproductform: `/editproduct/:id`,
  bulkupload: `/bulkupload/product`,
  subcategories: `/subcategories`,
  bazaarreport: `/bazaarreport`,
  addnewadvertisement: `/addnewadvertisement`,
  addbranch: `/addbranch`,
  advertisement: `/advertisement`,
  branch: `/branch`,
  editbranch: `/addbranch/:id`,
  branchplan: `/branchplan`,
  createorder: `/createorder`,
  editorder: `/editorder/:id`,
  order: `/order`,
  photoorder: `/photoorder`,
  trackorder: `/trackorder`,
  outOfDelivery:`outofdelivery`,
  SubbazarDashbord : `/SubbazarDashbord`,
  subcategoryplan : `/subcategoryplan`,
  productpricing: `/productpricing`,
  profile: '/profile',
  retailerDetails: '/retailers/:id',
  viewproductpricings: '/view-product-pricings',
  customerwisepricings: '/customer-wise-pricings',
  bankdetails: '/bank-details',
  addBankDetails: "/bank-details/add",
  users: '/users',
  rolepermissions: '/rolespermissions',
  addroles: '/addroles',
  productcombos: '/productcombos',
  addproductcombos: '/addproductcombos',
  addproductcombosform: '/addproductcombos/form',
  editproductcombosform: '/editproductcombos/:id',
  productstock: '/productstock',

  addons: `/addons/:planId`,
  addonPlanPay: `/addons/:planId/pay/:newPlan`,
  plans: `/plans`,
  plansPay: `/plans/:planId/pay`,

  admin: {
    bazaars: `/bazaars`,
    masterList: `/admin/master-list`,
  },
};
