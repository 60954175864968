import React from 'react'

interface props{
    id:any
}

export default function RetailerReports({id}:props){

    return(
        <>
        <div className="citycontainer p-[20px] mt-5" style={{ paddingTop: 0, height: "100%" }}>
                <div className="flex justify-between py-[20px]">
                    <p className="cityThead">Most Purchased Products</p>
                </div>
                <div>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b">
                                <th className="text-start py-3 cityTablehead">ITEM</th>
                                <th className="text-center py-3 cityTablehead">PRICE</th>
                                <th className="text-center py-3 cityTablehead">UNIT PURCHASED</th>
                                <th className="text-end py-3 cityTablehead">SALES</th>
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                        <p className='text-center' style={{ paddingTop: "15px", fontSize: "14px" }}>No Products yet</p>
                </div>
            </div>
        </>
    )
}