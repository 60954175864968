import { DashboardLayout } from "@/components/layouts";
import { useDashboardStyles } from "@/static/stylesheets/screens";
import DashboardCard from "../Dashboard/SummaryCard/DashboardCard";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useEffect, useState } from "react";
import DaskbordChart from "../Dashboard/SummaryCard/DaskbordChart";
import { SummaryCard } from "../Dashboard/SummaryCard";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { BazaarCard, BazaarCounter, BazaarsPlanList } from "../Bazaars";
import { BazaarReportCard } from "../Bazaars/BazaarReportCard";
import TwMultiSelect from "@/components/atoms/TwMultiSelect/TwMultiSelect";
import { FormControl, Grid, Select } from "@mui/material";
import ProductData from "../Dashboard/SummaryCard/ProductData";
import { Ads_Performance, Ads_Performance_array, Ads_Performance_data, Top_Poducts_array, Top_Poducts_data, Top_Retailers_array, Top_Retailers_data, dashborad_Poducts_array, dashborad_Poducts_data, dashborad_Retailers_array, dashborad_Retailers_data } from "../Dashboard/SummaryCard/Tabledata";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/Store";
import SaidbarApi from "@/Store/Sclicese/SaidBarItems";


function SubbazarDashbord() {
  const classes = useDashboardStyles();
  const [value, setValue] = useState('1');
  const data = [1, 2, 3, 4, 5];
  const saidbardata = useSelector<any>(state => state.SaidbarApidata.data.results)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(SaidbarApi())
  }, [])

  console.log("saidbardata", saidbardata);



  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <DashboardLayout>
      <div className={classes.root}>
        <div className={classes.summaryTitle}>
          <p>Dashboard</p>
        </div>

        <div className="grid grid-cols-4 gap-5">
          <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
            <DashboardCard arrow={"down"} title={"Total Products"} amount="13,596" />
          </div>
          <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
            <DashboardCard arrow={"up"} title={"Total  Orders"} amount="12324" />
          </div>
          <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
            <DashboardCard arrow={"down"} title={"Total Revenue"} amount="Rs. 12,00,000" />
          </div>
          <div className="boreder-2 px-[24px] py-[20px] border-[1px] border-[#EBEAED] border-solid relative rounded-[4px]">
            <DashboardCard arrow={"up"} title={"New Retailers"} amount="12" />
          </div>
        </div>

        <div>
          <p className="font-Manrope fon-[600] text-[18px] leading-[25px] tracking-[0.5px] text-[#2E2C34] pt-[33px] pb-[16px]">Compare Bazaar Performance</p>

          <div className="border-solid border-[1px]">
            <Box sx={{ width: '100%', typography: 'body1', '.css-13xfq8m-MuiTabPanel-root': { padding: 0 } }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "15px" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Revenue" value="1" />
                    <Tab label="Retailers" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1"><DaskbordChart /></TabPanel>
                <TabPanel value="2"><DaskbordChart /></TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>

        <div>
          <p className="font-Manrope fon-[600] text-[18px] leading-[25px] tracking-[0.5px] text-[#2E2C34] pt-[30px] pb-[22px]">Bazaar Performance</p>


          <div className="overflow-hidden !w-[100%]">
            <div className="flex flex-wrap overflow-x-auto w-[100%]">
              {data.map((item: any) => (
                <div className="w-[450px]">
                  <BazaarCard />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full">
          <ProductData headingdata={dashborad_Retailers_array} bodydata={dashborad_Retailers_data} tital='Top Retailers' dropdownname='All Bazaars' />
        </div>

        <div className="w-full">
          <ProductData headingdata={dashborad_Poducts_array} bodydata={dashborad_Poducts_data} tital='Top Poducts' dropdownname='Select Bazaar' />
        </div>

        <div className="flex justify-between py-[22px] px-[30px] items-center">
          <div className="flex gap-5 items-center">
            <p className="font-Manrope font-[600] text-[16px] leading-[22px] text-[#2E2C34] tracking-[0.5px]">Ads Performance</p>
            <Box>
              <FormControl>
                <Select
                  defaultValue={30}
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible': { outline: 'none', border: 0 }, padding: "5px 10px" }}
                  className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'
                >
                  <option value={10} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px] flex items-center'>Select Bazaar</option>
                  <option value={20} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'>Twenty</option>
                  <option value={30} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px] flex items-center'>Select Bazaar</option>
                </Select>
              </FormControl>
            </Box>
          </div>
          <button className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#5542F6] tracking-[0.5px]">View all</button>
        </div>
        <div className="px-[35px] pb-[22px] border-solid border-[#E1E1E1] border-[1px] rounded-[4px]">
          <div>
            <table className="w-full">
              <thead>
                <tr className="border-b-2 w-full !p-0">
                  {
                    Ads_Performance_array.map((x: string) => (

                      <th className={`${x == 'AMOUNT SPENT' ? 'rext-center' : 'text-left'} font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px] py-[10px]`}>{x}</th>
                    ))
                  }
                </tr>
              </thead>
              <tbody>
                {
                  Ads_Performance_data?.map((data: any) => {
                    return (
                      <tr>
                        <td className="py-[35px] font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.name}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Products}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.Bazaar}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.City}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.PRICE}</td>
                        <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.SOLD}</td>
                        <td className="text-center font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{data.AMOUNT_SPENT}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </DashboardLayout>
  )
}

export default SubbazarDashbord
