import { css } from "@emotion/css";

export const useOrderStyles = () => ({
  root: css`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    .commonTitle {
      font-family: "Manrope", serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 20px;
      color: #2e2c34;
    }
    .menu-button {
      background: #ffff !important;
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1) !important;
      border-radius: 8px !important;
      padding: 0px 18px !important; 
    }
  `,
  updateStatusModal: css`
  padding: 40px;
  position: relative;
  min-width: 700px;


  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
    display:flex;
    align-items:center;
    justify-content:center
  }

  .closeBtn {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
    width: 26px;
    height: 26px;
  }

  .selectStatus {
    margin-bottom: 60px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding-left:
  }

  .products {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;

    img {
      width: 36px;
      height: 36px;
    }

    .productName {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #2e2c34;
    }

    .amountBtns {
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      padding: 2px 10px;

      button {
        color: #ff6652;
        font-weight: 900;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #2e2c34;
      }
    }
  }
  .amountBoxWrapper {
    margin-bottom: 50px;
  }

  .nextButton {
    button {
      background: #ff6652;
      border-radius: 8px;
      padding: 12px 32px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`,
orderStatusModal: css`
padding: 40px;
position: relative;

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  width: 26px;
  height: 26px;
}

.orderTrack {
  margin-bottom: 50px;

  .orderID {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  .ordererName {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.orderStatusWrapper {
  display: flex;

  .orderStatus {
    width: 200px;

    .progressLine {
      display: flex;
      align-items: center;

      .circle {
        height: 30px;
        width: 40px;
        border-radius: 50%;
        background: #a8b6c3;
        position: relative;
        z-index: 1;
      }
      .circle::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .line {
        width: 100%;
        height: 4px;
        background: #a8b6c3;
      }
    }
    .done {
      .circle {
        background: #fd6b09;
      }
      .line {
        background: #fd6b09;
      }
    }
  }
  .status p {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }
  .status {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .status img {
    width: 18px;
  }

  .dateAndTime {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
}
`,
});
