import { ActionButton } from "@/components/atoms/Button/ActionButton";
import { DashboardLayout } from "@/components/layouts";
import { useaddPlanDetails } from "@/static/stylesheets/molecules/addPlan";
import { ArrowBackIos, Delete, Edit, Visibility } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AddPlans = () => {
    const classes = useaddPlanDetails();
    const [paymentType, setPaymentType] = React.useState("paid");
    const [masterType, setMasterType] = useState("Regional Wholeseller");
    const navigate = useNavigate()

    const handleChangeMasterType = (event: SelectChangeEvent) => {
        setMasterType(event.target.value as string);
    };
    return (
        <div>
            <DashboardLayout>
                <div className={classes.root}>
                    <div className="container">
                        <div className="textContainer flex justify-between">
                            <div className="flex">
                                <div onClick={() => navigate('/plan')}>
                                    <ArrowBackIos style={{ color: '#84818A', cursor: 'pointer' }} className='mt-1' />
                                </div>
                            <p className="titleHead">Add Plan</p>
                            </div>
                            <div className="formContainer">
                                <Button className="preview" onClick={() => navigate("/previewplan")}>
                                    <Visibility style={{ marginRight: '5px' }} />
                                    <span >Preview</span>
                                </Button>
                            </div>
                        </div>
                        <div className="flex">
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons"
                                        name="controlled-radio-buttons"
                                        value={paymentType}
                                        onChange={() => setPaymentType("free")}
                                    >
                                        <FormControlLabel
                                            value="Free "
                                            control={<Radio />}
                                            checked={paymentType === "free" ? true : false}
                                            label="Free"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons"
                                        name="controlled-radio-buttons"
                                        value={paymentType}
                                        onChange={() => setPaymentType("paid")}
                                    >
                                        <FormControlLabel
                                            value="Paid"
                                            control={<Radio />}
                                            checked={paymentType === "paid" ? true : false}
                                            label="Paid"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        {paymentType === "paid" ? (
                            <>
                                <div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="Firm Name" variant="standard" />
                                        <TextField id="standard-basic" label="Last Name" variant="standard" />
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="Branches" variant="standard" />
                                        <TextField id="standard-basic" label="Users per branch" variant="standard" />
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="Start Date" variant="standard" type="date" InputLabelProps={{ shrink: true }} />
                                        <div className="singleForm">
                                            <Select
                                                label="Start Time"
                                                variant={"standard"}
                                                fullWidth={true}
                                                value={masterType}
                                                onChange={handleChangeMasterType}
                                            >
                                                <MenuItem
                                                    value={"Regional Wholeseller"}
                                                >
                                                    4 pm
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Regional Wholeseller 1"}
                                                >
                                                    5 pm
                                                </MenuItem>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="End Date" variant="standard" type="date" InputLabelProps={{ shrink: true }} />
                                        <div className="singleForm">
                                            <Select
                                                label="End Time"
                                                variant={"standard"}
                                                fullWidth={true}
                                                value={masterType}
                                                onChange={handleChangeMasterType}
                                            >
                                                <MenuItem
                                                    value={"Regional Wholeseller"}
                                                >
                                                    4 pm
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Regional Wholeseller 1"}
                                                >
                                                    5 pm
                                                </MenuItem>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="formContainer">

                                        <div className="singleForm">
                                            <Select
                                                label="Bazaar"
                                                variant={"standard"}
                                                fullWidth={true}
                                                value={masterType}
                                                onChange={handleChangeMasterType}
                                            >
                                                <MenuItem
                                                    value={"Regional Wholeseller"}
                                                >
                                                    Electronic Computer
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Regional Wholeseller 1"}
                                                >
                                                    Electronic Computer
                                                </MenuItem>
                                            </Select>
                                        </div>
                                        <TextField id="standard-basic" label="State" variant="standard" />
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="District" variant="standard" />

                                        <TextField id="standard-basic" label="City" variant="standard" />
                                    </div>
                                    <div className="formContainer">
                                        <Typography
                                            sx={{
                                                fontFamily: 'Manrope',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                lineHeight: '22px',
                                                color: '#4E2FA9',
                                            }}
                                        >Plan Features</Typography>
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="25 Projects" variant="outlined" />
                                        <div className="w-[97px] h-[48px] border border-[#EBEAED] flex items-center justify-between p-2">
                                            <Edit style={{ color: '#84818A', }} />
                                            <span style={{ borderRight: '2px solid #EBEAED', height: '34px' }}></span>
                                            <Delete style={{ color: '#FC3400' }} />
                                        </div>
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="25 Projects" variant="outlined" />
                                        <div className="w-[97px] h-[48px] border border-[#EBEAED] flex items-center justify-between p-2 borderRight">
                                            <Edit style={{ color: '#84818A' }} />
                                            <span style={{ borderRight: '2px solid #EBEAED', height: '34px' }}></span>
                                            <Delete style={{ color: '#FC3400' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="formContainer">
                                    <Button className="add-more">
                                        <AddOutlinedIcon />
                                        <span >Add More</span>
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* Agency */}
                                <div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="Firm Name" variant="standard" />
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="Start Date" variant="standard" type="date" InputLabelProps={{ shrink: true }} />
                                        <div className="singleForm">
                                            <Select
                                                label="Start Time"
                                                variant={"standard"}
                                                fullWidth={true}
                                                value={masterType}
                                                onChange={handleChangeMasterType}
                                            >
                                                <MenuItem
                                                    value={"Regional Wholeseller"}
                                                >
                                                    4 pm
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Regional Wholeseller 1"}
                                                >
                                                    5 pm
                                                </MenuItem>
                                            </Select>
                                        </div>

                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="End Date" variant="standard" type="date" InputLabelProps={{ shrink: true }} />
                                        <div className="singleForm">
                                            <Select
                                                label="End Time"
                                                variant={"standard"}
                                                fullWidth={true}
                                                value={masterType}
                                                onChange={handleChangeMasterType}
                                            >
                                                <MenuItem
                                                    value={"Regional Wholeseller"}
                                                >
                                                    4 pm
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Regional Wholeseller 1"}
                                                >
                                                    5 pm
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="formContainer">
                                        <Typography
                                            sx={{
                                                fontFamily: 'Manrope',
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                fontSize: '16px',
                                                lineHeight: '22px',
                                                color: '#4E2FA9',
                                            }}
                                        >Plan Features</Typography>
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="25 Projects" variant="outlined" />
                                        <div className="w-[97px] h-[48px] border border-[#EBEAED] flex items-center justify-between p-2">
                                            <Edit style={{ color: '#84818A', }} />
                                            <span style={{ borderRight: '2px solid #EBEAED', height: '34px' }}></span>
                                            <Delete style={{ color: '#FC3400' }} />
                                        </div>
                                    </div>
                                    <div className="formContainer">
                                        <TextField id="standard-basic" label="25 Projects" variant="outlined" />
                                        <div className="w-[97px] h-[48px] border border-[#EBEAED] flex items-center justify-between p-2 borderRight">
                                            <Edit style={{ color: '#84818A' }} />
                                            <span style={{ borderRight: '2px solid #EBEAED', height: '34px' }}></span>
                                            <Delete style={{ color: '#FC3400' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="formContainer">
                                    <Button className="add-more">

                                        <AddOutlinedIcon />
                                        <span>Add More</span>
                                    </Button>
                                </div>
                            </>
                        )}
                        <div className="flex gap-5 py-7">
                            <ActionButton title="Cancel" variant="default" />
                            <ActionButton title="Submit" variant="primary" />
                        </div>
                    </div>
                </div>
            </DashboardLayout >
        </div >
    )
}

export default AddPlans
