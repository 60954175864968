import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { toast } from "react-toastify";


// ---------- Get offers ------------ //
export const GetOffersSlice: any = createAsyncThunk('offers', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/offer/`, 'get');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const GetOffersReducers = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetOffersSlice.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetOffersSlice.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetOffersSlice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


// ---------- Post offers ------------ //
export const CreateOffersSlice: any = createAsyncThunk('Createoffers', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/offer/`, 'POST', data.data);
    toast.success("success!")
    data.navigate('/offers')
    return responce.data
  }
  catch (error: any) {
    toast.error(error.response.statusText)
    return rejectWithValue(error)
  }
})

export const CreateOffersReducers = createSlice({
  name: 'Createoffers',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      CreateOffersSlice.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      CreateOffersSlice.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      CreateOffersSlice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

// ---------- Edit offers ------------ //
export const EditOffersSlice: any = createAsyncThunk('EditOffersSlice', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/offer/${data.data.id}/`, 'put', data.data);
    toast.success("Edit Successfully!")
    data.navigate('/offers')
    return responce.data
  }
  catch (error: any) {
    toast.error(error.response.statusText)
    return rejectWithValue(error)
  }
})

export const EditOffersSliceReducers = createSlice({
  name: 'EditOffersSlice',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditOffersSlice.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditOffersSlice.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditOffersSlice.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


// ---------- Detail offers ------------ //
export const DetailOffers: any = createAsyncThunk('DetailOffers', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/offer/${data}/`, 'get');
    return responce.data
  }
  catch (error: any) {
    return rejectWithValue(error)
  }
})

export const DetailOffersReducers = createSlice({
  name: 'DetailOffers',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DetailOffers.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      DetailOffers.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DetailOffers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})


// ---------- Delete offers ------------ //
export const DeleteOffers: any = createAsyncThunk('DeleteOffers', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/wholeseller/offer/${data}/`, 'DELETE');
    toast.success("successfully deleted!")
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const DeleteOffersdata = createSlice({
  name: 'DeleteOffers',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DeleteOffers.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      DeleteOffers.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DeleteOffers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

// ---------- search offers ------------ //
export const SearchOffers: any = createAsyncThunk('SearchOffers', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/offer/data/?search=${data}`, 'get');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const SearchOffersdata = createSlice({
  name: 'SearchOffers',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SearchOffers.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      SearchOffers.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      SearchOffers.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})
