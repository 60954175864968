import React from "react";

interface SearchFieldProps {
  icontype?: boolean,
  inputdata?: any,
  inputwidth?: any,
  className?: string;
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const {icontype,inputdata} = props;

  const inputvalue = (e : any) =>{
    inputdata(e.target.value)
  }
  return (
    <div className={`shadow-md ${icontype ? 'w-[339px] h-[48px] rounded-[4px] shadow-[0px 1px 3px rgba(0, 0, 0, 0.12)]' : `h-[46px] w-[${props.inputwidth ? props.inputwidth : "500px"}] flex items-center`} ${props.className}`}>
      <form className={`max-w-sm ${icontype && 'w-full h-full'}`}>
        <div className="relative h-full">
        {!icontype && <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 left-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>}
          <input
            type="text"
            placeholder={`${icontype ? 'Search bazaar...' : 'Search ... '}`}
            className={`w-full py-2 pl-12 pr-4 h-full text-gray-500  border-radius-5 h-5 w-578 rounded-md outline-none bg-light ${icontype ? 'pl-[16px] pr-12 py-[14px]' : ''}`}
            onChange={inputvalue}
          />
          {
            icontype && <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400 right-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          }
        </div>
      </form>
    </div>
  );
};

export { SearchField };
