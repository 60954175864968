import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FormControl, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/Store';
import { TopProductsList, categoriesList, subCategoriesList } from '@/Store/Sclicese/DashboardSclice';
import { useEffect, useState } from 'react';

interface Iprops {
  headingdata?: any
  bodydata?: any
  tital?: string
  dropdownname?: string
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '75px', ms: 2 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

function ProductData(props: Iprops) {
  const dispatch = useDispatch<AppDispatch>()
  const [arraydata, setArrayData] = useState<any>(undefined)
  const TopProductsListdata = useSelector<any>(state => state.TopProductsListdata.data.result)
  const categoriesListdata = useSelector<any>(state => state.categoriesListdata.data["category name"])
  const subCategoriesListdata = useSelector<any>(state => state.subCategoriesListdata.data["subcategory name"])

  useEffect(() => { 
    if (props?.bodydata == "Top_Poducts_data") {
      dispatch(TopProductsList())
    }
    if (props?.bodydata == "Categories_data") {
      dispatch(categoriesList())
    }
    if (props?.bodydata == "Sub_Categories") {
      dispatch(subCategoriesList())
    }
  }, [])

  useEffect(() => {
    if (props?.bodydata == "Top_Poducts_data") {
      if (TopProductsListdata) {
        setArrayData(TopProductsListdata)
      }
    }

    if (props?.bodydata == "Categories_data") {
      if (categoriesListdata) {
        setArrayData(categoriesListdata)
      }

    }

    if (props?.bodydata == "Sub_Categories") {
      if (subCategoriesListdata) {
        setArrayData(subCategoriesListdata)
      }
    }
  }, [TopProductsListdata, categoriesListdata, subCategoriesListdata])

  return (
    <div className="px-[35px] py-[22px] border-solid border-[#E1E1E1] border-[1px] rounded-[4px] my-[25px]">
      <div className="flex justify-between pb-[30px]">
        <div className='flex gap-5'>
          <p className="font-Manrope font-[600] text-[16px] leading-[22px] text-[#2E2C34] tracking-[0.5px] flex items-center">{props?.tital}</p>
          {
            props.dropdownname ?
              <>
                <div>
                  <Box>
                    <FormControl>
                      <Select
                        defaultValue={30}
                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible': { outline: 'none', border: 0 }, padding: "5px 10px" }}
                        className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'
                      >
                        <option value={10} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'>{props.dropdownname}</option>
                        <option value={20} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'>Twenty</option>
                        <option value={30} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'>{props.dropdownname}</option>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </> : ''
          }
        </div>
        <button className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#5542F6] tracking-[0.5px]">View all</button>
      </div>
      <table className="w-full">
        <thead>
          <tr className="border-b-2">
            {
              props?.headingdata?.map((item: any) => {
                return (
                  <>
                    <th className={`font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px] ${(item == "ITEM" || item == 'name') ? "text-left" : "text-center"} ${(item == "name") ? 'w-[60%]' : ''}`}>{item}</th>
                  </>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {console.log("bodydata", props?.bodydata)}
          {
            arraydata?.map((item: any) => {
              return (
                <>
                  <tr >
                    <td><span className="flex gap-3 py-3 font-Manrope font-[600] text-[14px] leading-[20px] text-[#2E2C34]"><img src={item?.image} alt="" className="w-[36px] h-[36px] rounded-[2px]" />{item.product_name || item.category_name || item.subcategory_name}</span></td>
                    {item?.Bazaar ? <td className="text-left font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] w-[135px]">{item?.Bazaar}</td> : ""}
                    {item?.City ? <td className="text-center font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] w-[135px]">{item?.City}</td> : ""}
                    {item?.most_purchased_by ? <td className="font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] text-left"><p>{item.most_purchased_by}</p><span>{item.product_total_mrp}</span><span className='text-[#84818A] ps-[8px]'>16%</span><p className='pt-[2px]'><LinearProgressWithLabel value={20} /></p></td> : ''}
                    {item?.product_total_mrp ? <td className="text-left font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] w-[135px]">{item?.product_total_mrp}</td> : ""}
                    {item?.Orders ? <td className="text-center font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] w-[135px]">{item?.Orders}</td> : ""}
                    {item?.ORDERS ? <td className="text-left font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{item.ORDERS}</td> : ''}
                    {item?.sold ? <td className="text-left font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] w-[135px]">{item?.sold}</td> : ""}
                    <td className="text-center font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]">{item.sales}</td>
                  </tr>
                </>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default ProductData
