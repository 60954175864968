import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const GetCityList: any = createAsyncThunk('city_list', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/city/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const GetCityListFromDistrict: any = createAsyncThunk('city_list_from_district', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/g-city/?ids=${data}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const GetCityListFromDistrictMultiple: any = createAsyncThunk('city_list_from_district_multiple', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/g-city/?ids=${data}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  multipleData: any,
  loading: boolean,
  error: any,
}

const initialState: initialStateType = {
  data: [],
  multipleData: [],
  loading: false,
  error: null,
}

export const CityListReducers = createSlice({
  name: 'city_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetCityList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetCityList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetCityList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder.addCase(
      GetCityListFromDistrict.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetCityListFromDistrict.fulfilled, (state, action) => {
        state.loading = false
        let tmpArr: any = []
        if (Array.isArray(action.payload.results)) {
          action.payload.results.forEach((arr: any) => {
            tmpArr = tmpArr.concat(arr.city)
          })
        }
        state.data = tmpArr
      })
    builder.addCase(
      GetCityListFromDistrict.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder.addCase(
      GetCityListFromDistrictMultiple.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetCityListFromDistrictMultiple.fulfilled, (state, action) => {
        state.loading = false
        let tmpArr: any = []
        if (Array.isArray(action.payload.results)) {
          action.payload.results.forEach((arr: any) => {
            tmpArr = tmpArr.concat(arr.city)
          })
        }
        state.multipleData = tmpArr
      })
    builder.addCase(
      GetCityListFromDistrictMultiple.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})
