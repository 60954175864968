import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'
import { toast } from "react-toastify";
import { getWholeSellerId } from "@/helper";
import { AlertError } from "@/alert/Alert";


//----------- Get data --------------//
export const GetRetailer: any = createAsyncThunk('GetRetailer', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/data/wholeseller/${getWholeSellerId()}/`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState: initialStateType = {
  data: [],
  loading: false,
  error: null,
}

export const GetRetailerdata = createSlice({
  name: 'GetRetailer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetRetailer.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetRetailer.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetRetailer.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- Post data --------------//
export const PostRetailer: any = createAsyncThunk('PostRetailer', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/retailer/data/', 'POST', data.data);
    toast.success("success!")
    data.navigate('/retailers')
    return result.data
  }
  catch (error: any) {
    let message: any = [];
    error.response.data.errors?.map((x: any) => {
      message.push(x.detail)
    })
    AlertError(`1. ${message}`);
    // toast.error(error.response.statusText);
    return error
  }
})


export const PostRetailerdata = createSlice({
  name: 'PostRetailer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      PostRetailer.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      PostRetailer.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      PostRetailer.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- Search data --------------//
export const SearchRetailer: any = createAsyncThunk('SearchRetailer', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api(`api/retailer/data/?search=${data}`, 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

export const SearchRetailerdata = createSlice({
  name: 'SearchRetailer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      SearchRetailer.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      SearchRetailer.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      SearchRetailer.rejected, (state, action) => {
        state.loading = false
      })
  }
})

//----------- Retailer Id data ------------//
export const getIdRetaier: any = createAsyncThunk('getIdRetaier', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/retailer/data/${data}/`, 'get');
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})



export const getIdRetaierdata = createSlice({
  name: 'getIdRetaier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getIdRetaier.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      getIdRetaier.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      getIdRetaier.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- Delete data --------------//
export const DeleteRetaier: any = createAsyncThunk('DeleteRetaier', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/retailer/data/${data}/`, 'DELETE');
    toast.success("successfully deleted!")
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})



export const DeleteRetaierdata = createSlice({
  name: 'DeleteRetaier',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      DeleteRetaier.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      DeleteRetaier.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      DeleteRetaier.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- Edit data --------------//
export const EditRetaier: any = createAsyncThunk('EditRetaier', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/retailer/data/${data.data.id}/`, 'patch', data.data);
    data.navigate('/retailers');
    toast.success("edit success!")
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})



export const EditRetaierdata = createSlice({
  name: 'EditRetaier',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      EditRetaier.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      EditRetaier.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      EditRetaier.rejected, (state, action) => {
        state.loading = false
      })
  }
})


//----------- retailerNumber data --------------//
export const retailerNumber: any = createAsyncThunk('retailerNumber', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/retailer/retailer_number/', 'POST', data);
    return result.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

//----------- getretailerNumber data --------------//
export const getretailerNumber: any = createAsyncThunk('getretailerNumber', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/retailer/retailer_number/', 'get');
    return result.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const getretailerNumberdata = createSlice({
  name: 'getretailerNumber',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getretailerNumber.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      getretailerNumber.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      getretailerNumber.rejected, (state, action) => {
        state.loading = false
      })
  }
})

