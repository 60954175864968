import { api } from "@/Axios/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";

const UserNumberVerify = createAsyncThunk('UserNumberVerify', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/verify_phone/', 'post',data);
    toast.success(result?.data?.details)
    return result.data
  }
  catch (error:any) {
    toast.error(error?.response?.data?.details)
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}


export const WholesellerNumberVerify = createSlice({
  name: 'UserNumberVerify',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      UserNumberVerify.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      UserNumberVerify.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      UserNumberVerify.rejected, (state, action) => {
        state.loading = false
      })
  }
})

export default UserNumberVerify
