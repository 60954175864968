import { AppDispatch } from '@/Store';
import { topBranches } from '@/Store/Sclicese/DashboardSclice';
import { Padding } from '@mui/icons-material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


function TableComponets() {
  const dispatch = useDispatch<AppDispatch>()
  const topBranchesdata: any = useSelector<any>(state => state.topBranchesdata.data["Top Branches"])

  useEffect(() => {
    dispatch(topBranches())
  }, [])

  return (
    <div className='px-[35px] pt-[20px] pb-[25px] border-solid border-[#E1E1E1] border-[1px] rounded-[4px] my-[25px]'>
      <div className="flex justify-between pb-[30px]">
        <p className="font-Manrope font-[600] text-[16px] leading-[22px] text-[#2E2C34] tracking-[0.5px] flex items-center">Top Branches</p>
        <div>
          <Box>
            <FormControl>
              <Select
                defaultValue={30}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus-visible': { outline: 'none', border: 0 } }}
                className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'
              >
                {
                  topBranchesdata?.map((x: any) => {
                    return (
                      <option value={30} className='!font-Manrope !font-[500] !text-[14px] !leading-[20px]'>{x.State}</option>
                    )
                  })
                }

              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <table className='w-full'>
        <thead>
          <tr className="border-b-2">
            <th className='font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px] text-left pb-[8px]'>Branch Name</th>
            <th className='font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px] text-left pb-[8px]'>Orders</th>
            <th className='font-Manrope font-[600] text-[12px] leading-[18px] text-[#84818A] tracking-[0.5px] text-left pb-[8px]'>Revenue</th>
          </tr>
        </thead>
        <tbody>
          {
            topBranchesdata?.map((x: any) => {
              return (
                <tr>
                  <td className='font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px] py-[12px]'>{x["Branch Name"]}</td>
                  <td className='font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]'>{x.Orders}</td>
                  <td className='font-Manrope font-[500] text-[14px] leading-[20px] text-[#2E2C34] tracking-[0.5px]'>{x.Revenue}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default TableComponets
