import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const GetTallyCredentials: any = createAsyncThunk('GetTallyCredentials', async (data: any, { rejectWithValue }) => {
  try {
    const result: any = await api('api/wholeseller/tally', 'get');
    return result.data
  }
  catch (error) {
    return error
  }
})

type initialStateType = {
  data: any,
  loading: boolean,
  error: any,
}

const initialState:initialStateType = {
    data: [],
    loading: false,
    error: null,
}

export const TallyServiceData = createSlice({
  name: 'TallyService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetTallyCredentials.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetTallyCredentials.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetTallyCredentials.rejected, (state, action) => {
        state.loading = false
      })
  }
})