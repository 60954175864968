import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from '../../Axios/api'

export const GetdistrictList: any = createAsyncThunk('GetdistrictList', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/district/`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const GetdistrictListFromState: any = createAsyncThunk('GetdistrictListFromState', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/g-dist/?ids=${data}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

export const GetdistrictListFromStateMultiple: any = createAsyncThunk('GetdistrictListFromStateMultiple', async (data: any, { rejectWithValue }) => {
  try {
    const responce: any = await api(`api/location/g-dist/?ids=${data}`, 'get')
    return responce.data
  }
  catch (error) {
    return rejectWithValue(error)
  }
})

type initialStateType = {
  data: any,
  multipleData:any,
  loading: boolean,
  error: any,
}

const initialState: initialStateType = {
  data: [],
  multipleData: [],
  loading: false,
  error: null,
}

export const DistrictListReducers = createSlice({
  name: 'city_list',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetdistrictList.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetdistrictList.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
    builder.addCase(
      GetdistrictList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder.addCase(
      GetdistrictListFromState.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetdistrictListFromState.fulfilled, (state, action) => {
        state.loading = false
        let tmpArr: any = []
        if (Array.isArray(action.payload.results)) {
          action.payload.results.forEach((arr: any) => {
            tmpArr = tmpArr.concat(arr.district)
          })
        }
        // state.data = action.payload.results[0]?.district
        state.data = tmpArr
      })
    builder.addCase(
      GetdistrictListFromState.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

    builder.addCase(
      GetdistrictListFromStateMultiple.pending, (state, action) => {
        state.loading = true
      })
    builder.addCase(
      GetdistrictListFromStateMultiple.fulfilled, (state, action) => {
        state.loading = false
        let tmpArr: any = []
        if (Array.isArray(action.payload.results)) {
          action.payload.results.forEach((arr: any) => {
            tmpArr = tmpArr.concat(arr.district)
          })
        }
        state.multipleData = tmpArr
      })
    builder.addCase(
      GetdistrictListFromStateMultiple.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})